import React, {useCallback, useEffect, useRef} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {api} from '../System/api';
//import {arrDiff, arrUnique, multiGetReducer} from '../System/helpers';
//import {useDataStore} from '../System/DataStore';
//import categoriesStoreParams from '../Interests/storeParams';
//import {loyaltyLevels as loyaltyLevelsStoreParams} from '../Loyalty/StoreParams';
//import usePrevious from '../Hooks/usePrevious';
//import {useAppEvent} from '../System/AppEvent';
import {GUIDE_VERSION} from '../AppGuide/GuideData';
import {fromBase64} from 'js-base64';
import moment from 'moment';
import settings from '../Defaults/settings';

import * as userProfile from './reducers/userProfileSlice';
import * as accommodation from './reducers/accommodationSlice';
import * as bookmarks from './reducers/bookmarksSlice';
import * as loyaltyLevels from './reducers/loyaltyLevelsSlice';
import * as auth from './reducers/authSlice';
import * as nav from './reducers/navigationSlice';
import * as appEvent from './reducers/appEventSlice';
import * as draftBooking from './reducers/draftBookingSlice';
import {useDispatch, useSelector} from 'react-redux';
import WelcomeBonusModal from '../Marketing/WelcomeBonusModal';
import {isObject} from '../System/helpers';


const GlobalStoreController = ({children}) => {
  //const [jwtToken, setJwtToken] = useState();

  const dispatch = useDispatch();
  const checkAuthInterval = useRef();
  const accommodationCheckerRef = useRef();
  const welcomeBonusRef = useRef();
  const draftBookingCheckRef = useRef();

  __DEV__ && console.log('' +
    '\n===================================================' +
    '\n========= UPDATE GLOBAL STORE CONTROLLER ==========' +
    '\n==================================================='
  );

  const prevAuthState = useSelector(auth.selectPrevState);
  const authState = useSelector(auth.selectState);
  const firstLogin = useSelector(auth.selectFirstLogin);
  const jwtToken = useSelector(auth.selectJWT);
  const event = useSelector(appEvent.select);
  const lastDraftBooking = useSelector(draftBooking.selectLast);
  const accommodationData = useSelector(accommodation.select);
  const currentRoute = useSelector(nav.selectCurrentRoute);

  /*useEffect(()=>{
    console.log('change lastDraftBooking:', lastDraftBooking);
  }, [lastDraftBooking]);*/

  //console.log('auth state', authState);

  //const _userInterests = useDataStore(userInterestsStoreParams);
  //const accommodation = useDataStore(accommodationStoreParams);
  //const userProfile = useDataStore(userProfileStoreParams);
  //const loyaltyLevels = useDataStore(loyaltyLevelsStoreParams);

  /* Workaround */
  const checkJWTState = jwt => {
    if (jwt) {
      try {
        jwt = jwt.split('.');
        let jwtInfo = fromBase64(jwt[1]);
        jwtInfo = JSON.parse(jwtInfo);
        let timeCreated = moment.unix(jwtInfo.iat);
        //last generation Jun 20, 2023
        if (timeCreated.isBefore(moment('2023-06-21'))) {
          return false;
        }
        //let jwtCreatedAgo = moment().diff(timeCreated,'days');
        //console.log('jwt info', jwtInfo, timeCreated.format('YYYY-MM-DD HH:mm:ss'), jwtCreatedAgo);
        /*if (Math.abs(jwtCreatedAgo) > 5 ) {
          return false;
        }*/
      } catch (e) {
        __DEV__ && console.warn('cant extract jwt info');
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (authState === 'auth') {
      /*checkAuthInterval.current = setInterval(() => {
        if (authState !== 'skip') {
          __DEV__ && console.log('Global jwt state: ' + jwtToken);
          //checkAuth();
          dispatch(userProfile.getValue()).then();
        }
      }, 600000);
      return () => clearInterval(checkAuthInterval.current);*/
    }
  }, [/*auth*/]);

  /* Default values */

  useEffect(() => {
    //AsyncStorage.removeItem('loyaltyLevels');
    onInit();

    /*AsyncStorage.multiGet(['JWT', 'authState', 'concierge']).then(values => {
      const {JWT, authState, concierge} = multiGetReducer(values);
      setJwtToken(JWT);
      setAuthState(authState);
      setIsExternal(concierge === '1');
    });*/
  }, []);

  useEffect(() => {
    firstLogin === true && onFirstLogin();
  }, [firstLogin]);

  useEffect(()=>{
    /*TODO: IS TEMPORARY WAY*/
    accommodationCheckerRef.current
    && clearInterval(accommodationCheckerRef.current);
    if (accommodationData.value && authState === 'auth') {
      accommodationCheckerRef.current = setInterval(() => {
        api.user.accommodationChanged().then(res => {
          if (res.data.changed) {
            dispatch(accommodation.reloadValue());
          }
        }).catch(e=>{
          __DEV__ && console.warn('cant check isChanged');
        });
      }, 60000);
    }
    return ()=>{
      clearInterval(accommodationCheckerRef.current);
    };
  }, [authState, accommodationData]);

  /** APP EVENTS */
  const onFirstLogin = () => {
    settings.enableWelcomeBonus &&
    setTimeout(()=>{
      dispatch(appEvent.newEvent('showWelcomeBonus'));
    }, 3000);
  };

  const onAfterAuth = () => {
    dispatch(bookmarks.init()).unwrap().then(res=>{
      __DEV__ && console.log('new bookmarks is:', res);
    });
    checkDraftBookings();
    checkPreviousPage();
  };

  const onAuthorized = async () => {
    dispatch(userProfile.getValue()).then();
    //accommodation.accommodationGetValue();
    //loyaltyLevels.loyaltyLevelsGetValue();
  };

  const onAfterSkip = () => {
    __DEV__ && console.log('OnAfterSkipAuth');
    dispatch(userProfile.resetValue());
    dispatch(accommodation.resetValue());
    checkGuide();
  };

  const onInit = () => {
    dispatch(auth.getState()).then();
    dispatch(loyaltyLevels.getValue()).then();
    /*TODO: only for testing V */
    //checkDraftBookings();
  };

  const onAfterLogout = () => {
    dispatch(bookmarks.clear());
    clearInterval(accommodationCheckerRef.current);
    clearInterval(draftBookingCheckRef.current);
  };
  /** ^APP EVENTS^ */

  useEffect(() => {
    __DEV__ && console.log('auth state:' + prevAuthState + '=>' + authState);
    if (authState === 'auth') {
      onAuthorized();
    }
    //console.log({prevAuthState, authState});
    if (prevAuthState === 'login' && authState === 'auth') {
      onAfterAuth();
    }
    if (prevAuthState === 'auth' && (authState === 'login' || authState === 'skip')) {
      onAfterLogout();
    }
    if (
        (prevAuthState === 'login' || prevAuthState === 'auth')
        && authState === 'skip'
      ){
      onAfterSkip();
    }
  }, [authState, prevAuthState]);


  useEffect(() => {
    //console.log({currentRoute});
    if (!settings.enableAuthSkip
      && (
        authState !== 'auth'
      )
    ){
      dispatch(auth.logout());
      if (currentRoute.name !== 'Auth' && currentRoute.ready){
        //console.log({currentRoute});
        dispatch(nav.setAction({type:'reset', route: 'Auth'}));
      }
    }
  }, [dispatch, authState, currentRoute]);

  const checkGuide = () => {
    settings.enableGuidePopup &&
    AsyncStorage.getItem('app_guide_skip').then(item => {
      if (item !== GUIDE_VERSION) {
        setTimeout(
          ()=>{
            dispatch(appEvent.newEvent('showGuide'));
          }
          ,300
        );
      }
    });
  };

  const checkDraftBookings = useCallback(()=>{
    if (lastDraftBooking && !lastDraftBooking?.touched) {
      if (lastDraftBooking?.navParams) {
          draftBookingCheckRef.current = setInterval(()=>{
            /*navigation.navigate(
              lastDraftBooking.navParams.screen,
              lastDraftBooking.navParams.params
            );*/
            dispatch(nav.setAction({
              type:'reset',
              route: lastDraftBooking.navParams.screen,
              params: lastDraftBooking.navParams.params,
            }));

            dispatch(draftBooking.touch(lastDraftBooking?.offer?.id));
          }, 500);
      }
    }
    return () => {
      clearInterval(draftBookingCheckRef.current);
    };
  }, [lastDraftBooking]);


  const checkPreviousPage = useCallback(()=>{
    AsyncStorage.getItem('PREV_NAV_STATE').then(json_nav_state => {
      if (!json_nav_state) {return;}
      let nav_state = JSON.parse(json_nav_state);
      if (isObject(nav_state) && nav_state?.routes?.length > 0) {
        dispatch(nav.setAction({
          type: 'replace',
          routes: nav_state.routes,
        }));
        AsyncStorage.removeItem('PREV_NAV_STATE');
      }
    });
  },[authState]);

  useEffect(()=>{
    __DEV__ && console.log('new app event:',event);
    if (event.name === 'unAuthorized' && authState !== 'login') {
      __DEV__ && console.log('UNAUTHORIZED Event', event);
      if (settings.enableAuthSkip) {
        dispatch(auth.setState('skip'));
        auth.removeJwtToken();
      } else {
        dispatch(auth.setState('login'));
        auth.removeJwtToken();
        //navigation.reset({index: 0, routes: [{name: 'Auth'}]});
        dispatch(nav.getCurrentRoute()).unwrap().then((_currentRoute)=>{
          //console.log({_currentRoute});
          _currentRoute.name !== 'Auth'
          &&
          dispatch(nav.setAction({type:'reset', route: 'Auth'}));
        });
      }
    }
    if (event.name === 'showWelcomeBonus') {
      welcomeBonusRef.current?.show();
    }
  }, [event]);

  const getUserInterests = () => {
    return new Promise((resolve, reject) => {
      AsyncStorage.getItem('_userInterests').then(async interests => {
        if (
          interests &&
          interests.length > 0 &&
          interests.split(',').length > 0
        ) {
          resolve(interests.split(','));
        } else {
          let _auth = await dispatch(auth.getState()).unwrap();
          if (_auth.state !== 'auth') {
            return resolve([]);
          }
          api.user
            .getInterests()
            .then(res => {
              let interestsLoaded = res.data.interests.map(i => i.id);
              AsyncStorage.setItem(
                '_userInterests',
                interestsLoaded.join(','),
              ).then(() => {
                //_setUserInterests(interestsLoaded);
                resolve(interestsLoaded);
              });
            })
            .catch(er => reject(er));
        }
      });
    });
  };

  useEffect(() => {
    __DEV__ && console.log('jwt token CHANGED: ' + jwtToken);

    let onAuthFail = () => {
      if (settings.enableAuthSkip) {
        dispatch(auth.setState('skip'));
        auth.removeJwtToken();
      } else {
        dispatch(auth.setState('login'));
        auth.removeJwtToken();
        //navigation.reset({index: 0, routes: [{name: 'Auth'}]});
        dispatch(nav.getCurrentRoute()).unwrap().then((_currentRoute)=>{
          _currentRoute.name !== 'Auth'
          &&
          dispatch(nav.setAction({type:'reset', route: 'Auth'}));
        });
      }
    };

    if (!jwtToken) {
      onAuthFail();
    } else {
      if (checkJWTState(jwtToken)){
        dispatch(userProfile.getValue(true))
          .unwrap()
          .then(res=>{
            //console.log('try to auth', res);
            dispatch(auth.setState('auth'));
          })
          .catch(err=>{
            //console.warn('auth error:', err);
            onAuthFail();
          });
      } else {
        __DEV__ && console.log('JWT state fails');
        onAuthFail();
      }
    }
  }, [jwtToken]);

  return (
    <>
      <WelcomeBonusModal ref={welcomeBonusRef}/>
    </>
  );
};

export default GlobalStoreController;
