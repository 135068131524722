import chroma from 'chroma-js';
import {api} from '../System/api';
import settings from '../Defaults/settings';
//import defaultImg from '../../img/DefaultCategory3.png';

const defaultImg = require('./../../img/DefaultCategory3.png');
const defaultColor = '#dfffe1';

const categoryAdapter = (data) => {
  let color = chroma?.valid(data.color) ? data.color : defaultColor;
  return (
    {
      ...data,
      title: data?.name,
      color,
      darkColor: chroma(color).darken(1.5).saturate(4).hex(),
      ...prepareImage(data),
    }
  );
};

const prepareImage = (data) => {
  let uri = data?.image ? api.baseurl + data?.image.trim('/') : null;
  let isValid = data?.image && /^\/uploads\/category\/.+?\.\w+?$/.test(data?.image);

  let images = {
    image: isValid ? {uri} : defaultImg,
    //image_sm: isValid ? {uri} : defaultImg,
    //image_md: isValid ? {uri} : defaultImg,
    image_sm: isValid ? {
        uri: uri.replace(/(\.\w+?)$/, '.min$1'),
      } : defaultImg,
    image_md: isValid ? {
      uri: uri.replace(/(\.\w+?)$/, '.med$1'),
    } : defaultImg,
  };
  if (!settings?.enableUseCompressedImages){
    images.image_sm = images.image;
    images.image_md = images.image;
  }
  return images;
};

export default categoryAdapter;
