import React, {useEffect, useRef} from 'react';
import {View, StyleSheet} from 'react-native';
import SimpleBottomSheet from './SimpleBottomSheet';
import AppText from '../Basic/AppText';
import {useDispatch, useSelector} from 'react-redux';
import * as _appEvent from '../GlobalStore/reducers/appEventSlice';
import NewButton from '../Basic/NewButton';
import * as auth from '../GlobalStore/reducers/authSlice';

const AuthReqBottomSheet = () =>
{
  const ref = useRef();
  const event = useSelector(_appEvent.select);
  const dispatch = useDispatch();

  useEffect(()=>{
    if (event.name === 'openAuthReq') {
      ref.current?.open();
    }
  }, [event]);

  const handleAuth = () => {
    dispatch(auth.logout());
    dispatch(auth.skipToLogin(true));
    ref.current?.close();
  };

  return (
    <SimpleBottomSheet ref={ref} lastPoint={'40%'}>
      <View style={styles.mainBox}>
        <AppText style={styles.title}>
          {'Для продолжения необходима авторизация'}
        </AppText>
        <AppText style={styles.mainText}>
          {'Пожалуйста авторизуйтесь, чтобы воспользоваться всеми функциями приложения'}
        </AppText>
      </View>
      <View style={styles.buttonBox}>
        <NewButton
          style={styles.button}
          onPress={handleAuth}
        >
          {'К авторизации   >'}
        </NewButton>
      </View>
    </SimpleBottomSheet>
  );
};

const styles = StyleSheet.create({
  mainBox: {
    padding: 20,
    flex: 1,
  },
  title: {
    fontSize: 20,
    color: '#262626',
  },
  mainText: {
    paddingTop: 18,
    color: '#424242',
  },
  buttonBox: {
    flex: 1,
  },
  button: {},
});

export default  AuthReqBottomSheet;
