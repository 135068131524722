import React, {lazy, Suspense, useImperativeHandle, useRef, useState, forwardRef, createContext} from 'react';
import {Platform, TouchableOpacity, View, StyleSheet, ActivityIndicator} from 'react-native';
import AppText from '../Basic/AppText';
import SimpleBottomSheet from './SimpleBottomSheet';
//import Map from '../Map/Map';
import {Icon} from '@rneui/themed';
import {defaultStyles} from '../Defaults/Styles';

const MapComponent = lazy(() =>
  import('../Map/Map.js'),
);

const MapBottomSheet = forwardRef((props, ref) => {
  const [geoTracking, setGeoTracking] = useState(false);
  const [geoObject, setGeoObject] = useState();
  const mapRef = useRef();
  const sheetRef = useRef();

  useImperativeHandle(ref, () => ({
    ...sheetRef.current,
    showPlaces: geo => {
      //console.log('set geolocations', geo);
      if (!Array.isArray(geo)) {
        geo = [geo];
      }
      geo = geo.map(({lon, lat, id, title}, key) => ({
        name: title,
        point: {lon, lat},
        id: id || key,
        show: true,
      }));
      setGeoObject(geo[0]);
      setTimeout(() => {
        mapRef.current?.setMarkers(geo);
        mapRef.current?.fitByGeo(geo);
      }, 300);
    },
  }));

  return (
    <SimpleBottomSheet
      ref={sheetRef}
      lastPoint={'85%'}
      //panDownToClose={false}
      panningGesture={false}
      onOpen={() => setGeoTracking(true)}
      onClose={() => setGeoTracking(false)}
      index={0}>
      <View
        style={{height: Platform.OS === 'web' ? 'inherit' : '100%', bottom: 0}}>
        <Suspense fallback={
          <View style={styles.loader}>
            <ActivityIndicator size={'large'}/>
          </View>
        }>
          <MapComponent ref={mapRef} geoTracking={geoTracking} />
        </Suspense>
      </View>
      {geoObject
        ? <View style={styles.titleContainer}>
          <TouchableOpacity
            onPress={()=>{
              mapRef.current?.setMapCenter(geoObject.point, 16);
            }}
            style={styles.titleBox}
          >
            <View style={styles.titleIcon}>
              <Icon
                name={'location-on'}
                type={'material'}
                color={'#bdbdbd'}
              />
            </View>
            <AppText style={styles.titleText}>{geoObject.name}</AppText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={()=>{
              mapRef.current?.buildRouteTo(geoObject.point);
            }}
            style={styles.titleIcon}>
            <Icon
              name={'route'}
              type={'font-awesome-5'}
              color={'#646464'}
            />
          </TouchableOpacity>
        </View>
        : null
      }
    </SimpleBottomSheet>
  );
});

const MapContext = createContext();

const MapProvider = ({mapRef, children}) => {
  const mapSheetRef = useRef();

  let _mapRef = mapRef || mapSheetRef;

  const openMapWithGeo = geo => {
    _mapRef.current.open();
    _mapRef.current.showPlaces(geo);
  };

  const settings = {
    openMapWithGeo,
  };

  return (
    <MapContext.Provider value={settings}>
      {children}
      {!mapRef ? <MapBottomSheet ref={mapSheetRef}/> : null}
    </MapContext.Provider>
  );
};

export {MapProvider, MapContext};
export default MapBottomSheet;


const styles = StyleSheet.create({
  titleContainer: {
    position: 'absolute',
    width: '90%',
    borderRadius: 50,
    backgroundColor: '#fff',
    bottom: 35,
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    paddingHorizontal: 20,
    ...defaultStyles.buttonShadow,
  },
  titleBox: {
    flexDirection: 'row',
  },
  titleIcon: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  titleText: {
    fontSize: 20,
    color: '#818181',
  },
});
