import React, {lazy, memo} from 'react';
import LazyPage from '../Basic/LazyPage';

const Component = lazy(() =>
  import('./StoryGallery.js'),
);

const Lazy = memo(() =>
  <LazyPage Component={Component} />, ()=>true);

export default Lazy;
