import moment from 'moment';

const calendarRestrictionAdapter = ({workDates, notWorkDates, value, allowRangeSelection}) => {
  let dayMarker, disabledDates = [], minDate, maxDate;
  if (workDates && Object.keys(workDates).length > 0) {
    //console.log('workDates:', workDates);
    let dateArr = Object.keys(workDates);
    minDate = moment('2970-01-01');
    maxDate = moment('1970-01-01');
    for (let i in dateArr){
      let date = moment(dateArr[i]);
      minDate = (date.isBefore(minDate) ? date : minDate);
      maxDate = (date.isAfter(maxDate) ? date : maxDate);
    }

    dayMarker = moment(value[0]).isBefore(minDate) ? minDate.format('YYYY-MM-DD') : value[0];
    dayMarker = moment(value[0]).isAfter(maxDate) ? maxDate.format('YYYY-MM-DD') : dayMarker;
    value = [
      dayMarker,
      allowRangeSelection ? moment(value[1]).isAfter(maxDate) ? maxDate : value[1] : null,
    ];
    if (moment(minDate) && moment(maxDate)) {
      let disableCursor = moment(minDate).clone();
      let diff = maxDate.diff(moment(minDate), 'days');
      let _disabledDates = [];
      for (let i = 1; i < diff; i++){
        let date = disableCursor.add(1,'d');
        if (!dateArr.includes(date.format('YYYY-MM-DD'))) {
          disabledDates.push(date.format('YYYY-MM-DD'));
        }
      }
    }
  } else {
    minDate = moment();
    maxDate = moment();//.add(1, 'month');
  }
  if (notWorkDates) {
    disabledDates = disabledDates.concat(Object.keys(notWorkDates).map(v => moment(v)));
  }

  return {minDate, maxDate, disabledDates, value};
};

export default calendarRestrictionAdapter;
