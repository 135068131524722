import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Image, View, StyleSheet, Platform} from 'react-native';
import Modal from 'react-native-modal';
import coinsImage from '../../img/Coins.png';
import AppText from '../Basic/AppText';
import AppButton from '../Basic/AppButton';
import {useDispatch} from 'react-redux';
import * as auth from '../GlobalStore/reducers/authSlice';
import settings from '../Defaults/settings';

const numFmt = (n) => (Intl.NumberFormat('ru-RU',{maximumFractionDigits: 0}).format(n));

const WelcomeBonusModal = forwardRef((props, ref) =>
{
  const [bonusValue, setBonusValue] = useState(5000);
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();

  useImperativeHandle(ref,
    ()=>({
      toggle: () => { setModalVisible(v=>!v); },
      show: () => { setModalVisible(true); },
      hide: () => { setModalVisible(false); },
    })
  );

  const handleClose = () => {
    setModalVisible(false);
    dispatch(auth.setFirstLogin(false));
  };

  return (<View>
    <Modal isVisible={modalVisible} >
      <View style={styles.modalContainer}>
        <View
          style={[
            styles.widgetContainer,
            styles.wbBox,
          ]}
        >
          <Image
            source={coinsImage}
            style={styles.wbCoinsImage}
            resizeMode={'contain'}
          />
          <View style={[styles.widgetLeft]}>
            <AppText style={styles.wbTitle}>{settings.orgName + ' любит Вас!'}</AppText>
            <AppText style={[styles.wbText, {marginBottom: 10}]}>
              {'За регистрацию Вам начислено'}
              <AppText style={{fontSize: 20}}>
                {'\n' + numFmt(bonusValue) + ' бонусов!'}
              </AppText>
            </AppText>
            <AppText style={styles.wbText}>{'Используйте при оплате первого бронирования'}</AppText>
            <AppText style={[styles.wbText, styles.wbTextBold]}>{'Отдыхайте с нами чаще!'}</AppText>
          </View>
          <AppButton
            onPress={handleClose}
            title={'Хорошо'}
            style={styles.wbBonusButton}
          />
        </View>
      </View>
    </Modal>
  </View>);
});

let styles = StyleSheet.create({
  //welcome bonus
  modalContainer: {
    ...(Platform.OS === 'web' ?
      {
        maxWidth: 500,
        margin: 'auto',
      } : {}),
    borderRadius: 16,
    justifyContent: 'center',
    width: '100%',
    //height: 350,
  },
  wbBox: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 16,
  },
  wbBonusButton: {
    width: '80%',
    marginTop: 40,
  },
  wbTitle: {
    paddingBottom: 15,
    paddingTop: 15,
    fontSize: 21,
    fontWeight: '500',
  },
  wbText: {
    fontSize: 14,
    paddingTop: 7,
    textShadowColor: 'white',
    textShadowRadius: 1,
    textShadowOffset: {width: 1, height: 1},
  },
  wbCoinsImage: {
    height: 220,
    width: 230,
    opacity: 0.3,
    position: 'absolute',
    right: -30,
    top: 0,
  },
  wbTextBold: {
    fontWeight: 'bold',
  },
  widgetLeft:{
    width: '75%',
    marginRight: 4,
    paddingLeft: 7,
    //height: 50,
  },
  widgetRight:{
    flexDirection: 'column',
    width: '24%',
    marginLeft: 4,
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  widgetContainer:{
    padding: 12,
    paddingBottom: 22,
    paddingTop: 24,
  },
});

export default WelcomeBonusModal;
