import React, {useEffect, useState} from 'react';
import {ActivityIndicator, SafeAreaView, View, StyleSheet, Platform, Linking} from 'react-native';
import {WebView} from 'react-native-webview';
import PageHeader, {useGoBack} from '../Basic/PageHeader';
import {useToast} from 'react-native-toast-notifications';
import {api} from '../System/api';
import {decode} from 'js-base64';
import AppText from '../Basic/AppText';
import {useBottomSheetModal} from '@gorhom/bottom-sheet';
//import NewButton from '../Basic/NewButton';
import Link from '../Basic/Link';
import {tempBookingsGet} from '../Booking/tempBookingStorage';
import {getMinWidth} from '../Defaults/DisplayWidth';
import {useNavigation, useRoute} from '@react-navigation/native';
import moment from 'moment';

const windowWidth = getMinWidth();

const paymentTimeoutMin = 29;

const LinkPayment = () =>
{
  const navigation = useNavigation();
  const route = useRoute();
  const {booking_id, link} = route.params;
  //console.log('ROUTE PARAMS:',route.params);
  const goBack = useGoBack();

  const [loadStatus, setLoadStatus] = useState('loading');
  const [viewLoading, setViewLoading] = useState(true);
  const [paymentLink, setPaymentLink] = useState(null);
  const [paymentUntil, setPaymentUntil] = useState(null);
  let toast = useToast();
  let bottomSheet = useBottomSheetModal();

  const openIndependentPageOnWeb = (url) => {
    return;
    if (Platform.OS === 'web'){
      setLoadStatus('open_external');
      setViewLoading(false);
      try {
        Linking.openURL(url);
        //window.location.replace(url);
        goBack();
      } catch (e) {}
    }
  };


  useEffect(()=>{
    bottomSheet.dismissAll();
    let onLink = (_link) => {
      setPaymentLink(_link);
      setLoadStatus('success');
      openIndependentPageOnWeb(_link);
    };
    let onPaymentFail = (err = null) => {
      __DEV__ && console.log('error', err);
      setViewLoading(false);
      setLoadStatus('fail');
    };
    if (link) {
      __DEV__ && console.log('PAYMENT LINK is :',link);
      onLink(decode(link));
      return;
    }
    let getPaymentLinkViaApi = () => {
      api.bookings
      .setId()
      .list({id: booking_id})
      .then((res)=>{
        let booking = res.data[0];
        __DEV__ && console.log({booking});
        if (booking?.paymentUntil) {
          let _paymentUntil = moment(booking.paymentUntil);
          setPaymentUntil(_paymentUntil);
          if (_paymentUntil.isBefore(moment())){
            setLoadStatus('payment_expired');
            return;
          }
        } else {
          __DEV__ && console.warn('param "Payment until" is invalid', booking?.paymentUntil);
        }
        if (booking.paymentLink) {
          onLink(booking.paymentLink);
        } else {
          api.bookings
          .setId()
          .getPaymentLink({booking_id})
          .then(res => {
            onLink(res.data?.formUrl);
          }).catch(err=>{
            onPaymentFail(err);
          });
          //setPaymentUntil(booking.date);
        }
      })
      .catch((e)=>{
        onPaymentFail(e);
      });
    };

    tempBookingsGet({id: booking_id}).then(booking => {
      if (Object.keys(booking).length === 0){
        getPaymentLinkViaApi();
      } else {
        setPaymentLink(booking.paymentLink);
        setLoadStatus('success');
        openIndependentPageOnWeb(booking.paymentLink);
      }
    }).catch(e => {
      getPaymentLinkViaApi();
    });
  }, []);

  const messageHandler = (event) => {
    let message = event.nativeEvent.data;
    try {
      const {type, command} = JSON.parse(message);
      __DEV__ && console.log('MESSAGE:', message);
      if (type === 'payment_frame_cmd'){
        switch (command){
          case 'payment_fail': {
            break;
          }
          case 'payment_success': {
            __DEV__ && console.log('payment is success');
            break;
          }
          case 'goback' : {
            navigation.goBack();
          }
        }
      }
    } catch (e) {
      __DEV__ && console.info('message format is invalid: ',message);
    }
  };

  const showSuccessPopup = () => {
    toast.show('Успешная оплата');
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <PageHeader title={'Оплата заказа'} />
      {viewLoading !== false ?
        <View style={styles.loaderBox}>
          <AppText style={styles.loaderText}>
            Подождите немного {'\n'}
            Идет загрузка платёжного окна
          </AppText>
          <ActivityIndicator size={'large'}/>
        </View>
        : null
      }
      {loadStatus === 'success'
        ? <WebView
            style={{
              //flex: 1,
              width: windowWidth,
            }}
            source={{uri:paymentLink}}
            onNavigationStateChange={request => {}}
            javaScriptEnabled={true}
            injectedJavaScriptBeforeContentLoaded={`(function() {
              window.postMessage = function(data) {
                window.ReactNativeWebView.postMessage(data);
              };
              window.top.postMessage = function(data) {
                window.ReactNativeWebView.postMessage(data);
              };
            })()`}
            onMessage={messageHandler}
            onLoad={(data) => {
              setViewLoading(false);
            }}
            originWhitelist={['*']}
            cacheEnabled={false}
            scalesPageToFit={true}
            //scalesPageToFit={false}
            nestedScrollEnabled={true}
            //automaticallyAdjustContentInsets={false}
            scrollEnabled={true}
          />
        : null}
      {loadStatus === 'open_external'
        ? <View style={styles.failBox}>
          <AppText style={[styles.failText, {textAlign: 'center'}]}>
            {'Платёжное окно открыто на другой странице,' +
             '\nесли этого не произошло,' +
             '\nнажмите кнопку "Оплатить" ниже'}
          </AppText>
          <Link title={'Оплатить'} url={paymentLink} style={styles.paymentButtonText}/>
        </View>
        : null}
      {loadStatus === 'fail'
        ? <View style={styles.failBox}>
            <AppText style={styles.failText}>
              Произошла ошибка при загрузке платёжного окна
            </AppText>
          </View>
        : null}
      {loadStatus === 'payment_expired'
        ? <View style={styles.failBox}>
          <AppText style={styles.failText}>
            Истёк срок оплаты услуги
          </AppText>
        </View>
        : null}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  loaderBox: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderText: {
    textAlign: 'center',
    padding: 20,
    fontSize: 18,
    color: '#656565',
  },
  failBox: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  failText: {
    padding: 20,
    fontSize: 18,
  },
  paymentButton: {
    backgroundColor: 'rgba(34, 34, 34, 1)',
    paddingVertical: 14,
    paddingHorizontal: 25,
    minWidth: 220,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  paymentButtonText: {
    fontSize: 17,
    color: '#fff',
    textAlign: 'center',
  },
});

export default LinkPayment;
