import React from 'react';
import {View, StyleSheet} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AppText from '../Basic/AppText';
import {levelById} from './LevelsData';
import {Icon} from '@rneui/themed';


export const levelSizes = {
  large: 52,
  middle: 32,
  small: 22,
};

export const LoyaltyLevel = ({level, size}) => {
  size = levelSizes[size] || levelSizes.middle;
  level = level || 1;
  let lv = levelById(level);

  return (
    <View
      style={[
        styles.loyaltyLevelShadow,
        {shadowColor:lv.shadowColor},
        {height:size, width:size, maxWidth: size},
      ]}>
      <LinearGradient
        style={[
          styles.loyaltyLevelGradient,
          {height:size, width:size},
        ]} colors={lv.colors}>
        <View style={[
          styles.loyaltyLevelNum,
          {
            height:size * 0.8,
          },
        ]}>
          {lv.icon
            ? <Icon {...lv.icon} size={size * 0.414} />
            : <AppText style={[
              styles.loyaltyLevelNumText,
              {
                lineHeight: size * 0.514 * 1.38,
                fontSize: size * 0.514,
                height:size * 0.714,
              },
            ]}>
              {level}
            </AppText>}
        </View>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  loyaltyLevelNum:{
    borderRadius: 50,
    backgroundColor: '#fff',
    height: 27,
    aspectRatio: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loyaltyLevelShadow:{
    borderRadius: 50,
    shadowOpacity: 1,
    shadowOffset: {width: 0, height:3},
    shadowRadius: 3,
    elevation: 6,
  },
  loyaltyLevelGradient:{
    height: 35,
    width: 35,
    aspectRatio: 1,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loyaltyLevelNumText:{
    fontSize: 18,
    lineHeight: 25,
    fontWeight: '600',
    textAlign: 'center',
    textAlignVertical: 'center',
    height: 25,
  },
});
