import { configureStore } from '@reduxjs/toolkit';
import styleReducer from './reducers/styleSlice';
import statusBarReducer from './reducers/statusBarSlice';
import userInterestsReducer, {init as userInterestsInit} from './reducers/userInterestsSlice';
import storiesReducer from './reducers/storiesSlice';
import userProfileReducer from './reducers/userProfileSlice';
import accommodationReducer from './reducers/accommodationSlice';
import loyaltyLevelsReducer from './reducers/loyaltyLevelsSlice';
import authReducer, {init as authInit} from './reducers/authSlice';
import categoriesReducer, {init as categoriesInit} from './reducers/categoriesSlice';
import appEventReducer from './reducers/appEventSlice';
import bookmarksReducer from './reducers/bookmarksSlice';
import draftBookingReducer, {init as draftBookingInit} from './reducers/draftBookingSlice';
import navigationReducer from './reducers/navigationSlice';

const store = configureStore({
  reducer: {
    style: styleReducer,
    statusBar: statusBarReducer,
    userInterests: userInterestsReducer,
    stories: storiesReducer,
    userProfile: userProfileReducer,
    accommodation: accommodationReducer,
    loyaltyLevels: loyaltyLevelsReducer,
    auth: authReducer,
    categories: categoriesReducer,
    appEvent: appEventReducer,
    bookmarks: bookmarksReducer,
    draftBooking: draftBookingReducer,
    navigation: navigationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

store.dispatch(authInit());
store.dispatch(userInterestsInit());
store.dispatch(categoriesInit());
store.dispatch(draftBookingInit());

store.subscribe(() =>{
    //console.log('State was changed', {s:store.getState().userProfile});
  }
);

export default store;
