import React, {useCallback} from 'react';
import {api} from '../System/api';

import * as auth from '../GlobalStore/reducers/authSlice';
import * as bookmarks from '../GlobalStore/reducers/bookmarksSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useToast} from 'react-native-toast-notifications';
import {RegisterToast} from '../Notifications/ToastViews';
import {useNavigation} from '@react-navigation/native';

const useBookmarks = () => {

  const dispatch = useDispatch();
  const authState = useSelector(auth.selectState);
  const toast = useToast();
  const navigation = useNavigation();

  const toggleBookmark = useCallback((id, bookmark) => {
    dispatch(
      bookmarks.edit({
        id,
        action: bookmark ? '+' : '-',
      })
    )
    .then(()=>{
      if (authState === 'auth') {
        api.bookmarks
          .change({offer_id: id, bookmark})
          .then(() => {})
          .catch(e => console.error(e));
      } else {
        toast.hideAll();
        let messageId = toast.show(
          <RegisterToast
            onPress={() => {
              toast.hide(messageId);
              dispatch(auth.logout());
              //navigation.reset({index: 0, routes: [{name: 'Auth'}]});
            }}
          />,
          {
            title:
              'Зарегистрируйтесь, чтобы воспользоваться разделом "избранное"',
            type: 'default',
          },
        );
      }
    })
    .catch(e => console.error(e));
    return true;
  }, [authState]);

  return {toggleBookmark};
};

export default useBookmarks;
