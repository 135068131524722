import React, {createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {View} from 'react-native';
import Attribute from './Attribute';

const OfferAttributes = forwardRef(({offer, onChange}, ref) => {
  const {attributes} = offer;
  const [attrRefs, setAttrRefs] = useState(
    Array.from({length: Object.keys(attributes).length}, x => createRef()),
  );
  const [state, setState] = useState({});
  const changeAttribute = (props) => {
    setState(v => {
      v[props.key] = props;
      return {...v};
    });
  };
  useEffect(()=>{
    onChange(state);
  },[state]);

  useImperativeHandle(ref, ()=>({
    validate:()=>{
      return new Promise((resolve, reject)=>{
        let attrRef, _valid, result = {price:0, valid: true};
        try {
          for (let i in attrRefs) {
            attrRef = attrRefs[i];
            _valid = attrRef.current.validate();
            result = {
              price: result.price + _valid.price,
              valid: result.valid && _valid.valid,
            };
          }
        } catch (e) {
          return reject(e);
        }
        return resolve(_valid);
      });
    },
    clear:()=>{
      return new Promise((resolve, reject)=>{
        let attrRef, _valid;
        try {
          for (let i in attrRefs) {
            attrRef = attrRefs[i];
            _valid = _valid && attrRef.clearValidation();
          }
        } catch (e) {
          return reject(e);
        }
        return resolve();
      });
    },
  }), [attrRefs]);

  useEffect(()=>{
    if (attributes.length > attrRefs.length) {
      setAttrRefs(v => {
        return v.concat(
          Array.from({length: Object.keys(attributes).length - attrRefs.length}, x =>
            createRef(),
          ),
        );
      });
    }
  }, [attributes]);

  return (
    <View>
      {
        Object.values(attributes).map((item, key) =>
          <Attribute
            ref={attrRefs[key]}
            key={key}
            {...item}
            onChange={changeAttribute}
          />
        )
      }
    </View>
  );
});

export default OfferAttributes;
