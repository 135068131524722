import React, {memo, useMemo} from 'react';
import {View, StyleSheet, Platform, ScrollView} from 'react-native';
import AppText from '../Basic/AppText';
import offerAdapter from '../Offers/OfferAdapter';
import moment from 'moment';
import TariffsBookingView from '../Tariffs/TariffsBookingView';
import {BottomSheetScrollView} from '@gorhom/bottom-sheet';
//import OfferAttributes from '../Attributes/OfferAttributes';
import {AttributesLine} from '../Attributes/AttributesLine';
//import PrettyPrice from '../Basic/prettyPrice';
import {humanizePrice} from '../Cards/CardPrice';
import Performers from './Performers';
import outletAdapter from '../Outlet/OutletAdapter';
import {CardDescription} from '../Cards/CardElements';
import {StatusView} from '../Main/BookingStatus';

const ScrollViewComponent = Platform.OS === 'web' ? ScrollView : BottomSheetScrollView;



export const BookingDetailsMyRest = ({booking, state}) => {
  if (!booking) {
    __DEV__ && console.log('empty booking');
    return <></>;
  }
  let {offer, dateFrom, date, status, bookingOfferItems, full_price, ...other} = booking;
  let outlet = outletAdapter(offer.outlet);

  if (!offer) {
    __DEV__ && console.log('offer is not found');
    return <></>;
  }
  __DEV__ && console.log('outlet', offer.outlet);
  __DEV__ && console.log('booking', booking);
  __DEV__ && console.log('booking other', other);
  __DEV__ && console.log('offer', offer,dateFrom);
  offer = offerAdapter(offer);
  __DEV__ && console.log('offer Attributes', offer.attributes);

  return (
    <View style={styles.detailsBox}>
      <ScrollViewComponent style={{height:'100%'}} contentContainerStyle={{flex: 1}}>
        <CommonDetailTitle
          offerTitle={offer.title}
          dateFrom={dateFrom}
          fullPrice={full_price}
        />
        <View style={[styles.section]}>
          <FieldLabel text={'Место проведения'}/>
          <AppText style={styles.detailsOutletText}>{outlet?.name}</AppText>
          <CardDescription html={outlet.description} maxHeight={50} hide={true}/>
        </View>
        {/*{
          bookingOfferItems && bookingOfferItems?.length > 0
            ? <View style={styles.section}>
                <FieldLabel text={'Выбранные тарифы'}/>
                <TariffsBookingView tariffs={bookingOfferItems}/>
              </View>
            : null
        }*/}
        {
          offer?.attributes && Object.keys(offer.attributes).length > 0
            ? <View style={[styles.section]}>
              <FieldLabel text={'Выбранные опции'}/>
              <AttributesLine
                list={offer.attributes}
                showEmpty={true}
                itemStyle={{color: '#2f2f2f'}}
              />
            </View>
            : null
        }
        <StatusSection statе={state}/>
      </ScrollViewComponent>
    </View>
  );
};

export const StatusSection = ({state, paymentLink, bookingId}) =>
{
  console.log('STATE MDFK',state);
  return (<View style={[styles.section]}>
    <FieldLabel text={'Статус и оплата'}/>
    <StatusView {...state}/>
  </View>);
};

export const BookingDetailsTNG = ({booking, state}) =>
{
  let {
    id, treatmentStart, treatmentName, price, therapists,
    docId, facility, outlet, ...other} = booking;
    __DEV__ && console.log('TNG BOOKING data',booking);// return null;

  const _payment = useMemo(()=>{
    if (booking?.paymentData){
      return {
        ...booking.paymentData,
      };
    } else {
      return null;
    }
  }, [booking]);

  if (!booking) {
    __DEV__ && console.log('empty booking');
    return <></>;
  }

  return (
    <View style={styles.detailsBox}>
      <ScrollViewComponent style={{height:'100%'}} contentContainerStyle={{flex: 1}}>
        <CommonDetailTitle
          offerTitle={treatmentName}
          dateFrom={treatmentStart}
          fullPrice={price}
        />
        <View style={[styles.section]}>
          <FieldLabel text={'Место проведения'}/>
          <AppText style={styles.detailsOutletText}>{outlet.name}</AppText>
          {
            outlet.name !== facility
            ? <AppText style={styles.detailsFacilityText}>{facility}</AppText>
            : null
          }
        </View>
        {therapists && therapists.length > 0
          ? <View style={[styles.section]}>
              <FieldLabel text={'Исполнители'}/>
              <Performers values={therapists}/>
            </View>
          : null
        }
        {/*<View style={[styles.section]}>
          <FieldLabel text={'Бонусы'}/>
          <BookingBonuses bookingId={docId} value={null}/>
        </View>*/}
        <StatusSection state={state} payment={_payment} />
      </ScrollViewComponent>
    </View>
  );
};

const FieldLabel = ({text, children}) => {
  return <View style={styles.label}>
    <AppText style={styles.labelText}>{text || children}</AppText>
  </View>;
};

const CommonDetailTitle = ({dateFrom, offerTitle, fullPrice}) =>
{
  let fmt = 'YYYY-MM-DDTHH:mm:ssZZ';
  let currYear = moment().format('YYYY');
  let year = moment(dateFrom, fmt).format('YYYY');
  let dateOfService = moment(dateFrom, fmt).format('D MMM');
  let timeOfService = moment(dateFrom, fmt).format('HH:mm');

  return (
    <View style={[styles.title, styles.section]}>
      <AppText style={styles.titleText}>
        <AppText style={styles.timeText}>{timeOfService}</AppText>
        {'   '}{offerTitle}
      </AppText>
      <View style={styles.titleSecondLine}>
        <AppText style={styles.price}>
          {humanizePrice({price:fullPrice})}
        </AppText>
        <AppText style={styles.dateText}>
          {dateOfService}
          {year !== currYear ? year : null}
        </AppText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  detailsBox: {
    flex: 1,
  },
  section: {
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  title: {
  },
  titleText: {
    fontSize: 18,
    fontWeight: '400',
  },
  timeText: {
    fontWeight: '400',
    color: '#000',
    fontSize: 22,
  },
  label: {
    paddingBottom: 5,
  },
  labelText: {
    color: '#5e5e5e',
  },
  titleSecondLine: {
    flexDirection: 'row',
    marginTop: 12,
  },
  price: {
    flex: 1,
    fontSize: 14,
    fontWeight: '500',
  },
  dateText: {
    flex: 1,
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'right',
  },

  detailsOutletText: {
    marginBottom: 5,
  },
  detailsFacilityText: {
  },
});
