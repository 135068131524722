import React, {useContext, useEffect, useState} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Platform, StyleSheet} from 'react-native';

const preloadImage = require('./../../img/mr_view_blur.jpg');

const colors = {
  concierge: {
    mainLight: '#3ab4fc',
    main: '#25729D',
    mainTrDark: '#2543624D',
  },
  default: {
    bonus: '#00adad',
    itemDark: '#dedede',
    item: '#F6F6F6FF',
    itemActive: '#222222FF',
    mainLightPanel: '#FFEFE0FF',
    mainLight: '#FEE8D0',
    main: '#FDB161',
    mainTrDark: '#c96700',
  },
};

const FontFamily = 'Euclid Circular B';

const getThemedColors = (theme = 'default') => {
  return colors[theme] ? colors[theme] : colors.default;
};

const colorVar = async () => {
  let c = await AsyncStorage.getItem('concierge');
  return (c === '1')
    ? colors.concierge
    : colors.default;
};

const defaultStyles = {
  /*buttonShadow: {
    shadowOpacity: 0.4,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 2,
    elevation: 4,
  },*/
  buttonShadow:{
    shadowOpacity: 0.06,
    shadowColor: '#000',
    shadowOffset: {width: 0, height:4},
    shadowRadius: 2,
    elevation: 6,
    backgroundColor: '#fff',
  },
  /*cardShadow: {
    shadowOpacity: 0.8,
    shadowOffset: {width: 0, height: 0},
    shadowColor: 'rgb(115, 139, 186)',
    shadowRadius: 7,
    elevation: 4,
  },*/
  cardShadow: {
    shadowOpacity: 0.8,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'rgb(213,213,213)',
    shadowRadius: 4,
    elevation: 3,
    backgroundColor: '#fff',
  },
  webScrollView: {
    ...(Platform.OS === 'web' ?
    {
      height: 0,
      flexGrow: 1,//normally working scroll view in web
    }
    : {
      flex: 1,
    }),
  },
  fullScreenHeight: {
    //...(Platform.OS === 'web' ? {height: '100vh'} : {flex: 1}),
    //...(Platform.OS === 'web' ? {height: '100%'} : {flex: 1}),
    flex: 1,
  },
  borderSeparator: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgb(243,242,242)',
  },
  //highlighted text
  hlText:{
    color: colors.default.mainTrDark,
  },
};

const defaultStylesheet = StyleSheet.create(defaultStyles);

export {
  colors,
  getThemedColors,
  colorVar,
  defaultStylesheet,
  defaultStyles,
  preloadImage,
  FontFamily,
};

