import React, {useEffect, useRef, useState} from 'react';
import {
  ActivityIndicator,
  Image, Platform,
  SafeAreaView,
  ScrollView,
  SectionList,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import AppText from '../Basic/AppText';
import {colors, defaultStyles, FontFamily} from '../Defaults/Styles';
import {getMinWidth} from '../Defaults/DisplayWidth';
import {api} from '../System/api';
import {useGoBack} from '../Basic/PageHeader';
import offerAdapter from '../Offers/OfferAdapter';
import {humanizePrice} from '../Cards/CardPrice';
import TextInputTimeout from '../Basic/TextInputTimeout';
import outletAdapter from '../Outlet/OutletAdapter';
import activityAdapter from '../Activities/ActivityAdapter';
import AntiMenuFade from '../Basic/AntiMenuFade';
import moment from 'moment';
import {datesGenerator} from '../System/scheduleHelpers';
import {useNavigation} from '@react-navigation/native';
const windowWidth = getMinWidth();

const searchGroups = {
  Events: {
    name: 'Афиша',
  },
  Offer: {
    name: 'Услуги',
  },
  Autlet: {
    name: 'Объекты',
  },
};

const searchEmptyImage = require('../../img/SearchNotFound.png');

const search = (query) => {
  return new Promise((resolve, reject) => {
    api.search({query}).then(res=>{
      //console.log(res);
      /*for (let i in res.data){
        res.data[i] = res.data[i].filter(v => v.active !== false);
      }*/
      resolve(res.data);
    }).catch((err)=>{
      reject(err);
    });
  });
};

const Search = () =>
{
  const navigation = useNavigation();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState('All');
  const [prevQuery, setPrevQuery] = useState('');
  const [searchState, setSearchState] = useState();
  //const navigation = useNavigation();
  const goBack = useGoBack();
  const inputRef = useRef();

  const handleClose = () => {
    api.pending.abort('search');
  };

  useEffect(() => {
    return handleClose;
  }, []);

  const handlePressSearchItem = (type, {id, title, name}) => {
    switch (type) {
      case 'Events': {
        navigation.navigate('Activity', {id});
      } break;
      case 'Offer': {
        navigation.navigate('Offer', {id});
      } break;
      case 'Autlet': {
        navigation.navigate('Offers', {id, type:'outlet', title: name});
      } break;
    }
  };

  const research = (query) => {
    if (query === '' || query === null || query === undefined) {
      setSearchState('emptyQuery');
      return;
    }
    if (prevQuery === query) {
      setSearchState('ready');
      //console.log({prevQuery, query});
      return;
    }
    if (Platform.OS === 'web') {
      try {
        history.replaceState({}, 'Search', 'search?q=' + encodeURI(query));
      } catch (e){}
    }

    setItems([]);
    setPrevQuery(query);
    search(query).then(result=>{
      let sections = Object
        .keys(result)
        .reduce((a, v)=>{
          a.push({title:v, type:v, data: result[v]}); return a;
        }, []);
      setItems(sections);
      if (Object.values(result).reduce((a,v)=>(a += v.length), 0) > 0){
        setSearchState('ready');
      } else {
        setSearchState('empty');
      }
    }).catch(err=>{});
  };

  return (
    <SafeAreaView style={[styles.searchBox, {width: getMinWidth()}]}>
      <View style={styles.searchBar}>
        <TextInputTimeout
          autoFocus={true}
          clearButtonMode={'while-editing'}
          style={styles.searchTextInput}
          placeholder={'Я ищу'}
          placeholderTextColor={'rgba(150, 150, 150, 1)'}
          timeout={1500}
          onChangeText={(text)=>{
            //console.log('start change ', text);
            setSearchState('search');
          }}
          onChangeTextTimeout={(text)=>{
            //console.log('changed with timeout ', text);
            research(text);
          }}
        />
        <TouchableOpacity
          style={{flex:2, justifyContent: 'center', alignItems: 'flex-end'}}
          onPress={()=>{goBack();}}>
          <AppText style={{fontSize: 17, color:'rgba(167, 167, 167, 1)'}}>{'Отмена'}</AppText>
        </TouchableOpacity>
      </View>
      <View style={styles.contentBox}>
        <View style={styles.contentNav}>
          {searchState === 'ready' ? <SearchNav sections={items} onPress={setFilter}/> : null}
        </View>
        <View style={styles.contentList}>
          {searchState === 'search' ? <View><ActivityIndicator size={'small'}/></View> : null}
          {searchState === 'empty'
            ? <View style={styles.emptyListContainer}>
              <View style={styles.emptyListGroup}>
                <Image resizeMode={'contain'} source={searchEmptyImage} style={styles.emptySearchImage}/>
                <AppText style={styles.emptySearchText}>{'К сожалению, ничего не нашлось'}</AppText>
              </View>
            </View>
            : null
          }
          <SectionList
            style={styles.list}
            sections={items.filter(v=>(filter === 'All' || v.type === filter))}
            renderItem={data => <SearchItem {...data} onPress={handlePressSearchItem}/> }
            renderSectionHeader={({ section: { title } })=><View />}
            keyExtractor={(item)=>(item.type + '_' + item.id)}
            ListFooterComponent={()=><AntiMenuFade height={100} />}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  headerBox: {
    flexDirection: 'row',
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBar: {
    flexDirection: 'row',
    height: 48,
    paddingHorizontal: 16,
    borderBottomColor: 'rgb(240,240,240)',
    borderBottomWidth: 1,
  },
  mrIconImage: {
    width: 23, height: 30, alignSelf: 'center',
  },
  mrTextImage: {
    flex: 7, height: '33%',
  },
  mrIcon: {
    flex:1,
  },
  searchIcon: {
    flex:1,
  },
  searchTextInput: {
    flex: 6,
    fontSize: 17,
    fontFamily: FontFamily,
  },
  emptySearchImage: {
    height: 300,
    width: '70%',
    alignSelf: 'center',
  },
  emptySearchText: {
    fontWeight: '500',
    fontSize: 16,
    textAlign: 'center',
  },
  emptyListContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emptyListGroup: {
    flexDirection: 'column',
    width: '100%',
    marginTop: '-15%',
  },

  //search modal
  contentBox: {
    height: '100%',
    padding: 16,
  },
  list: {
    maxHeight: '100%',
  },
  searchBox: {
    ...defaultStyles.fullScreenHeight,
    backgroundColor: '#fff',
    width: windowWidth,
  },
  contentNav:{

  },
  contentList:{
    //height: '100%',
    ...defaultStyles.webScrollView,
  },

  //search items
  searchItem: {
    flexDirection: 'row',
    paddingVertical: 16,
    alignItems: 'stretch',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(248, 248, 248, 1)',
  },
  searchItemImage: {
    width: 52,
    height: 52,
    borderRadius: 8,
    backgroundColor: '#e5e5e5',
  },
  searchItemDescr: {
    flexDirection: 'column',
    justifyContent:'space-between',
    marginLeft: 8,
  },
  searchItemTextTop: {
    fontSize: 14,
    color:'#222222',
    fontWeight: '500',
    flex: 1,
  },
  searchItemTextMiddle: {fontSize: 12, color:'#888888', flex: 1},
  searchItemTextBottom: {fontSize: 13, color:'#FDB161', flex: 1},

  //search item offer
  searchItemOfferBottom: {
    flexDirection: 'row',
  },
  searchItemOfferPrice: {fontSize: 13, color:'#FDB161'},
  label:{
    paddingVertical: 1,
    paddingHorizontal: 6,
    backgroundColor:'#1eaad3',
    borderRadius: 8,
    marginLeft: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText : {
    fontSize: 9.5,
    textTransform: 'uppercase',
    color: '#fff',
    //textAlignVertical: 'center',
  },

  //seach nav
  searchNavList: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(248, 248, 248, 1)',
  },
  searchNavItem: {
    paddingTop: 16,
    marginRight: 16,
  },
  searchNavText: {
    marginBottom: 16,
    color: 'rgba(184, 184, 184, 1)',
  },
  searchNavTextActive: {
    color: '#000',
  },
  searchNavSelector: {
    height: 2,
    backgroundColor: 'rgba(253, 177, 97, 1)',
  },

  //activity times
  timesBox: {
    flexDirection: 'row',
  },
  timesItem: {
    padding: 2,
    paddingHorizontal: 8,
    borderRadius: 50,
    backgroundColor: colors.default.main,
    marginRight: 6,
  },
  timesItemText: {
    fontSize: 11,
    color: '#fff',
  },
});

const ActivityTimes = ({apiSchedule}) => {
  //let times = generateTimesToday(apiSchedule);
  let times = datesGenerator(apiSchedule, {from:moment(), to:moment().add(5,'days')});
  //console.log({times});
  return <View style={styles.timesBox}>
    {Object.values(times).map(item=>(
      <View key={item.date()} style={styles.timesItem}>
        <AppText style={styles.timesItemText}>
          {item.format('DD MMM HH:mm')}
        </AppText>
      </View>
    ))}
  </View>;
};

export const SearchItem = ({item, section:{type}, onPress}) => {
  switch (type) {
    case 'Events': {
      let activity = activityAdapter(item);
      //console.log(JSON.stringify(activity));
      return <TouchableOpacity
        style={styles.searchItem}
        onPress={()=>onPress(type, item)}
      >
        <View>
          <Image style={styles.searchItemImage} source={activity.preview}/>
        </View>
        <View style={styles.searchItemDescr}>
          <AppText style={styles.searchItemTextTop}>{activity.title}</AppText>
          <AppText style={styles.searchItemTextMiddle}>{'Расписание'}</AppText>
          <ActivityTimes size={'mini'} {...activity}/>
        </View>
      </TouchableOpacity>;
    }
    case 'Offer': {
      let offer = offerAdapter(item);
      return <TouchableOpacity
        style={styles.searchItem}
        onPress={()=>onPress(type, item)}
      >
        <View>
          <Image style={styles.searchItemImage} source={offer.mainImage}/>
        </View>
        <View style={styles.searchItemDescr}>
          <AppText style={styles.searchItemTextTop}>{offer.title}</AppText>
          <AppText style={styles.searchItemTextMiddle}>{offer.outlet.title}</AppText>
          <View style={styles.searchItemOfferBottom}>
            <AppText style={styles.searchItemOfferPrice}>{
              offer.price
              ? humanizePrice(offer.price, true)
              : 'бесплатно'
            }</AppText>
            {
              offer?.isUseSlots
                ? <View style={styles.label}>
                  <AppText style={styles.labelText}>{'Оплата онлайн'}</AppText>
                </View>
                : null
            }
          </View>
        </View>
      </TouchableOpacity>;
    }
    case 'Autlet': {
      let outlet = outletAdapter(item);
      return <TouchableOpacity
        style={styles.searchItem}
        onPress={()=>onPress(type, item)}
      >
        <View>
          <Image style={styles.searchItemImage} source={outlet.mainImage}/>
        </View>
        <View style={styles.searchItemDescr}>
          <AppText style={styles.searchItemTextTop}>{outlet.name}</AppText>
          <AppText style={styles.searchItemTextMiddle}>{'Объекты'}</AppText>
        </View>
      </TouchableOpacity>;
    }
  }
};

const SearchNav = ({sections, onPress, selected}) => {
  const [current, setCurrent] = useState();
  useEffect(()=>{
    if (selected === undefined){
      setCurrent('All');
    } else {
      setCurrent(selected);
    }
  },[selected]);

  const handleChangeSelected = (type) => {
    setCurrent(type);
    onPress && onPress(type);
  };

  return (
   <ScrollView horizontal={true} style={styles.searchNavList}>
     <TouchableOpacity
       onPress={()=>handleChangeSelected('All')}
       style={styles.searchNavItem}
     >
       <AppText style={[
           styles.searchNavText,
           current === 'All' ? styles.searchNavTextActive : null,
         ]}
       >
         {'Все'}{' '}{sections.reduce((a,v)=>(a += v.data.length),0)}
       </AppText>
       <View style={current === 'All' ? styles.searchNavSelector : null}/>
     </TouchableOpacity>
     {sections.filter(v=>v.data.length > 0).map((v,key)=>(
       <TouchableOpacity
         onPress={()=>handleChangeSelected(v.type)}
         style={styles.searchNavItem}
         key={key}
       >
         <AppText
           style={[
             styles.searchNavText,
             current === v.type ? styles.searchNavTextActive : null,
           ]}
         >
           {searchGroups[v.type].name}{' '}{v.data.length}
         </AppText>
         <View style={current === v.type ? styles.searchNavSelector : null}/>
       </TouchableOpacity>
     ))}
   </ScrollView>
 );
};

export default Search;
