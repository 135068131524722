import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Image, SafeAreaView, SectionList, StyleSheet, TouchableOpacity, View} from 'react-native';
import {Icon} from '@rneui/base';
import Modal from 'react-native-modal';
import AppText from './AppText';
import {getMinWidth} from '../Defaults/DisplayWidth';
import {FontFamily} from '../Defaults/Styles';
import {api} from '../System/api';
import {useNavigation} from '@react-navigation/native';
import TextInputTimeout from './TextInputTimeout';
import {SearchItem} from '../Search/Main';
import settings from '../Defaults/settings';

const windowWidth = getMinWidth();

const logoImg = settings.headerLeftIcon;
const logoString = settings.headerTopLogo;

const search = (query) => {
  return new Promise((resolve, reject) => {
    api.search({query}).then(res=>{
      //console.log(res);
      resolve(res.data);
    }).catch((err)=>{
      reject(err);
    });
  });
};

const SearchHeader = ({search}) => {

  const searchModalRef = useRef();
  const navigation = useNavigation();

  return (
    <>
      <SafeAreaView style={styles.headerBox}>
        <View style={styles.mrIcon}>
          <Image
            source={logoImg}
            resizeMode={'contain'}
            style={styles.mrIconImage}
          />
        </View>
        <Image resizeMode={'contain'} style={styles.mrTextImage} source={logoString}/>
        <TouchableOpacity
          onPress={()=>{
            navigation.navigate('Search');
            //searchModalRef.current?.open();
          }}
          style={styles.searchIcon}
        >
          <Icon size={20} name={'search'} type={'feather'}/>
        </TouchableOpacity>
      </SafeAreaView>
      <SearchModal ref={searchModalRef}/>
    </>
  );
};

const SearchModal = forwardRef(({},ref) =>
{
  const [isVisible, setIsVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [prevQuery, setPrevQuery] = useState('');
  const [searchState, setSearchState] = useState();
  const navigation = useNavigation();

  const handleClose = () => {
    api.pending.abort('search');
  };

  useEffect(() => {
    return handleClose;
  }, []);

  useImperativeHandle(ref,()=>({
    open: () => {
      setIsVisible(true);
    },
    close: () => {
      setIsVisible(false);
    },
  }),[setIsVisible]);

  const handlePressSearchItem = (type, id) => {
    switch (type) {
      case 'Events': {
      } break;
      case 'Offer': {
        navigation.navigate('Offer', {id});
      } break;
      case 'Autlet': {

      } break;
    }
    ref.current?.close();
  };

  const research = (query) => {
    if (query === '' || query === null || query === undefined) {return;}
    if (prevQuery === query) {
      console.log({prevQuery, query});
      return;
    }
    setItems([]);
    search(query).then(result=>{
      let sections = Object
        .keys(result)
        .reduce((a, v)=>{
          a.push({title:v, type:v, data: result[v]}); return a;
        }, []);
      setItems(sections);
    }).catch(err=>{});
  };

  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={()=>{setIsVisible(false);}}
      style={{padding:0, margin: 0}}
      hasBackdrop={false}
      animationIn={'slideInDown'}
      animationOut={'slideOutUp'}
      onDismiss={handleClose}
    >
      <View style={styles.searchBox}>
        <SafeAreaView style={{flex:1}}>
          <View style={{flexDirection: 'row', height: 48, paddingHorizontal: 16}}>
            <TextInputTimeout
              autoFocus={true}
              clearButtonMode={'while-editing'}
              style={styles.searchTextInput}
              placeholder={'Я ищу'}
              placeholderTextColor={'rgba(150, 150, 150, 1)'}
              timeout={1500}
              onChangeText={(text)=>{
                console.log('start change ', text);
              }}
              onChangeTextTimeout={(text)=>{
                console.log('changed with timeout ', text);
                research(text);
              }}
            />
            <TouchableOpacity
              style={{flex:2, justifyContent: 'center', alignItems: 'flex-end'}}
              onPress={()=>{setIsVisible(false);}}>
              <AppText style={{fontSize: 17, color:'rgba(167, 167, 167, 1)'}}>{'Отмена'}</AppText>
            </TouchableOpacity>
          </View>
          <View style={styles.contentBox}>
            <View style={styles.contentNav} />
            <View style={styles.contentList}>
              <SectionList
                sections={items}
                renderItem={data => <SearchItem {...data} onPress={handlePressSearchItem}/> }
                renderSectionHeader={({ section: { title } })=><View />}
              />
            </View>
          </View>
        </SafeAreaView>
      </View>
    </Modal>
  );
});

const styles = StyleSheet.create({
  headerBox: {
    flexDirection: 'row',
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'rgb(240,240,240)',
  },
  mrIconImage: {
    width: 23, height: 30, alignSelf: 'center',
  },
  mrTextImage: {
    flex: 7, height: '33%',
  },
  mrIcon: {
    flex:1,
  },
  searchIcon: {
    flex:1,
  },
  searchTextInput: {
    flex: 6,
    fontSize: 17,
    fontFamily: FontFamily,
  },

  //search modal
  contentBox: {
    flex:1,
    padding: 16,
  },
  searchBox: {
    flex: 1,
    backgroundColor: '#fff',
    width: windowWidth,
  },
  contentNav:{

  },
  contentList:{

  },
});

export default SearchHeader;
