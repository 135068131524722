import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  View,
  SafeAreaView,
  SectionList,
  StyleSheet,
  ActivityIndicator, TouchableOpacity,
} from 'react-native';
import ActivityCard from '../Activities/ActivityCardNew';
import YearActivityCard from '../Activities/ActivityWideCard';
/*import {
  ActivityCategoryFilter,
  ActivityDateFilter,
} from '../Activities/ActivityFilters';*/
import Divider from '../Basic/Divider';
import {api} from '../System/api';
//import activity from '../Activities/Activity';
import activityAdapter from '../Activities/ActivityAdapter';
import moment from 'moment';
import 'moment/locale/ru';
//moment.locale('ru');
import AntiMenuFade from '../Basic/AntiMenuFade';
import {arrIntersect, isObject, sortObject} from '../System/helpers';
//import BookingWrapper from '../Booking/BookingWrapper';
import categoryAdapter from '../Interests/CategoryAdapter';
//import {checkTariffs} from '../Validators/Tariffs';
//import TariffBottomSheet from '../BottomSheets/TariffBottomSheet';
import AppText from '../Basic/AppText';
import DropdownList from '../Basic/DropdownList';
import Calendar from '../Calendar/Calendar';
import Modal from 'react-native-modal';
import NewButton from '../Basic/NewButton';

import {getMinWidth} from '../Defaults/DisplayWidth';
import PageHeader from '../Basic/PageHeader';
import {capitalize} from 'react-native-codegen/lib/generators/Utils';
import {datesGenerator} from '../System/scheduleHelpers';
import ActivityBottomSheetNew from '../BottomSheets/ActivityBottomSheetNew';
import {useNavigation, useRoute} from '@react-navigation/native';
import {Icon} from '@rneui/base';

import * as statusBar from '../GlobalStore/reducers/statusBarSlice';
import {useDispatch} from 'react-redux';

const windowWidth = getMinWidth();

const defaultDateFilter = {
  from: moment(),
  to: moment().add(7, 'd'),
};

const Activities = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [categories, setCategories] = useState({});
  const [activities, setActivities] = useState({});
  const [dateFilter, setDateFilter] = useState(defaultDateFilter);
  const [currentActivity, setCurrentActivity] = useState({});
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [activityOfYear, setActivityOfYear] = useState(route?.params?.eventOfYear || false);
  const [requestWait, setRequestWait] = useState(false);
  const dispatch = useDispatch();

  const sectionListRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    //loadCategories();
    //setActivities([]);
    //loadActivities();
  }, []);

  /*useEffect(() => {
    //console.log('dateFilter',dateFilter);
    //console.log('categoryFilter',categoryFilter);
    //categoryFilter
    loadActivities();
  }, [categoryFilter]);*/

  useEffect(() => {
    return navigation.addListener('focus', () => {
      dispatch(statusBar.setStyle({backgroundColor: '#ffffff'}));
    });
  }, [navigation]);

  useEffect(() => {
    dispatch(statusBar.setStyle({backgroundColor: '#ffffff'}));
  }, []);

  useEffect(() => {
    /*if (dateFilter) {
      //console.log('dateFilter',dateFilter);
      let dateDiff = dateFilter
        .startOf('day')
        .diff(moment().startOf('day'), 'days');
      //console.log('diff about', moment().startOf('day'), dateFilter.startOf('day'),'is', dateDiff);
      if (Object.keys(activities).length > 1) {
        sectionListRef.current.scrollToLocation({
          sectionIndex: Math.max(dateDiff || 0, 0),
          itemIndex: 1,
        });
      }
    }*/
    if (dateFilter) {
      //console.log({dateFilter});
      setActivities([]);
      loadActivitiesData();
    }
  }, [dateFilter]);

  useEffect(()=>{
    if (activityOfYear !== undefined){
      setActivities([]);
      let afterOneYear = moment().add(1, 'year');
      setDateFilter(dts => ({
        from: dts.from,
        to: !activityOfYear ? moment().add(7,'days') : afterOneYear /*dts.to*/,
      }));
      //loadActivitiesData();
    }
  },[activityOfYear]);

  /*const loadCategories = async () => {
    let k = 0;
    while (1) {
      let page = 1;
      let res = await api.activities.categories({
        //page:page++,
      });
      //console.log('CATS:',res.data.categories);
      /!*if (res.data?.categories?.length < 1 || !Array.isArray(res.data?.categories)){
        break;
      }*!/
      let cats = {};
      for (let i in res.data.categories) {
        let item = res.data.categories[i];
        cats[item.id] = categoryAdapter(item);
      }
      setCategories(v => ({...v, ...cats}));
      if (k === 1) {
        break;
      }
      k++;
    }
  };*/

  const getActivity = ({id, date, time}) => {
    let dateKey = moment(date).format('YYYY-MM-DD');
    if (
      id === undefined ||
      !isObject(activities) ||
      Object.keys(activities).length === 0 ||
      !activities[dateKey]
    ) {
      return {};
    }
    let data = activities[dateKey].data.find(
      v => v.id === id && v.time === time,
    );
    //console.log('activityData of ', date, ' is:', data);
    return data;
  };

  /*const prepareCurrentActivity = () => {
    let activity = getActivity(currentActivity);
    if (!activity || Object.keys(activity).length === 0) {
      return {};
    }
    let activityDate = moment(activity.date);
    let actionData = {
      offer: activity?.offer,
      date: [activityDate.format('YYYY-MM-DD')],
      time: activity.time,
      restrictions: {
        workDates: {
          [activityDate.format('YYYY-MM-DD')]: {
            date: activityDate,
            timeList: [activity.time],
          },
        },
      },
    };
    //console.log(actionData);
    return actionData;
  };*/

  const renderActivity = useCallback(({item}) => {
    const CardComponent = activityOfYear
      ? YearActivityCard
      : ActivityCard;
    return (
      <CardComponent
        {...item}
        onPress={item => {
          setCurrentActivity(item);
          modalRef.current.open();
        }}
      />
    );
  },[activityOfYear]);

  const updateActivity = (props) => {
    let {id} = props;
    //let dateKey = moment(date).format('YYYY-MM-DD');
    setActivities(v => {
      for (let dateKey in v){
        for (let idx in v[dateKey].data) {
          let item = v[dateKey].data[idx];
          if (item.id === id) {
            v[dateKey].data[idx] = {
              ...v[dateKey].data[idx],
              ...props,
            };
          }
        }
      }
      return {...v};
    });
  };

  /*
  [
    {
      "0": [
        {"from": "12:30", "to": "20:01"}
      ],
      "6": [
        {"from": "", "to": ""}
      ],
      "from": "2022-11-18",
      "to": "2023-01-30",
      "allDay": 0,
      "interval": 60
    }
  ]*/

  const categoryFilterFx = (item) => {
    return ({
      ...item,
      data: item.data.filter(i=>(
        (
          categoryFilter.length === 0
          ||
          arrIntersect(categoryFilter, i.categories.map(j=>j.id)).length > 0
        )
      )),
    });
  };

  /*const prepareDates = (dates, filter) => {
    //dates = {};
    return dates
      .map(v => moment(v.dateStart))
      .filter(v => !filter || v.isBetween(filter.from, filter.to, undefined, '[]'))
      .reduce((a, v)=>({...a, [v.format('YYYY-MM-DD HH:mm:00')]:v}), {});
  };*/

  const loadActivitiesData = useCallback(async () => {
    //setActivities({});
    let dateFrom = dateFilter.from;
    let dateTo = dateFilter.to;
    let dateNow = moment().add(-50, 'minutes');
    let dates;

    setRequestWait(true);
    let res = await api.activities.listV2({
      page: 1,
      dateFrom: dateFrom.format('YYYY-MM-DD'),
      dateTo: dateTo.format('YYYY-MM-DD'),
      //categories: categoryFilter,
      eventYear: activityOfYear,
    });
    //console.log({data: res.data});
    let cats = {};
    for (let id in res.data.categories) {
      let item = res.data.categories[id];
      cats[item.id] = categoryAdapter(item);
    }
    setCategories(cats);

    let group = {};
    for (let id in res.data.events) {
      let item = res.data.events[id];
      //console.log({item});
      if (item?.type === 'concert'){ continue; }

      dates = item.apiSchedule
        ? datesGenerator(item.apiSchedule, dateFilter)
        : []; //prepareDates(item.eventsDates, dateFilter);
      //console.log({dates});
      for (let ind in dates){
        let date = dates[ind];
        let dateFmt = date.format('YYYY-MM-DD');
        let time = date.format('HH:mm');
        if (!group[dateFmt]){
          group[dateFmt] = {
            title: dateFmt,
            data: {},
          };
        }
        group[dateFmt].data[time + '_' + item.id] = activityAdapter({
          ...item,
          date,
          //categoryFilter,
          offer: null,
          offerId: item?.offer?.id,
        });
        //group
      }
      for (let date in group){
        group[date].data = Object.values(group[date].data).sort((a, b) =>
          moment(a.date).diff(moment(b.date))
        );
      }
      setActivities(sortObject(group));
    }
    setRequestWait(false);
  }, [dateFilter, activityOfYear]);

  /*useEffect(()=>{
    console.log('changeCat',categories);
  }, [categories]);*/

  const handleActivityOfYear = () => {
    setActivityOfYear(v=>!v);
  };

  return (
    <>
      <PageHeader title={'Афиша'} useBack={false}/>
      <SafeAreaView style={styles.container}>
        <View style={styles.filterButtonBox}>
          <ActivityDateFilter
            buttonStyle={styles.filterButton}
            onChange={setDateFilter}
            calendarMaxDate={
              activityOfYear
                ? moment().add(1,'year').format('YYYY-MM-DD')
                : null
            }
            value={dateFilter}
            onAction={
              (action)=>{
                if (action.type === 'announce') {
                  setActivityOfYear(1);
                }
              }
            }
          />
          <ActivityCategoryFilter
            buttonStyle={styles.filterButton}
            onChange={setCategoryFilter}
            list={
              Object
              .values(categories)
              .map(i=>({...i, selected: categoryFilter.includes(i.id)}))
            }
          />
          <TouchableOpacity
            style={[
              styles.fButton,
              styles.filterButton,
              activityOfYear ? styles.fButtonActive : null,
              styles.yearActivityBtn,
            ]}
            onPress={handleActivityOfYear}
          >
            <AppText style={[
              styles.fButtonText,
              activityOfYear ? styles.fButtonActiveText : null,
            ]}>
              {'Анонс событий'}
            </AppText>
            <Icon style={styles.fButtonIcon} name={'star'} size={18} color={'rgb(255,172,56)'}/>
          </TouchableOpacity>
        </View>
        <SectionList
          ref={sectionListRef}
          stickySectionHeadersEnabled={true}
          sections={
            Object.values(activities)
            /*.map(filterByChosenCategory)*/
            .map(categoryFilterFx)
            .filter(v => v.data !== undefined && v.data.length > 0)
          }
          renderItem={renderActivity}
          keyExtractor={(item, index) => item.id + '|' + index}
          onScrollToIndexFailed={() => console.log('not found section')}
          renderSectionHeader={(props)=><ActivitySectionHeader {...props}/>}
          ListFooterComponent={() => (
            <>
              {Object.keys(activities).length === 0 && requestWait ? (
                <>
                  <Divider type={'dashed'} />
                  <View style={styles.eventsLoaderBox}>
                    <ActivityIndicator size={'large'} />
                  </View>
                </>
              ) : null}
              {Object.keys(activities).length === 0 && !requestWait
                ?<>
                  <Divider type={'dashed'} />
                  <View style={styles.eventsNotFoundBox}>
                    <AppText style={styles.eventsNotFoundText}>
                      {'По указанным фильтрам \n событий не найдено'}
                    </AppText>
                  </View>
                </>
                : null
              }
              <AntiMenuFade />
            </>
          )}
        />
      </SafeAreaView>
      <ActivityBottomSheetNew
        ref={modalRef}
        //key={sheetUpdater}
        activity={getActivity(currentActivity)}
        onPressTag={tag => {
          modalRef.current.close();
          navigation.navigate({name: 'Offers', params: tag});
        }}
        updateActivity={updateActivity}
      />
    </>
  );
};

const ActivityDateFilter = ({onChange, buttonStyle, value, calendarMaxDate, onAction}) =>
{
  const [title, setTitle] = useState('Ближайшее время');
  const ref = useRef();
  const calendarModalRef = useRef();

  useEffect(() => {
    if (value) {
      //defaultDateFilter.from.startOf('day') == value[0]
      setTitle(prettyInterval(value.from,value.to));
    }
  },[value]);

  let prettyInterval = (d1, d2) => {
    let y1 = d1.format('YY'), y2 = d2.format('YY');
    let m1 = d1.format('MMM'), m2 = d2.format('MMM');
    let df1 = d1.format('D'), df2 = d2.format('D');
    if (y1 !== y2) {
      return `${df1} ${m1} ${y1}г. - ${df2} ${m2} ${y2}г.`;
    } else if (m1 !== m2) {
      return `${df1} ${m1} - ${df2} ${m2}`;
    } else {
      if (df1 === df2) {return `${df1} ${m1}`;}
      return `${df1} - ${df2} ${m2}`;
    }
  };

  let
    today = moment(),
    tomorrow = moment().add(1,'d'),
    weekend = [moment().day(6),moment().day(7)],
    week = [today, moment().add(7,'d')],
    month = [today, moment().add(1,'M')],
    dateChoice = '',
    prettyToday = today.format('DD MMM'),
    prettyTomorrow = tomorrow.format('DD MMM'),
    prettyWeekend = prettyInterval(weekend[0], weekend[1]),
    prettyWeek = prettyInterval(week[0], week[1]),
    prettyMonth = prettyInterval(month[0], month[1]),
    prettyDateChoice = '';

  const handleChange = (item) => {
    let {id, data} = item[0];
    data = Array.isArray(data) ? data : [data, data];
    setTitle(prettyInterval(data[0], data[1]));
    onChange({
      from: data[0],
      to: data[1],
    });
  };

  return (
    <>
      <View style={styles.filterBox}>
      <DropdownList
        ref={ref}
        title={title}
        list={[
          {
            id: 'announce',
            title: 'Анонс событий',
            subtitle:'Анонс событий года',
            data: null,
            onPress : () => {
              onAction && onAction({type: 'announce'});
              ref.current?.hide();
            },
            icon: {name:'star', size:20, color:'rgb(255,172,56)'},
          },
          {id: 'today',      title: 'Сегодня',    subtitle: prettyToday, data:today},
          {id: 'tomorrow',  title: 'Завтра',     subtitle: prettyTomorrow, data:tomorrow},
          {id: 'weekend',    title: 'Выходные',   subtitle: prettyWeekend, data:weekend},
          {id: 'week',       title: 'Неделя',     subtitle: prettyWeek, data:week},
          //{id: 'month',      title: 'Месяц',      subtitle: prettyMonth, data:month},
          {
            id: 'dateChoice',
            title: 'Выбор даты',
            subtitle: prettyDateChoice,
            data: dateChoice,
            icon: {name: 'keyboard-arrow-right', type: 'material', size: 16},
            onPress : () => {
              ref.current?.hide(()=>{
                calendarModalRef.current?.show();
              });
            },
          },
        ]}
        onChange={handleChange}
        buttonStyle={buttonStyle}
      />
    </View>
      <ActivityDateChoice
        ref={calendarModalRef}
        onChange={(data)=>{
          let from = moment(data[0], 'YYYY-MM-DD'),
              to = moment(data[1] || data[0], 'YYYY-MM-DD');
          onChange({from, to});
          setTitle(prettyInterval(from, to));
        }}
        maxDate={calendarMaxDate}
      />
    </>
  );
};

const ActivityCategoryFilter = ({list, buttonStyle, onChange}) =>
{
  return (
    <View style={styles.filterBox}>
      <DropdownList
        buttonStyle={buttonStyle}
        multiple={true}
        list={list}
        title={'Все категории'}
        activeTitle={'Категорий'}
        onChange={data=>onChange(data.map(i=>i.id))}
      />
    </View>
  );
};

const ActivityDateChoice = forwardRef(({onChange, defaultDates, maxDate}, ref) => {
  const [modalShow, setModalShow] = useState(false);
  const [dates, setDates] = useState([
    moment().format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]);

  useImperativeHandle(ref, () => ({
    show: () => setModalShow(true),
    hide: () => setModalShow(false),
  }), [setModalShow]);

  const handleChange = (data) => {
    if (!data[1] || moment(data[1]).isBefore(data[0])) {
      /* TODO: move this logic into calendar component*/
      data = [data[0], data[0]];
    }
    setDates(data);
  };

  const handleConfirm = ()=>{
    onChange(dates);
    setModalShow(false);
  };

  return (
    <Modal
      style={styles.calendarModal}
      isVisible={modalShow}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      onBackdropPress={()=>setModalShow(false)}
    >
      <View style={styles.calendarBox}>
        <Calendar
          onChange={handleChange}
          value={[
            moment().format('YYYY-MM-DD'),
            moment().add(3,'d').format('YYYY-MM-DD'),
          ]}
          allowRangeSelection={true}
          width={windowWidth}
          dateHeight={45}
          maxDate={maxDate || moment().add(7,'days').format('YYYY-MM-DD')}
        />
        <NewButton
          style={styles.calendarButton}
          children={'Выбрать'}
          onPress={handleConfirm}
        />
      </View>
    </Modal>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    //padding: 16,
  },
  sectionHeaderBox: {
    top: -1,
    paddingVertical: 12,
    paddingHorizontal: 12,
    backgroundColor: '#F6F6F6',
  },
  sectionHeaderText: {
    fontSize: 17,
  },
  filterButtonBox: {
    marginVertical: 10,
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexWrap:'wrap',
    //rowGap: 20,
  },
  filterButton: {
    marginRight: 7,
    marginTop: 8,
    //marginBottom: 7,
  },

  //filters
  filterBox: {
    flexDirection: 'row',
  },


  //calendar modal
  calendarModal: {
    padding: 0,
    paddingVertical: 10,
    margin: 0,
    width: windowWidth,
    alignSelf: 'center',
  },
  calendarBox: {
    backgroundColor: '#fff',
    paddingVertical: 20,
    width: windowWidth,
  },
  calendarButton: {
    marginTop: 40,
  },

  //yearActivity filter
  yearActivityBtn: {
    marginTop: 8,
  },

  //filter button
  fButton: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: '#F6F6F6',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  fButtonIcon: {
    marginLeft: 8,
  },
  fButtonText: {
    fontSize: 14,
    color: 'rgba(113, 113, 113, 1)',
  },
  fButtonActive: {
    backgroundColor: 'rgba(51, 51, 51, 1)',
  },
  fButtonActiveText: {
    color: '#fff',
  },

  //loader
  eventsLoaderBox: {
    flex: 1,
    width: '100%',
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },

  //events not found
  eventsNotFoundBox: {
    flex: 1,
    width: '100%',
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventsNotFoundText: {
    fontSize: 18,
    fontWeight: '400',
    color: '#494949',
    textAlign: 'center',
  },
});

export const ActivitySectionHeader = memo(({section: {title}, height}) =>
{
  const prettifyDate = (df /*formatted*/ ) => {
    let c = moment(),
        cf = c.format('YYYY-MM-DD'),
        d = moment(df, 'YYYY-MM-DD'),
        wd = moment().day(),
        cy = c.format('YYYY'),
        y = d.format('YYYY'),
        weekday;

    if (df === cf) {weekday = 'Сегодня';}
    else if (df === c.clone().add(-1,'d').format('YYYY-MM-DD')){
      weekday = 'Вчера';
    } else if (df === c.clone().add(+1,'d').format('YYYY-MM-DD')){
      weekday = 'Завтра';
    } else {weekday = capitalize(d.format('dddd'));}

    return `${weekday}, ${d.format('D MMMM')} ${(y !== cy ? y : '')}`;
  };

  return (
    <View style={[styles.sectionHeaderBox, {height}]}>
      <AppText style={styles.sectionHeaderText}>
        {prettifyDate(title)}
      </AppText>
    </View>
  );
},(p,n)=>{
  return p.section.title === n.section.title;
});

export default Activities;
