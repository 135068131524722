import {ImageBackground, StyleSheet, View} from 'react-native';
import {CardFade} from '../Cards/CardElements';
import AppText from '../Basic/AppText';
import TariffsMiniView from '../Tariffs/TariffsMiniView';
import humanize from 'humanize-number';
import React, {useEffect, useState} from 'react';
import {calcOfferFinalPrice} from '../Cards/CardPrice';
import {colors} from '../Defaults/Styles';
import moment from 'moment';
import {AttributesLine} from '../Attributes/AttributesLine';

export const MiniOffer = (offer) => {
  const [totalPrice, setTotalPrice] = useState(calcOfferFinalPrice(offer));
  const {
    id,
    title,
    mainImage,
    gallery,
    style,
    tariffs,
    showTariffs,
    onPressTariffs,
    showPrice,
    loyaltyBonus,
    date,
    time,
    attributes,
    showAttributes,
  } = offer;

  //let {min, max} = getAggTariffPrice(offer);

  useEffect(()=>{
    setTotalPrice(calcOfferFinalPrice(offer));
  }, [offer, attributes]);

  if (!id) {return null;}

  return (
    <View style={[{flexDirection: 'row', alignItems: 'center'}, style]}>
      <ImageBackground
        style={{width: 70, height: 70}}
        imageStyle={{borderRadius: 50}}
        source={(Array.isArray(gallery) && gallery[0]? gallery[0] : mainImage)}
        defaultSource={require('./../../img/mr_view_blur.jpg')}
      />
      <View
        style={{
          position: 'absolute',
          left: 0,
          height: 70,
          width: 70,
        }}>
        <CardFade gradientStyle={{borderRadius: 50}} />
      </View>
      <View style={{flex: 1, paddingLeft: 15}}>
        <AppText
          style={{
            fontSize: 16,
            fontWeight: '500',
          }}>
          {title}
        </AppText>
        <View>
          {showTariffs ? <TariffsMiniView tariffs={tariffs} onPress={onPressTariffs}/> : null}
        </View>
        <View>
          {showPrice ?
            <View style={styles.totalPriceContainer}>
              <AppText style={styles.totalPrice}>
                {humanize(totalPrice - loyaltyBonus, {delimiter: ' '}) + ' ₽'}
              </AppText>
              {loyaltyBonus ?
                <AppText style={styles.loyaltyBonus}>
                  {humanize(loyaltyBonus, {delimiter: ' '}) + ' B'}
                </AppText>
                : null
              }
              <View style={styles.offerDateTimeBox}>
                {date ?
                  <AppText style={styles.offerDateTime}>
                    {moment(date).format('D MMMM')}
                  </AppText> : null
                }
                {time ?
                  <AppText style={styles.offerDateTime}>
                    {date ? ' • ' : null}
                    {time}
                  </AppText> : null
                }
              </View>
            </View>
            : null}
        </View>
        <View style={styles.attributes}>
          {showAttributes
            ? <AttributesLine list={attributes}/>
            : null
          }
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  totalPriceContainer: {
  },
  totalPrice: { color: '#6c6c6c'},
  loyaltyBonus: {
    color: colors.default.bonus,
    lineHeight: 15,
  },
  offerDateTimeBox: {
    flexDirection: 'row',
  },
  offerDateTime: {
    color: '#a9a9a9',
  },
});

export default MiniOffer;
