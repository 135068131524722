import {LoadingOverlay, PromiseOverlay} from '../Basic/BasicElements';
import {Image, Modal, Platform} from 'react-native';
import Confirm from '../Basic/Confirm';
import ConfirmPhone from '../Basic/ConfirmPhone';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import {api} from '../System/api';
import {communicationAction} from '../Offers/communication';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useToast} from 'react-native-toast-notifications';
import AppText from '../Basic/AppText';
import {useNavigation} from '@react-navigation/native';
import {encode} from 'js-base64';
import {BottomSheetModalProvider, useBottomSheet, useBottomSheetModal} from '@gorhom/bottom-sheet';
import {tempBookingStore} from './tempBookingStorage';
import {isObject} from '../System/helpers';
import BookingFormBottomSheet from '../BottomSheets/BookingFormBottomSheet';
import {metricEvent} from '../Metrics/MetricHelper';

import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import {useDispatch} from 'react-redux';

const BookingContext = createContext();

const BookingProvider = props => {
  const {children, onPressTariffs} = props;

  const [actionData, setActionData] = useState();
  const [recallShow, setRecallShow] = useState(false);
  const confirmPhoneRef = useRef();
  const loadingOverlay = useRef();
  const calendarRef = useRef();

  //const bottomSheets = useBottomSheetModal();

  const toast = useToast();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const afterOpenUrl = useCallback(() => {
    loadingOverlay.current?.hide();
    calendarRef.current?.close();
  }, [loadingOverlay]);

  const handleCalendarAction = async ({
    chosenDates,
    chosenTime,
    action,
    clientPhone,
    clientName,
    clientComment,
    clientRoom,
    recall,
    loyaltyBonus,
    onSuccess,
  }) => {
    __DEV__ &&
      console.log(
        'CALENDAR DATA:',
        chosenDates,
        chosenTime,
        actionData,
        action,
        recall,
        loyaltyBonus,
      );

    //setRecallShow(true);
    //return null;

    let userTz = moment.tz.guess();
    let dateFrom = moment(
      `${chosenDates[0]} ${chosenTime}`,
      'YYYY-MM-DD HH:mm',
    ); //.tz(userTz);
    let dateTo = moment(`${chosenDates[0]} ${chosenTime}`, 'YYYY-MM-DD HH:mm'); //.tz(userTz);

    //console.log('converted time', {userTz, dateFrom, dateTo});

    let bookingMethod,
      bookingData = {
        date_from: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
        date_to: dateTo.format('YYYY-MM-DD HH:mm:ss'),
        action,
        schedule_recall: recall,
        comment: clientComment || '',
        room: clientRoom || '',
      };

    if (actionData?.subAction === 'changeBooking') {
      bookingMethod = api.bookings.change;
      bookingData.booking_id = actionData.offer.booking.id;
    } else {
      bookingData.offer_id = actionData.offer.id;

      if (actionData.offer?.tariffs) {
        let offer_items = actionData.offer.tariffs.reduce((a, v) => {
          if (v.count) {
            a.push({id: v.id, count: v.count});
          }
          return a;
        }, []);
        __DEV__ && console.log('BOOKED OFFER ITEMS:', offer_items);
        if (offer_items.length) {
          bookingData.offer_items = offer_items;
        }
      }

      if (actionData.offer?.specialOffer) {
        bookingData.special_offer_id = actionData.offer.specialOffer.id;
      }

      if (actionData.offer?.selection) {
        bookingData.selection_id = actionData.offer.selection.id;
      }

      if (clientPhone) {
        bookingData.phone = clientPhone;
        bookingData.name = clientName;
        bookingMethod = api.bookings.createTemp;
      } else {
        bookingMethod = api.bookings.create;
      }
    }

    loadingOverlay?.current?.show();

    let cpa = await AsyncStorage.getItem('CPA');
    if (cpa && cpa !== 'undefined') {
      bookingData.cpa = cpa;
    }

    let attributes;
    if ((attributes = actionData?.offer?.attributes) && isObject(attributes)){
      let _attributes = Object
        .values(attributes)
        .filter(v=>v.value)
        .map(v=>({attribute: v.id, value: v.value}));
      __DEV__ && console.log('Booked attributes', _attributes);
      bookingData.offerAttribute = _attributes;
    } else {
      __DEV__ && console.log('cant find attributes', attributes);
    }

    if (loyaltyBonus){
      bookingData.bonuses = loyaltyBonus;
    }

    bookingMethod(bookingData)
      .then(res => {
        onSuccess && onSuccess();

        __DEV__ && console.log('bookingResult:', res);
        const {booking, uid, paymentLink} = res.data;

        if (actionData.subAction !== 'changeBooking') {
          metricEvent('booking_request');
          if (clientPhone) {
            metricEvent('booking_request_unauth');
          }
        }


        AsyncStorage.removeItem('CPA')
          .then(() => {})
          .catch(() => {});

        dispatch(
          appEvent.newEvent({
            name : 'addBooking',
            params: {
              item: {
                id: booking || bookingData.booking_id,
                type: 'booking',
                dateFrom,
                action: actionData?.subAction,
              },
            },
          })
        );

        dispatch(appEvent.newEvent('dismissAllBottomSheets'));

        if (clientPhone) {
          tempBookingStore({
            ...bookingData,
            id:booking,
            paymentLink,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            offer: {
              ...actionData?.offer,
              id: bookingData.offer_id,
            },
          });
        }

        communicationAction({
          action,
          data: {
            booking_id: booking,
            offer: {name: actionData.offer.title},
            time_from: chosenTime,
            time_to: chosenTime,
            date_from: dateFrom,
          },
          isAuthSkipped: !!clientPhone,
        })
          .then(() => {
            if (action === 'call_later') {
              if (actionData.offer?.isUseSlots) {
                navigation.navigate({
                  name: 'Payment',
                  params: {
                    booking_id: booking,
                    ...(!clientPhone ? {} : {link: encode(paymentLink)}),
                  },
                });
              } else {
                //show recall promise popup
                loadingOverlay.current.hide(true).then(() => {
                  setRecallShow(true);
                  //bottomSheets.dismissAll();
                });
              }
            }
            afterOpenUrl();
          })
          .catch(e => {
            __DEV__ && console.warn('Communication action err', e);
            toast.show(
              <>
                <AppText>Не удаётся открыть </AppText>
                <AppText href={e.url} accessibilityRole="link" target="_blank">
                  {action === 'call' ? 'Телефон' : action}
                </AppText>
              </>,
              {
                title: 'Всплывающие окна заблокированы',
                type: 'danger',
                duration: 30000,
              },
            );
            afterOpenUrl();
          });
        toast.show('', {
          title: 'Заявка отправлена',
          type: 'default',
        });
      })
      .catch(res => {
        toast.show('', {
          title: 'Ошибка при отправке заявки',
          type: 'error',
          duration: 60000,
        });

        calendarRef?.current?.reloadRestrictions();
        loadingOverlay?.current?.hide();
        __DEV__ && console.warn('BOOKING ERROR:', res);
        //console.log({bookingData, bookingMethod});
      });
  };

  useEffect(() => {
    //__DEV__ && console.log({actionData});
  }, [actionData]);

  const openBookingForm = data => {
    if (!data) {
      throw Error('offer should not be empty');
    }
    calendarRef.current?.present();
    setActionData(data);
  };

  const updateBookingForm = data => {
    setActionData(data);
  };

  const closeBookingForm = () => {
    calendarRef.current?.dismiss();
  };

  const settings = {
    openBookingForm,
    closeBookingForm,
    updateBookingForm,
  };

  return (
    <BookingContext.Provider value={settings}>
      {children}
      <BottomSheetModalProvider>
        <BookingFormBottomSheet
          ref={calendarRef}
          onAction={handleCalendarAction}
          onPressTariffs={onPressTariffs}
          {...actionData}
        />
      </BottomSheetModalProvider>
      <LoadingOverlay
        ref={loadingOverlay}
        animationType={'none'}
        loading={false}
      />
      <Modal visible={recallShow} transparent={true} animationType="fade">
        <PromiseOverlay onPressClose={() => setRecallShow(false)}>
          <Image
            style={{width: 40, height: 40}}
            source={require('./../../img/png_icons/CallLaterGreen.png')}
          />
          <AppText
            style={{
              marginVertical: 20,
              marginHorizontal: 50,
              fontSize: 18,
              fontWeight: '500',
              textAlign: 'center',
            }}>
            {
              actionData?.offer?.successBookingPopupText
              ? actionData.offer.successBookingPopupText
              : 'Мы скоро перезвоним Вам!'
            }
          </AppText>
        </PromiseOverlay>
      </Modal>
      <Confirm ref={confirmPhoneRef} Component={ConfirmPhone} visible={false} />
    </BookingContext.Provider>
  );
};

const useBookingContext = () => useContext(BookingContext);

export {BookingProvider, BookingContext, useBookingContext};
