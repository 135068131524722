import {View, StyleSheet, ImageBackground, TouchableOpacity} from 'react-native';
import AppText from '../Basic/AppText';
import React, {useState} from 'react';
import useCategoryLoader from './CategoriesLoader';
import {useNavigation} from '@react-navigation/native';
//import SkeletonPlaceholder from 'react-native-skeleton-placeholder';
import {SkeletonContainer, Skeleton} from 'react-native-skeleton-component';

import {getMinWidth} from '../Defaults/DisplayWidth';
import LinearGradient from 'react-native-linear-gradient';
const windowWidth = getMinWidth();
const cardHeight = windowWidth * 0.40;
const defaultColor = '#e9ffe4';

const cardsOnLine = 2;
const cardsMargin = (cardsOnLine - 2) / cardsOnLine;


const CategoryNavPlaceholder = () => {
  const cardWidth = 100 / cardsOnLine - (cardsOnLine > 1 ? 1 : 0);
  return (
    <SkeletonContainer /*backgroundColor={'#d9d9d9'} highlightColor={'#ececec'}*/ animation={'wave'}>
      <View style={styles.categoryContainer}>
        <Skeleton key={0} style={[styles.categoryCard, {width: '100%'}]}/>
        {Array(cardsOnLine).fill(1).map((v,k) =>
          <Skeleton key={k + 1} style={[styles.categoryCard, {width: (cardWidth - cardsMargin) + '%'}]}/>
        )}
      </View>
    </SkeletonContainer>
  );
};

const CategoryCard = ({id, title, image, color, style}) => {
  const navigation = useNavigation();
  return <TouchableOpacity
    onPress={()=>{
      navigation.navigate('Offers',{
        id,
        title,
        type: 'category',
      });
    }}
    style={[styles.categoryCard, style, {backgroundColor: color || defaultColor}]}>
    <ImageBackground
      source={image}
      style={styles.categoryCardBackgr}
      imageStyle={styles.categoryCardImage}
    >
      <LinearGradient colors={['#00000000','rgba(0,0,0,0.5)']} style={styles.categoryCardGrad}/>
      <AppText style={styles.categoryCardText}>{title}</AppText>
    </ImageBackground>
  </TouchableOpacity>;
};

const CategoryNav = () => {
  const filter = (item) => item.activeInterests.length > 0;
  const {categories, loading} = useCategoryLoader();
  const [showMore, setShowMore] = useState(false);
  const count = Object.keys(categories).length;
  const residue = (count - 1) % cardsOnLine;
  const cardWidth = 100 / cardsOnLine - (cardsOnLine > 1 ? 1 : 0);
  const lastsWidth = residue > 1 ? (100 / residue - 1) : 100;

  return (<>
    {loading !== 'loading' && count ?
        <>
          <View
            style={styles.categoryContainer}>
            <CategoryCard
              key={Object.values(categories)[0]?.id}
              style={{width: '100%'}}
              {...categories[0]}
              image={categories[0]?.image_md}
            />
            {
              categories
              && Object.values(categories)
                .filter(filter)
                .filter((i, k) => (showMore || k <= cardsOnLine))
                .slice(1)
                .map((item,k) => <CategoryCard
                  key={item.id}
                  {...item}
                  image={(k >= (count - residue - 1 )) ? item.image_md : item.image_sm}
                  style={[
                    {width: (cardWidth - cardsMargin) + '%'},
                    (k >= (count - residue - 1 ) ? {width: lastsWidth + '%'} : null),
                  ]}
                />)
            }
          </View>
          <TouchableOpacity
            onPress={()=>setShowMore(v=>!v)}
            style={styles.moreBtn}
          >
            <AppText style={styles.moreBtnText}>
              {!showMore ? 'Показать все' : 'Скрыть'}
            </AppText>
          </TouchableOpacity>
        </>
        : <CategoryNavPlaceholder/>
    }
    </>);
};

const styles = StyleSheet.create({
  categoryContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    flex: 1,
  },
  categoryCard: {
    width: '49%',
    height: cardHeight,
    backgroundColor: '#e8e8e8',
    marginBottom: 20,
    borderRadius: 8,
  },
  categoryCardImage: {
    borderRadius: 8,
  },
  categoryCardBackgr: {
    height: '100%',
    width: '100%',
  },
  categoryCardGrad: {
    height: cardHeight * 0.32,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  categoryCardText: {
    padding: 8,
    fontWeight: '500',
    fontSize: 14.5,
    lineHeight: 16.5,
    color: '#FFFFFF',
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
  },

  moreBtn:{
    padding: 6,
    paddingHorizontal: 15,
    borderRadius: 50,
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    alignSelf: 'center',
  },
  moreBtnText:{
    color: '#3b3b3b',
    //fontSize: 14,
  },
});

export default CategoryNav;
