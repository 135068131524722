import React, {useContext} from 'react';
import {View, TouchableOpacity} from 'react-native';
import AppText from '../Basic/AppText';
import {Icon} from '@rneui/themed';
import {useDispatch, useSelector} from 'react-redux';
import * as auth from '../GlobalStore/reducers/authSlice';

const LoginButton = () => {
  const dispatch = useDispatch();
  const {defaultColors} = useSelector((state) => state.style);
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={() => {
        dispatch(auth.logout());
        dispatch(auth.skipToLogin(true));
      }}>
      <View style={{alignItems: 'flex-end', marginRight: 10}}>
        <AppText
          style={{
            fontSize: 12,
            marginBottom: -2,
            color: defaultColors.main,
          }}>
          {'РЕГИСТРАЦИЯ'}
        </AppText>
        <AppText
          style={{
            fontSize: 12,
            marginTop: -2,
            color: defaultColors.main,
          }}>
          {'ВОЙТИ'}
        </AppText>
      </View>
      <Icon name="login" type="ant-design" color={defaultColors.main} />
    </TouchableOpacity>
  );
};

export default LoginButton;
