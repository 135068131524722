import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {multiGetReducer} from '../../System/helpers';
import * as userProfile from './userProfileSlice';
import * as accommodation from './accommodationSlice';
import settings from '../../Defaults/settings';

const authStates = [
  'login',
  'auth',
];

if (settings.enableAuthSkip){
  authStates.push('skip');
} else {
  authStates.push('init');
}

const defaults = {
  authState : settings.enableAuthSkip ? 'skip' : 'init',
  authStates,
};

const initialState = {
  state: defaults.authState,
  prevState: undefined,
  jwt: undefined,
  firstLogin: undefined,
};

/*
useEffect(() => {
  console.log('jwt token CHANGED: ' + jwtToken);
  if (checkJWTState(jwtToken)){
    dispatch(userProfile.getValue(true))
      .unwrap()
      .then(res=>{
        console.log('try to auth', res);
        setAuthState('auth');
      })
      .catch(err=>{
        console.warn('auth error:', err);
        setAuthState('login');
      });
  } else {
    console.log('JWT state fails');
    setAuthState('skip');
    removeJwtToken();
  }
}, [jwtToken]);

const setAuthState = state => {
    let value = defaults.authStates.includes(state) ? state : defaults.authState;
    AsyncStorage.setItem('authState', value).then(() => {
      _setAuthState(value);
      //console.log('changeAuthState to ' + value);
    });
  };

  const getAuthState = () => {
    return new Promise(resolve => {
      AsyncStorage.getItem('authState').then(state => {
        resolve(defaults.authStates.includes(state) ? state : defaults.authState);
      });
    });
  };
*/

/*const setIsFirstLogin = value => {
  AsyncStorage.setItem('firstLogin', value ? '1' : '0').then(() => {
    //_setIsFirstLogin(value);
  });
};

const getIsFirstLogin = async () => {
  let value = await AsyncStorage.getItem('firstLogin');
  return value === '1' || value === null;
};*/


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setState: (state, {payload}) => {
      payload = defaults.authStates.includes(payload) ? payload : defaults.authState;
      AsyncStorage.setItem('authState', payload).then(() => {
        //console.log('changeAuthState to ' + value);
      });
      state.prevState = state.state;
      state.state = payload;
      //console.log('set state to:',payload,'from',state.state);
    },
    setJwtToken: (state, {payload}) => {
      state.jwt = payload;
    },
    //removeJwtToken,
    setLoginSkipper: (state,{payload}) => {
      state.loginSkipper = payload;
    },
    setFirstLogin: (state, {payload}) => {
      state.firstLogin = payload;
    },
  },
});

const init = () => (dispatch) => {
  AsyncStorage.multiGet(['JWT', 'authState']).then(values => {
    const {JWT, authState} = multiGetReducer(values);
    dispatch(authSlice.actions.setJwtToken(JWT));
    dispatch(authSlice.actions.setState(authState));
  });
};

const selectState = (state) => state.auth.state;
const selectFirstLogin = (state) => state.auth.firstLogin;
const selectJWT = (state) => state.auth.jwt;
const selectPrevState = (state) => state.auth.prevState;
const select = (state) => state.auth;

const saveJwtToken = (payload) => (dispatch) => {
  if (payload) {
    AsyncStorage.setItem('JWT', payload).then(() => {
      dispatch(authSlice.actions.setJwtToken(payload));
      console.log('set jwt token', payload);
    });
  }
};

const removeJwtToken = () => {
  AsyncStorage.removeItem('JWT').then(()=>{});
};

const getState = createAsyncThunk(
  'auth/getState',
  async (data, {getState}) => {
    let state = getState().auth;
    let authState = await AsyncStorage.getItem('authState');
    //state.state = authState;
    return {
      ...state,
      state: defaults.authStates.includes(authState) ? authState : defaults.authState,
    };
  }
);

const logout = () => (dispatch) => {
  dispatch(authSlice.actions.setState('login'));
  dispatch(userProfile.resetValue());
  dispatch(accommodation.resetValue());
  removeJwtToken();
};

const skipToLogin = () => (dispatch) => {
  dispatch(authSlice.actions.setLoginSkipper(1));
};

export const {setState, setJwtToken, setFirstLogin} = authSlice.actions;
export {
  selectState, selectJWT, selectPrevState, selectFirstLogin, select,
  init,
  saveJwtToken, removeJwtToken,
  getState,
  logout,
  skipToLogin,
};

export default authSlice.reducer;
