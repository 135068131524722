import React, {useState, useEffect, useRef, useContext, Suspense, lazy} from 'react';

import {
  SafeAreaView,
  ScrollView,
  //StatusBar,
  StyleSheet,
  TextInput,
  //useColorScheme,
  View,
  ImageBackground,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  Image,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import CodeInput from './../Basic/CodeInput';
//import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import {api} from '../System/api';

import * as auth from '../GlobalStore/reducers/authSlice';

import {
  GrayButton,
  LoadingOverlay,
  PhoneInput,
} from '../Basic/BasicElements';
import AppText from '../Basic/AppText';
import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';
import {DimensionsContext} from '../Defaults/DisplayWidth';
//import phoneInput from '../Basic/PhoneInput';
import NewButton from '../Basic/NewButton';
import CheckBox from '../Basic/CheckBox';
import {useNavigation, useRoute} from '@react-navigation/native';
import {metricEvent} from '../Metrics/MetricHelper';

import * as statusBar from '../GlobalStore/reducers/statusBarSlice';
//import * as userProfile from '../GlobalStore/reducers/userProfileSlice';
import {useDispatch, useSelector} from 'react-redux';
import {skipToLogin} from '../GlobalStore/reducers/authSlice';
import settings from '../Defaults/settings';
import {useToast} from 'react-native-toast-notifications';
//import {useNavigation} from '@react-navigation/native';

//import agreements from '../Docs/DocumentLinks';
//import {BottomSheetScrollView} from '@gorhom/bottom-sheet';

import HtmlDocument from '../Docs/Types/Html';


const ErrorText = () =>
  <AppText style={{textAlign:'center', flex:1, flexDirection: 'center'}}>
    {'Попробуйте ещё раз немного позднее, либо обратитесь к администратору'}
  </AppText>;


const LoginBackground = props => {
  const {children} = props;
  return (
    <ImageBackground
      style={styles.loginBackground}
      source={require('./../../img/MyRestBackgroundKandinsky.jpg')}
      resizeMode="cover">
      {/*<LinearGradient
        style={styles.linearGradient}
        colors={['#ffffff00', '#fff', '#fff']}
      />*/}
      {/*<LinearGradient
        style={styles.linearGradient2}
        colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0)']}
      />*/}
      {props.children}
    </ImageBackground>
  );
};

const FlyingTag = props => {
  return <AppText style={[props.tagStyle, styles.flyTags]}>{props.content}</AppText>;
};

const secToFormat = (sec, format = 'mm:ss') => {
  let date = moment('2015-01-01', 'YYYY-MM-DD');
  date.startOf('day').seconds(sec);
  return date.format(format);
};

const TextButton = props => {
  const {containerStyle, textStyle, title, onPress} = props;
  return (
    <TouchableOpacity onPress={onPress} style={containerStyle}>
      <AppText style={textStyle}>{title}</AppText>
    </TouchableOpacity>
  );
};

const RegisterInput = props => {
  const {style, value, onChangeText, placeholder, wrongData} = props;
  const defaultStyle = {
    backgroundColor: 'rgba(128, 148, 156, 0.2)',
    height: 50,
    borderRadius: 10,
    fontSize: 16,
    paddingHorizontal: 25,
    //marginBottom: '4%',
    ...(Platform.OS === 'web' ? {outlineStyle: 'none'} : {}),
  };
  Object.assign(
    defaultStyle,
    wrongData
      ? {borderColor: 'rgba(235, 87, 87, 1)', borderWidth: wrongData ? 1 : 0}
      : {},
  );

  return (
    <TextInput
      style={[style, defaultStyle]}
      onChangeText={onChangeText}
      value={value}
      placeholder={placeholder}
    />
  );
};

const pause = sec => {
  return new Promise((rs, rj) => {
    setTimeout(rs, sec * 1000);
  });
};

const Register = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [step, setStep] = useState(1);
  const [mainTitle, setMainTitle] = useState('ПОДБЕРЕМ #ОТДЫХ');
  const [subTitle, setSubTitle] = useState('ПО ВАШИМ ИНТЕРЕСАМ');
  const [modalActive, setModalActive] = useState(false);
  const [loadOverlay, setLoadOverlay] = useState(false);
  const [wrongName, setWrongName] = useState(false);
  const [step3head, setStep3head] = useState({});
  const [step3Attempts, setStep3Attempts] = useState(1);
  const [isEmptyName, setIsEmptyName] = useState(true);
  const cdInterval = useRef(null);
  const phoneInputRef = useRef();
  const [countdown, setCountdown] = useState(59);
  const [stayAuth, setStayAuth] = useState(false);
  const [badCode, setBadCode] = useState(true);
  const [valid, setValid] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();


  const [state, setState] = useState({
    phone: {
      masked: '',
      obfuscated: '',
      unmasked: '',
    },
    secureCode: null,
    name: '',
    lastname: '',
    room: '',
    agreement: false,
  });
  const [secureCodeLength] = useState(5);

  const policySheet = useRef(null);
  const agreementSheet = useRef(null);
  const confidentialSheet = useRef(null);

  const toLoginSkipper = useSelector((state)=>state.auth.toLoginSkipper);
  const {width, height} = useContext(DimensionsContext);

  const sendPhone = () => {
    return new Promise((resolve, reject) => {
      setCountdown(59);
      startCountdown();
      /*if (state.phone.unmasked !== '9854446297') {
        resolve(true);
        return alert(`prod send sms to phone ${state.phone.unmasked} disabled`);
      }*/
      api.user
        .register({phone: '7' + state.phone.unmasked})
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  };

  const stopCountdown = () => {
    clearInterval(cdInterval.current);
  };

  const acceptCode = (value) => {
    let realCode = value || state.secureCode;
    //let realCode = secureCodeRef.current.state.codeArr.join('');
    //console.log('realCode', realCode);
    if (realCode.length < secureCodeLength) {
      return;
    }
    if (isEmptyName && state.name.length < 2) {
      setWrongName(true);
      return;
    }
    setLoadOverlay(true);
    let onError = (resp) => {
      setLoadOverlay(false);
      if (resp.data.token === false){
        setBadCode(true);
        return;
      }
      if (resp?.data?.error) {
        toast.show(resp?.data?.error, {title: 'Произошла ошибка', duration: 20000});
      } else {
        toast.show(<ErrorText/>, {title: 'Произошла ошибка.', duration: 200000});
      }
    };
    api.user
      .auth({
        phone: '7' + state.phone.unmasked,
        code: realCode,
        name: state.name,
        stay_auth: stayAuth,
      })
      .then(resp => {
        setLoadOverlay(false);

        if (resp?.data?.error) {
          console.log(resp.data);
          onError(resp);
          return;
        }
        if (resp.data.token === false){
          setBadCode(true);
          return;
        }
        if (resp.status !== 200) {
          __DEV__ && console.log('code auth error');
        } else {
          if (resp.data.token !== false)
          {
            if (!isEmptyName) { metricEvent('user_auth'); } else { metricEvent('user_register');}

            dispatch(auth.saveJwtToken(resp.data.token));
            dispatch(auth.setState('auth'));
            dispatch(auth.setFirstLogin(isEmptyName));
            //navigation.navigate('Main');
          }
        }
      })
      .catch(resp => {
        onError(resp);
      });
    /*pause(5).then(() => {
      setLoadOverlay(false);
      setStep(3);
    });*/
  };

  const acceptPhone = () => {
    /*console.log('phone:', state.phone);
    if (state.phone.unmasked.length === 10) {
      setStep(step + 1);
    }*/
    if (state.phone.unmasked.length !== 10) {
      return;
    }
    if (!state.agreement){
      setValid('Согласие на обработку данных обязательно');
      return;
    }
    let phone = '7' + state.phone.unmasked;

    setLoadOverlay(true);
    sendPhone()
      .then(res => {
        //console.log('SMS RESP:', res.data);
        setIsEmptyName(!!res.data?.emptyName);
        setLoadOverlay(false);
        setStep(2);
      })
      .catch(err => {
        setLoadOverlay(false);
        toast.show(<ErrorText/>, {title: 'Произошла ошибка.', duration: 200000});
      });
  };

  const _setState = data => {
    return setState(d => ({...d, ...data}));
  };

  const startCountdown = () => {
    __DEV__ && console.log('START COUNTDOWN');
    __DEV__ && console.log('interval_id', cdInterval.current);
    if (!cdInterval.current) {
      cdInterval.current = setInterval(() => {
        setCountdown(d => {
          if (d <= 0) {
            clearInterval(cdInterval.current);
            __DEV__ && console.log('STOP COUNTDOWN');
            cdInterval.current = null;
          }
          return d - 1;
        });
      }, 1000);
    } else {
      __DEV__ && console.log(countdown);
    }
  };

  useEffect(() => {
    let cStep = steps[step];
    if (cStep.mainTitle) {
      setMainTitle(cStep.mainTitle);
    }
    if (cStep.subTitle) {
      setSubTitle(cStep.subTitle);
    }
    if (cStep.init) {
      cStep.init();
    }

    return () => {
      if (cStep.destroy) {
        cStep.destroy();
      }
    };
  }, [step]);

  useEffect(() => {
    dispatch(statusBar.setStyle({backgroundColor: '#fff'}));
    if (route?.params?.start === null) {
      setStep(1);
    }
  }, []);

  useEffect(()=>{
    if (toLoginSkipper){
      setStep(1);
      dispatch(skipToLogin(false));
    }
  }, [toLoginSkipper]);

  const steps = {
    0: {
      mainTitle: 'ПОДБЕРЕМ #ОТДЫХ',
      subTitle: 'ПО ВАШИМ ИНТЕРЕСАМ',
      content: (
        <>
          <View
            style={{
              alignSelf: 'center',
              flex: 2,
              //flex: 3.4,
              justifyContent: 'space-evenly',
              maxWidth: 390,
              minWidth: 390,
            }}>
            <FlyingTag
              tagStyle={{marginLeft: '41%'}}
              content={'# ЭКСКУРСИЯ В ГОРЫ'}
            />
            <FlyingTag
              tagStyle={{marginLeft: '12%'}}
              content={'# УЖИН С ВИДОМ НА ГОРЫ'}
            />
            <FlyingTag
              tagStyle={{marginLeft: '18%'}}
              content={'# ПРОГУЛКА НА ЯХТЕ'}
            />
            <FlyingTag
              tagStyle={{marginLeft: '48%'}}
              content={'# ПОСЕЩЕНИЕ SPA'}
            />
            <FlyingTag
              tagStyle={{marginLeft: '13%'}}
              content={'# ПРОГУЛКА НА ЛОШАДЯХ'}
            />
            <FlyingTag
              tagStyle={{marginLeft: '36%'}}
              content={'# ДЕТСКИЙ ПАРК'}
            />
          </View>
          <View
            style={{
              ...(Platform.OS === 'web' ? {flex: 2} : {flex: 1.5}),
              justifyContent: 'flex-start',
              //backgroundColor:'#00ff004f',
            }}>
            <AppText
              style={[
                styles.servicesCounterText,
                {
                  fontSize: width < 350 || height < 750 ? 24 : 28,
                  marginTop: '15%',
                },
              ]}>
              БОЛЕЕ 6000 УСЛУГ{'\n'}ДЛЯ КАЖДОГО ИЗ ВАС
            </AppText>
          </View>
          <View
            style={{
              alignSelf: 'center',
              justifyContent: 'center',
              ...(Platform.OS === 'web'
                ? {
                    position: 'fixed',
                    bottom: 0,
                    width: width,
                    height: 140,
                  }
                : {flex: 1}),
            }}>
            <NewButton
              style={[styles.registerButton]}
              textStyle={styles.buttonTitle}
              onPress={() => {
                setStep(step + 1);
                if (Platform.OS === 'web') {
                  window.ym(87017273, 'reachGoal', 'register_button');
                }
              }}
            >
              {'ЗАРЕГИСТРИРОВАТЬСЯ / ВОЙТИ'}
            </NewButton>
            {settings.enableAuthSkip ?
            <GrayButton
              containerStyle={styles.skipButton}
              onPress={() => {
                dispatch(auth.setState('skip'));
                //navigation.navigate('Main');
              }}>
              Пропустить
            </GrayButton> : null}
          </View>
        </>
      ),
    },
    1: {
      mainTitle: 'ПРОГРАММА ЛОЯЛЬНОСТИ',
      subTitle: 'ШАГ 1 ИЗ 2',
      content: (
        <>
          <View
            style={{
              flex: 3,
              //justifyContent: 'center',
              marginTop: '16%',
            }}>
            <View style={styles.authPanel}>
              <AppText
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 16,
                  lineHeight: 23,
                  color: 'rgba(128, 148, 156, 1)',
                }}>
                Укажите Ваш мобильный телефон
              </AppText>
              <PhoneInput
                ref={phoneInputRef}
                style={{
                  marginTop: 14,
                  fontSize: 22,
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  letterSpacing: 4,
                }}
                onChange={p => _setState({phone: p})}
              />
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 24,
                  alignItems: 'center',
                }}>
                <CheckBox
                  checked={state.agreement}
                  color={valid && !state.agreement ? '#d9782f' : null}
                  onChange={c=>{
                    _setState({agreement:c});
                    setValid('');
                  }}
                />
                <AppText
                  style={{
                    flex: 8,
                    paddingLeft: 8,
                    //paddingTop: 6,
                  }}>
                  Даю свое согласие на подключение к программе лояльности, а так же обработку своих персональных данных
                   в соответствие с{' '}
                  <AppText
                    onPress={() => {
                      policySheet.current.open();
                    }}
                    style={{textDecorationLine: 'underline'}}>
                    политикой обработки ПД
                  </AppText>
                  {' '}и{' '}
                  {/*<AppText
                    onPress={() => {
                      confidentialSheet.current.open();
                    }}
                    style={{textDecorationLine: 'underline'}}>
                    политикой конфиденциальности
                  </AppText>*/}
                  <AppText
                    onPress={() => {
                      agreementSheet.current.open();
                    }}
                    style={{textDecorationLine: 'underline'}}
                  >
                    пользовательским соглашением
                  </AppText>
                </AppText>
              </View>
              <View style={{marginVertical: 24, alignSelf: 'center'}}>
                <AppText style={styles.valid}>
                  {valid}
                </AppText>
                <NewButton
                  style={{width: '100%'}}
                  textStyle={styles.buttonTitle}
                  onPress={acceptPhone}
                >
                  {'ЗАРЕГИСТРИРОВАТЬСЯ / ВОЙТИ'}
                </NewButton>
              </View>
            </View>
          </View>
          {settings.enableAuthSkip ?
          <GrayButton
            containerStyle={{
              position: 'absolute',
              bottom: 10,
              alignSelf: 'center',
            }}
            onPress={() => {
              dispatch(auth.setState('skip'));
              //navigation.navigate('Main');
            }}>
            Пропустить
          </GrayButton> : null}
        </>
      ),
      init: () => {
        phoneInputRef?.current?.focus();
      },
    },
    2: {
      mainTitle: 'ПРОГРАММА ЛОЯЛЬНОСТИ',
      subTitle: 'ШАГ 2 ИЗ 2',
      content: (
        <>
          <View
            style={{
              alignSelf: 'center',
              justifyContent: 'center',
              flex: 7,
              flexDirection: 'column',
              width: '100%',
            }}>
            <View style={styles.authPanel}>
              <View>
                <AppText
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: 18,
                    lineHeight: 26,
                  }}>
                  Введите пин-код, отправленный в sms на номер{' '}
                  {state.phone ? state.phone.masked : '< Нет номера >'}
                </AppText>
              </View>
              <View style={{marginTop: '3%'}}>
                <CodeInput
                  codeLength={secureCodeLength}
                  badCode={badCode}
                  onChange={v => {
                    _setState({secureCode: v});
                    setBadCode(false);
                  }}
                  onFullFill={v=>{
                    setBadCode(false);
                    _setState({secureCode: v});
                    acceptCode(v);
                  }}
                />
              </View>
              {isEmptyName ? (
                <View style={{marginTop: '5%'}}>
                  <AppText
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: 18,
                      lineHeight: 26,
                    }}>
                    Введите Ваше имя
                  </AppText>
                  <RegisterInput
                    style={{marginTop: '3%'}}
                    onChangeText={v => {
                      setWrongName(false);
                      _setState({name: v});
                    }}
                    wrongData={wrongName}
                  />
                </View>
              ) : null}
              <View
                style={{
                  width: '100%',
                  marginTop: '10%',
                  alignSelf: 'center',
                }}>
                <NewButton
                  style={{width: '100%'}}
                  onPress={() => {
                    acceptCode();
                  }}
                >
                  {'ПОДТВЕРДИТЬ'}
                </NewButton>
              </View>
              <View
                style={{
                  marginTop: '7%',
                }}>
                <AppText
                  style={{
                    color: '#7C829C',
                    textAlign: 'center',
                    marginBottom: '7%',
                  }}>
                  Не пришел пин-код?
                  {countdown > 0
                    ? '\nПовторная отправка через ' + secToFormat(countdown)
                    : null}
                </AppText>
                {countdown > 0 ? null : (
                  <GrayButton
                    containerStyle={{
                      alignSelf: 'center',
                      width: '100%',
                      marginBottom: '7%',
                    }}
                    style={{
                      textAlign: 'center',
                    }}
                    onPress={() => {
                      sendPhone().then(res => __DEV__ && console.log('RESMS:', res.data));
                    }}
                    title={'Отправить повторно'}
                  />
                )}
              </View>
            </View>
          </View>
          {/*<View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '5%',
            }}>
            <AppText
              style={{
                fontSize: 16,
                lineHeight: 35,
                paddingHorizontal: 16,
              }}>
              Оставаться в системе
            </AppText>
            <Switch
              trackColor={{false: '#767577', true: defaultColors.mainTrDark /*'#1FA1554D'*!/}
              thumbColor={stayAuth ? defaultColors.main : '#f4f3f4'}
              ios_backgroundColor="#fff"
              onValueChange={() => setStayAuth(v => !v)}
              value={stayAuth}
            />
          </View>*/}
        </>
      ),
      init: () => {
        startCountdown(59);
      },
      destroy: () => {
        stopCountdown();
      },
    },
  };

  return (
    <SafeAreaView style={{height: '100%', minHeight: 600}}>
      <LoginBackground />
      <View
        style={{
          flex: 1.4,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {/*<MrSvgText />*/}
        <Image
          source={require('./../../img/logo/LOGO_150.png')}
          style={{
            marginTop: Platform.OS === 'web' ? 45 : 30,
            height: 90,
            minWidth: 220,
            //marginRight: '-6%',
            //resizeMode: 'center',
            opacity: 0.9,
          }}
          resizeMode={'contain'}
          resizeMethod={'scale'}
        />
      </View>
      <View
        style={{
          flex: 2.6,
          alignItems: 'center',
          justifyContent: 'center',
          //backgroundColor: '#dedede4f',
        }}>
        <AppText
          style={{
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: width < 350 || height < 750 ? 20 : 22,
            textAlign: 'center',
            letterSpacing: 1,
            textTransform: 'uppercase',
          }}>
          {mainTitle}
        </AppText>
        <AppText
          style={{
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: width < 350 || height < 750 ? 13 : 17.5,
            textAlign: 'center',
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: 'green',
          }}>
          {subTitle}
        </AppText>
      </View>
      <View style={styles.mainContainer}>
        {steps[step].content}
      </View>
      <SimpleBottomSheet ref={confidentialSheet} open={false} >
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            backgroundColor: 'white',
          }}>
          <ScrollView style={{width: '100%'}} contentContainerStyle={{height: '100%', width:'100%'}}>
            <Suspense fallback={<ActivityIndicator />}>
            </Suspense>
          </ScrollView>
        </View>
      </SimpleBottomSheet>
      <SimpleBottomSheet ref={agreementSheet} open={false} >
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            backgroundColor: 'white',
          }}>
          <ScrollView style={{width: '100%'}} contentContainerStyle={{height: '100%', width:'100%'}}>
            <Suspense fallback={<ActivityIndicator />}>
              <HtmlDocument useTitle={false} id={3} />
            </Suspense>
          </ScrollView>
        </View>
      </SimpleBottomSheet>
      <SimpleBottomSheet ref={policySheet} open={false} >
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            backgroundColor: 'white',
          }}>
          <ScrollView style={{width: '100%'}} contentContainerStyle={{height: '100%', width:'100%'}}>
              <Suspense fallback={<ActivityIndicator />}>
                <HtmlDocument useTitle={false} id={2} />
              </Suspense>
          </ScrollView>
        </View>
      </SimpleBottomSheet>
      <LoadingOverlay loading={loadOverlay} />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  authPanel: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,

    elevation: 2,
    backgroundColor: '#fff',
    width: '90%',
    maxWidth: 390 * 0.9,
    borderRadius: 20,
    alignSelf: 'center',
    paddingTop: '7%',
    paddingHorizontal: '6%',
    alignContent: 'stretch',
    flexDirection: 'column',
    marginBottom: 100,
  },
  textMrLogo: {
    position: 'relative',
    bottom: 60,
    alignSelf: 'center',
    height: '20%',
    backgroundColor: '#fff',
  },
  servicesCounterText: {

    fontStyle: 'normal',
    textAlign: 'center',
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 38,
    color: '#FFFFFF',
  },
  servicesCounter: {
    width: 308,
    height: '20%',
    alignSelf: 'center',
    bottom: 170,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  linearGradient: {
    width: '100%',
    height: 230,
    position: 'absolute',
    bottom: 0,
    left: 0,
    //flex: 1,
  },
  linearGradient2: {
    width: '100%',
    height: 400,
    position: 'absolute',
    bottom: 90,
    left: 0,
  },
  loginBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    //width: '100%',
    flex: 1,
    //height: '100%',
  },
  buttonTitle: {
    color: 'white',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 16,
    letterSpacing: 1,
    textAlign: 'left',
  },
  registerButton: {
    width: 340,
    //height: 40,
    //backgroundColor: '#1FA357',
  },
  skipButton: {
    position: 'absolute',
    bottom: 10,
    alignSelf: 'center',
    height: 30,
  },
  mainContainer: {
    flex: 10,
  },
  flyTags: {

    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    //lineHeight: 30,
    letterSpacing: 1,
    textTransform: 'uppercase',
    color: 'white',
  },
  codeFieldRoot: {marginTop: 20},
  scCell: {
    width: 40,
    height: 40,
    lineHeight: 38,
    fontSize: 24,
    borderWidth: 2,
    borderColor: '#00000030',
    textAlign: 'center',
  },
  scFocusCell: {
    borderColor: '#000',
  },
  valid: {
    textAlign: 'center',
    color: '#8f3d00',
    justifyContent: 'flex-end',
    marginBottom: 5,
  },
});

export default Register;
