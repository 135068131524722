import {Avatar} from '@rneui/themed';
import {View} from 'react-native';
import React from 'react';
import {useNavigation} from '@react-navigation/native';

const HeaderAvatar = props => {
  const navigation = useNavigation();
  return (
    <View style={{width: 40, paddingRight: '4%'}}>
      <Avatar
        onPress={() => navigation.navigate({name:'Profile'})}
        size={40}
        rounded
        source={require('../../img/userDefaultAvatar.png')}
      />
    </View>
  );
};

export default HeaderAvatar;
