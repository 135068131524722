import React from 'react';
import {
  Image,
  TouchableOpacity,
  View,
} from 'react-native';
import {Icon} from '@rneui/themed';
//import {CardFade} from '../Cards/CardElements';
//import MaskInput from 'react-native-mask-input';
//import TariffsMiniView from '../Tariffs/TariffsMiniView';
//import humanize from 'humanize-number';
import AppText from '../Basic/AppText';

export AppButton from './AppButton';
export GrayButton from './GrayButton';
export MrSvgText from './MrSvgText';
export Tag from './Tag';
export EmptyPage from './EmptyPage';
export AntiMenuFade from './AntiMenuFade';
export ListFooter from './ListFooter';
export Divider from './Divider';
export LoadingOverlay from './LoadingOverlay';
export PageHeader from './PageHeader';
export DateRangeView from './DateRangeView';
export CloserArrow from './CloserArrow';
export LoginButton from './LoginButton';
export PhoneOverlay from './PhoneOverlay';
export AuthOverlay from './AuthOverlay';
export PromiseOverlay from './PromiseOverlay';
export PhoneInput from './PhoneInput';

const MsngrButtonLabel = ({title, children, style, containerStyle}) => {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
          height: 30,
          flexWrap: 'wrap',
          marginTop: 10,
        },
        containerStyle,
      ]}>
      <AppText
        style={[
          {
            alignSelf: 'center',
            textAlign: 'center',

            fontSize: 13,
            lineHeight: 15,
            color: '#52646B',
          },
          style,
        ]}>
        {children || title}
      </AppText>
    </View>
  );
};

export {MsngrButtonLabel};

export const WhatsAppButton = ({onPress, size, title, titleStyle}) => {
  return (
    <View>
      <TouchableOpacity
        style={{
          width: size || 60,
          height: size || 60,
          borderRadius: 50,
          backgroundColor: 'rgba(38, 211, 103, 1)',
          justifyContent: 'center',
          alignSelf: 'center',
        }}
        onPress={onPress}>
        <Icon
          style={{alignSelf: 'center'}}
          name={'whatsapp'}
          type={'fontisto'}
          size={size ? size * 0.5833 : 35}
          color={'white'}
        />
      </TouchableOpacity>
      {title ? (
        <MsngrButtonLabel containerStyle={titleStyle} title={title} />
      ) : null}
    </View>
  );
};
export const TelegramButton = ({onPress, size, title, titleStyle}) => {
  return (
    <View>
      <TouchableOpacity
        style={{
          width: size || 60,
          height: size || 60,
          borderRadius: 50,
          backgroundColor: 'rgba(36, 160, 221, 1)',
          justifyContent: 'center',
          alignSelf: 'center',
        }}
        onPress={onPress}>
        <Icon
          style={{alignSelf: 'center'}}
          name={'telegram-plane'}
          type={'font-awesome-5'}
          size={size ? size * 0.5833 : 35}
          color={'white'}
        />
      </TouchableOpacity>
      {title ? (
        <MsngrButtonLabel containerStyle={titleStyle} title={title} />
      ) : null}
    </View>
  );
};
export const CallButton = ({onPress, size, title, titleStyle}) => {
  return (
    <View>
      <TouchableOpacity
        style={{
          width: size || 60,
          height: size || 60,
          borderRadius: 50,
          backgroundColor: 'rgba(47, 128, 237, 1)',
          justifyContent: 'center',
          alignSelf: 'center',
        }}
        onPress={onPress}>
        <Icon
          style={{alignSelf: 'center'}}
          name={'phone'}
          type={'feather'}
          size={size ? size * 0.5833 : 35}
          color={'white'}
        />
      </TouchableOpacity>
      {title ? (
        <MsngrButtonLabel containerStyle={titleStyle} title={title} />
      ) : null}
    </View>
  );
};
export const CallLaterButton = ({onPress, size, title, titleStyle}) => {
  return (
    <View>
      <TouchableOpacity
        style={{
          width: size || 60,
          height: size || 60,
          backgroundColor: '#52646B',
          borderRadius: 50,
          justifyContent: 'center',
          alignSelf: 'center',
        }}
        onPress={onPress}>
        <Image
          style={{
            width: size ? size * 0.6666 : 40,
            height: size ? size * 0.6666 : 40,
            alignSelf: 'center',
          }}
          source={require('./../../img/png_icons/CallLater.png')}
        />
      </TouchableOpacity>
      {title ? (
        <MsngrButtonLabel
          containerStyle={[{width: 60}, titleStyle]}
          title={title}
        />
      ) : null}
    </View>
  );
};
