import React from 'react';
import {View, StyleSheet} from 'react-native';
import humanize from 'humanize-number';
import AppText from '../Basic/AppText';

const Tariff = ({price,count,title}) => {
  return (
    <View style={styles.tariffContainer}>
      <View style={styles.tariffTitleContainer}>
        <View style={styles.tariffTitle}>
          <AppText style={styles.tariffTitleText}>{title}</AppText>
        </View>
        <View style={styles.priceContainer}>
          <AppText style={styles.price}>
            {humanize(parseInt(price), {delimiter: ' '}) + ' ₽'}
          </AppText>
        </View>
      </View>
      <View style={styles.tariffCountContainer}>
        <AppText style={styles.tariffCount}>{' x '}{count}</AppText>
      </View>
    </View>
  );
};

const TariffsBookingView = ({tariffs}) => {
  return (<View>
    {tariffs.map(item=> (
        <Tariff {...item} {...item.offer_item} />
      )
    )}
  </View>);
};

const styles = StyleSheet.create({
  tariffItemsCounter:{
    //padding: 10,
    flex: 1,
  },
  price:{
    textAlign: 'right',

    fontSize: 16,
  },
  totalContainer:{
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  totalText:{

    fontSize: 18,
    color: '#777676',
  },
  tariffTitleContainer:{
    flex:15,
    flexDirection: 'row',
    //borderColor: 'silver',
    //borderWidth: 1,
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    margin: 3,//for shadow
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    justifyContent: 'center',
  },
  tariffContainer:{
    flexDirection: 'row',
    //borderColor: 'silver',
    //borderWidth: 1,
    marginBottom: 4,
    backgroundColor: 'white',
    justifyContent: 'center',
  },
  tariffPointActive:{
    backgroundColor: '#23b865',
  },
  tariffTitle:{
    flex: 9.5,
    justifyContent: 'center',
  },
  tariffTitleText:{

    fontSize: 14,
    fontWeight: '400',
    lineHeight: 17,
  },
  tariffCountContainer:{
    flex: 2,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    paddingRight: 1,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    margin: 3,//for shadow
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    borderRadius: 50,
  },
  tariffCount:{
    fontSize: 16,
    textAlign:'center',
    //alignSelf: 'flex-start',
    textAlignVertical: 'center',
  },
  priceContainer:{
    flex: 5.5,
    justifyContent: 'center',
  },
});

export default TariffsBookingView;
