import React from 'react';
import {View} from 'react-native';
import {BlurView as BlurViewExpo} from 'expo-blur';

const blurTypeMap = {
  xlight: 'light',
  light:'light',
  dark:'dark',
  extraDark:'light',
  regular:'light',
  prominent:'light',
  chromeMaterial: 'light',
  material: 'dark',
  thickMaterial: 'dark',
  thinMaterial: 'dark',
  ultraThinMaterial: 'dark',
  chromeMaterialDark: 'dark',
  materialDark: 'dark',
  thickMaterialDark: 'dark',
  thinMaterialDark: 'dark',
  ultraThinMaterialDark: 'dark',
  chromeMaterialLight: 'light',
  materialLight: 'light',
  thickMaterialLight: 'light',
  thinMaterialLight: 'light',
  ultraThinMaterialLight: 'light',
};

const BlurView = ({
  blurType,
  blurAmount,
  reducedTransparencyFallbackColor,
  children,
  ...other
}) => {
  return (
    <BlurViewExpo
      intensity={blurAmount}
      tint={blurTypeMap[blurType] || 'default'}
      {...other}
    >
      {children}
    </BlurViewExpo>
  );
};

const VibrancyView = ({children, ...props}) => {
  console.warn('Vibrancy is not supported in WEB');
  return (<View {...props} >{children}</View>);
};

export default {BlurView, VibrancyView};
