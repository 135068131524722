import React, {useRef} from 'react';
import {BottomSheetModalProvider} from '@gorhom/bottom-sheet';
import MapBottomSheet, {MapProvider} from './MapBottomSheet';
import {BookingProvider} from '../Booking/Context';
import {DynamicBottomSheetProvider} from './DynamicBottomSheetContext';

const BottomSheetsProviders = ({children}) => {
  const mapRef = useRef();

  return (
    <MapProvider mapRef={mapRef}>
      <DynamicBottomSheetProvider>
        <BookingProvider>
          <BottomSheetModalProvider>
              {children}
            <MapBottomSheet ref={mapRef} />
          </BottomSheetModalProvider>
        </BookingProvider>
      </DynamicBottomSheetProvider>
    </MapProvider>
  );
};

export default BottomSheetsProviders;
