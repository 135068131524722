import {api} from '../System/api';

const outletAdapter = ({
                       id,
                       name,
                       preview,
                       description,
                       shortDescription,
                       image,
                       schedule,
                       autletLoyaltyDiscounts,
                       ...other
                     }) => {

  try {
    schedule =  (typeof schedule === 'object' ? schedule : JSON.parse(schedule));
  } catch (e) {schedule = {};}

  let loyaltyDiscounts = {};

  try {
    loyaltyDiscounts = (autletLoyaltyDiscounts || []).reduce((a, v) => ({
      ...a,
      [v?.loyaltyLevelName.toLowerCase()]: v?.discountPercent,
    }), {});
  } catch (e) {}

  let card = {
    id,
    name,
    mainImage:
      image && /^\/uploads\/autlet\/.+?\.\w+?$/.test(image)
        ? {uri: api.baseurl + image.trim('/')}
        : require('./../../img/mr_view_blur.jpg'),
    description,
    shortDescription,
    schedule,
    loyaltyDiscounts,
    ...other,
  };
  return card;
};

export default outletAdapter;
