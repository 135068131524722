import React, {useState, useImperativeHandle, useEffect, forwardRef} from 'react';
import {ActivityIndicator, Modal, View} from 'react-native';

const LoadingOverlay = forwardRef((props, ref) => {
  const {loading, ...other} = props;
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    show: wait => {
      setShow(true);
      if (wait) {
        return new Promise(resolve => {
          let wi = setInterval(() => {
            if (ref?.current?.isVisible()) {
              clearInterval(wi);
              return resolve();
            }
          }, 50);
        });
      }
    },
    hide: wait => {
      setShow(false);
      if (wait) {
        return new Promise(resolve => {
          let wi = setInterval(() => {
            if (!ref?.current?.isVisible()) {
              clearInterval(wi);
              return resolve();
            }
          }, 50);
        });
      }
    },
    isVisible: () => visible,
  }));

  useEffect(() => {
    setShow(loading);
  }, [loading]);

  return (
    <Modal
      animationType="fade"
      visible={show}
      transparent={true}
      onRequestClose={() => {}}
      onShow={() => setVisible(true)}
      onDismiss={() => setVisible(false)}
      {...other}>
      <View
        style={{
          backgroundColor: '#ffffffcf',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}>
        <ActivityIndicator size="large" />
      </View>
    </Modal>
  );
});

export default LoadingOverlay;
