import React, {lazy} from 'react';
import LazyPage from '../Basic/LazyPage';

const Component = lazy(() =>
  import('./Main.js'),
);

const Lazy = () =>
  <LazyPage Component={Component} />;

export default Lazy;
