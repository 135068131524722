import moment from 'moment-timezone';
import {api} from '../System/api';
import offerAdapter from '../Offers/OfferAdapter';
import {isObject} from '../System/helpers';
import categoryAdapter from '../Interests/CategoryAdapter';


const activityAdapter = ({
  id,
  title,
  description,
  location,
  price,
  date,
  category,
  categoryFilter,
  image,
  video,
  offer,
  offerId,
  points,
  apiSchedule,
  duration,
}) => {
  category = category || [];
  let categories = category.map(categoryAdapter);
  //console.log({duration});

  let categoryIndex =
  categoryFilter
    ? Math.max(
      categories.findIndex(v => categoryFilter && categoryFilter.includes(v.id)),
      0,
    )
    : 0;
  category = categories[categoryIndex];
  points = points && isObject(points) ? Object.values(points) : points;

  let activity = {
      id,
      title,
      description,
      place: location,
      payment: !!price,
      price,
      category: {
        ...category,
        type: 'category',
      },
      categories,
      date,
      time: date ? moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ', true).format('HH:mm') : undefined, //2022-03-17T02:00:00+00:00
      preview: image && /^\/uploads\/events\/.+?\.\w+?$/.test(image)
        ? {uri: api.baseurl + image.trim('/')}
        : require('./../../img/mr_view_blur.jpg'),
      video: video && /^\/uploads\/events\/.+?\.\w+?$/.test(video)
        ? {uri: api.baseurl + video.trim('/')}
        : undefined,
      offer: offer ? offerAdapter(offer) : null,
      points,
      offerId,
      apiSchedule,
      duration,
  };
  return activity;
};

export default activityAdapter;
