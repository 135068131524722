import {createSelector, createSlice} from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';

const initialState = {
  drafts: {},
};

const mergeDrafts = (items) => new Promise((resolve, reject) =>
{
  AsyncStorage.getItem('draftBooking')
  .then((data) => {
    let drafts = null;
    try { drafts = JSON.parse(data);} catch (e) {}
    drafts = drafts ? drafts : {};
    const merged = {...drafts, ...items};
    AsyncStorage.setItem('draftBooking', JSON.stringify(merged))
    .then(() => {
      resolve(merged);
    })
    .catch(e=>reject(e));
  })
  .catch(e=>reject(e));
});

export const draftBookingSlice = createSlice({
  name: 'draftBooking',
  initialState,
  reducers: {
    setState: (state, {payload}) => {
      state.drafts = payload;
    },
  },
});

const add = (payload) => (dispatch, getState) => {
  const {offer, booking, navParams} = payload;
  const draftId = offer.id;
  let state = getState().draftBooking;
  const newState = {
    ...state.drafts,
    [draftId]: {
      offer,
      booking,
      dateCreate: (Date.now()),
      dateExpire: (Date.now() + (24 * 60 * 60 * 1000)), // 1 day to expired
      touched: false,
      navParams,
    },
  };
  mergeDrafts(newState).then((merged) => {
    console.log('dispatch merged', merged);
    dispatch(draftBookingSlice.actions.setState(merged));
  });
};

const remove = (payload) => (dispatch, getState) =>
{
  let state = getState().draftBooking;
  let newState;
  if (state.drafts[payload]) {
    newState = state.drafts.filter(
      v => toString(v.offer.id) !== toString(payload)
    );
    AsyncStorage.setItem('draftBooking', JSON.stringify(newState)).then(() => {
      dispatch(draftBookingSlice.actions.setState(newState));
    });
  }
};

const init = () => (dispatch) => {
  AsyncStorage.getItem('draftBooking').then(data => {
    console.log('<<<initialization of booking drafts>>>', data);
    let drafts = null;
    try {drafts = JSON.parse(data);} catch (e) {}
    if (drafts === null){
      drafts = {};
      AsyncStorage.setItem('draftBooking', JSON.stringify(drafts)).then();
    }
    let now = Date.now();
    let deleted = 0;
    /* delete expired drafts */
    for (let i in drafts){
      if (drafts[i] && (!drafts[i].dateExpire || drafts[i].dateExpire < now)) {
        delete drafts[i];
        deleted++;
      }
    }
    if (deleted > 0) {
      AsyncStorage.setItem('draftBooking', JSON.stringify(drafts)).then();
    }
    dispatch(draftBookingSlice.actions.setState(drafts));
  }).catch(e=>{
    __DEV__ && console.warn('cant init booking drafts');
  });
};

const touch = (payload) => (dispatch, getState) => {
  let state = getState().draftBooking;
  if (state.drafts[payload]){
    let newState = {
      ...state.drafts,
      [payload]:{
        ...state.drafts[payload],
        touched: true,
      },
    };
    mergeDrafts(newState).then((merged) => {
      dispatch(draftBookingSlice.actions.setState(merged));
    });
  }
};

const select = (state) => state.draftBooking.drafts;
const selectLast = createSelector(
  [select],
  drafts => Object.values(drafts).reduce((a,v)=>
  {
    if (a === null || v.dateCreate >= (a?.dateCreate || 0))
    {return v;} else {return a;}
  }, null)
);
export const {setState} = draftBookingSlice.actions;
export {init, add, remove, selectLast, select, touch};

export default draftBookingSlice.reducer;
