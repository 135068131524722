import React, {useContext} from 'react';
import PageHeader from '../Basic/PageHeader';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import Bookings from '../Main/BookingsNew';
import {BonusTransactionsList} from '../Loyalty/BonusTransactions';
import {SafeAreaView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
//import AppText from '../Basic/AppText';

const Tab = createMaterialTopTabNavigator();
const BookingsNav = props => {
  const {defaultColors} = useSelector((state) => state.style);
  return (
    <SafeAreaView style={styles.safeArea}>
      <PageHeader title={''}/>
      <Tab.Navigator
        screenOptions={{
          tabBarIndicatorStyle: {
            height: 4,
            backgroundColor: defaultColors.main,
          },
        }}>
        <Tab.Screen
          options={{tabBarLabel: 'Мои заказы'}}
          name="BookingsList"
          component={Bookings}
        />
        <Tab.Screen
          options={{tabBarLabel: 'Списания и зачисления'}}
          name="Transactions"
          component={BonusTransactionsList}
        />
      </Tab.Navigator>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
});

export default BookingsNav;
