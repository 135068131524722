import {createDataStoreSlice} from '../../System/DataStoreRedux';
import storeParams from '../../Interests/userInterestsStoreParams';
import {api} from '../../System/api';

const userInterestsSlice = createDataStoreSlice(
  storeParams
);

export const {resetValue, setValue} = userInterestsSlice.storeSlice.actions;
export const {getValue, reloadValue, init, select} = userInterestsSlice;

export const save = () => (dispatch, getState) => {
  let state = getState();
  let authState = state.auth.state;
  let userInterests = state.userInterests.value;

  console.log({'saveUserInterests':1, authState, userInterests});

  authState === 'auth' &&
  api.user
    .setInterests(userInterests)
    .then(res => {
      /* TODO: create trouble checking */
      __DEV__ && console.log('save user interests res:',res);
    })
    .catch(res => {
      __DEV__ && console.warn(res);
    });
};

export default userInterestsSlice.storeSlice.reducer;
