import React, {useEffect, useImperativeHandle, useState, forwardRef} from 'react';

const Confirm = forwardRef((props, ref) => {
  const {Component, visible, componentProps} = props;
  const [state, setState] = useState({});
  const [show, setShow] = useState(false);
  const [onConfirmAction, setOnConfirmAction] = useState(() => {});
  const [onCancelAction, setOnCancelAction] = useState(() => {});
  const softSetState = st => setState(v => ({...v, ...st}));

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  useImperativeHandle(ref, () => ({
    show: ({data, onConfirm, onCancel}) => {
      setState(v => ({...v, ...data}));
      if (onConfirm) {
        setOnConfirmAction(v => () => {
          //console.log('CONFIRM');
          onConfirm({...data, ...ref.current.getData()});
        });
      }
      if (onCancel) {
        setOnCancelAction(v => () => {
          //console.log('CANCEL');
          onCancel({...data, ...ref.current.getData()});
        });
      }
      setShow(true);
    },
    hide: () => {
      setShow(false);
    },
    getData: () => state,
  }));

  return (
    <>
      <Component
        show={show}
        {...componentProps}
        onConfirm={() => onConfirmAction()}
        onCancel={() => onCancelAction()}
        onClose={() => setShow(false)}
        onChangeData={softSetState}
      />
    </>
  );
});

export default Confirm;
