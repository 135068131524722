import React, {useEffect, useRef, useState, forwardRef} from 'react';
import {TextInput} from 'react-native';

const TextInputTimeout = forwardRef((props, ref) =>
{
  const {timeout, onChangeText, onChangeTextTimeout, autoFocus} = props;

  const [value, setValue] = useState(value);
  const timeoutRef = useRef();
  const inputRef = useRef();

  const handleTextChange = (text) => {
    setValue(text);
    onChangeText && onChangeText(text);
  };

  useEffect(()=>{
    if (timeout && value !== undefined) {
      clearInterval(timeoutRef.current);
      timeoutRef.current = setTimeout(()=>{
        onChangeTextTimeout && onChangeTextTimeout(value);
      }, timeout);
    }
    return () => {
      clearInterval(timeoutRef.current);
    };
  },[value, timeout]);

  useEffect(()=>{
    autoFocus && inputRef.current?.focus();
  }, [autoFocus]);

  const handleBlur = () => {
    clearInterval(timeoutRef.current);
    onChangeTextTimeout && onChangeTextTimeout(value);
  };

  return (
    <TextInput
      ref={inputRef}
      {...props}
      onChangeText={handleTextChange}
      returnKeyType={'search'}
      onBlur={handleBlur}
    />
  );
});

export default TextInputTimeout;
