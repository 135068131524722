import React from 'react';
import AppText from '../Basic/AppText';
import {View, StyleSheet} from 'react-native';


const bookingStates = {
  deleted: {
    color: '#ffa7a7',
    text: 'Удалена',
  },
  waiting: {
    color: '#fff2ea',
    text: 'Ожидает подтверждения',
  },
  cancel: {
    color: '#ffd7b7',
    text: 'Ожидает отмены',
  },
  canceled: {
    color: '#ffc1b0',
    text: 'Отменён',
  },
  booked: {
    color: '#c4f5b1',
    text: 'Подтверждено',
  },
  approved: {
    color: '#c4f5b1',
    text: 'Подтверждено',
  },
  change: {
    color: '#f5dbff',
    text: 'Ожидает изменения',
  },
  changed: {
    color: '#c8f4ff',
    text: 'Изменено',
  },
  tmp: {
    color: '#d3d3d3',
    text: 'В процессе подтверждения',
  },
  payment_waiting: {
    color: '#ffd7b7',
    text: 'Ожидание платежа',
  },
  paid: {
    color: '#c4f5b1',
    text: 'Оплачено',
  },
  process: {
    color: '#c4e8e8',
    text: 'Оказание услуги',
  },
};

const StatusView = ({text, color, containerStyle, textStyle}) => {
  return (
    <View style={[styles.statusBox,{backgroundColor: color}, containerStyle]}>
      <AppText style={[styles.status, textStyle]}>
        {text}
      </AppText>
    </View>
  );
};

const prepareState = (status) => {
  return bookingStates[status] || bookingStates.waiting;
};

const tngStatusConverter = (status) => {
  let tngBookingStates = {
    PAID: bookingStates.paid,
    COMPLETE: bookingStates.approved,
    INCOMPLETE: bookingStates.canceled,
    CANCELLED: bookingStates.canceled,
    MISSED: bookingStates.canceled,
    TREATMENT: bookingStates.process,
  };
  return tngBookingStates[status] || bookingStates.waiting;
};

const prepareStateTng = (booking) => {
  if (booking?.paymentData){
    return  prepareState(booking.paymentData.status);
  } else {
    return tngStatusConverter(booking.status);
  }
};

const styles = StyleSheet.create({
  statusBox : {
    borderRadius: 50,
    marginBottom: 16,
    alignSelf: 'flex-start',
    paddingVertical: 5,
    paddingHorizontal: 8,
  },
  status: {
    color: 'rgb(136,136,136)',
    fontSize: 13,
  },
});

export {
  bookingStates,
  StatusView,
  prepareState,
  prepareStateTng,
  tngStatusConverter,
};
