import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Image, Platform} from 'react-native';

export default class ScaledImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getImageDims = this.getImageDims.bind(this);
    this.updateDims = this.updateDims.bind(this);
  }

  getImageDims(source){
    return new Promise((resolve, reject) => {
      if (Platform.OS === 'web'){
        //console.log('try to get image size for web');
        Image.getSize(source?.uri || source, (width, height) => {
          resolve({width, height});
        });
      } else {
        if (source?.uri) {
          Image.getSize(source?.uri, (width, height) => {
            resolve({width, height});
          });
        } else {
          let {width, height} = Image.resolveAssetSource(source);
          resolve({width, height});
        }
      }
    });
  };

  updateDims = () => {
    this.getImageDims(this.props.source).then(({width, height}) => {
      // });
      if (this.props.width && !this.props.height) {
        this.setState({
          width: this.props.width,
          height: height * (this.props.width / width),
        });
      } else if (!this.props.width && this.props.height) {
        this.setState({
          width: width * (this.props.height / height),
          height: this.props.height,
        });
      } /*else {
        this.setState({width: width, height: height});
      }*/
    }).catch((e)=>{__DEV__ && console.warn(e);});
  };

  componentDidUpdate(prevProps ) {
    if (
      prevProps.source !== this.props.source
      || prevProps.width !== this.props.width){
      this.updateDims();
    }
  }

  UNSAFE_componentWillMount() {
    this.updateDims();
  }

  render() {
    return (
      <Image
        {...this.props}
        style={[
          this.props.style,
          {
            height: this.state.height,
            width: this.state.width,
          },
        ]}
      />
    );
  }
}

ScaledImage.propTypes = {
  //...Image.PropTypes,
  //styles: PropTypes.object
  width: PropTypes.number,
  height: PropTypes.number,
};
