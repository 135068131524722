import React from 'react';
import {Platform, SafeAreaView, TouchableOpacity, StyleSheet} from 'react-native';
import {Icon} from '@rneui/themed';
import {useGoBack} from './PageHeader';


const BackButton = ({containerStyle, style, beforeGoBack, dark}) => {
  const goBack = useGoBack();

  const handlePress = () => {
    beforeGoBack && beforeGoBack();
    goBack();
  };

  return (
    <SafeAreaView
      style={[styles.nav, containerStyle]}
    >
      <TouchableOpacity
        onPress={handlePress}
        style={[
          styles.back,
          dark ? styles.backDark : null,
          style,
        ]}
      >
        <Icon
          type={'material'}
          name={'arrow-back'}
          size={20}
          color={'#fff'}
        />
      </TouchableOpacity>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  nav: {
    //flex: 1,
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    zIndex: 110,
  },
  backDark: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  back: {
    marginTop: Platform.OS === 'web' ? 20 : 0,
    height: 40,
    width: 40,
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(117,117,117,0.68)',
    borderRadius: 50,
    //...defaultStyles.buttonShadow,
  },
});

export default BackButton;
