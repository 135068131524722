import React, {useEffect} from 'react';
import {View, StyleSheet} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
} from 'react-native-reanimated';
import {colors} from '../Defaults/Styles';

const Progress = ({percent}) => {
  const percentWidth = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {width: percentWidth.value + '%'};
  });

  const setPercent = (p) => {
    percentWidth.value =
      withDelay(
        300,
        withSpring(p)
      );
  };

  useEffect(()=>{
    if (percent){
      setPercent(percent);
    }
  }, [percent]);

  return (
    <View style={styles.full}>
      <Animated.View style={[styles.progress, animatedStyle]}/>
    </View>
  );
};

const styles = StyleSheet.create({
  full: {
    width: '100%',
    height: 12,
    borderRadius: 8,
    backgroundColor: colors.default.mainLight,
  },
  progress: {
    height: 12,
    borderRadius: 8,
    backgroundColor: colors.default.main,
  },
});

export default Progress;
