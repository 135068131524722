import React, {useEffect, useRef, useState} from 'react';
import {View, FlatList, StyleSheet, TouchableOpacity, ImageBackground} from 'react-native';
import offerAdapter from './OfferAdapter';
import AppText from '../Basic/AppText';
import {api} from '../System/api';
import LinearGradient from 'react-native-linear-gradient';
import OfferDetailsBottomSheet2 from '../BottomSheets/OfferDetailsBottomSheet2';

const SpecialOffersList = () => {
  const [offers, setOffers] = useState({});
  const [loading, setLoading] = useState(true);

  const offerDetailsRef = useRef();

  const generateId = card => 'i' + card.id;

  const loadOffers = async () => {
    //const ids =  id.toString().split(',');
    let params = {
      limit: 30,
      page: 1,
    };
    /*if (type === 'offers') {
      params.ids = ids;
    }*/

    while (1) {
      try {
        let res = await api.specialOffers.list(params);
        if (params.page === 1) {
          setOffers({});
          setLoading(false);
        }
        res.data.forEach( (spOffer) => {
          let offer_ids = spOffer.offers.map(item => item.id);
          if (offer_ids && offer_ids.length > 0) {
            api.offers.multipliedByInterests({id : offer_ids, limit: 10, page: 1}).then(offers => {
              offers.data.result.forEach((offer) => {
                offer.title = spOffer.title;
                offer.description = spOffer.description;
                offer.discount = spOffer.discount;
                if (spOffer.price){
                  offer.price = spOffer.price;
                  offer.priceFrom = 0;
                }
                let card = offerAdapter(offer);
                card.specialOffer = spOffer;
                card.disableBonusPayment = true;
                setOffers(v => ({
                  ...v,
                  [generateId(card)]: card,
                }));
              });
            });
          }
        });
        if (res.data.length === 0) {
          break;
        }
        params.page++;
      } catch (e) {
        //console.log('list error:',e);
        break;
      }
    }
  };
  useEffect(() => {
    setOffers({});
    loadOffers();
  }, []);

  const getOffer = (offer) => {
    return new Promise((resolve, reject) => {
      if (!offer?.id) {
        reject('offer not found');
      } else {
        resolve(offers[generateId(offer)]);
      }
    });
  };

  const updateOffer = (offer) => {
    let offerId = generateId(offer);
    setOffers(v => ({
        ...v,
        [offerId]: {
          ...v[offerId],
          ...offer,
        },
      })
    );
  };


  const renderSpecialOffer = ({item}) => {
    return (
      <TouchableOpacity
        style={styles.cardBox}
        onPress={()=>offerDetailsRef.current?.openWithOffer(item)}
      >
      <ImageBackground
        imageStyle={styles.image}
        source={item.mainImage}
      >
        <LinearGradient style={styles.gradient} colors={['rgba(0,0,0,0.56)', 'rgba(255,255,255,0)']}/>
        <View style={styles.content}>
          <AppText style={styles.title}>{item.title}</AppText>
        </View>
      </ImageBackground>
    </TouchableOpacity>);
  };

  return (
    <>
      <FlatList
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.container}
        data={Object.values(offers)}
        renderItem={renderSpecialOffer}
      />
      <OfferDetailsBottomSheet2
        ref={offerDetailsRef}
        getOffer={getOffer}
        updateOffer={updateOffer}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 12,
  },
  cardBox: {
    width: 224,
    height: 224,
    backgroundColor: 'rgba(246, 246, 246, 1)',
    borderRadius: 6,
    marginRight: 12,
  },
  content: {
    padding: 10,
    height: 224,
    width: 224,
    resizeMode: 'stretch',
  },
  image: {
    borderRadius: 6,
    width: 224,
    height: 224,
  },
  title: {
    fontSize: 16,
    color: '#fff',
  },
  gradient: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 200,
  },
});

export default SpecialOffersList;
