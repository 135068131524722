import React, {
  useRef,
  useState,
  useCallback, useMemo,
} from 'react';
import {
  View,
  StyleSheet, SectionList,
} from 'react-native';
import TagNav from './TagNavigation';
import Tile from './Tile';
import Animated from 'react-native-reanimated';


const TagNavigatedTile = (props) =>
{
  const {
    sections,
    renderTileCard,
    renderSectionHeader,
    keyExtractor,
    cardKeyExtractor,
    headerEndReached,
    sectionsEndReached,
    loading,
    ListHeader,
    ListFooter,
    onPressCard,
    ListEmptyComponent,
    ListComponent,
    listStyle,
    style,
    scrollEventThrottle,
    onScroll,
    lockScroll,
  } = props;

  const tileRef = useRef();
  const focusTimeout = useRef();
  const [focusedSection, setFocusedSection] = useState(0);
  const [renderedRows, setRenderedRows] = useState(1);

  //console.log({focusedSection});

  const onViewRef = useRef(({changed, viewableItems})=>{
    let inView = viewableItems.filter(i=>i.isViewable);
    clearTimeout(focusTimeout.current);
    focusTimeout.current = setTimeout(()=>{
      setFocusedSection(inView[0]?.section?.section || 0);
    }, 200);
    //console.log('curr viewed:',inView[0]?.section?.category);
  });

  const scrollTo = index => {
    tileRef.current?.scrollToLocation({
      sectionIndex: index,
      itemIndex: 1,
      animated: false,
    });
  };

  const onPressTag = useCallback(({navId}) => {
    try {
      scrollTo(navId);
    } catch (e) {
      if (renderedRows < navId) {
        /**
         NOTE: this condition prevent fail. Bypassing getItemLayout.
         Not rendered items has unknown height that trouble to precise scrolling
         */
        let to = renderedRows;
        let limit = 1000;
        let int = setInterval(()=>{
          to = (to > navId) ? navId : to;
          try {
            scrollTo(to);
            setRenderedRows(to);
            //console.log('scrollTo:', to);
            if (to === navId){ return clearInterval(int); }
            to += 2;
            limit = 1000;
          } catch (er){
            if (--limit === 0){ return clearInterval(int); }
            __DEV__ && console.warn('scrollTo trouble',er);
          }
        },100);
      } else {
        scrollTo(navId);
      }
    }
  },[renderedRows]);

  const handleNavEndReached = useCallback((props) => {
    if (!headerEndReached || loading) {return;}
    //console.log('nav end reached:', props);
     headerEndReached();
  },[loading, headerEndReached]);

  const navSections = useMemo(()=>sections.map(i=>({title: i.section.title, id: i.section.id})),[sections]);

  return (
    <Animated.View style={[styles.cardPanel,style]}>
      {/*<SectionList
        ref={tileRef}
        initialNumToRender={1000}
        stickySectionHeadersEnabled={true}
        renderSectionHeader={renderSectionHeader || _renderSectionHeader}
        style={styles.cardList}
        //data={Object.values(offers)}
        sections={sections}
        renderItem={renderSection}
        keyExtractor={keyExtractor || (i=>i)}
        ListFooterComponent={ListFooter}
        viewabilityConfig={{
          viewAreaCoveragePercentThreshold: 20,
        }}
        onViewableItemsChanged={onViewRef.current}
        disableVirtualization={Platform.OS === 'web'}
        legacyImplementation={Platform.OS === 'web'}
        onEndReached={sectionsEndReached || (()=>{})}
        ListEmptyComponent={ListEmptyComponent}
      />*/}
      <View>
        <TagNav
          list={navSections}
          onPressTag={onPressTag}
          focusedSection={focusedSection}
          onEndReached={handleNavEndReached}
          loading={loading}
          style={styles.tagNavStyle}
        />
      </View>
      <Tile
        ref={tileRef}
        renderSectionHeader={renderSectionHeader}
        sections={sections}
        ListHeader={ListHeader}
        ListFooter={ListFooter}
        onViewableItemsChanged={onViewRef.current}
        onEndReached={sectionsEndReached || (()=>{})}
        ListEmptyComponent={ListEmptyComponent}
        renderTileCard={renderTileCard}
        onPressCard={onPressCard}
        keyExtractor={keyExtractor}
        cardKeyExtractor={cardKeyExtractor}
        SectionListComponent={ListComponent || SectionList}
        sectionListStyle={listStyle}
        scrollEventThrottle={scrollEventThrottle}
        onScroll={onScroll || (()=>{})}
        nestedScrollEnabled={true}
        scrollEnabled={!lockScroll}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  cardPanel: {
    flex: 1,
  },
  cardListItem:{
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  cardListSectionSep: {
    backgroundColor: '#F6F6F6FF',
    paddingTop: 8,
    marginLeft: -15,
    marginRight: -15,
  },
  cardListSection: {
    //top: -1,
    padding: 10,
    paddingTop: 18,
    width: '100%',
    backgroundColor: '#fff',
  },
  cardListSectionText: {
    fontSize: 21,
    lineHeight: 22,
    fontWeight: '400',
    textAlign: 'center',
    color: '#5d5d5d',
  },


  //tileCard
  cardBox:{
    width: '48.5%',
    //height: 150,
    backgroundColor: 'white',
  },
  cardPrice:{
    marginTop: 4,
    color: '#FDB161',
    fontWeight: '500',
  },
  cardTitle:{
    marginTop: 4,
    fontSize: 14,
    lineHeight: 15,
  },
  cardImage: {
    width: '100%',
    aspectRatio: 17 / 16,
    borderRadius: 8,
    backgroundColor: '#F6F6F6FF',
  },
  cardImageWrap: {
    borderRadius: 8,
  },
  descriptionBox:{
    paddingLeft: 4,
  },
});

export default TagNavigatedTile;
