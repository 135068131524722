import React, {useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Icon} from '@rneui/base';

const CheckBox = ({checked, style, activeStyle, onChange, color, activeColor}) => {
  const [state, setState] = useState(false);

  useEffect(()=>{
    setState(checked === true);
  }, [checked]);

  return (
    <TouchableOpacity
      onPress={()=>{
        setState(!state);
        onChange && onChange(!state);
      }}
      style={styles.container}
    >
      <Icon
        //name={!state ? 'ellipse-outline' : 'checkmark-circle'} type={'ionicon'}
        name={!state ? 'circle' : 'check-circle'} type={'feather'}
        color={state ? (activeColor || '#40b740') : (color || '#888888')}
        size={30}
        style={[
          styles.icon,
          style,
          (state ? styles.activeIcon : {}),
          (state ? activeStyle : {}),
        ]}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex:1,
  },
  icon: {
    //width: 20,
    //height: 20,
  },
  activeIcon: {
  },
});

export default CheckBox;
