import {api} from '../System/api';
import AsyncStorage from '@react-native-async-storage/async-storage';

const load = (setState) => {
  return new Promise((resolve, reject) => {
    AsyncStorage.getItem('authState').then(authState => {
      if (!authState || authState === 'skip') {
        return resolve([]);
      }
      api.user
        .getInterests()
        .then(res => {
          let interestsLoaded = res.data.interests.map(i => parseInt(i.id));
          resolve(interestsLoaded);
        })
        .catch(er => reject(er));
    }).catch(er => reject(er));
  });
};

const storeParams = {
  name: 'userInterests',
  expires: 0, //never
  attempts: 1,
  load,
  onError: (state) => {
    //setState && setState('fail');
    return true;
    //return load(setState, attempt);
  },
  onExpired: (state) => {
    //setState && setState('loading');
    return true;
    //return load(setState, attempt);
  },
};
export default storeParams;
