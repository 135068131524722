import {createDataStoreSlice} from '../../System/DataStoreRedux';
import {loyaltyLevels} from '../../Loyalty/StoreParams';

const loyaltyLevelsSlice = createDataStoreSlice(
  loyaltyLevels
);

export const {resetValue, setValue} = loyaltyLevelsSlice.storeSlice.actions;
export const {
  getValue,
  reloadValue,
  init,
  select,
  selectValue,
  selectState,
} = loyaltyLevelsSlice;


export default loyaltyLevelsSlice.storeSlice.reducer;
