import React, {useEffect, useState} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import AppText from '../Basic/AppText';
import useProfileWithAccommodation from '../User/useProfileWithAccommodation';

import * as loyaltyLevels from '../GlobalStore/reducers/loyaltyLevelsSlice';
import {useSelector} from 'react-redux';
import {levelSizes, LoyaltyLevel} from './Level';
import {getLevelProgressParams, levelTitle} from './LevelsData';
import {Skeleton, SkeletonContainer} from 'react-native-skeleton-component';
import Progress from './Progress';
import humanize from 'humanize-number';
import {windowWidth} from '../Defaults/DisplayWidth';
import {getCommonState} from '../System/DataStoreRedux';

const MainPageWidget = ({children, style}) =>
{
  const navigation = useNavigation();
  const [_loadingState, setLoadingState] = useState('loading');
  const _loyaltyLevels = useSelector(loyaltyLevels.select);
  const {userProfile, loadingState} = useProfileWithAccommodation();
  const [progress, setProgress] = useState({});

  useEffect(()=>{
    setLoadingState(
      getCommonState([_loyaltyLevels.state, loadingState])
    );
    setProgress(
      getLevelProgressParams(_loyaltyLevels, userProfile)
    );
    __DEV__ && console.log('progress', getLevelProgressParams(_loyaltyLevels, userProfile));
  },[_loyaltyLevels, loadingState]);

  const handlePressWidget = () => {
    navigation.navigate('Loyalty');
  };

  return (
    <TouchableOpacity onPress={handlePressWidget} style={styles.container}>
      {_loadingState === 'loading' ? <WidgetPlaceholder/> : null }
      {_loadingState === 'loaded' ?
        <View style={styles.loyaltyBox}>
          <View style={styles.loyaltyLevelGroup}>
            <LoyaltyLevel
              size={windowWidth >= 390 ? 'large' : 'middle'}
              level={userProfile?.loyaltyLevel}
            />
            <View style={styles.loyaltyTitles}>
              <AppText>{'Ваш уровень'}</AppText>
              <AppText style={styles.loyaltyLevelNameText}>
                {levelTitle(userProfile?.loyaltyLevel)}
              </AppText>
            </View>
          </View>
          <View style={styles.progressGroup}>
            <View style={styles.progressTitles}>
              <AppText style={styles.progressText}>
                {
                  progress.isLastLevel
                    ? 'Вы достигли максимального уровня'
                    : humanize(userProfile?.pointAccrued || 0,{delimiter:' '})}
              </AppText>
              <AppText style={styles.progressText}>
                {'Прогресс'}
              </AppText>
            </View>
            <Progress
              percent={
                progress.isLastLevel ? 100 : userProfile?.pointAccrued / progress.nextLevelSum * 100
              }
            />
          </View>
        </View>
        : null
      }
      {children}
    </TouchableOpacity>
  );
};

const WidgetPlaceholder = () => (
    <View style={styles.loyaltyBox}>
      <View style={styles.loyaltyLevelGroup}>
        <SkeletonContainer animation={'wave'}>
          <Skeleton style={[styles.loyaltyLevel, {
            borderRadius: 100,
            height: levelSizes.large,
            width: levelSizes.large,
          }]} />
        </SkeletonContainer>
        <SkeletonContainer animation={'wave'}>
          <View style={styles.loyaltyTitles}>
            <Skeleton style={{width:70, height: 12, marginBottom: 8, borderRadius: 6}}
            />
            <Skeleton style={{width:60, height: 22, borderRadius: 6}}
              //imageStyle={styles.storyImg}
            />
          </View>
        </SkeletonContainer>
      </View>
      <SkeletonContainer animation={'wave'}>
        <View style={styles.progressTitles}>
          <Skeleton style={{height: 12, width: 30, borderRadius: 6}}/>
          <Skeleton style={{height: 12, width: 50, borderRadius: 6}}/>
        </View>
        <Skeleton style={[styles.loyaltyLevel, {
          borderRadius: 6,
          height: 12,
          width: '100%',
          marginTop: 2,
        }]} />
      </SkeletonContainer>
    </View>
);

const styles = StyleSheet.create({
  container: {
    height: '100%',
    padding: 12,
  },
  loyaltyLevelGroup: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  loyaltyTitles: {
    marginLeft: 10,
  },
  loyaltyLevelNameText: {
    fontSize: windowWidth >= 390 ? 22 : 18,
    fontWeight: '500',
    color: '#868686',
  },
  loyaltyBox: {
    backgroundColor: 'white',
    padding: 12,
    borderRadius: 8,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    height: '100%',
  },
  progressTitles: {
    marginTop: 14,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  progressText: {
    fontSize: 12,
    color: '#696969',
    marginBottom: 4,
  },
});

export default MainPageWidget;
