import { createSlice } from '@reduxjs/toolkit';
import {isObject} from '../../System/helpers';

const initialState = {
  name: null,
  code: null,
  time: null,
  params: {},
};

export const appEventSlice = createSlice({
  name: 'appEvent',
  initialState,
  reducers: {
    newEvent: (state, {payload}) => {
      let event = payload;
      if (!isObject(payload)){
        event = {
          name: payload,
        };
      } else {
        if (!event?.name){
          throw new Error('newEvent should be with name');
        }
      }
      state.name = event.name;
      state.params = event?.params;
      state.time = (Date.now());
      state.code = event.name + '#' + state.time;
      //console.log(state);
    },
  },
});

export const select = (state) => state.appEvent;
export const {newEvent} = appEventSlice.actions;

export default appEventSlice.reducer;
