import {api} from '../System/api';

const settings = {
  enableWelcomeBonus: true,
  enableBonusPayment: true, // false,
  enableLevelSharing: true, //__DEV__,
  enableClubcarOffer: true,
  clubCarOffer: {
    prod: 1689,//1234,
    dev: 1689,//3421,
  },
  enableSelfCheckin: true, //__DEV__ || /\/\/testshop2?\./.test(api.baseurl),
  enableGuidePopup: false,
  enableUseCompressedImages: true,
  enableDepositTopup: true, //__DEV__ || /\/\/testshop2?\./.test(api.baseurl),
  bottomMenuCenter: 'UpcomingBookings',
  //bottomMenuCenter: /\/\/testshop2?\./.test(api.baseurl) ? 'UpcomingBookings' : 'ByInterests', // 'UpcomingBookings' || 'ByInterests'
  enableAuthSkip: true,
  enableTemporaryBooking: false, // only with enableAuthSkip=true
  enableBungalowBooking: __DEV__,
  enableMapNavigation: false,

  //pictures settings
  headerLeftIcon: require('../../img/logo/LOGO_BLACK_50.png'), // '../../img/MrLogoSquare.png'
  headerTopLogo: require('../../img/MyRestLogoString.png'),// ../../img/MrLogoString.png
  mapMainScreenImage: require('../../img/YekaterinburgMapCrop4.jpg'),// ../../img/MapMainScreen.png

  //app texts
  orgName: 'Мой отдых',

  //url
  devUrl: 'https://srv7.jeen.me/',
  prodUrl: 'https://srv7.jeen.me/',

  //map
  mapCenterPoint: {
    lat:60.605897000000000,
    lon:56.837941000000000,
  },
  mapAzimuth: 99.803,
  mapZoom: 16,

  //
  supportPrettyPhone: '8 (800) 707 11 68',
  supportPhone: 88007071168,
  supportEmail: 'info.myrest@gmail.com',

};

export default settings;
