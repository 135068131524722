import AsyncStorage from '@react-native-async-storage/async-storage';
import {isObject, multiGetReducer} from './helpers';
import md5 from 'crypto-js/md5';
import {Platform} from 'react-native';
import settings from '../Defaults/settings';


const devUrl = settings.devUrl;

const prodUrl = window?.origin ? window.origin + '/' : settings.prodUrl;
const baseurl = __DEV__ ? devUrl : prodUrl;

/** TODO: drop this sht V */
const selfCheckinAlias = /\/\/testshop2/.test(baseurl) ? 'reg2' : 'reg1';

//const baseurl = 'https://backend.mriya.me/';

const setId = (item, method) => {
  if (typeof method === 'function') {
    if (typeof item?.name === 'undefined'){
      __DEV__ && console.warn('api method haven`t name property');
    }
    let id = md5((new Date()).getTime() + (item?.name || '')).toString();
    context.requestId = id;
    method(id);
  } else {
    context.requestId = method;
  }

  return item;
};

const useExternal = [
  /api\/categories/,
  /api\/offers/,
  /jwt\/user\/wishlist\/get/,
];

const checkUseExternal = (url) => {
  for (let i in useExternal){
    if (useExternal[i].test(url)) {return true;}
  }
    return false;
};

const context = {
  requestId: null,
  requestID: null,
};

const api = {
  baseurl,
  request (method, url, data, headers = {}, auth) {
    //check auth necessary
    auth = auth
      || /jwt\//.test(url)
      || /reg1\//.test(url);
    let responseHeaders = {},
      responseStatus = 0,
      requestData = data;
    let requestCode = md5(url + Date.now()) + '_' + url;
    let requestID = this.requestId;
    return new Promise((resolve, reject) => {
      AsyncStorage.multiGet(['JWT', 'authState', 'concierge']).then(store => {
        store = multiGetReducer(store);
        let token = store?.JWT;
        let setToken = store?.authState === 'auth';

        if (setToken || auth) {
          if (!token) {
            api.onError && api.onError({}, {requestCode, useAuth: auth, responseCode:-100});
            return reject('token is not found');
          }
          headers = Object.assign({}, headers, {
            Authorization: 'Bearer ' + token,
          });
        }

        /*if (checkUseExternal(url)){
          if (store?.concierge === '1'){
            url += (/\?/.test(url) ? '&' : '?') + 'isExternal=1';
          } else {
            url += (/\?/.test(url) ? '&' : '?') + 'isExternal=0';
          }
        }*/

        if (api.trace || process.env.NODE_ENV === 'development') {
          console.log(
            `API: [${method.toUpperCase()}] ${url} (rID:${requestID}) ${
              setToken
                ? 'with token: ' + (token + '').substr(0, 100)
                : 'without token'
            }
          `,
          );
        }

        const controller = new AbortController();
        api.pending.requests[requestCode] = {
          id: requestID,
          url,
          controller,
          data,
        };

        let params = {
          method: method.toUpperCase(),
          //mode: 'no-cors',
          //credentials: 'include',
          //credentials: api.credentials,
          signal: api.pending.requests[requestCode].controller.signal,
          headers: Object.assign({}, api.headers, headers),
        };
        //console.log(params);
        if (!['get', 'head'].includes(method)) {
          params.body = JSON.stringify(data);
        }

        let fetchUrl = (/^https?/.test(url) ? '' : baseurl) + url;

        fetch(fetchUrl, params)
          .then(async res => {
            api.pending.requests[requestCode] && delete api.pending.requests[requestCode];
            let resEr = res.clone();
            let resp;
            let formatOut = data => {
              resolve({
                data,
                status: res.status,
                headers: res.headers,
                requestHeaders: Object.assign({}, api.headers, headers),
                requestData,
              });
            };

            if (!res.ok) {
              try {
                let responseCode = res.status;
                __DEV__ && console.log('RES STATUS:', responseCode);
                resp = await res.json();
                api.onError && api.onError(resp, {requestCode, useAuth: auth, responseCode});
                return reject(resp);
              } catch (e) {
                let responseCode = resEr.status;
                __DEV__ && console.log('CAN`T DECODE RESP as JSON (' + responseCode + ')');
                resp = await resEr.text();
                __DEV__ && console.warn('API ERROR:', url, resp.substr(0, 200) + '\nTRUNCATED...');
                api.onError && api.onError(resp, {requestCode, useAuth: auth, responseCode});
                return reject(resp);
              }
            } else {
              resp = await res.json();
            }
            return formatOut(resp);
          })
          .catch(err => {
            api.pending.requests[requestCode] && delete api.pending.requests[requestCode];
            //__DEV__ && console.warn(err);
            api.onError && api.onError(err, {requestCode, useAuth: auth, responseCode: 0});
            return reject(err);
          });
      });
    });
  },
  pending : {
    requests: {},
    abort: (match) => {
      __DEV__ && console.log('abort',match,{all:api.pending.requests});
      for (let i in api.pending.requests) {
        let c =  api.pending.requests[i];
        if (
          !match ||
          (match instanceof RegExp && match.test(i)) ||
          (i.includes(match)) ||
          (c.id && c.id === match)
        ) { c.controller.abort(); }
      }
    },
  },
  onError : () => {
    /**init in apiContext*/
  },
};

api.trace = false;

api.headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

if (Platform.OS === 'android'){
  api.headers['Content-Type'] = 'application/json';
}

['get', 'post', 'put', 'delete', 'patch'].forEach(method => {
  api[method] = api.request.bind(context, method);
});

/**USERS*/
api.user = {
  auth: props => {
    const {phone, code, name} = props;
    return api.post(`user/auth/${phone}`, {code, name}, {}, false);
  },
  register: props => {
    const {phone, code} = props;
    return api.post(`user/register/${phone}`, {code}, {}, false);
  },
  logout: () => api.get('jwt/user/logout', {}, {Accept: '*/*'}),
  find: props => {
    return api.post('user/search', {...props}, {}, false);
  },
  findExtend: props => {
    return api.post('user/extended-search', {...props}, {}, false);
  },
  sendSms: props => {
    const {phone} = props;
    return api.post(`user/send/${phone}`, {...props}, {}, false);
  },
  get: props => {
    //checkAuthHeader();
    return api.get('jwt/user/personal', {}, {Accept: '*/*'}, true);
  },
  getInterests: () => {
    return api.get('jwt/user/interests/get');
  },
  setBonuses: () => {
      return api.get('jwt/user/getbonuses');
  },
  set: ({name, email, cpa, sendSms, sendEmails}) => {
    return api.post('jwt/user/personal/change', onlyExists({fio: name, email, cpa, sendSms, sendEmails}));
  },
  /*setInterests: interests => {
    //checkAuthHeader();
    return api.post('jwt/user/interests/set', {
      interestsIds: interests.join(','),
    });
  },*/
  setInterests: interests => {
    //checkAuthHeader();
    return api.post('jwt/user/add-interest-to-client', {
      interests,
    });
  },
  sendApproveEmail: (email) => {
    return api.post(`user/send-approve/${email}`);
  },
  approveEmail: ({email, code}) => {
    return api.post(`user/approve/${email}`, {code});
  },
  question: props => {
    const {booking_id, offer_id, phone, cpa, text, action, authorized, name} =
      props;
    let request = onlyExists({
      offer: offer_id,
      bookingId: booking_id,
      action,
      text,
      phone,
      cpa,
      name,
      //customerName,
    });

    //console.log('QUESTION REQUEST', request);

    return api.post(
      authorized ? 'jwt/user/create-question' : 'user/create-question',
      request,
    );
  },
  accommodation: props => api.get('jwt/tng/user/personal'),
  accommodationChanged: props => api.get('jwt/tng/user/isChanged'),
  roommates: () => api.get('jwt/user/roommates'),
  loyaltyShare: ({userId, phone, email}) =>
  api.post('jwt/user/share',onlyExists({
    userId,
    phone: phone,
    email: email,
  })),
  deleteRequest: ({phone}) => api.post(`user/send-delete/${phone}`),
  deleteConfirm: ({phone, code}) => api.post(`user/delete/${phone}`,{code}),
};

/**INTERESTS*/
api.interests = {
  list: ({page, limit}) => api.get(`api/interests?page=${page}`),
  categories: ({page}) => api.get(`api/categories?page=${page}`),
};

/**OUTLETS*/
api.outlets = {
  get: (id) => api.get(`api/autlets/${id}`),
  list: ({ids}) => api.get('api/autlets' + getParams({ids})),
};

/**OFFERS*/
api.offers = {
  name: 'offers',
  get: ({id}) => api.get(`api/offers/${id}`),
  list: ({
           page,
           limit,
           interests,
           categories,
           outlets,
           ids,
           //typeService,
           type,
           subTypes,
           _order,
           interestsSeparately,
           interestsSorting,
  }) =>
    api.get(
      'api/offers' +
        getParams({
          page,
          limit,
          id: ids,
          interests: interests && interests.join(','),
          category: categories && categories.join(','),
          outlet: outlets && outlets.join(','),
          //typeService,
          type: type && type.join(','),
          subTypes: subTypes && subTypes.join(','),
          _order,
          interestsSeparately,
          interestsSorting,
        }),
    ),
  multipliedByInterests: ({
          page,
          limit,
          id,
          interests,
          categories,
          outlets,
          //typeService,
          type,
          subTypes,
          order,
  }) =>
    api.post('capi/offers/multiplied-by-interest',
    onlyExists({
      page,
      limit,
      id,
      interests,
      categories,
      outlets,
      //typeService,
      type,
      subTypes,
      order,
    })
  ),
  selections: ({code}) => api.get(`capi/selection/${code}`),
  recall_list: () => api.get('api/schedule-recall/list'),
  getTimeTable: ({id}) => api.get(`timetable/${id}`),
  recommendations: () => api.get('jwt/tng/user/recommendations'),
};
api.offers.setId = (s)=>setId(api.offers,s);

/**SPECIAL OFFERS*/
api.specialOffers = {
  //get: ({id}) => api.get(`api/offers/${id}`),
  list: ({page, limit, ids}) =>
    api.post(
      'jwt/client/get-specialoffer-by-client',{
        page,
        limit,
        //id: ids,
      }
    ),
};

/**BOOKINGS*/
api.bookings = {
  name: 'bookings',
  get: ({id}) => api.get(`api/bookings/${id}`),
  list: ({page, limit, dateFrom, order, dateTo, id}) =>
    api.post(
      'jwt/user/bookings' + getParams({page, limit, id, order}),
      onlyExists({
        dateFrom,
        dateTo,
        //order,
        id: Array.isArray(id) ? id.join(',') : id,
      }),
    ),
  upcoming: ({page, limit, dateFrom, dateTo}) => {
    page = page || 1;
    let offset = limit * (page - 1);
    let count = limit || 10;
    return api.post('jwt/user/tng/bookings',{offset, count, dateFrom, dateTo});
    //return api.post('jwt/user/tng/docs',onlyExists({offset, count, date_from}));
  },
  create: ({offer_id, date_from, date_to, action, cpa,
             offer_items, room, comment, special_offer_id, selection_id,
             offerAttribute, bonuses}) =>
    api.post(
      'jwt/user/book',
      onlyExists({offer_id, date_from, date_to, action, cpa,
        offer_items, room, comment, special_offer_id, selection_id,
        offerAttribute, bonuses}),
    ),
  createTemp: ({offer_id, date_from, date_to, phone, name, action,
                 cpa, offer_items, room, comment, special_offer_id, selection_id,
                 offerAttribute, bonuses}) =>
    api.post(
      'user/book',
      onlyExists({
        offer_id, date_from, date_to, phone, name, action,
        cpa, offer_items, room, comment, special_offer_id, selection_id,
        offerAttribute, bonuses,
      }),
      {},
      false,
    ),
  change: ({booking_id, date_from, date_to, action, cpa, room, comment}) =>
    api.post(
      `jwt/user/book/change/${booking_id}`,
      onlyExists({date_from, date_to, room, comment}),
    ),
  changeDate: ({booking_id, date_from, date_to, action, cpa}) =>
    api.post(
      `jwt/user/book/change/date/${booking_id}`,
      onlyExists({
        dateFrom: date_from,
        dateTo: date_to,
      }),
    ),
  cancel: ({booking_id}) =>
    api.post(`jwt/user/book/change/${booking_id}`, {status: 'cancel'}),
  transactions: ({page, limit, id}) => {
    limit = limit || 1;
    page = page || 1;
    let offset = limit * (page - 1);
    let count = limit || 10;
    //return api.post('jwt/user/tng/bookings',{offset, count}); //changed 05.05.2023
    return api.post('jwt/user/tng/docs' + (id ? ('/' + id) : ''), {offset, count});
  },
  getPaymentLink: ({booking_id}) => {
    return api.post('jwt/booking/getPaymentLink/' + booking_id);
  },
};
api.bookings.setId = (s)=>setId(api.bookings,s);

/**BOOKMARKS*/
api.bookmarks = {
  name: 'bookmark',
  list: ({page, limit}) =>
    api.get('jwt/user/wishlist/get' + getParams({page, limit})),
  set: ({offer_id}) =>
    api.post('jwt/user/wishlist/add', {offerId: offer_id}),
  remove: ({offer_id}) =>
    api.post('jwt/user/wishlist/remove', {offerId: offer_id}),
  change: ({offer_id, bookmark}) =>
    bookmark ? api.bookmarks.set({offer_id}) : api.bookmarks.remove({offer_id}),
};
api.bookmarks.setId = (s)=>setId(api.bookmarks,s);

/**DEPOSIT*/
api.deposit = {
  name: 'deposit',
  topup: (amount) =>
    api.post('jwt/user/deposit/' + amount),
};

/**CHATS*/
api.chats = {
  get: uid => api.post(`jwt/user/chat/${uid}`),
  list: ({page, limit, booking_id, outlet_id}) =>
    api.get(
      'jwt/user/chats' +
        getParams({page, limit, booking: booking_id, outlet: outlet_id}),
    ),
  sendMessage: ({chatUid, message}) =>
    api.post(`user/chat/new-message/${chatUid}`, {message}),
  messages: ({chatUid, id, date}) => {
    id = id || '{id}';
    date = date || '{date}';
    return api.post(`jwt/user/chat/messages/${chatUid}/${id}/${date}`);
  },
};

/**ACTIVITIES*/
api.activities = {
  name: 'activities',
  get: ({id}) => api.get(`api/events/${id}`),
  list: ({page, date, categories}) => api.post('api/events/list',
    onlyExists({
      date,
      categoryIds: categories,
    })
  ),
  listV2: ({page, dateFrom, dateTo, categories, eventYear}) => api.post('api/events/new-list',
    onlyExists({
      dateStart: dateFrom,
      dateFinish: dateTo,
      categoryIds: categories,
      eventYear,
    })
  ),
  categories: ({page, date}) => api.post('api/events/categories/list',
    onlyExists({date})
  ),
};
api.activities.setId = (s)=>setId(api.activities,s);

/**MAP POINTS*/
api.mapPoints = {
  list: ({page}) => api.get('api/points' + getParams({page})),
};

/**STORIES*/
api.stories = {
  list: ({page}) => api.get('api/story/list' + getParams({page})),
};

/**FAQs*/
api.faqs = {
  name: 'faqs',
  list: ({page}) => api.get('api/faqs' + getParams({page})),
};
api.faqs.setId = (s)=>setId(api.faqs,s);

/**SEARCH*/
api.search = ({query}) => api.post('api_search',{query});

/**LOYALTY*/
api.loyalty = {
  levels: ({page}) => api.get('api/loyalty_levels' + getParams({page})),
};

/**SELF CHECKIN**/
api.selfCheckin = {
  name: 'selfCheckin',
  users: () =>
    api.get('jwt/checkinhotel/guest/count'),
  availableBookings: () =>
    api.get('jwt/checkinhotel/bookings/available'),
  registerRequest: (data) =>
    api.post('jwt/checkinhotel/create/booking',data),
  requestList: () =>
    api.get('jwt/checkinhotel/get/list'),

  /** new */
  draft: {
    info: (data) =>
      api.post(selfCheckinAlias + '/oapi/booking/info', data),
    init: () =>
      api.post(selfCheckinAlias + '/oapi/booking/add/draft/jwt', {}),
    editData: (data) =>
      api.post(selfCheckinAlias + '/oapi/booking/add/draft/jwt', data),
    addFile: (data) =>
      api.post(selfCheckinAlias + '/oapi/file/upload', data),
    complete: () =>
      api.post(selfCheckinAlias + '/oapi/booking/complete', {}),
  },
};
api.selfCheckin.setId = (s)=>setId(api.selfCheckin,s);
api.basket = {
  get: () => api.get('/jwt/basket/get'),
  removeItem: (id) => api.post('/jwt/basket/remove', {basket_item_id: id}),
  setItem: (data) => api.post('/jwt/basket/set', data),
};

/** BUNGALOW **/
api.bungalow = {
  name: 'bungalow',
  list: () => api.get('api/bungalows?page=1'),
  sectors: () => api.get('api/beaches?page=1'),
  schedule: () => api.post('api/schedule/bungalow', {}),
};

/** FEEDBACK */
api.feedback = {
  name: 'feedback',
  support: ({
    user, senderName, text, rating, offer, images,
  }) => api.post('api/reviews', onlyExists({
    user, senderName, text, rating, offer, images,
    type: 'support',
  })),
};

/** DOCUMENTS */
api.docs = {
  name: 'docs',
  list: () => api.get('api/documents'),
  get: ({id}) => api.get(`api/documents/${id}`),
};

const checkAuthHeader = () => {
  if (!api.headers.Authorization) {
    throw new Error('Auth Header is Required for this method');
  }
};

api.checkAuth = () => {
  return new Promise((resolve, reject) => {
    try {
      api.user.get().then(res => {
        __DEV__ && console.log('CHECK Auth:', res);
        resolve(!res.data.error);
      }).catch((e)=>{
        //__DEV__ && console.log('CHECK Auth ERROR:', e);
        reject(e);
      });
    } catch (e) {
      //__DEV__ && console.log('CHECK Auth ERROR:', e);
      reject(e);
    }
  });
};

const mockError = () => (new Promise((resolve,reject)=>{
  setTimeout(()=>reject('mock error'), 2000);
}));

const onlyExists = obj => {
  for (let i in obj) {
    if (typeof obj[i] === 'undefined' || obj[i] === null) {
      delete obj[i];
    }
  }
  return obj;
};

const getParams = params => {
  let query = [];
  let objectFields = (name, item, depth = 1, parents = []) => {
    let maxDepth = 5;
    let inner_query = [];
    let parent_names = parents.concat(name).map((v,i)=>i === 0 ? v : `%5B${v}%5D`).join('');
    if (depth > maxDepth){ return inner_query;}
    if (isObject(item)){
      Object.entries(item).forEach(v => {
        let [_name, _item] = v;
        inner_query = inner_query.concat(objectFields(_name, _item, ++depth, parents.concat(name)));
      });
    } else if (Array.isArray(item)) {
      inner_query = inner_query.concat(item.map((v) => parent_names + '%5B%5D=' + v));
    } else {
      inner_query = inner_query.concat(parent_names + '=' + item);
    }
    return inner_query;
  };
  for (let i in params) {
    if (params[i]) {
      if (Array.isArray(params[i])) {
        query = query.concat(params[i].map((v) => i + '%5B%5D=' + v));
      } else if (isObject(params[i])) {
        query = query.concat(objectFields(i, params[i]));
      } else {
        query.push(i + '=' + params[i]);
      }
    }
  }
  query = query.join('&');
  return query.length > 0 ? '?' + query : '';
};

api.isAbort = e => e.name === 'AbortError';

export {api};
