import {Platform} from 'react-native';

export const metricEvent = (event, params) => {
  try {
    if (Platform.OS === 'web') {
      yandexMetricEvent(event);
    }
  } catch (e) {
    __DEV__ && console.warn('cant trigger metric event ' + event);
  }
};

export const yandexMetricEvent = (event) => {
  window.ym(87017273, 'reachGoal', event);
};
