//import CustomBackdrop from '../BottomSheets/CustomBackdrop';
import {View} from 'react-native';
//import {BottomSheetModal} from '@gorhom/bottom-sheet';
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';

import {isObject} from '../System/helpers';

import PopupMenuActionButtons from './PopupMenuActionButtons';
import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';

export const standardItems = {
  findSimilar: {
    title: 'Найти похожее по интересам',
    icon: {name: 'hash', type: 'feather'},
  },
  changeDate: {
    title: 'Изменить дату и время',
    icon: {name: 'calendar', type: 'feather'},
  },
  chatQuestion: {
    title: 'Задать вопрос в чате ',
    icon: {name: 'chatbox-outline', type: 'ionicon'},
  },
  simpleQuestion: {
    title: 'Задать вопрос',
    disableAction: true,
    children: props => <PopupMenuActionButtons {...props} />,
  },
  recallQuestion: {
    title: 'Задать вопрос',
    icon: {name: 'call-in', type: 'simple-line-icon'},
  },
  cancelBooking: {
    title: 'Отменить мероприятие',
    icon: {name: 'closecircleo', type: 'ant-design'},
  },
  sendReview: {
    title: 'Написать отзыв',
    icon: {name: 'emotsmile', type: 'simple-line-icon'},
  },
};

const PopupMenu = forwardRef((props, ref) => {
  const {popupContent, popupOpen, maxHeight} = props;
  const bottomSheetRef = useRef(null);
  const [bottomSheetContent, setBottomSheetContent] = useState();

  useImperativeHandle(ref, () => ({
    open: () => {
      bottomSheetRef.current.present();
    },
    close: () => {
      bottomSheetRef.current.dismiss();
    },
  }));

  useEffect(() => {
    if (popupOpen) {
      bottomSheetRef.current.present();
    } else {
      bottomSheetRef.current.dismiss();
    }
  }, []);

  useEffect(() => {
    setBottomSheetContent(popupContent);
  }, [popupContent]);

  return (
    <SimpleBottomSheet
      ref={bottomSheetRef}
      index={0}
      lastPoint={400}
    >
      <View style={{padding: 30}}>{bottomSheetContent}</View>
    </SimpleBottomSheet>
  );
});

const popupMenuItemsGenerator = items => {
  let menuItems = [];
  items = Array.isArray(items) ? items : [];
  let empty = Array.isArray(items) && items.length === 0;
  let chItem;

  items.forEach(item => {
    if (isObject(item)){
      menuItems.push(item);
    } else {
      if ((chItem = standardItems[item])){
        if (!chItem.data) {chItem.data = {};}
        if (!chItem.data.action) {chItem.data.action = item;}
        menuItems.push(chItem);
      } else {
        __DEV__ && console.warn('menu item:', item, 'is not found');
      }
    }
  });

  return menuItems;
};

export {popupMenuItemsGenerator, PopupMenu};
