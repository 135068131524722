import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  View,
  StyleSheet, Platform,
} from 'react-native';
import AppText from './AppText';
import BlurView from '../Basic/AppBlurView';
import {Skeleton, SkeletonContainer} from 'react-native-skeleton-component';

const TagNav = forwardRef(({
                  list,
                  onPressTag,
                  focusedSection,
                  onEndReached,
                  loading,
                  showScroll,
                  style,
                  containerStyle,
                  itemStyle,
                  useBlur,
                  useAutoScroll,
      },ref) => {
  const [currItem, setCurrItem] = useState(0);
  const scrollRef = useRef();

  const handlePressTag = ({id, navId, ...other}) => {
    setCurrItem(navId);
    onPressTag && onPressTag({id, navId, ...other});
  };

  useEffect(()=>{
    if (useAutoScroll === true && currItem !== undefined){
      try {ref.current?.scrollToCurrent();} catch (e){}
    }
  },[currItem]);

  const scrollTo = (navItemIndex, params = {}) => {
    //if (navItemIndex === 0) return;
    try {
      scrollRef.current?.scrollToIndex({
        animated: true,
        viewPosition: 0.5,
        ...params,
        index: navItemIndex,
      });
    } catch (e) {
      __DEV__ && console.warn('cant scroll navigated tile because', e);
    }
  };

  useImperativeHandle(ref, ()=>({
    scrollToCurrent : (params) => scrollTo(currItem, params),
    setCurrentIndex: (index, params) => {
      if (index !== currItem) {
        setCurrItem(index);
        scrollTo(index, params);
      }
    },
    setCurrentId: (id, params) => {
      let itemIndex = list.findIndex(i=>i.id === id);
      if (itemIndex !== currItem) {
        setCurrItem(itemIndex);
        scrollTo(itemIndex, params);
      }
    },
    scrollTo: scrollTo,
  }),[currItem]);

  useEffect(()=>{
    if (!focusedSection?.id) {return;}
    let navItemId = list.findIndex(i=>i.id === focusedSection.id);
    navItemId = Math.max((navItemId || 0), 0);
    //console.log('focus item', focusedSection, navItemId);
    setCurrItem(navItemId);
    if (list.length > 1) {
      scrollTo(navItemId);
    }
  },[focusedSection]);

  return (
    <FlatList
      ref={scrollRef}
      style={style}
      contentContainerStyle={[styles.navTagList, containerStyle]}
      horizontal={true}
      showsHorizontalScrollIndicator={showScroll === true}
      data={list}
      renderItem={({item, index:navId})=>{
        return <Tag
          {...item}
          key={navId}
          navId={navId}
          onPress={handlePressTag}
          focused={currItem}
          useBlur={useBlur}
          style={itemStyle}
        />;
      }}
      onEndReachedThreshold={0.1}
      onEndReached={onEndReached}
      ListFooterComponent={
        <View style={styles.footer}>
          {loading ? <ActivityIndicator size={'small'} /> : null}
        </View>}
      ListEmptyComponent={<SkeletonContainer>
        {Array(8).fill(null).map((v,k)=>
          <Skeleton key={k} style={[styles.navTag, styles.navTagLoading]}/>)
        }
      </SkeletonContainer>}
    />
  );
});

const Tag = ({id, title, navId, focused, onPress, useBlur, style}) => {

  const ViewComponent = useBlur ? BlurView : View;

  const isFocused = focused === navId;
  return (
    <TouchableOpacity
      onPress={()=>onPress && onPress({id, navId})}
    >
      <ViewComponent
        style={[
          styles.navTag,
          style,
          isFocused ? styles.navTagFocused : null,
          (useBlur ? {backgroundColor: null} : {}),
        ]}
        {...(useBlur ? {
          blurType : isFocused ? 'dark' : 'light',
          blurAmount : Platform.OS === 'web' ? 50 : 4,
        } : {})}
      >
        <AppText style={[
          styles.navTagText,
          isFocused ? styles.navTagFocusedText : null,
        ]}>{title}</AppText>
      </ViewComponent>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  //tagNav

  navTag:{
    borderRadius: 17,
    backgroundColor: 'rgba(246, 246, 246, 1)',
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginRight: 8,
    //alignItems: 'center',
    //justifyContent: 'center',
  },
  navTagText: {
    textAlignVertical: 'center',
    fontSize: 13,
    lineHeight: 16,
  },
  navTagFocused: {
    backgroundColor: 'rgba(51, 51, 51, 1)',
  },
  navTagFocusedText: {
    color: '#fff',
  },
  navTagListMargin: {
    marginTop: 16,
  },
  navTagList: {
    paddingLeft: 12,
    paddingBottom: 16,
    paddingTop: 16,
    overflow: 'hidden',
  },
  navTagLoading: {
    width: 140,
    height: 30,
  },
  footer: {
    justifyContent:'center',
    alignSelf:'center',
    flex: 1,
    width: 40,
    marginRight: 20,
  },
});

export default TagNav;
