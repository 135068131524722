import {useState, useEffect} from 'react';
import categoryAdapter from './CategoryAdapter';
import {api} from '../System/api';
import {useDispatch, useSelector} from 'react-redux';
//import usePrevious from '../Hooks/usePrevious';
import * as userInterests from '../GlobalStore/reducers/userInterestsSlice';
import * as auth from '../GlobalStore/reducers/authSlice';
import * as categories from '../GlobalStore/reducers/categoriesSlice';
import {sortObject, sortObjectByKeys} from '../System/helpers';


const CategoriesLoaderHook = (props) => {
  const dispatch = useDispatch();
  const authState = useSelector(auth.selectState);
  const chosenCategories = useSelector(categories.selectChosen);
  const loadedCategories = useSelector(categories.selectLoaded);
  const categoriesState = useSelector(categories.selectState);

  /*useEffect(()=>{
    if (categories && categories.length === 0) {
      setCategories({});
      setLoading('loading');
      loadCategories().then().catch(e=>{console.error(e);});
    } else {
      setLoading('loaded');
    }
  },[]);*/

  useEffect(()=>{
    //console.log({setCategories});
    //dispatch(categories.setLoaded());
    if (categoriesState !== 'loading') {
      dispatch(categories.setState('loading'));
      loadCategories().then().catch(e=>{console.error(e);});
    }
  }, []);

  const loadCategories = async () => {
    let _userInterests = authState === 'auth'
      ? await dispatch(userInterests.getValue()).unwrap().value || []
      : []
    ;
    //console.log({_userInterests});
    let page = 1;
    let _categories = {};
    while (1) {
      let res = await api.interests.categories({page});
      if (res.data.length === 0) {break;}
      for (let i in res.data) {
        let _category = res.data[i];
        if (_category.activeInterests.length > 0){
          _category.activeInterests = _category.activeInterests.map(i => ({
            ...i,
            selected: _userInterests.includes(i.id),
          }));
          _category.selected = Object.keys(chosenCategories).includes(_category.id);
          _categories[_category.id] = categoryAdapter(_category);
        }
      }
        /*.map(item => {
          if (chosenCategories && Array.isArray(chosenCategories)){
            //set SELECTED state from storage

          }
          if (Array.isArray(_userInterests) && _userInterests.length > 0) {
            item.activeInterests = item.activeInterests.map(i => ({
              ...i,
              selected: _userInterests.includes(i.id),
            }));
          }
          return categoryAdapter(item);
        });*/

      let categoriesToSet = {..._categories, ...loadedCategories};
      dispatch(categories.setLoaded(categoriesToSet));
      page++;
    }
    dispatch(categories.setState('loaded'));
  };

  return {
    categories: sortObjectByKeys(loadedCategories,[{prior:'desc'}]),
    loading: categoriesState !== 'loaded',
  };
};

export default CategoriesLoaderHook;
