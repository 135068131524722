import {isObject} from '../System/helpers';

export const levelByName = (levelName, notFoundId = 1) => {
  levelName = levelName.toLowerCase();
  for (const id in loyaltyLevelsParams){
    if (loyaltyLevelsParams[id].name === levelName){ return parseInt(id); }
  }
  return notFoundId;
};

export const levelByTitle = (levelName, notFoundId = 1) => {
  levelName = levelName.toLowerCase();
  for (const id in loyaltyLevelsParams){
    if (loyaltyLevelsParams[id].title.toLowerCase() === levelName){ return parseInt(id); }
  }
  return notFoundId;
};
export const levelTitle = (level, notFoundId = 1) => {
  level = loyaltyLevelsParams[level] ? level : notFoundId;
  return  loyaltyLevelsParams[level].title;
};
export const levelById = (levelId, notFoundId = 1) => {
  levelId = loyaltyLevelsParams[levelId] ? levelId : notFoundId;
  return  loyaltyLevelsParams[levelId];
};

export const getLevelProgressParams = (loyaltyLevels, userProfile) => {
  let maxLevelNum = 1;
  if (isObject(loyaltyLevels.value)) {
    maxLevelNum = Object.values(loyaltyLevels.value).reduce((a, v) =>
      (v?.isPersonal ? a : Math.max(a, parseInt(v.level))), 1);
  }
  let nextLevelNum = Math.min(maxLevelNum, userProfile?.loyaltyLevel + 1);
  let currLevel = loyaltyLevels.value[userProfile?.loyaltyLevel];
  let nextLevel = loyaltyLevels.value[nextLevelNum];
  let maxLevel = levelById(maxLevelNum);

  return ({
    nextLevelSum: nextLevel?.balanceFrom,
    isLastLevel: currLevel?.isPersonal === true
      || maxLevelNum === userProfile?.loyaltyLevel,
    isShareable: !!currLevel?.shareable,
    currentLevelData: currLevel,
    maxLevelNum,
    maxLevelData: {...loyaltyLevels[maxLevel], maxLevel},
  });
};

export const loyaltyLevelsParams = {
  1: {
    title:'White',
    name:'white',
    colors: ['rgb(255,255,255)','rgb(238,238,238)'],
    shadowColor: 'rgba(0,0,0,0.14)',
  },
  2: {
    title:'Bronze',
    name:'bronze',
    colors: ['rgb(255,248,245)','rgb(201,140,78)'],
    shadowColor: 'rgba(167, 100, 91, 0.24)',
  },
  3: {
    title:'Silver',
    name:'silver',
    colors: ['rgba(253, 253, 253, 1)','rgba(208, 208, 208, 1)'],
    shadowColor: 'rgba(191, 191, 191, 0.24)',
  },
  4: {
    title:'Gold',
    name:'gold',
    colors: ['rgba(255, 239, 223, 1)','rgb(197,145,12)'],
    shadowColor: 'rgba(253, 197, 138, 0.24)',
    sharing: true,
  },
  5: {
    title:'Platinum',
    name:'platinum',
    colors: ['rgb(245,245,245)','rgb(175,156,142)'],
    shadowColor: 'rgba(124,115,73,0.24)',
    sharing: true,
  },
  6: {
    title:'Diamond',
    name:'sb_diamond',
    colors: ['rgb(233,247,255)','rgb(204,232,255)'],
    shadowColor: 'rgba(82,153,234,0.24)',
    sharing: true,
    icon: {
      type: 'simple-line-icon',
      //type: 'material-community',
      name: 'diamond',
      color: '#cce7ff',
    },
  },
  /*6: {
    title:'Изумрудный',
    name:'vip',
    colors: ['rgba(123, 250, 242, 1)','rgba(99, 209, 143, 1)'],
    shadowColor: 'rgba(113, 233, 203, 0.24)',
  },
  7: {
    title:'Рубиновый',
    name:'elite',
    colors: ['rgba(248,186,250,0.48)','rgb(248,86,114)'],
    shadowColor: 'rgba(242, 126, 173, 0.42)',
  },*/
};
