import React, {useEffect, useRef, useState} from 'react';
import {ActivityIndicator, Platform, View} from 'react-native';
//import Auth from './Auth/Auth';
//import Concierge from './Concierge/Navigation';
//import User from './User/User';
//import Profile from './User/Profile';
//import Purchases from './Purchases/List';
//import SpecialOffers from './Offers/SpecialOffers';
//import Bookings from './FileUploader/BookingsNew';
//import Notifications from './Notifications/List';
//import AppLoading from 'expo-app-loading';
//import InterestsChoice from './Interests/InterestsChoice';
//import {api} from './System/api';
//import addConciergeNavigationConfig from './Concierge/addConciergeNavigationConfig';
//import TestMap from './Map/TestMap';
//import {Page as Guide} from './AppGuide/Gallery';
//import SystemNavigationBar from 'react-native-system-navigation-bar';


import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  NavigationContainer,
  getStateFromPath,
  createNavigationContainerRef,
  CommonActions,
} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import { StackActions } from '@react-navigation/routers';
import queryString from 'query-string';
import BottomSheetsProviders from './BottomSheets/BottomSheetsProviders';

// optimized screens
import ByInterests from './Offers/ByInterestsLazy';
import StoryGallery from './Stories/StoryGalleryLazy';
import ProfileLazy from './User/ProfileLazy';
import Offer from './Offers/OfferLazy';
import Search from './Search/MainLazy';
import Activity from './Activities/ActivityLazy';
import SpecialOffer from './Offers/SpecialOfferLazy';
import Loyalty from './Loyalty/LoyaltyLazy';
import LoyaltyMore from './Loyalty/LoyaltyMoreLazy';
import SelfCheckin from './SelfCheckin/FileUploaderLazy';
import LoyaltyRulesLazy from './Loyalty/LoyaltyRulesLazy';
import {InterestsChoiceLazy} from './Interests/InterestsChoiceLazy';
import Bookmarks from './Bookmarks/ListLazy';
import Offers from './Offers/ListLazy';
import RoomService from './RoomService/TileLazy';
import ActivitiesMap from './Map/ActivitiesMapLazy';
import Docs from './Docs/DocumentLinksLazy';
//import Faq from './Docs/FaqLazy';


// not optimized screens
import BonusTransactions from './Loyalty/BonusTransactions';
import SelfCheckinRequestList from './SelfCheckin/RequestList';
import BitrixChat from './Chat/BitrixChat';
import Basket from './Basket/Basket';
import Selection from './Offers/Selection';
import {RatingTest} from './Reviews/RatingStar';
import Register from './Auth/Register';
import UserSettings from './User/UserSettings';
import Logout from './User/Logout';
//import Contacts from './Contacts/ListNew';
import Payment from './Payment/Link';
import PaymentStatus from './Payment/PaymentStatus';
import WebViewMessageTest from './Payment/WebViewMessageTest';
import TestPage from './Test/TestPage';
import BookingsNav from './Booking/BookingsNav';
import TopupDeposit from './TopupDeposit/Form';
import CategoryChoice from './Interests/CategoryChoice';
//import {BungalowBookingForm} from './Booking/BungalowBooking';
//unnecessary to optimize
import Main from './Main/NavigationNew';

/*DOC FILES*/
/*import TermsOfUse from './Docs/TermsOfUse';
import PersonalDataProcessingPolicy from './Docs/PersonalDataProcessingPolicy';
import MessageReceivingApproval from './Docs/MessageReceivingApproval';
import PersonalDataProcessingAgreement from './Docs/PersonalDataProcessingAgreement';
import ConfidentialPolicy from './Docs/ConfidentialPolicy';*/
import {HtmlDocumentScreen} from './Docs/Types/Html';

import * as nav from './GlobalStore/reducers/navigationSlice';
import * as auth from './GlobalStore/reducers/authSlice';
import {useDispatch, useSelector} from 'react-redux';

import settings from './Defaults/settings';
import Support from './Feedback/Support';
import AuthReqBottomSheet from './BottomSheets/AuthReqBottomSheet';
import AccountIsDeletedBottomSheet from './BottomSheets/AccountIsDeletedBottomSheet';

const Stack = createStackNavigator();

const save2States = path => {
  AsyncStorage.getItem('PATH_STACK').then(stack_json=>{
    let stack;
    if (stack_json === undefined || stack_json === null){
      stack = [];
    } else {
      try {
        stack = JSON.parse(stack_json);
      } catch (e){ stack = [];}
    }
    if (!Array.isArray(stack)){
      stack = [];
    }

    if (stack[stack.length - 1] !== path){
      stack.push(path);
    }
    if (stack.length > 2){
      stack = stack.slice(1);
    }

    AsyncStorage.setItem('PATH_STACK', JSON.stringify(stack)).then();
  });
};

const linking = {
  prefixes: [
    'https://srv7.jeen.me',
    'https://127.0.0.1:9000/',
  ],
  config: {
    screens: {
      Auth: {
        path: '/auth',
        exact: true,
      },
      Main: {
        path: '/',
        screens: {
          /*Interests: {
            path: '/interests',
            exact: true,
          },*/
          MainPage: {
            path: '/feed',
            exact: true,
          },
          /*Rest: {
            path: '/rest',
            exact: true,
          },
          List: {
            path: '/bookmarks',
            exact: true,
          },*/
          Activities: {
            path: '/activities',
            exact: true,
          },
          RoomService: {
            path: '/room_service',
            exact: true,
          },
          Interests: {
            path: '/my_interests',
            exact: true,
          },
          Profile: {
            path: '/profile',
            exact: true,
          },
          UpcomingBooking: {
            path: '/my_bookings',
            exact: true,
          },
          /*Chats: {
            path: 'chats',
            screens: {
              Chat: 'chat',
              ChatList: {
                path: 'chat-list',
                screens: {
                  All: 'all',
                  Archive: 'archive',
                },
              },
            },
          },*/
        },
      },
      Loyalty: {
        path: 'loyalty',
        exact: true,
      },
      Account: {
        path: '/account',
        exact: true,
      },
      CategoryChoice: {
        path: '/categories',
        exact: true,
      },
      InterestsChoice: {
        path: '/categories/interests',
        exact: true,
      },
      /*ConciergeOffers: {
        path: '/concierge/offers',
        exact: true,
      },
      ConciergeOffer: {
        path: '/concierge/offer',
        exact: true,
      },*/
      LoyaltyRules:{
        path: '/loyalty/rules',
        exact: true,
      },
      Offers: {
        path: '/offers',
        exact: true,
      },
      Offer: {
        path: '/offer',
        exact: true,
      },
      Selection: {
        path: '/s/:code',
        exact: true,
        parse:{
          code: (code) => `${code}`,
        },
      },
      Activity: {
        path: '/activity',
        exact: true,
      },
      /*Purchases: {
        path: '/purchases',
        exact: true,
      },*/
      Docs: {
        path: '/docs',
        exact: true,
      },
      HtmlDocument: {
        path: '/html-document',
        exact: true,
      },
      /*Contacts: {
        path: '/contacts',
        exact: true,
      },*/
      /*Notifications: {
        path: '/notifications',
        exact: true,
      },*/
      Payment: {
        path: '/pay',
        exact: true,
      },
      StoryGallery: {
        path: '/story',
        exact: true,
      },
      /*PaymentStatus: {
        path: '/payment_status',
        exact: true,
      },*/
      /*ActivitiesMap: {
        path: '/activities/map',
        exact: true,
      },*/
      Bookings: {
        path: '/rest',
        exact: true,
      },
      Bookmarks: {
        path: '/bookmarks',
        exact: true,
      },
      ActivitiesMap: {
        path: '/activities/map',
        exact: true,
      },
      Search: {
        path: '/search',
        exact: true,
      },
      SelfCheckin: {
        path: '/checkin/upload',
        exact: true,
      },
      SelfCheckinList: {
        path: '/checkin/requests',
        exact: true,
      },
      Basket: {
        path: '/basket',
        exact: true,
      },
      TopupDeposit: {
        path: '/deposit_topup',
        exact: true,
      },
      BungalowBookingForm: {
        path: '/bungalow_booking',
        exact: true,
      },
      Support: {
        path: '/support',
        exact: true,
      },
    },
  },
  getStateFromPath(path, config)
  {
    if (/(\?|&)cpa=/.test(path)) {
      let params = queryString.parse(path.replace(/^.*?\?/, ''));
      let cpa = params.cpa;
      AsyncStorage.setItem('CPA', cpa).then(() => {});
    } else if (/\/cpa\//.test(path)) {
      let cpa = path.match(/\/cpa\/([^?&]*?)(?:\/|\?|&|$)/);
      if (typeof cpa[1] !== 'undefined' && cpa[1].length > 0) {
        AsyncStorage.setItem('CPA', cpa[1]).then(() => {});
      }
      path = path.replace(/\/cpa\/.*?(\/|\?|$)/,'$1');
      //console.log('EDIT PATH',path);
    }
    let stateFromPath = getStateFromPath(path, config);

    if (!/\/(login|auth|logout)/.test(path)) {
      AsyncStorage.setItem('PREV_PATH', path).then(() => {});
      if (stateFromPath !== undefined) {
        AsyncStorage.setItem('PREV_NAV_STATE', JSON.stringify(stateFromPath)).then(() => {});
      }
    }

    //save raw input state before routing
    save2States(path);

    if (/^\/?concierge/.test(path)){
      AsyncStorage.setItem('concierge',1);
    } else {
      AsyncStorage.setItem('concierge',0);
    }
    return stateFromPath;
  },
};

//linking.config.screens.Concierge = addConciergeNavigationConfig(linking.config.screens.FileUploader);
//console.log(linking.config.screens);

const Navigate = ({style}) => {
  const authState = useSelector(auth.selectState);
  const navAction = useSelector(nav.selectAction);
  //const navigationRef = useRef();
  const navigationRef = createNavigationContainerRef();

  const dispatch = useDispatch();

  /*const checkAuth = () => {
    api
      .checkAuth()
      .then(res => {
        setAuthState(res ? 'auth' : 'skip');
        if (!res) {
          clearInterval(checkAuthInterval.current);
        }
      })
      .catch(res => {
        setAuthState('skip');
      });
  };

  useEffect(() => {
    __DEV__ && console.log('global token changed: ', jwtToken);
    checkAuth();
  }, [jwtToken]);*/

  /*useEffect(() => {
    if (authState !== 'login' && !settings.enableAuthSkip){
      //REDIRECT to first url after login
      if (Platform.OS === 'web') {
        AsyncStorage.getItem('PATH_STACK').then(stack_json => {
          let stack = JSON.parse(stack_json);
          //console.log('STACK IN AUTH CHANGE',stack);
          if (
            stack.length > 1
            && /^\/?auth/.test(stack[1])
          ) {
            let prev_path = stack[0];
            if (
              !/^\/?[lL]ogout/.test(prev_path)
              && !/^\/?feed/.test(prev_path)
            ){
              //console.log('GOTO ' + prev_path + ' after login');
              //synthetic push
              save2States('/');
              // eslint-disable-next-line no-undef
              history.pushState({},'', prev_path);
              // eslint-disable-next-line no-undef
              location.reload();
            }
          }
        });
      }
    }
  }, [authState]);*/

  useEffect(()=>{
    //SystemNavigationBar.navigationHide();
  },[]);

  /*useEffect(()=>{
    console.log({authState});
  },[authState]);*/

  useEffect(()=>{
    switch (navAction.type) {
      case 'navigate':
      case 'push': {
        //console.log('push/navigate cmd', navAction.route, navAction.params);
        navigationRef.dispatch(
          StackActions.push(navAction.route, navAction.params)
        );
      } break;
      case 'reset':
      case 'replace': {
        let currRoute = navigationRef?.current?.getCurrentRoute();
        let resetObj = {
          index: navAction.index || 0,
          routes:
            navAction.routes
            || [{
              name: navAction.route,
              ...(navAction.route === currRoute?.name //&& navAction.params === undefined
                  ? {params:currRoute?.params}
                  : {}
              ),
            }],
        };
        //console.log('replace/reset cmd', resetObj);
        navigationRef.dispatch(
          CommonActions.reset(resetObj)
        );
      } break;
    }
  },[navAction]);

  if (authState === undefined) {
    return ( <ActivityIndicator size={'large'}/>
      /*<AppLoading
        startAsync={getAuthState}
        onFinish={getAuthState().then(a => setAuth(a))}
        onError={console.warn}
      />*/
    );
  }

  return (
    <View style={{flex: 1, height: '100%'}}>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        onReady={() => {
          let route = navigationRef?.current?.getCurrentRoute();
          dispatch(nav.setCurrentRoute({...route, ready:true}));

          if (Platform.OS === 'web'){
            // eslint-disable-next-line no-undef
            let path = location?.pathname + location?.search;
            //console.log('COMPARE ROUTE AND PATH', route, path);
            //console.log('save pushed input state on init');
            //save pushed input state after routing
            save2States(path);
          }
        }}
        onStateChange={() => {
          let route = navigationRef?.current?.getCurrentRoute();
          dispatch(nav.setCurrentRoute({...route, ready:true}));

          if (Platform.OS === 'web'){
            // eslint-disable-next-line no-undef
            let path = location?.pathname + location?.search;
            //console.log('COMPARE ROUTE AND PATH', route, path);
            //save pushed input state after routing
            save2States(path);
          }
        }}
      >
        {/** Custom provider includes default provider of BottomSheets */}
        <BottomSheetsProviders>
          <Stack.Navigator
            screenOptions={{
              header: () => <View style={{height: 0}}/>,
              //headerShown: false,
              //headerMode: 'screen',
              animationEnabled: true,
              //navigationBarColor: barColor,
            }}
            //initialRouteName={'Loyalty'}
          >
            {authState === 'login' || authState === 'init'
            ? <Stack.Screen
                name={'Auth'}
                component={Register}
                options={{title: 'Авторизация'}}
              />
            : null}
            {
              (!settings.enableAuthSkip && authState !== 'auth')
              || (settings.enableAuthSkip && authState === 'login')
              ? null
              : <>
                <Stack.Screen
                  name={'Main'}
                  component={Main}
                  options={{title: 'Основная страница'}}
                />
                <Stack.Screen
                  name={'Loyalty'}
                  component={Loyalty}
                  options={{title: 'Программа лояльности'}}
                />
                <Stack.Screen
                  name={'Search'}
                  component={Search}
                  options={{title: 'Поиск', headerShown: false}}
                />
                {/*<Stack.Screen
                  name={'Concierge'}
                  component={Concierge}
                  options={{title: 'Основная страница'}}
                />*/}
                <Stack.Screen
                  name={'Account'}
                  component={UserSettings}
                  options={{title: 'Страница пользователя'}}
                />
                <Stack.Screen
                  name={'Profile'}
                  component={ProfileLazy}
                  options={{title: 'Страница пользователя'}}
                />
                <Stack.Screen
                  name={'Logout'}
                  component={Logout}
                  options={{title: 'Хотите выйти?'}}
                />
                <Stack.Screen
                  name={'ActivitiesMap'}
                  component={ActivitiesMap}
                  options={{title: 'Карта активностей'}}
                />
                <Stack.Screen
                  name={'Activity'}
                  component={Activity}
                  options={{title: 'Мероприятие'}}
                />
                <Stack.Screen
                  name={'Payment'}
                  component={Payment}
                  options={{title: 'Страница оплаты'}}
                />
                <Stack.Screen
                  name={'PaymentStatus'}
                  component={PaymentStatus}
                  options={{title: 'Статус оплаты'}}
                />
                <Stack.Screen
                  name={'WebViewMessageTest'}
                  component={WebViewMessageTest}
                  options={{title: 'Тест сообщений оплаты'}}
                />
                {__DEV__ ? <Stack.Screen
                  name={'Test'}
                  component={TestPage}
                  options={{title: 'Страница для теста'}}
                /> : null}
                {__DEV__
                  ? <Stack.Screen
                    name={'Rating'}
                    component={RatingTest}
                    options={{title: 'Страница rating'}}
                  />
                  : null
                }
                {/*<Stack.Screen
                  name={'TestMap'}
                  component={TestMap}
                  options={{title: 'Страница для теста'}}
                />*/}
                {/*<Stack.Screen
                  name={'Purchases'}
                  component={Purchases}
                  options={{title: 'Страница покупок'}}
                />*/}
                <Stack.Screen
                  name={'Docs'}
                  component={Docs}
                  options={{title: 'Страница документов'}}
                />
                {/*<Stack.Screen
                  name={'Faq'}
                  component={Faq}
                  options={{title: 'Страница документов'}}
                />*/}
                {/*<Stack.Screen
                  name={'Contacts'}
                  component={Contacts}
                  options={{title: 'Страница контаков'}}
                />*/}
                {/*<Stack.Screen
                  name={'ConciergeOffers'}
                  component={Offers}
                  options={{title: 'Страница предложений'}}
                />*/}
                <Stack.Screen
                  name={'Offers'}
                  component={Offers}
                  options={{title: 'Страница предложений'}}
                />
                <Stack.Screen
                  name={'Offer'}
                  component={Offer}
                  options={{title: 'Предложение'}}
                />
                <Stack.Screen
                  name={'Selection'}
                  component={Selection}
                  options={{title: 'Подборка'}}
                />
                <Stack.Screen
                  name={'SpecialOffer'}
                  component={SpecialOffer}
                  options={{title: 'Страница Спецпредложения'}}
                />
                <Stack.Screen
                  name={'RoomService'}
                  component={RoomService}
                  options={{title: 'Румсервис'}}
                />
                <Stack.Screen
                  name={'Bookings'}
                  component={BookingsNav}
                  options={{title: 'Мои бронирования'}}
                />
                <Stack.Screen
                  name={'Bookmarks'}
                  component={Bookmarks}
                  options={{title: 'Моё избранное'}}
                />
                <Stack.Screen
                  name={'TopupDeposit'}
                  component={TopupDeposit}
                  options={{title: 'Пополнение депозита'}}
                />
                <Stack.Screen
                  name={'BonusTransactions'}
                  component={BonusTransactions}
                  options={{title: 'Транзакции бонусов'}}
                />
                {/*<Stack.Screen
                  name={'Notifications'}
                  component={Notifications}
                  options={{title: 'Страница уведомлений'}}
                />*/}
                {/* NEW INTERFACE METHODS */}
                <Stack.Screen
                  name={'CategoryChoice'}
                  component={CategoryChoice}
                  options={{title: 'Подбор Интересов'}}
                />
                <Stack.Screen
                  name={'InterestsChoice'}
                  component={InterestsChoiceLazy}
                  options={{title: 'Подбор Интересов'}}
                />
                <Stack.Screen
                  name={'ByInterests'}
                  component={ByInterests}
                  options={{title: 'По интересам'}}
                />
                <Stack.Screen
                  name={'StoryGallery'}
                  component={StoryGallery}
                  options={{title: 'Стори'}}
                />
                {/*<Stack.Screen
                  name={'TermsOfUse'}
                  component={TermsOfUse}
                  options={{title: 'Пользовательское соглашение'}}
                />*/}
                <Stack.Screen
                  name={'HtmlDocument'}
                  component={HtmlDocumentScreen}
                  options={{title: 'Документ'}}
                />
                {/*<Stack.Screen
                  name={'PersonalDataProcessingPolicy'}
                  component={PersonalDataProcessingPolicy}
                  options={{title: 'Обработка персональных данных'}}
                />
                <Stack.Screen
                  name={'MessageReceivingApproval'}
                  component={MessageReceivingApproval}
                  options={{title: 'Согласие на получение уведомлений'}}
                />
                <Stack.Screen
                  name={'PersonalDataProcessingAgreement'}
                  component={PersonalDataProcessingAgreement}
                  options={{title: 'Согласие на обработку персональных данных'}}
                />
                <Stack.Screen
                  name={'ConfidentialPolicy'}
                  component={ConfidentialPolicy}
                  options={{title: 'Политика конфиденциальности'}}
                />*/}
                <Stack.Screen
                  name={'LoyaltyMore'}
                  component={LoyaltyMore}
                  options={{title: 'О программе лояльности'}}
                />
                <Stack.Screen
                  name={'LoyaltyRules'}
                  component={LoyaltyRulesLazy}
                  options={{title: 'О программе лояльности'}}
                />
                {/*<Stack.Screen
                  name={'Guide'}
                  component={Guide}
                  options={{title: 'О программе лояльности'}}
                />*/}
                <Stack.Screen
                  name={'SelfCheckin'}
                  component={SelfCheckin}
                  options={{title: 'Саморегистрация'}}
                />
                <Stack.Screen
                  name={'SelfCheckinList'}
                  component={SelfCheckinRequestList}
                  options={{title: 'Саморегистрация'}}
                />
                <Stack.Screen
                  name={'Basket'}
                  component={Basket}
                  options={{title: 'Корзина заказов'}}
                />
                <Stack.Screen
                  name={'BitrixChat'}
                  component={BitrixChat}
                  options={{title: 'БитриксЧат тест'}}
                />
                {/*<Stack.Screen
                  name={'BungalowBookingForm'}
                  component={BungalowBookingForm}
                  options={{title: 'Бронирование бунгало'}}
                />*/}
                <Stack.Screen
                  name={'Support'}
                  component={Support}
                  options={{title: 'Обратная связь'}}
                />
              </>
            }
          </Stack.Navigator>
          <AuthReqBottomSheet/>
          <AccountIsDeletedBottomSheet/>
        </BottomSheetsProviders>
      </NavigationContainer>
    </View>
  );
};

export default Navigate;
