import AsyncStorage from '@react-native-async-storage/async-storage';

const validateData = (data) => {
  try {
    let object = JSON.parse(data);
    if (!object?.value) {return false;}
    return object;
  } catch (e) {
    return false;
  }
};

const isExpired = ({expired}) => {
  return !!expired && expired < timestamp();
};

const timestamp = () => (new Date()).getTime() / 1000;

export const storage = ({name, expires}) => ({
  reset: () => {
    return AsyncStorage.removeItem(name);
  },
  set: (value) => {
    let data = {
      value,
      modified: timestamp(),
      expired: expires ? timestamp() + (expires * 60) : 0,
    };
    return AsyncStorage.setItem(name, JSON.stringify(data));
  },
  get: () => {
    return new Promise((resolve, reject) => {
      AsyncStorage.getItem(name).then(result=>{
        let data;
        if (result === null || result === undefined){
          return reject({
            state:'error',
            type:'empty',
            error:'value not found',
            data: result,
          });
        }
        if (!(data = validateData(result))) {
          return reject({
            state:'error',
            type:'invalid',
            error:'data format is invalid',
            data: result,
          });
        }
        if (isExpired(data)) {
          return reject({
            state:'error',
            type:'expired',
            error:'data is expired',
            data: result,
          });
        }
        resolve(data.value);
      }).catch(error => {
        reject({state:'error', type:'other', error});
      });
    });
  },
  raw: () => {
    return AsyncStorage.getItem(name);
  },
  error: (err) => {
    return new Promise((resolve, reject)=>{
      reject(err);
    });
  },
});

export default storage;
