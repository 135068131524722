import React from 'react';
import {View} from 'react-native';
import moment from 'moment';
import AppText from '../Basic/AppText';

const Line = ({line, children, width, color, background}) => {
  return (
    <View
      style={{
        borderColor: color || 'rgba(0, 0, 0, 0.1)',
        borderWidth: width || 1,
        borderRadius: 0,
        height: 0,
        borderStyle: line || 'solid',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <View
        style={{
          position: 'absolute',
          backgroundColor: background || 'white',
          width: '100%',
          top: -(width || 1),
          height: width || 1,
        }}
      />
      {children}
    </View>
  );
};

const Divider = ({type, children, color, background}) => {
  switch (type) {
    case 'date':
      let date = moment(children, 'DD.MM.YYYY');
      let sameYear = date.format('YYYY') === moment().format('YYYY');
      let textDate = date.format('DD MMMM,' + (!sameYear ? ' YYYYг.' : ' dddd'));
      return (
        <Line line={'dashed'} width={1.2} color={color || 'rgba(0, 0, 0, 0.2)'}>
          <View
            style={{
              borderColor: 'rgba(0, 0, 0, 0.2)',
              backgroundColor: 'white',
              borderStyle: 'dashed',
              borderWidth: 1.2,
              paddingHorizontal: 10,
              borderRadius: 40,
              height: 30,
              justifyContent: 'center',
            }}>
            <AppText style={{ fontWeight: '500'}}>
              {textDate}
            </AppText>
          </View>
        </Line>
      );
    case 'dashed': return <Line line={'dashed'} width={1.2} color={color || 'rgba(0, 0, 0, 0.2)'} background={background} />;
    default:
      return <Line />;
  }
};

export default Divider;
