import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  ActivityIndicator,
  FlatList, Linking,
  Platform,
  SafeAreaView,
  SectionList,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import PageHeader from '../Basic/PageHeader';
import {api} from '../System/api';
import moment from 'moment/moment';
import NewButton from '../Basic/NewButton';
import AppText from '../Basic/AppText';
import {colors, defaultStyles} from '../Defaults/Styles';
import {sortArrayByKeys, sortObjectByKeys} from '../System/helpers';
import AntiMenuFade from '../Basic/AntiMenuFade';
import {ActivitySectionHeader} from '../Main/ActivitiesNew';
//import {Icon} from '@rneui/base';
import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';
import Calendar from '../Calendar/Calendar';
import {humanizePrice} from '../Cards/CardPrice';
import sectionListGetItemLayout from 'react-native-section-list-get-item-layout';
import {BookingDetailsMyRest, BookingDetailsTNG} from './BookingDetails';
import {windowHeight} from '../Defaults/DisplayWidth';
import {useNavigation} from '@react-navigation/native';

import * as statusBar from '../GlobalStore/reducers/statusBarSlice';
import * as auth from '../GlobalStore/reducers/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import settings from '../Defaults/settings';
import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import {prepareState, prepareStateTng, StatusView} from '../Main/BookingStatus';

const ITEM_HEIGHT = 100;
const SECTION_HEIGHT = 46;

//console.log(demoData);

const prepareUpcomingBookings = (data) =>
{
  return v => {
    let booking, dates = {...v}, date;
    for (let i in data){
      let item = data[i];
      if (item.treatmentStart){
        booking = {
          id: item.id,
          date : moment(item.treatmentStart),
          outlet: {
            title: item.outlet.name,
          },
          offer: {
            title: item.treatmentName,
            price: item.total,
          },
          bonus: 0,
          bonusPayment: 0,
          rawData: item,
          type: 'tng',
          dateCreate: null,
          state: prepareStateTng(item),
        };
      } else {
        booking = {
          id: item.id,
          date : moment(item.dateFrom),
          outlet: {
            title: item.offer?.outlet?.name,
          },
          offer: {
            title: item.offer.title,
            price: item.full_price,
          },
          bonus: 0,
          bonusPayment: 0,
          rawData: item,
          type: 'system',
          dateCreate: item.date,
          state: prepareState(item.status),
        };
      }
      date = booking.date.format('YYYY-MM-DD');
      booking.uniqid = booking.type + '_' + booking.id;
      if (!dates[date]){
        dates[date] = {data: {}};
      }
      dates[date].date = date;
      dates[date].data[booking.uniqid] = booking;
    }
    //console.log(dates);
    return dates;
  };
};

const UnauthMessage = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  return (
    <View style={styles.fullScreenMsg}>
      <View style={styles.msgTextBox}>
        <AppText style={styles.msgText}>
          {'Пожалуйста, авторизуйтесь, чтобы увидеть все свои предстоящие бронирования'}
        </AppText>
      </View>
      <NewButton
        style={styles.msgButton}
        children={'К авторизации'}
        onPress={()=>{
          dispatch(auth.logout());
          //navigation.reset({index: 0, routes: [{name: 'Auth'}]});
        }}
      />
    </View>
  );
};

const EmptyContentMessage = ({onPressDetails}) => {
  return (
    <View style={styles.fullScreenMsg}>
      <View style={styles.msgTextBox}>
        <AppText style={styles.msgText}>
          {'Услуги не найдены'}
        </AppText>
      </View>
      <NewButton
        style={styles.msgButton}
        children={'Подробнее'}
        onPress={()=>{
          onPressDetails && onPressDetails();
        }}
      />
    </View>
  );
};

const Day = ({active, day, disabled, onPress}) => {
  day = moment(day, 'YYYY-MM-DD');
  const handlePress = () => {
    onPress(day, disabled);
  };

  return (
    <TouchableOpacity
      onPress={disabled ? ()=>{} : handlePress}
      style={[
        styles.day,
        active ? styles.dayActive : null,
        disabled ? styles.dayDisabled : null,
      ]}
    >
      <View style={styles.dayDowBox}>
        <AppText style={styles.dayDow}>
          {day.format('dd')}
        </AppText>
      </View>
      <View style={[
        styles.dayNumBox,
        active ? styles.dayNumBoxActive : null,
        disabled ? styles.dayNumBoxDisabled : null,
      ]}>
        <AppText style={[
          styles.dayNum,
          active ? styles.dayNumActive : null,
          disabled ? styles.dayNumDisabled : null,
        ]}>
          {day.format('DD')}
        </AppText>
      </View>
    </TouchableOpacity>
  );
};

const DaysList = ({days, value, onPressDay, loadingState, onListEndReached}) => {
  //const [_days, setDays] = useState([]);
  const [currDay, setCurrDay] = useState(moment().format('YYYY-MM-DD'));
  const [viewableMonth, setViewableMonth] = useState('');
  const [_days, setDays] = useState([]);
  const daysRef = useRef();

  const viewableHandler = useRef(({changed, viewableItems})=>{
    let inView = viewableItems.filter(i=>i.isViewable);
    let date = moment(inView[0]?.item?.date);
    setViewableMonth(date && date?.isValid() ? date.format('MMMM') : '');
  });

  useEffect(()=>{
    days &&
    setDays(v => {
      let arr = [];
      let daysList = days;//.sort((a, b) => (moment(b).isAfter(a) ? -1 : 1));
      let today = moment();
      let first_day = moment(days[0]);

      for (let i in daysList) {
        let day = moment(daysList[i]);
        let next_day = moment(daysList[parseInt(i) + 1]);
        let diff = next_day.isValid() ? moment(next_day).diff(day, 'days') : 0;
        arr.push({date:daysList[i]});
        if (diff > 0) {
          arr = arr.concat(
            Array(diff - 1).fill(1).map((d, ind) => ({
              date: day.clone().add(ind + 1, 'days'),
              disabled: true,
            })),
          );
        }
      }

      let diff = first_day.isValid() ? first_day.diff(today, 'days') : 0;
      if (diff > 0) {
        arr =
          Array(diff + 1).fill(1).map((d, ind) => ({
            date: today.clone().add(ind, 'days'),
            disabled: true,
          })).concat(arr);
      }
      return arr;
    });
  } ,[days]);

  useEffect(()=>{
    if (value && currDay !== value){
      setCurrDay(value);
      let day_id = Math.max(days.findIndex(v=>v === value), 0);
      daysRef.current?.scrollToIndex({index: day_id});
    }
  }, [value]);

  return (
    <View style={styles.dayListBox}>
      <FlatList
        ref={daysRef}
        horizontal={true}
        data={_days}
        showsHorizontalScrollIndicator={false}
        renderItem={
          ({item})=> <Day
            active={currDay === item.date}
            day={item.date}
            disabled={item.disabled}
            onPress={(d)=>{
              let day = d.format('YYYY-MM-DD');
              setCurrDay(day);
              onPressDay && onPressDay(day);
            }}
          />
        }
        style={styles.dayScroller}
        contentContainerStyle={styles.dayScrollBox}
        ListFooterComponent={()=>
          <View style={styles.dayListRightMargin}>
            {loadingState === 'loading' ? <ActivityIndicator size={'small'}/> : null}
          </View>
        }
        onEndReachedThreshold={0.1}
        onEndReached={onListEndReached}
        onViewableItemsChanged={viewableHandler.current}
        viewabilityConfig={{
          minimumViewTime: 500,
          itemVisiblePercentThreshold: 10,
          waitForInteraction: false,
        }}
      />
      <View style={styles.monthBox}>
        <AppText style={styles.monthText}>{viewableMonth}</AppText>
      </View>
    </View>
  );
};

const DayBooking = ({id, type, date, outlet, offer, bonusPayment, bonus, onPress, state}) => {

  const handlePress = () => {
    return onPress && onPress(id, type, date);
  };

  return (
    <TouchableOpacity style={styles.dayOfferBox} onPress={handlePress}>
      <View style={styles.offerDateBox}>
        <AppText style={styles.offerDateText}>
          {date.format('HH:mm')}
        </AppText>
      </View>
      <View style={styles.offerDetails}>
        <View style={styles.offerTitleBox}>
          <AppText style={styles.offerTitleText} numberOfLines={2}>
            {offer.title}
          </AppText>
        </View>
        <View style={styles.offerOutletBox}>
          <AppText style={styles.offerOutletText}>
            {outlet.title}
          </AppText>
        </View>
        <View style={styles.priceBox}>
          <AppText style={styles.priceText}>
            {humanizePrice({price: offer.price}, true)}
          </AppText>
          <StatusView {...state} textStyle={styles.dbStateText}/>
        </View>
      </View>
      {/*<View style={styles.bonusBox}>
        <View style={styles.bonusMinusBox}>
          <AppText style={styles.bonusMinusText}>{'-'}{bonusPayment}</AppText>
        </View>
        <View style={styles.bonusAddBox}>
          <AppText style={styles.bonusAddText}>{'+'}{bonus}</AppText>
        </View>
      </View>*/}
    </TouchableOpacity>
  );
};

const SectionHeader = ({section}) => {
  return (
    <View style={styles.sectionBox}>
      <AppText style={styles.sectionBox}>
        {section.section}
      </AppText>
    </View>
  );
};

const BookingDetails = ({booking}) => {

  const DetailsComponent = booking?.type === 'tng'
    ? BookingDetailsTNG
    : BookingDetailsMyRest;

  return (
    <DetailsComponent booking={booking?.rawData} state={booking.state}/>
  );
};


const UpcomingBookings = () =>
{
  const now = moment().format('YYYY-MM-DD');
  const [loadingState, setLoadingState] = useState('loaded');
  const [dates, setDates] = useState({});
  const [sectionNavigator, setSectionNavigator] = useState(now);
  const [calendarDate, setCalendarDate] = useState([now]);
  const [renderedRows, setRenderedRows] = useState(1);
  const [currBooking, setCurrBooking] = useState(1);

  const pageRef = useRef();
  const listRef = useRef();
  const calendarSheetRef = useRef();
  const bookingDetailsRef = useRef();
  const informPopupRef = useRef();

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const authState = useSelector(auth.selectState);
  const _appEvent = useSelector(appEvent.select);

  useEffect(() => {
    return navigation.addListener('focus', () => {
      dispatch(statusBar.setStyle({backgroundColor: '#fff'}));
    });
  }, [navigation]);

  useEffect(()=>{
    console.log({loadingState});
  },[loadingState]);

  useEffect(()=>{
    dispatch(statusBar.setStyle({backgroundColor: '#fff'}));
  },[]);

  const viewableHandler = useRef(({changed, viewableItems})=>{
    let inView = viewableItems.filter(i=>i.isViewable);
    //clearTimeout(focusTimeout.current);
    /*focusTimeout.current = setTimeout(()=>{
      setFocusedSection(inView[0]?.section?.section || 0);
    }, 200);*/
    //console.log('curr viewed:',inView[0]?.section?.category);
    let date_id = inView[0]?.section?.title;
    setSectionNavigator(date_id);
  });

  useEffect(()=>{
    if (authState === 'auth'){
      loadData();
    } else {
      setLoadingState('loaded');
    }
  },[authState]);

  useEffect(() => {
    let {name, params} = _appEvent;
    if (name === 'addBooking'){
      setLoadingState('loading');
      api.bookings
        .upcoming({
          dateFrom: moment(params.item.dateFrom).format('YYYY-MM-DD'),
          dateTo: moment(params.item.dateFrom).add(1, 'day').format('YYYY-MM-DD'),
          page: 1,
          limit: 20,
          //order_by: 'desc',
        })
        .then(res => {
          setDates(
            prepareUpcomingBookings(res.data.success)
          );
          setLoadingState('loaded');
        })
        .catch(err=>{
          __DEV__ && console.warn(err);
          setLoadingState('error');
        });
    }
  }, [_appEvent]);

  const loadData = () => {
    const limit = 20;
    setLoadingState('loading');
    let page = pageRef.current || 1;
    api.bookings.upcoming({
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().add(+999, 'day').format('YYYY-MM-DD'),
      page,
      limit,
      //order_by: 'desc',
    })
      .then(res=>{
        if (page === 1 && (!res.data?.success || res.data?.success?.length === 0)){
          setLoadingState('empty');
        } else {
          if (res?.data?.success && res?.data?.success.length >= limit) {
            pageRef.current = (pageRef.current || 1) + 1;
          }
          setDates(
            prepareUpcomingBookings(res.data.success)
          );
          //console.log(res);
          setLoadingState('loaded');
        }
      })
      .catch(err=>{
        setLoadingState('error');
      });
    //setDates(demoData);
  };

  /*const prepareSections = () => {
    let sections = [];
    for (let date in demoData) {
      let item = demoData[date];
      sections.push({
        title: date,
        data: item.offers.sort((a,b)=>a.date.format('YYYYMMDDHHmmss') - b.date.format('YYYYMMDDHHmmss')),
      });
    }
    sections = sortArrayByKeys(sections,[{section: 'asc'}]);
    return sections;
  };*/

  const prepareSections = (data) => {
    let sections = [];
    for (let key in data) {
      let item = data[key];
      sections.push({
        title: item.date,
        data: Object.values(sortObjectByKeys(item.data, [{date:'asc'}])),
      });
    }
    //sections = sortArrayByKeys(sections,[{section: 'asc'}]);
    sections = sortArrayByKeys(sections, [{title: 'asc'}]);
    //console.log({sections});
    return sections;
  };

  const handleCalendar = () => {
    calendarSheetRef.current?.open();
  };

  const handleCalendarChange = (dates) => {
    setCalendarDate(dates[0]);
  };

  const handleSubmitCalendar = useCallback(() => {
    setSectionNavigator(calendarDate);
    handleDayChange(calendarDate);
  },[calendarDate]);

  const handleBookingPress = (id, type, date) => {
    setCurrBooking({id, type, date});
    bookingDetailsRef.current?.open();
  };

  const getCurrentBooking = () => {
    if (!currBooking?.date?.isValid()) {
      return {};
    }
    const d = currBooking.date.format('YYYY-MM-DD');
    if (dates[d] && dates[d].data){
      return dates[d].data[currBooking.type + '_' + currBooking.id] || {};
    } else { return {}; }
  };

  const handleEndReached = () => {
    if (loadingState !== 'loading' && loadingState !== 'empty'){
      loadData();
    }
  };

  const days = useMemo(() => {
    return Object
      .values(dates)
      .map(v => v.date)
      .sort((a,b)=>(moment(b).isAfter(a) ? -1 : 1));
  }, [dates]);

  const handleDayChange = (day) => {
    let sect_id = days.findIndex((v)=>v === day);
    listRef.current?.scrollToLocation({
      sectionIndex: sect_id,
      itemIndex: 0,
      animated: false,
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <PageHeader title={'забронированные услуги'}  useBack={false}/>
      <View style={styles.topDatesNavBox}>
        {days.length > 0
          ? <DaysList
            days={days}
            onPressDay={handleDayChange}
            value={sectionNavigator}
            loadingState={loadingState}
            onListEndReached={handleEndReached}
          />
          : null
        }
        {/*<TouchableOpacity
          style={styles.calendarBtn}
          onPress={handleCalendar}
        >
          <Icon name={'calendar'} type={'feather'}/>
        </TouchableOpacity>*/}
      </View>
      <SectionList
        ref={listRef}
        keyExtractor={(item) => 'key_' + item?.uniqid }
        renderItem={({item}) => <DayBooking {...item} onPress={handleBookingPress}/>}
        sections={prepareSections(dates)}
        renderSectionHeader={(section) => <ActivitySectionHeader height={SECTION_HEIGHT} {...section}/>}
        onViewableItemsChanged={viewableHandler.current}
        viewabilityConfig={{
          minimumViewTime: 500,
          itemVisiblePercentThreshold: 10,
          waitForInteraction: false,
        }}
        scrollEventThrottle={32}
        renderSectionFooter={()=><View style={{height: 30}}/>}
        disableVirtualization={Platform.OS === 'web'}
        legacyImplementation={Platform.OS === 'web'}
        /*getItemLayout={(data, index) => {
          console.log(index, data[index].length);
          return ({
            length: ITEM_HEIGHT + 1,
            offset : (ITEM_HEIGHT + 1) * index + SECTION_HEIGHT * index, index,
          });
        }}*/
        getItemLayout = {sectionListGetItemLayout({
          // The height of the row with rowData at the given sectionIndex and rowIndex
          getItemHeight: (rowData, sectionIndex, rowIndex) => ITEM_HEIGHT, //sectionIndex === 0 ? 100 : 50,

          // These four properties are optional
          getSeparatorHeight: () => 0 /*/ PixelRatio.get()*/, // The height of your separators
          getSectionHeaderHeight: () => SECTION_HEIGHT, // The height of your section headers
          getSectionFooterHeight: () => 30, // The height of your section footers
          listHeaderHeight: 0, // The height of your list header
          listFooterHeight: 200,
        })}
        ListFooterComponent={()=><>
          {authState !== 'auth' ? <UnauthMessage/> : null}
          {
            <AntiMenuFade height={200} style={{justifyContent:'center'}}>
              {loadingState === 'loading' ? <ActivityIndicator size={'large'}/> : null}
            </AntiMenuFade>
          }
          {loadingState === 'empty'
            ? <EmptyContentMessage
                onPressDetails={()=>{informPopupRef.current.open();}}
            />
            : null}
        </>
        }
        onEndReached={handleEndReached || (()=>{})}
      />
      <SimpleBottomSheet
        ref={calendarSheetRef}
        lastPoint={'50%'}
      >
        <View style={styles.calendarBox}>
          <Calendar
            onChange={handleCalendarChange}
            value={[sectionNavigator]}
          />
          <NewButton
            style={styles.calendarSubmit}
            onPress={()=>{
              calendarSheetRef.current?.close();
              handleSubmitCalendar();
            }}
          >
            {'Применить'}
          </NewButton>
        </View>
      </SimpleBottomSheet>
      <SimpleBottomSheet
        ref={bookingDetailsRef}
      >
        <BookingDetails booking={getCurrentBooking()}/>
      </SimpleBottomSheet>
      <SimpleBottomSheet
        ref={informPopupRef}
        lastPoint={'40%'}
      >
        <View style={styles.informBox}>
          <AppText style={styles.informText}>
            {'В этом разделе отображаются ваши заказы на дополнительные услуги курорта, такие как экскурсии, SPA и другие. '}
            {'Сейчас таких заказов не найдено.'}
            {'\n\nОбращайтесь в единый контактный центр '}
            <AppText
              onPress={()=>{Linking.openURL('tel:' + settings.supportPhone);}}
              style={defaultStyles.hlText}
            >
              {settings.supportPrettyPhone}
            </AppText>
            {' или к сотрудникам курорта, если у вас возникли вопросы.'}
          </AppText>
        </View>
      </SimpleBottomSheet>
    </SafeAreaView>
  );
};


export default UpcomingBookings;


const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
  },

  //day
  calendarBtn: {
    padding: 15,
    width: 40,
    flex: 1,
  },
  topDatesNavBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dayListBox: {
    paddingTop: 15,
    flex: 10,
  },
  dayScrollBox: {},
  dayScroller: {
    width: '100%',
  },
  day: {
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 14,
  },
  dayActive: {

  },
  dayDisabled : {

  },
  dayDowBox: {

  },
  dayDow: {
    marginBottom: 5,
    color: '#707070',
  },
  dayNumBox: {
    width: 40,
    height: 40,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.default.item,
  },
  dayNumBoxActive: {
    backgroundColor: colors.default.itemActive,
  },
  dayNumBoxDisabled : {
    backgroundColor: '#f8f7f7',
  },
  dayNum: {

  },
  dayNumActive : {
    color: '#fff',
  },
  dayNumDisabled : {
    color: '#dedede',
  },
  dayListRightMargin: {
    width: 30,
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 24,
    marginRight: 12,
    flex: 1,
  },
  monthBox:{
    marginLeft: 14,
    marginBottom: 6,
  },
  monthText:{
    color: '#707070',
  },


  //section
  sectionBox: {
    padding: 4,
    borderTopWidth: 6,
    borderTopColor: '#eeeeee',
    backgroundColor: '#fff',
  },
  sectionText: {
    color: '#3b3b3b',
  },

  //day item
  dayOfferBox:{
    padding: 12,
    borderBottomColor: '#f1f1f1',
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: ITEM_HEIGHT,
  },
  offerDateBox: {
    flex: 1.5,
    marginRight: 10,
  },
  offerDateText:{
    fontWeight: '400',
    fontSize: 17,
  },
  offerOutletBox: {

  },
  offerOutletText: {
    color: '#757575',
  },
  offerTitleBox: {
    //height: '50%',
    flexDirection: 'row',
  },
  offerTitleText: {
    //flex:1,
    width: '100%',
    flexWrap: 'wrap',
  },
  offerDetails: {
    flex: 8,
  },

  //day booking
  dbStateText: {
    fontSize: 11,
  },

  //bonus

  bonusBox:{
    flex: 1,
  },
  bonusMinusBox:{},
  bonusMinusText:{
     color: colors.default.bonus,
  },
  bonusAddBox:{},
  bonusAddText:{
    color: '#28c00b',
  },


  //calendar
  calendarBox: {
    padding: 20,
    paddingTop: 40,
    flex: 1,
  },
  calendarSubmit: {
    position: 'absolute',
    bottom: 40,
    width: '100%',
  },


  //messageCard

  fullScreenMsg:{
    height: windowHeight * 0.80,
    width: '100%',
    paddingHorizontal: '20%',
    justifyContent: 'center',
  },
  msgTextBox:{
    paddingVertical: 20,
  },
  msgText:{
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '300',
  },
  msgButton:{
    width: '100%',
    backgroundColor: colors.default.main,
  },


  //inform box
  informBox: {
    padding: 20,
  },
  informText: {
    color: '#737373',
  },
});
