import {TouchableOpacity, View} from 'react-native';
import React from 'react';
import AppText from '../Basic/AppText';
import {useSelector} from 'react-redux';

const AppButton = props => {
  const {onPress, rootStyle, titleStyle, style, title, underlayColor, hidden} =
    props;

  const {defaultColors} = useSelector((state) => state.style);

  return hidden ? null : (
    <TouchableOpacity
      activeOpacity={0.6}
      onPress={onPress || (() => {})}
      underlayColor={underlayColor || 'white'}
      style={rootStyle}>
      <View
        style={[
          {
            backgroundColor: defaultColors.main,
            borderRadius: 40,
            alignSelf: 'center',
            justifyContent: 'center',
          },
          style,
        ]}>
        <AppText
          style={[
            {
              textTransform: 'uppercase',
              fontSize: 14,
              lineHeight: 20,
              alignSelf: 'center',
              padding: 10,
              color: 'white',
              justifyContent: 'center',
            },
            titleStyle,
          ]}>
          {title}
        </AppText>
      </View>
    </TouchableOpacity>
  );
};

export default AppButton;
