import React, {useCallback, useEffect, useState} from 'react';
import {TouchableOpacity, View, StyleSheet, FlatList, Platform, ScrollView, Modal} from 'react-native';
import {BackgroundImage} from '@rneui/base';
import AppText from '../Basic/AppText';
import {api} from '../System/api';
import bungalowImage from '../../img/Bungalow.jpeg';
import LinearGradient from 'react-native-linear-gradient';
import {getMinWidth} from '../Defaults/DisplayWidth';
import MiniOffer from '../Offers/MiniOffer';
import ScaledImage from '../Basic/ScaledImage';
import {BottomSheetScrollView} from '@gorhom/bottom-sheet';
import {useNavigation} from '@react-navigation/native';
import {SafeAreaView, useSafeAreaInsets} from 'react-native-safe-area-context';
import PageHeader from '../Basic/PageHeader';
import LoadingContainer from '../Basic/LoadingContainer';
import NewButton from '../Basic/NewButton';
import {buttonsNames} from '../Offers/defaults';
import {stateToRouteChain, webUrlGetAllParams} from '../System/navHelpers';
import * as _auth from '../GlobalStore/reducers/authSlice';
import {AuthOverlay} from '../Basic/BasicElements';
import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import * as draftBooking from '../GlobalStore/reducers/draftBookingSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useBookingContext} from './Context';
import LoadingOverlay from '../Basic/LoadingOverlay';
import settings from '../Defaults/settings';


const windowWidth = getMinWidth();

const bungalowOfferId = /\/test/.test(api.baseurl) ? 99 : 123;

const bungalow_images = {
  1: {
    id: 1,
    src: require('../../img/bungalow/bungalow_schema_1.png'),
  },
  2: {
    id: 2,
    src: require('../../img/bungalow/bungalow_schema_2.png'),
  },
  3: {
    id: 3,
    src: require('../../img/bungalow/bungalow_schema_3.png'),
  },
  4: {
    id: 4,
    src: require('../../img/bungalow/bungalow_schema_4.png'),
  },
  5: {
    id: 5,
    src: require('../../img/bungalow/bungalow_schema_5.png'),
  },
  6: {
    id: 6,
    src: require('../../img/bungalow/bungalow_schema_6.png'),
  },
};

const MainPageWidget = ({onPress}) =>
{

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const authState = useSelector(_auth.selectState);

  const handlePress = useCallback(() => {
    if (authState === 'auth' || settings.enableTemporaryBooking) {
      navigation.navigate('BungalowBookingForm');
    } else {
      dispatch(appEvent.newEvent('openAuthReq'));
    }
  }, [authState]);

  return (
    <>
      <AppText style={styles.sectionTitle}>
        {'Аренда бунгало'}
      </AppText>
      <TouchableOpacity
        onPress={handlePress}
        style={[
          styles.sectionImageBox,
        ]}
      >
        <BackgroundImage
          source={bungalowImage}
          style={[styles.sectionCard, styles.widgetBox]}
          imageStyle={styles.sectionImage}
        >
          <LinearGradient
            colors={['#00000000','rgba(0,0,0,0.6)']}
            style={styles.widgetGradient}
          />
          <AppText style={[styles.sectionImageText, styles.widgetText]}>
            {'Забронировать'}
          </AppText>
        </BackgroundImage>
      </TouchableOpacity>
    </>
  );
};

const BungalowBookingForm = () =>
{

  const [mapSector, setMapSector] = useState(null);
  const [locked, setLocked] = useState(null);
  const [loadingOverlay, setLoadingOverlay] = useState(true);
  const [authOverlayShow, setAuthOverlayShow] = useState(false);
  const [bungalows, setBungalows] = useState();
  const [schedule, setSchedule] = useState({});
  const [sectors, setSectors] = useState({});

  const [chosenDates, setChosenDates] = useState();
  const [clientPhone, setClientPhone] = useState();
  const [clientName, setClientName] = useState();
  const [clientComment, setClientComment] = useState();
  const [clientRoom, setClientRoom] = useState();
  const [loyaltyBonus, setLoyaltyBonus] = useState();

  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const {handleCalendarAction} = useBookingContext();

  useEffect(()=>{
    setLoadingOverlay(true);
    Promise.allSettled([
      loadList(),
      loadSectors(),
      loadSchedule(),
    ]).then((commonRes)=>{
      console.log('common res:', commonRes);
      let time = Date.now();
      bungalowSorter(commonRes.map(v=>v.value)).then(()=>{
        setLoadingOverlay(false);
        console.log(time, Date.now(), (time - Date.now()) / 1000);
      }).catch(()=>{
        setLoadingOverlay(false);
      });

    });
  }, []);

  useEffect(()=>{
    if (mapSector != null) {

    }
  }, [setMapSector]);

  const handleSectorItemPress = (id) => {
    setMapSector(v=>{
      return v === id ? null : id;
    });
  };

  const loadList = () => {
    return new Promise((resolve,reject)=>{
      api.bungalow.list().then(res=>{
        console.log(res.data);
        setBungalows(res.data);
        resolve(res.data);
      }).catch(reject);
    });

  };

  const bungalowSorter = (res) => {
    return new Promise((resolve, reject) => {
      let [bungalows, sectors, schedule] = res;
      console.log('start sorter');
      let _schedule = {};
      for (let dt in schedule) {
        if (!_schedule[dt]) {
          _schedule[dt] = {
            date: dt,
            bungalows: {},
            sectors: {},
          };
        }
        for (let bng in schedule[dt]) {
          //console.log(JSON.stringify(Object.values(sectors)));
          let sector = Object.values(sectors).find(i => i.bungalows.includes('/api/bungalows/' + bng));
          if (!_schedule[dt].bungalows[bng]) {
            _schedule[dt].bungalows[bng] = {
              id: bng,
              sector: sector?.id,
            };
          }
          _schedule[dt].sectors[sector?.id] = sector?.name;
        }
      }
      console.log('end sorter');
      console.log(JSON.stringify(_schedule));
      resolve(_schedule);
    });
  };

  const loadSectors = () => {
    return new Promise((resolve,reject)=>{
      api.bungalow.sectors().then(res=>{
        console.log(res.data);
        let _sectors = {};
        for (let i in res.data) {
          let sect = res.data[i];
          _sectors[sect.id] = sect;
        }
        setSectors(_sectors);
        resolve(_sectors);
      }).catch(reject);
    });
  };

  const loadSchedule = () => {
     return new Promise((resolve,reject)=>{
      api.bungalow.schedule().then(res => {
        setSchedule(res.data);
        resolve(res.data);
      }).catch(reject);
    });
  };

  const validate = () => {
    return new Promise((resolve, reject)=>{
      setTimeout(()=> {
        resolve(false);
      }, 2000);
    });
  };

  const handleAction = async action =>
  {
    if (!await validate()) {return;}
    dispatch(_auth.getState())
      .unwrap()
      .then(state => {
        if (state.state !== 'auth') {
          //setPhoneOverlayShow(true);
          setAuthOverlayShow(true);
        } else {
          handleCalendarAction({
            chosenDates,
            /** check this solution*/
            chosenTime: '12:00',
            action,
            clientPhone,
            clientName,
            clientComment,
            clientRoom,
            /** check this solution*/
            recall: false,
            loyaltyBonus,
          });
          /*onAction({
            action: action,
            chosenDates,
            chosenTime,
            recall,
            clientComment,
            clientRoom,
            loyaltyBonus,
          });*/
        }
      });
  };

  return (
    <SafeAreaView style={styles.contentContainer}>
      <PageHeader title={'Забронировать бунгало'}/>
      <ScrollView>
        <MiniOffer />
        <View style={styles.section}>
          <AppText style={[styles.sectionLabel, {marginTop: 10}]}>
            Выберите пожалуйста сектор
          </AppText>
          <FlatList
            data={Object.values(bungalow_images)}
            keyExtractor={(item)=>item.id}
            renderItem={({item}) =>
              <BungalowMapItem
                {...item}
                onPress={handleSectorItemPress}
                selected={mapSector}
              />
            }
            horizontal={true}
            style={styles.bmList}
            contentContainerStyle={styles.bmListContainer}
          />
        </View>
      </ScrollView>
      <View style={[styles.bookingButtonBlock, {paddingBottom: insets.bottom}]}>
        <LoadingContainer loading={locked} >
          <NewButton
            style={styles.bookingButton}
            children={buttonsNames.default}
            onPress={() => handleAction('call_later')}
          />
        </LoadingContainer>
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={authOverlayShow}
      >
        <AuthOverlay
          onPressClose={() => setAuthOverlayShow(false)}
          onPressAuth={() => {
            let draftBookingData = {
              /*bookingAction,
              offer:{
                id: offer.id,
                tariffs: offer.tariffs,
                attributes: offer.attributes,
                mainImage: offer.mainImage,
              },
              booking: {
                chosenDates,
                chosenTime,
                clientComment,
                clientRoom,
                loyaltyBonus,
              },
              navParams: stateToRouteChain(
                navigation.getState(),
                (Platform.OS === 'web' ? webUrlGetAllParams(window.location) : {}),
              ),*/

            };

            dispatch(appEvent.newEvent('dismissAllBottomSheets'));
            //console.log('add draft booking data', draftBookingData);
            dispatch(draftBooking.add(draftBookingData));
            setTimeout(()=>{
              setAuthOverlayShow(false);
              navigation.reset({index: 0, routes: [{name: 'Auth'}]});
            }, 500);
            /*dispatch({
              action: bookingAction,
              chosenDates,
              chosenTime,
              clientComment,
              clientRoom,
              loyaltyBonus,
            });*/
          }}
        />
      </Modal>
      <LoadingOverlay loading={loadingOverlay}/>
    </SafeAreaView>
  );
};

const BungalowMapItem = ({src, id, onPress, selected}) => {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={()=>onPress(id)}>
      <ScaledImage
        style={[
          styles.bmItemBox,
          {opacity: selected == null || selected == id ? 1 : 0.4},
        ]}
        source={src}
        //imageStyle={styles.bmItemImage}
        height={600}
      >
      </ScaledImage>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex:1,
    backgroundColor: '#fff',
  },

  sectionTitle: {
    paddingHorizontal: 12,
    paddingVertical: 24,
    fontSize: 21,
    fontWeight: '500',
  },
  sectionImage: {
    borderRadius: 6,
  },
  sectionImageText: {
    fontSize: 14,
    lineHeight: 16,
    padding: 8,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  sectionImageBox: {
    paddingHorizontal: 12,
  },

  sectionCard: {
    height: windowWidth / 2.47,
    borderRadius: 6,
  },
  widgetBox: {
    backgroundColor: '#f6e9ff',
    //backgroundColor: '#fff8f1',
    borderRadius: 6,
  },
  widgetText: {
    color: 'white',
    fontWeight: '500',
    fontSize: 14.5,
  },
  widgetGradient: {
    width: '100%',
    height: '32%',
    position: 'absolute',
    bottom: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },


  //bungalow map item
  bmItemBox: {
    //width: 100,
    //height: 300,
    borderRadius: 12,
    margin: 10,
    marginRight: 0,
    marginLeft: 3,
  },
  bmItemImage: {
    borderRadius: 12,
  },
  bmList: {
    height: 630,
    marginHorizontal: -20,
  },
  bmListContainer: {
    paddingRight: 20,
    paddingLeft: 17,
  },


  //section
  section: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderBottomColor: '#FAFAFA',
    borderBottomWidth: 8,
  },
});


export {
  BungalowBookingForm,
  MainPageWidget,
};
