import React from 'react';
import {StyleSheet, View} from 'react-native';
import MiniOffer from '../Offers/MiniOffer';
import {defaultStyles} from '../Defaults/Styles';
//import TariffsView from '../Tariffs/TariffsView';
//import TariffsBookingView from '../Tariffs/TariffsBookingView';
//import TariffsMiniView from '../Tariffs/TariffsMiniView';
import TariffCounter from '../Tariffs/TariffCounter';
import moment from 'moment';


const BasketItem = ({id, offer, booking, dateFrom}) => {
  return <View style={styles.itemBox}>
    <MiniOffer
      {...offer}
      style={styles.offer}
      date={moment(dateFrom)}
      showPrice={true}
    />
    <TariffCounter {...offer}/>
  </View>;
};

const styles = StyleSheet.create({
  itemBox: {
    //height: 100,
    width: '100%',
    backgroundColor: 'white',
    //...defaultStyles.cardShadow,
    marginBottom: 14,
    borderRadius: 6,
    padding: 14,
  },
  offer: {
    marginBottom: 8,
  },
});

export default BasketItem;
