import React, {Suspense} from 'react';
import {View, StyleSheet, ActivityIndicator} from 'react-native';

const LazyPage = ({Component}) => {

  return (
    <View style={styles.container}>
      <Suspense fallback={
        <View style={styles.loader}>
          <ActivityIndicator size={'large'}/>
        </View>
      }>
        <Component/>
      </Suspense>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
  loader: {
    flex: 1,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    width: '100%',
  },
});

export default LazyPage;
