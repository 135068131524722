import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Moscow');

export const datesGenerator = (schedule, filter) => {
  let dates = {};
  let now = moment();
  let hourAgo = moment().add(-1, 'h');
  for (let interval in schedule) {
    let item = schedule[interval];
    let from = moment(item.from,'YYYY-MM-DD');
    let to = moment(item.to,'YYYY-MM-DD');
    if (filter){
      if (
        from.isBetween(filter.from, filter.to, 'dates', '[]')
        ||
        to.isBetween(filter.from, filter.to, 'dates', '[]')
        ||
        filter.from.isBetween(from, to, 'dates', '[]')
        ||
        filter.to.isBetween(from, to, 'dates', '[]')
      ){
        from = filter.from.isAfter(from) ? filter.from : from;
        to = filter.to.isBefore(to) ? filter.to : to;
      } else {
        __DEV__ && console.warn('filter outersects: ', {filter, from, to});
        continue;
      }
    }

    let cursor = from.isAfter(hourAgo) ? from.clone() : hourAgo.clone();
    //cursor.startOf('day');
    //console.log({cursor, from, to, item:JSON.stringify(item)}, cursor.isBetween(from,to,null,'[]'));
    while (cursor.isBetween(from,to,'date','[]')){
      let dow = cursor.day();
      let times = (item[dow] && item[dow][0] && item[dow][0]?.from)
        ? item[dow]
        : item[7/*default*/];
      if (times) {
        //console.log({fmt : cursor.format('YYYY-MM-DD HH:mm:00'), times, item});
        for (let time in times) {
          let nrm = times[time].from.match(/^(\d\d?):?(\d\d?)?/);
          if (!nrm || nrm[0] === null || nrm[0] === undefined) {continue;}
          let h = parseInt(nrm[1]);
          let m = parseInt(nrm[2]) || 0;
          let date_time = cursor.clone().set({minutes:m, hour:h});
          //console.log({time:times[time].from, date_time, hm:{h,m} });
          if (date_time.isAfter(hourAgo)) {
            dates[date_time.format('YYYY-MM-DD HH:mm:00')] = date_time;
          }
        }
      }
      cursor.add(1,'day');
    }
  }
  return dates;
};

export const generateTimesToday = (schedule) => {
  let times = [];
  let now = moment();
  let dow = now.day();
  for (let r in schedule){
    let range = schedule[r];
    if (now.isBetween(range.from, range.to)){
      let range_times = range[dow] || range?.default;
      if (range_times) {
        for (let i in range_times){
          times.push(range_times[i].from);
        }
      }
    }
  }
  return times;
};

