import React from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import AppText from '../Basic/AppText';

const TariffsMiniView = ({tariffs, onPress}) => {
  return <TouchableOpacity onPress={onPress}>
    {tariffs.filter(i=>i.count).map(item =>
      <View key={item.id} style={styles.tariffContainer}>
        <View style={styles.titleContainer}>
          <AppText style={styles.tariffText}>
            {item.title}{' '}
          </AppText>
        </View>
        <View style={styles.countContainer}>
          <AppText style={styles.tariffTextX}>
            {'x'}{item.count}
          </AppText>
        </View>
      </View>
    )}
  </TouchableOpacity>;
};

const styles = StyleSheet.create({
  tariffContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleContainer: {

  },
  countContainer: {
    width: 20,
    height: 15,
    borderRadius: 50,
    borderColor: 'white',
    borderWidth: 1,
    backgroundColor: '#efefef',
  },
  tariffText: {

    fontSize: 12,
    lineHeight: 15,
    color: '#6b6b6b',
    textAlignVertical: 'center',

  },
  tariffTextX: {

    textAlignVertical: 'center',
    fontSize: 9,
    textAlign: 'center',
    //lineHeight: 10,
  },
});

export default TariffsMiniView;
