import humanize from 'humanize-number';
import {calcOfferFinalPrice, getAggTariffPrice, humanizePrice} from '../Cards/CardPrice';
import AppText from '../Basic/AppText';
import React from 'react';
import NewButton, {textStyle} from '../Basic/NewButton';
import {buttonsNames} from './defaults';
import {StyleSheet, View} from 'react-native';

export const PriceButton = ({offer, onPress}) => {
  const {tariffs, actionButton, price, discount} = offer;
  const inverseDiscount = ((100 - (discount || 0)) / 100) || 0;
  const tariffsUsed = tariffs && tariffs?.length > 0;
  const finalPrice = calcOfferFinalPrice(offer);
  const tariffsPrices = getAggTariffPrice(offer);

  let buttonName = (buttonsNames[actionButton] || buttonsNames.default);

  let priceText = '', firstPriceText = '';
  if (tariffsUsed) {
    if (finalPrice === 0) {
      if (tariffsPrices.min === 0 && tariffsPrices.max === 0){
        priceText = 'бесплатно';
        firstPriceText = 'от ' + humanizePrice({price: tariffsPrices.fmin}, true);
      } else {
        priceText = 'от ' + humanizePrice({price: tariffsPrices.min}, true);
        firstPriceText = 'от ' + humanizePrice({price: tariffsPrices.fmin}, true);
      }
    } else {
      priceText = humanizePrice({price: finalPrice}, true);
      firstPriceText = humanizePrice({price: finalPrice / inverseDiscount}, true);
    }
  } else {
    if (finalPrice === 0) {
      priceText = firstPriceText = '';
      if (buttonName === buttonsNames.reserve){
        buttonName = buttonsNames.free_reserve;
      } else {
        priceText = 'Бесплатно';
      }
    } else {
      priceText = humanizePrice({price:finalPrice, from:price?.from}, true);
      firstPriceText = humanizePrice({price:finalPrice / inverseDiscount, from:price?.from}, true);
    }
  }

  return (
    <NewButton
      style={[styles.button, discount ? {paddingVertical: 10} : {}]}
      onPress={onPress}
    >
      <AppText style={textStyle}>
        {buttonName}
      </AppText>
      <View style={styles.pricesContainer}>
        {discount
          ? <AppText style={styles.buttonFirstPrice}>
              {firstPriceText}
            </AppText>
          : null
        }
        <AppText style={styles.buttonPrice}>
          {priceText}
        </AppText>
      </View>
    </NewButton>
  );
};

const styles = StyleSheet.create({
  button: {
    minWidth: '45%',
    flexDirection: 'row',
  },
  buttonPrice: {
    ...textStyle,
    color: '#fff',
    opacity: 0.5,
  },
  buttonFirstPrice: {
    ...textStyle,
    color: '#fff',
    fontSize: 14,
    marginBottom: -5,
    opacity: 0.3,
    textDecorationLine: 'line-through',
  },
  pricesContainer: {
    marginLeft: 10,
    alignItems: 'flex-start',
  },
});
