import React, {useEffect, useRef} from 'react';
import {View, StyleSheet} from 'react-native';
import SimpleBottomSheet from './SimpleBottomSheet';
import AppText from '../Basic/AppText';
import {useSelector} from 'react-redux';
import * as _appEvent from '../GlobalStore/reducers/appEventSlice';
import NewButton from '../Basic/NewButton';

const AccountIsDeletedBottomSheet = () =>
{
  const ref = useRef();
  const event = useSelector(_appEvent.select);

  useEffect(()=>{
    if (event.name === 'accountIsDeleted') {
      ref.current?.open();
    }
  }, [event]);

  const handleOk = () => {
    ref.current?.close();
  };

  return (
    <SimpleBottomSheet ref={ref} lastPoint={'40%'}>
      <View style={styles.mainBox}>
        <AppText style={styles.title}>
          {'Ваш профиль и связанные с ним данные успешно удалены'}
        </AppText>
        <AppText style={styles.mainText}>
          {'Вы можете в любое время зарегистрироваться снова, чтобы пользоваться всеми функциями приложения'}
        </AppText>
      </View>
      <View style={styles.buttonBox}>
        <NewButton
          style={styles.button}
          onPress={handleOk}
        >
          {'Отлично'}
        </NewButton>
      </View>
    </SimpleBottomSheet>
  );
};

const styles = StyleSheet.create({
  mainBox: {
    padding: 20,
    flex: 1,
  },
  title: {
    fontSize: 20,
    color: '#262626',
  },
  mainText: {
    paddingTop: 18,
    color: '#424242',
  },
  buttonBox: {
    flex: 1,
  },
  button: {},
});

export default  AccountIsDeletedBottomSheet;
