import React, {useContext} from 'react';
import {TouchableOpacity} from 'react-native';
import AppText from '../Basic/AppText';
import {useSelector} from 'react-redux';

const RegisterToast = ({onPress}) => {
  const {defaultColors} = useSelector((state) => state.style);
  return (
    <TouchableOpacity
    onPress={onPress}
    style={{
      backgroundColor: defaultColors.main,
      borderRadius: 20,
      paddingHorizontal: 10,
      paddingVertical: 2,
    }}>
      <AppText
        style={{
          color: 'white',
          fontSize: 18,
        }}>
        Зарегистрироваться
      </AppText>
    </TouchableOpacity>
  );
};

export {RegisterToast};
