import React, {useEffect, useRef, useState} from 'react';
import {SafeAreaView, View, StyleSheet, FlatList, TouchableOpacity, RefreshControl} from 'react-native';
import TagNav from '../Basic/TagNavigation';
import AppText from '../Basic/AppText';
import {BackgroundImage} from '@rneui/base';
import AntiMenuFade from '../Basic/AntiMenuFade';
import CategoryNav from '../Interests/CategoryNav';
import SearchHeader from '../Basic/SearchHeader';
//import Modal from 'react-native-modal';
import {getMinWidth} from '../Defaults/DisplayWidth';
import StoryList from '../Stories/StoryList';
import SpecialOffersList from '../Offers/SpecialOffersList';
const windowWidth = getMinWidth();
import {colors} from '../Defaults/Styles';
import LinearGradient from 'react-native-linear-gradient';
import {api} from '../System/api';
import settings from '../Defaults/settings';
import {useNavigation} from '@react-navigation/native';

import {setBottomBarColor} from '../GlobalStore/reducers/styleSlice';
import * as statusBar from '../GlobalStore/reducers/statusBarSlice';
import * as auth from '../GlobalStore/reducers/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import MainPageWidget from '../Loyalty/MainPageWidget';
//import RecommendationsList from '../Offers/RecommendationsList';
//import {useNavigation} from '@react-navigation/native';
import {MainPageWidget as BungalowWidget} from '../Booking/BungalowBooking';

const clubcarImage = require('../../img/ClubCar2Min.jpeg');
const mapImage = settings.mapMainScreenImage;

const MainPage = () => {
  const navigation = useNavigation();

  const listRef = useRef();
  const storiesRef = useRef();

  const authState = useSelector(auth.selectState);
  const dispatch = useDispatch();

  const [refreshing, setRefreshing] = useState(false);


  useEffect(() => {
    return navigation.addListener('focus', () => {
      dispatch(statusBar.setStyle({backgroundColor: '#fff'}));
      dispatch(setBottomBarColor('#fff'));
      //SystemNavigationBar.navigationHide();
    });
  }, [navigation]);
  useEffect(() => {
    dispatch(statusBar.setStyle({backgroundColor: '#fff'}));
    dispatch(setBottomBarColor('#fff'));
    //SystemNavigationBar.navigationHide();
  }, []);

  const handleRefresh = () => {
    storiesRef.current?.reload();
  };

  const pageElements = [
    {
      id: 1,
      title: 'Обзор',
      name: 'overview',
      section: <View style={[styles.section]}>
          <LinearGradient
            style={[styles.section, styles.welcomeSection]}
            colors={['#fffbf8','#ffedde','#fdf8f3']} //ffe1be, FFF9F2
            //useAngle={true}
            locations={[0.1,0.6,0.99]}
            //angle={135}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
        >
          <StoryList ref={storiesRef}/>
        </LinearGradient>
      </View>,
    },
    {
      id: 2,
      title: 'Лояльность',
      name: 'loyalty',
      section: <View style={[styles.section, styles.navSection]}>
        <AppText style={styles.sectionTitle}>
          {'Программа лояльности'}
        </AppText>
        {
          authState === 'auth'
            ?
            <View style={styles.sectionImageBox}>
              <View style={[
                styles.sectionCard,
                styles.loyaltySection,
              ]}>
                <MainPageWidget style={styles.sectionCard}>
                  {/*<AppText style={[styles.sectionImageText, styles.loyaltyMoreText]}>
                {'Подробнее'}
              </AppText>*/}
                </MainPageWidget>
              </View>
            </View>
            : <AuthRequiredWidget sectionName={'свой статус в программе лояльности'}/>
        }
      </View>,
    },
    {
      id: 3,
      title: 'Чем заняться',
      name: 'whattodo',
      section: <View style={[styles.section]}>
        <AppText style={styles.sectionTitle}>
          {'Чем заняться'}
        </AppText>
        <View style={styles.categorySection}>
          <CategoryNav />
        </View>
      </View>,
    },
    {
      id: 4,
      title: 'Club Car',
      name: 'map',
      section: <View style={[styles.section,styles.navSection]}>
        <AppText style={styles.sectionTitle}>
          {'Club Car'}
        </AppText>
        <TouchableOpacity
          onPress={()=>{
            navigation.navigate('Offer',{
              id: api.baseurl.indexOf('test') >= 0
                ? settings.clubCarOffer.dev
                : settings.clubCarOffer.prod});}}
          style={[
            styles.sectionImageBox,
          ]}
        >
          <BackgroundImage
            source={clubcarImage}
            style={[styles.sectionCard, styles.clubCarBox]}
            imageStyle={styles.sectionImage}>
            <LinearGradient
              colors={['#00000000','rgba(0,0,0,0.6)']}
              style={styles.clubCarGradient}
            />
            <AppText style={[styles.sectionImageText, styles.clubCarText]}>
              {'Заказать'}
            </AppText>
          </BackgroundImage>
        </TouchableOpacity>
      </View>,
      enabled: settings.enableClubcarOffer,
    },
    {
      id: 5,
      title: 'Навигация',
      name: 'map',
      section: <View style={[styles.section,styles.navSection]}>
        <AppText style={styles.sectionTitle}>
          {'Навигация'}
        </AppText>
        <TouchableOpacity
          onPress={()=>{navigation.navigate('ActivitiesMap');}}
          style={styles.sectionImageBox}
        >
          <BackgroundImage
            source={mapImage}
            style={styles.sectionCard}
            imageStyle={styles.sectionImage}>
            <AppText style={styles.sectionImageText}>
              {'Смотреть\nкарту'}
            </AppText>
          </BackgroundImage>
        </TouchableOpacity>
      </View>,
      enabled: settings.enableMapNavigation,
    },
    {
      id: 6,
      title: 'Спецпредложения',
      name: 'specialoffers',
      section: <View style={styles.section}>
        <AppText style={styles.sectionTitle}>
          {'Спецпредложения'}
        </AppText>
        {
          authState === 'auth'
          ? <SpecialOffersList />
          : <AuthRequiredWidget sectionName={'спецпредложения'}/>
        }
      </View>,
    },
    {
      id: 7,
      title: 'Аренда бунгало',
      name: 'bungalow',
      section: <View style={[styles.section]}>
        <BungalowWidget/>
      </View>,
      enabled: settings.enableBungalowBooking,
    },
    /*{
      id: 8,
      title: 'Рекомендации',
      name: 'recommendations',
      section: <View style={styles.section}>
        <AppText style={styles.sectionTitle}>
          {'Рекомендации'}
        </AppText>
        <RecommendationsList />
      </View>,
    },*/
    //{id: 8, title: 'Сервисы', name: 'services'},
    //{id: 9, title: '', navId: ''},
  ].filter(v => v.enabled !== false);

  return (
      <SafeAreaView style={styles.container}>
        <SearchHeader />
        <View style={styles.nav}>
          <TagNav list={pageElements} onPressTag={({navId})=>{
            listRef.current?.scrollToIndex({
              index: navId,
            });
          }}/>
        </View>
        <FlatList
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={handleRefresh}
            />
          }
          ref={listRef}
          renderItem={({item})=>item.section}
          data={pageElements}
          ListFooterComponent={
            <AntiMenuFade height={200} style={{backgroundColor:'rgba(246, 246, 246, 1)'}}/>
          }
        />
      </SafeAreaView>
  );
};

const AuthRequiredWidget = ({sectionName}) =>
{
  const navigation = useNavigation();
  const dispatch = useDispatch();

  return (
    <View style={styles.soAuthPlaceholder}>
      <AppText style={styles.soAuthText}>
        {'Необходимо'}
        <AppText
          onPress={()=>{
            dispatch(auth.logout());
            //navigation.reset({index: 0, routes: [{name: 'Auth'}]});
          }}
          style={styles.soAuthTextB}>
          {' авторизоваться'}
        </AppText>
        {`, чтобы увидеть ${sectionName}`}
      </AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
  },
  nav: {

  },
  section: {
    borderBottomWidth: 8,
    borderColor: 'rgba(246, 246, 246, 1)',
  },
  sectionTitle: {
    paddingHorizontal: 12,
    paddingVertical: 24,
    fontSize: 21,
    fontWeight: '500',
  },

  //sections
  welcomeSection: {
    backgroundColor:'rgba(255, 249, 242, 1)',
    paddingBottom: 24,
    paddingTop: 24,
  },

  categorySection: {
    paddingHorizontal: 12,
    paddingBottom: 10,
  },

  loyaltySection: {
    backgroundColor: 'rgba(255,239,224,1)',
    //flexDirection: 'row',
  },
  loyaltyMoreText: {
    color: 'rgba(253,177,97,1)',
  },

  sectionImage: {
    borderRadius: 6,
  },
  sectionImageText: {
    fontSize: 14,
    lineHeight: 16,
    padding: 8,
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    fontWeight: 500,
  },
  sectionImageBox: {
    paddingHorizontal: 12,
  },

  sectionCard: {
    height: windowWidth / 2.47,
    borderRadius: 6,
  },

  //navSection
  navSection: {
    paddingBottom: 24,
  },

  //specialOffer
  soAuthPlaceholder: {
    height: 100,
    width: '100%',
    padding: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  soAuthText: {
    fontSize: 16,
    alignSelf:'center',
  },
  soAuthTextB: {
    fontSize: 16,
    alignSelf:'center',
    fontWeight: '500',
    color: colors.default.main,
    //height: 16,
  },

  //clubCar
  clubCarBox: {
    backgroundColor: '#f6e9ff',
    //backgroundColor: '#fff8f1',
    borderRadius: 6,
  },
  clubCarText: {
    color: 'white',
    fontWeight: '500',
    fontSize: 14.5,
  },
  clubCarGradient: {
    width: '100%',
    height: '32%',
    position: 'absolute',
    bottom: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});

export default MainPage;
