import {View} from 'react-native';
import React from 'react';

export default ({height, children, style}) =>
  <View style={[{
    height: height || 100,
    width: '100%',
    //justifyContent: 'center',
    alignItems: 'center',
  },style]}>
    {children}
  </View>;
