import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import AppText from '../Basic/AppText';
import {BottomSheetTextInput} from '@gorhom/bottom-sheet';
import {Tumbler} from '../Basic/Tumbler';
import {colors} from '../Defaults/Styles';
//import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';
import {DropdownList} from '../Basic/DropdownList';
import {Icon} from '@rneui/base';
import {useDynamicBottomSheet} from '../BottomSheets/DynamicBottomSheetContext';

const defaultValid = {valid: true, price: 0, title: ''};
const defaultValidFalse = {
  ...defaultValid,
  title: 'Необходимо заполнить',
  valid: false,
};
const defaultValidTrue = {
  ...defaultValid,
};

const SwitchAttr = forwardRef(({id, value, values, onChange, style},ref) => {
  const [_value, setValue] = useState(0);
  const items = values.map((i,k) =>
    <AppText
      style={[
        styles.switchItemText,
        _value === k ? styles.switchItemTextActive : null,
      ]}>
      {i.value}
    </AppText>
  );
  const map = values.map(i=>i.id);

  useImperativeHandle(ref, ()=>({
    validate: () => {
      let _price = _value.price || 0;
      return ({
        ...defaultValidTrue,
        valid: true,
        price: _price,
      });
    },
  }), [_value]);

  useEffect(()=>{
    let _ind = values.findIndex(v=>v.id === value) || 0;
    if (_ind === -1){
      setValue(0);
      let firstValue = values[0];
      onChange({
        key: id,
        value: firstValue?.id,
        price: firstValue?.price || 0,
      });
    } else {
      setValue(_ind);
    }
  }, [value]);

  const handleChange = (v) => {
    if (map) {
      let val = values[map[v]];
      onChange({
        key: id,
        value: map[v],
        price: val?.price || 0,
      });
    }
  };

  return (
    <View style={[style, styles.switchBox]}>
      {/*{values.map(item => <View><AppText>{item.value}</AppText></View>)}*/}
      <Tumbler
        value={_value || 0}
        onChange={handleChange}
        items={items}
        itemStyle={{padding: 7}}
        drawerStyle={{
          backgroundColor: colors.default.main,
          borderRadius: 30,
          padding: 0,
        }}
        containerStyle={{
          padding:3,
          margin: 10,
          borderRadius: 30,
          minWidth: 110,
        }}
      />
    </View>
  );
});
const NumberAttr = forwardRef(({id, value, values, onChange, style},ref) => {
  return (
    <View style={[style]}>
      <BottomSheetTextInput
        keyboardType={'numeric'}
        value={value}
        //maxLength={4}
        onChangeText={text=>{
          onChange(text.replace(/[^0-9]/g, ''));
        }}
        style={styles.input}
      />
    </View>
  );
});
const CountAttr = forwardRef(({id, value, values, onChange, style},ref) => {
  const [state, setState] = useState();
  const firstValue = values[0];

  const [min, max] = values.reduce((a,v)=>{
    return [
      Math.min(a[0], parseInt(v?.value)),
      Math.max(a[1], parseInt(v?.value)),
    ];
  }, [parseInt(firstValue?.value), 0]);

  useEffect(()=>{
    setState(min);
  },[values]);

  useEffect(()=>{
    let _value,
      _ind = values.findIndex(v => v?.id == value);
    //console.log(_ind, state, values[_ind]);
    if (_value = values[_ind]){
      setState(parseInt(_value.value));
    }
  },[value]);

  useEffect(()=>{
    let _value,
      _ind = values.findIndex(v => v?.value == state);
    //console.log(_ind, state, values[_ind]);
    if (_value = values[_ind]){
      onChange({
        key: id,
        value: _value.id,
        price: _value.price || 0,
      });
    }
    //onChange(state);
  },[state]);

  const handleMinus = () => {
    setState(v => {
      let val = ((v || min) !== min ? v - 1 : v);
      //if (v !== val){ onCounterChange(id, val); }
      return val;
    });
  };
  const handlePlus = () => {
    setState(v => {
      let val = (v !== max ? (v || 0) + 1 : v);
      //if (v !== val) {onCounterChange(id, val);}
      return val;
    });
  };
  const handleClear = () => {
    setState(0);
  };

  return (
    <View style={[style, styles.counterContainer]} >
      <View style={styles.counter}>
        <TouchableOpacity style={styles.counterToolContainer} onPress={handleMinus} onLongPress={handleClear}>
          <Text style={styles.counterTool}>-</Text>
        </TouchableOpacity>
        <AppText style={styles.counterCount}>
          {state || 0}
        </AppText>
        <TouchableOpacity style={styles.counterToolContainer} onPress={handlePlus}>
          <Text style={styles.counterTool}>+</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
});
const TimelineAttr = forwardRef(({style},ref) => {
  return (
    <View style={[style]} />
  );
});
const TextAttr = forwardRef(({id, value, onChange, style},ref) => {
  return (
    <View style={[style]}>
      <BottomSheetTextInput
        keyboardType={'default'}
        value={value}
        maxLength={4}
        onChangeText={text=>{
          onChange(text);
        }}
        style={styles.input}
      />
    </View>
  );
});
const DropDownAttr = forwardRef(({id, value, values, onChange, style, required, ...other},ref) => {
  const listRef = useRef();
  const [_value, setValue] = useState([]);
  const {openWith, close} = useDynamicBottomSheet();

  const handleChange = (items)=>{
    setValue(items);
    close && close();
    onChange({
      key:id,
      value:items[0].id,
      price:items[0].data.price,
    });
  };

  const transformListItem = v => ({
    id: v.id,
    title: v.value,
    subtitle: (v.price ? '+' + v.price + ' руб.' : null),
    data: v,
    //icon: {name: '', type: '', size: 0},
    //onPress : () => {},
    selected: Array.isArray(_value) ? _value.findIndex(i=>i.id === v.id) >= 0 : false,
  });

  const content = <DropdownList
    ref={listRef}
    list={values.map(transformListItem)}
    onChange={handleChange}
    listContainerStyle={styles.dropDownListContainer}
  />;

  useImperativeHandle(ref, ()=>({
    validate: () => {
      console.log({required, other});
      if (!required) { return {...defaultValidTrue}; }
      let _valid = _value.length > 0;
      let _price = _value[0] ? (_value[0].price || 0) : 0;
      return ({
        ...defaultValid,
        valid: _valid,
        title: _valid ? '' : 'Необходимо выбрать значение',
        price: _price,
      });
    },
  }), [_value]);

  const title = _value && _value[0] ? _value[0].title : '';

  useEffect(()=>{
    let _ind = values.findIndex(v=>v.id === value) || 0;
    if (_ind !== -1){
      let v = values[_ind];
      setValue([transformListItem(v)]);
      //setValue(0);
      //let firstValue = values[0];
      //onChange({key: id, value: firstValue?.id});
    }
  }, [value]);

  return (
    <View style={[styles.dropDownItem, style]}>
      <View style={styles.dropDownBox}>
        <View style={styles.dropDownChoice}>
          <AppText style={styles.dropDownChoiceText}>
            {title}
          </AppText>
        </View>
        <TouchableOpacity
          style={styles.dropDownButton}
          onPress={()=>{
            openWith && openWith(content);
          }}
        >
          <Icon
            style={styles.dropDownIcon}
            type={'simple-line-icon'}
            name={'arrow-right'}
            size={15}
            color={'#fff'}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
});
const CommentAttr = forwardRef(({value, onChange, style},ref) => {
  return (
    <View style={[style]}>
      <BottomSheetTextInput
        numberOfLines={3}
        multiline={true}
        keyboardType={'default'}
        value={value}
        onChangeText={onChange}
        style={[styles.input, styles.commentInput]}
      />
    </View>
  );
});

export const attributeTypes = {
  switch: SwitchAttr,
  number: NumberAttr,
  count: CountAttr,
  timeline: TimelineAttr,
  text: TextAttr,
  comment: CommentAttr,
  dropdown: DropDownAttr,
  default: TextAttr,
};

export const getAttributeTypes = () => {
  return Object.keys(attributeTypes);
};

const getAttributeBoxStyle = (type) => {
  switch (type) {
    case 'switch' :
    case 'dropdown' :
    case 'count': return styles.twoColumnAttrBox;
    default : return  styles.oneColumnAttrBox;
  }
};

const getAttribute = (type) => {
  return attributeTypes[type || 'default'];
};

const Attribute = forwardRef(({id, title, type, required, values, value, onChange, style}, ref) => {
  const [valid, setValid] = useState({
    title: null,
  });
  const attrRef = useRef();
  const Attr = getAttribute(type);
  const dynamicStyle = getAttributeBoxStyle(type);

  const clearValidation = () => {
    setValid({...defaultValidTrue});
  };

  useImperativeHandle(ref, ()=>({
    validate: () => {
      let _valid = attrRef.current?.validate();
      setValid(_valid);
      return _valid;
    },
    clearValidation: () => {
      clearValidation();
    },
  }));

  const handleChange = (_value) => {
    clearValidation();
    onChange && onChange(_value);
  };

  return (
    <View style={[styles.attrBox, style, dynamicStyle]}>
      <View style={styles.attrTitle}>
        <AppText style={styles.attrTitleText}>
          {title}
        </AppText>
        {valid.title
          ? <AppText style={styles.attrValidText}>
            {valid.title}
          </AppText>
          : null}
      </View>
      <Attr
        key={0}
        ref={attrRef}
        id={id}
        value={value}
        values={values}
        style={styles.attrElementBox}
        onChange={handleChange}
        required={required}
      />
    </View>
  );
});

export default Attribute;

const styles = StyleSheet.create({
  attrTitle: {
    flex: 1,
    flexGrow:4,
  },

  attrTitleText:{
    paddingVertical: 16,
    fontSize: 17,
    fontWeight: '500',
  },
  attrValidText:{
    fontSize: 13,
    color: '#7c492a',
  },
  attrBox:{
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderBottomColor: '#FAFAFA',
    borderBottomWidth: 8,
    flexDirection: 'column',
  },
  attrElementBox:{
    flex: 1,
    flexGrow: 5,
    alignItems: 'flex-end',
  },
  twoColumnAttrBox:{
    flexDirection: 'row',
    flex: 1,
  },
  oneColumnAttrBox:{
    flexDirection: 'column',
  },

  input: {
    backgroundColor: '#f6f6f6',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 11,
    maxHeight: 160,
    marginBottom: 12,
  },

  //switch
  switchBox: {
    flex: 1,
    alignItems: 'flex-end',
  },
  switchItemText: {
    textAlign: 'center',
  },
  switchItemTextActive: {
    color:'white',
  },

  //comment input
  commentBox: {
    flexDirection: 'row',
  },
  commentInput: {
    paddingTop: 15,
    minHeight: 100,
  },

  //dropdown
  dropDownListContainer: {
    marginTop:15,
  },
  dropDownItem: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropDownBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  dropDownChoice: {
    marginRight: 5,
    flex: 1,
    alignItems: 'flex-end',
  },
  dropDownChoiceText: {
    color: '#9b9b9b',
  },
  dropDownButton: {
    width: 32,
    height: 32,
    backgroundColor: colors.default.main,
    justifyContent: 'center',
    borderRadius: 4,
  },
  dropDownArrow: {

  },
  dropDownIcon: {
    justifyContent: 'center',
  },

  //counter
  counterContainer: {

  },

  counter:{
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1000,
  },
  counterToolContainer:{
    borderColor: 'grey',
    borderRadius: 4,
    width: 32,
    height: 32,
    backgroundColor: colors.default.main,
    alignItems: 'center',
    justifyContent: 'center',
  },
  counterTool:{
    fontSize: 14,
    //textAlignVertical: 'center',
    textAlign: 'center',
    color: 'white',
  },
  counterCount:{
    fontSize: 17,
    width: 25,
    height: 23,
    textAlign:'center',
  },
});
