import React, {useEffect, useImperativeHandle, useRef, useState, forwardRef, memo, useCallback, useMemo} from 'react';
import {View, StyleSheet, Platform, FlatList} from 'react-native';
import {CardDescription, ImageCarousel, MapPoint} from '../Cards/CardElements';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import AppText from '../Basic/AppText';
import AntiMenuFade from '../Basic/AntiMenuFade';
import LinearGradient from 'react-native-linear-gradient';
import TariffCounter from '../Tariffs/TariffCounter';
import {getMinWidth} from '../Defaults/DisplayWidth';
import {checkTariffs} from '../Validators/Tariffs';
//import humanize from 'humanize-number';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {textStyle} from '../Basic/NewButton';
import {useBookingContext} from '../Booking/Context';
//import {humanizePrice} from '../Cards/CardPrice';
//import {buttonsNames} from './defaults';
import {defaultStyles} from '../Defaults/Styles';
import {PriceButton} from './OfferPrice';
import {outletHasLoyalty, OutletLoyalty} from '../Loyalty/OfferLoyalty';
import settings from '../Defaults/settings';
import {objectsEqual} from '../System/helpers';
import {metricEvent} from '../Metrics/MetricHelper';
import {BottomSheetFlatList} from '@gorhom/bottom-sheet';

import {useDispatch, useSelector} from 'react-redux';
import * as auth from '../GlobalStore/reducers/authSlice';
import * as appEvent from '../GlobalStore/reducers/appEventSlice';
//import {number} from 'prop-types';

const windowWidth = getMinWidth();
const ratioHeight = windowWidth * (2.75 / 3.75);
const minHeight = 150;
//const maxHeight = 100;

//let AnimatedFlatList;
//let AnimatedFlatList = Animated.createAnimatedComponent(__DEV__ ? BottomSheetFlatList : FlatList);

const OfferDetails = (props,ref) => {
  let {
    id,
    mainImage,
    gallery,
    video,
    title,
    description,
    tariffs,
    price,
    discount,
    actionButton,
    //methods
    updateOffer,
    duration,
    outlet,
    points,
    roundTop,
    roundBottom,
    //other
    ScrollComponent,
    disableBonusPayment,
  } = props;

  if (!ScrollComponent){
    ScrollComponent = Animated.FlatList;
  }

  const imgCarouselRef = useRef();

  const [validate, setValidate] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [galleryState, setGalleryState] = useState({count:0, index:1});

  const {openBookingForm} = useBookingContext();

  const authState = useSelector(auth.selectState);
  const insets = useSafeAreaInsets();

  let panRef = useRef();
  let listRef = useRef();
  const dispatch = useDispatch();

  const scrollY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (e) => {
      if (e.contentSize.height/* > minAnimHeight*/){
        scrollY.value = e.contentOffset.y;
      }
    },
  });

  const tariffsUsed = tariffs?.length > 0;

  const handlePressBook = () => {
    let valid;
    valid = tariffsUsed ? checkTariffs({tariffs}) : null;
    if (valid) {
      setValidate(valid);
      scrollToTariffs();
    } else {
      if (authState === 'auth' || settings.enableTemporaryBooking) {
        openBookingForm({offer:props, updateOffer});
      } else {
        dispatch(appEvent.newEvent('openAuthReq'));
      }
    }
  };

  useImperativeHandle(ref, ()=>({
    playVideo: imgCarouselRef?.current?.playVideo,
    pauseVideo: imgCarouselRef?.current?.pauseVideo,
    playMutedVideo : imgCarouselRef?.current?.playMutedVideo,
  }));

  useEffect(()=>{
    metricEvent('offer_details_open');
  },[]);

  const headerHeight = useAnimatedStyle(() => ({
    height: interpolate(
      scrollY.value || 0,
      [0, ratioHeight - minHeight],
      [ratioHeight, minHeight],
      Extrapolation.CLAMP,
    ),
  }));

  const geo = (points || []);


  const scrollToTariffs = () => {
    try {
      listRef.current.scrollToIndex({index:4, animated:true});
    } catch (e) {
      console.log('scroll to tariffs error', listRef);
    }
  };

  return (
    <View style={styles.container}>
      <ScrollComponent //AnimatedFlatList
        ref={listRef}
        style={{
          flex: 1,
          paddingTop: ratioHeight,
        }}
        keyExtractor={(item, number)=>number}
        renderItem={({item})=>item}
        scrollEventThrottle={30}
        onScroll={scrollHandler}
        contentContainerStyle={styles.detailsBox}
        data={[
          <View style={styles.title}>
            <AppText style={styles.titleText}>{title}</AppText>
          </View>,
          <View style={styles.markers}>
            {duration ? <View style={styles.marker}>
              <AppText style={styles.markerLabel}>{'Продолжительность'}</AppText>
              <AppText style={styles.markerText}>{duration}{' минут'}</AppText>
            </View> : null}
            <View style={[styles.marker, duration ? styles.nextMarker : {}]}>
              <AppText style={styles.markerLabel}>{'Локация'}</AppText>
              <AppText style={styles.markerText}>{outlet.title}</AppText>
            </View>
          </View>,
          <View style={styles.description}>
            <CardDescription
              html={description}
              textStyle={styles.descriptionText}
              hide={true}
            />
          </View>,
          (tariffs && tariffs.length > 0
            ? <View style={styles.tariffsView}>
              <AppText style={styles.sectionLabel}>
                {'Тарифы'}
              </AppText>
              <TariffCounter
                discount={discount}
                tariffs={tariffs}
                onCalcPrice={(total, tariffs)=>{
                  setTotalPrice(total || 0);
                  updateOffer({id, tariffs, total});
                  setValidate(undefined);
                }}
              />
            </View>
            : null),
          (geo?.length > 0 ? <View style={styles.markers}>
            <View style={styles.marker}>
              <MapPoint geo={geo}/>
            </View>
          </View> : null),
          (
            settings.enableBonusPayment
            && disableBonusPayment !== true
            && authState === 'auth'
            && outletHasLoyalty(outlet)
            ? <View style={[styles.markers, {paddingRight: 12}]}>
                <OutletLoyalty outlet={outlet} style={styles.marker}/>
              </View>
            : null
          ),
        ]}
        ListFooterComponent={<AntiMenuFade height={Platform.OS === 'web' ? 150 : 450}/>}
      />
      <Animated.View style={[headerHeight, {position: 'absolute', top: 0, left: 0}]}>
        <ImageCarousel
          ref={imgCarouselRef}
          onPress={()=>{imgCarouselRef?.current?.playVideo();}}
          cardOpen={true}
          items={mainImage || video ? [video || mainImage, ...gallery] : gallery}
          preview={mainImage}
          autoplay={false}
          autoplayVideo={true}
          mutedVideo={true}
          style={{
            ...styles.imageCarousel,
            ...(roundTop ? {borderTopLeftRadius: 16, borderTopRightRadius: 16} : {}),
            ...(roundBottom ? {borderBottomLeftRadius: 16, borderBottomRightRadius: 16} : {}),
          }}
          isCardCarouselItem={false}
          onChangeIndex={setGalleryState}
        >
          {/*<Dimmer dimmer={null} opacity={0} />*/}
          <LinearGradient
            style={styles.gradient}
            //colors={['#ffffff00', '#FFFFFFB2', '#ffffff']}
            colors={['#ffffff','#ffffff00']}
            start={{x:0, y:1}}
            end={{x:0, y:0}}
            locations={[0.05, 0.99]}
          />
        </ImageCarousel>
      </Animated.View>
      <View style={[styles.bookingBox, {paddingBottom: insets.bottom + styles.bookingBox.paddingBottom}]}>
        {validate ? <View style={styles.validateContainer}>
          <AppText style={styles.validateText}>
            {validate}
          </AppText>
        </View> : null}
        <View style={styles.continueContainer}>
          {/*<NewButton
            style={{minWidth: '45%', flexDirection: 'row'}}
            onPress={handlePressBook}
          >
            <AppText style={textStyle}>
              {(buttonsNames[actionButton] || buttonsNames.default) + '  '}
            </AppText>
            <AppText style={styles.buttonPrice}>
              {(
                tariffsUsed
                ? '₽' + humanize(totalPrice, {delimiter: ' '})
                : (price ? humanizePrice(price, true) : '')
              )}
            </AppText>
          </NewButton>*/}
          <PriceButton
            offer={props}
            onPress={handlePressBook}
          />
        </View>
      </View>
    </View>
  );
};

const OfferDetailsFWRef = forwardRef(OfferDetails);
const OfferDetailsMemo = memo(OfferDetailsFWRef,
  (p, n)=>
    objectsEqual(p,n,['attributes', 'tariffs', 'id'])
  );

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    ...defaultStyles.webScrollView,
  },
  sectionLabel: {
    paddingVertical: 16,
    fontSize: 17,
    fontWeight: '500',
    color: '#7c7c7c',
  },
  imageBox: {
    width: '100%',
    //aspectRatio: 3.75 / 2.75,
    //height: 370,
  },
  imageCarousel: {
    width: '100%',
    //borderRadius: 16,
  },
  gradient: {
    height: 80,
    marginTop: -79,
  },
  detailsBox:{
    padding:12,
  },
  title: {
    marginBottom: 16,
  },
  titleText: {
    fontSize:22,
  },
  description: {},
  descriptionText: {
    fontSize:15,
    lineHeight:22,
    color: 'rgba(90, 90, 90, 1)',
  },

  //booking button
  bookingBox: {
    position: 'absolute',
    bottom: 0,
    width: windowWidth,
    paddingVertical: 10,
    paddingBottom: Platform.OS === 'web' ? 20 : 10,
    backgroundColor: 'rgba(255,255,255,0.93)',
  },
  validateContainer: {
    //flex: 1,
    alignItems: 'center',
  },
  validateText: {
    color: '#a26524',

    padding: 3,
    paddingBottom: 6,
  },
  buttonPrice: {
    ...textStyle,
    color: '#fff',
    opacity: 0.5,
  },

  //markers
  markers: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: 'rgba(246, 246, 246, 1)',
    marginLeft: -12,
    marginRight: -12,
  },
  marker: {
    flex: 1,
    paddingVertical: 16,
    paddingLeft: 12,
  },
  nextMarker: {
    borderLeftWidth: 1,
    borderColor: 'rgba(246, 246, 246, 1)',
  },
  markerLabel: {
    fontSize: 14,
    color: 'rgba(167, 167, 167, 1)',
    marginBottom: 4,
    fontWeight: '300',
  },
  markerText: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 18,
  },

  //tariffs
  tariffsView: {
    marginBottom: 16,
  },
});

export default OfferDetailsMemo;
