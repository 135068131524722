import humanize from 'humanize-number';
import {
  Platform,
  View,
  StyleSheet,
} from 'react-native';
import AppText from '../Basic/AppText';
import React from 'react';

export const calcOfferFinalPrice = (offer) => {
  let {discount, tariffs, price, attributes} = offer;
  price = price?.price || 0;
  discount = ((100 - (discount || 0)) / 100) || 0;
  let priceWDisc = price * discount;
  let compoundPrice = 0;
  let useCompoundPrice = false;

  if (tariffs && tariffs.length > 0) {
    useCompoundPrice = useCompoundPrice || true;
    compoundPrice += tariffs.reduce((a, v) =>
      (a + (v.count || 0) * v.price * discount)
    , 0);
  }

  if (attributes && Object.keys(attributes).length > 0) {
    useCompoundPrice = useCompoundPrice || true;
    compoundPrice += Object.values(attributes).reduce((a,v)=>
      (a + (v.price || 0) * discount)
    , 0);
  }
  return useCompoundPrice ? compoundPrice : priceWDisc;
};

export const getAggTariffPrice = ({tariffs, discount}) => {
  discount = ((100 - (discount || 0)) / 100) || 0;
  return tariffs && tariffs?.length ? (tariffs.reduce((a,v)=>{
    let price = v.price * discount;
    if (a === null) {
      return {
        min: price,
        max: price,
        fmin: v.price,
        fmax: v.price,
      };
    } else {
      return {
        min: Math.min(a.min, price),
        max: Math.max(a.max, price),
        fmin: Math.min(a.fmin, v.price),
        fmax: Math.max(a.fmax, v.price),
      };
    }
  }, null)) : {min:0, max:0, fmin: 0, fmax:0};
};

export const humanizePrice = ({price,from}, currencyLead = false) =>
  (from ? 'от ' : '')
  + (currencyLead ? '₽ ' : '')
  + humanize(parseInt(price), {delimiter: ' '})
  + (currencyLead ? '' : ' ₽');

const Price = ({price, discount, style}) => {
  let showDiscount = discount;
  let discPrice = discount ? price - (price * (discount / 100)) : 0;
  let currentPrice = showDiscount ? discPrice : price;

  return (
    <View style={styles.priceBox}>
      {showDiscount ? <AppText style={styles.priceBeforeDisc}>{humanizePrice(price)}</AppText> : null}
      <AppText style={[styles.price, style]}>
        {humanizePrice(currentPrice)}
      </AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  priceBeforeDisc:{
    textDecorationLine: 'line-through',
    color: 'rgba(255,255,255,0.47)',
    marginBottom: -8,
    fontSize: 16,
    paddingLeft: 5,
  },
  priceBox: {
  },
  price: {
    color: 'white',
    fontSize: 28,
    fontWeight: Platform.OS === 'ios' ? '400' : '200',
    textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: 0, height: 0},
    textShadowRadius: 10,
    paddingLeft: 5, //for shadow padding
  },
});

export default Price;
