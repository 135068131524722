import storyAdapter from './storyAdapter';
import {useEffect, useState} from 'react';
import {api} from '../System/api';
import {isObject} from '../System/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {selectStories, setStories} from '../GlobalStore/reducers/storiesSlice';

const useStoriesLoader = () => {
  const dispatch = useDispatch();
  const stories = useSelector(selectStories);
  const [load, setLoad] = useState(false);

  const loadStories = async () => {
    let page = 1;
    while (1) {
      try {
        let res = await api.stories.list({page});
        let data = isObject(res.data) ? Object.values(res.data) : res.data;
        if (data && data.length > 0) {
          let nv = {};
          for (let i in data) {
            nv[data[i].id] = storyAdapter(data[i]);
          }
          dispatch(setStories(nv));
          page++;
          break; //TODO pagination is not working
        } else {
          break;
        }
      } catch (e) {
        setStories({});
        break;
      }
    }
    setLoad(false);
      //console.log('stories',JSON.stringify(res.data));

      /*  Object.keys(res.data).reduce((a, v) => ({...a, [v.id]: storyAdapter(res.data[v])}), {})
      );*/
  };

  useEffect(()=>{
    if (stories === undefined) {
      dispatch(setStories({}));
      setLoad(true);
      loadStories();
    }
    /*return () => {
      api.pending.abort('stories');
    };*/
  },[stories]);

  const reload = () => {
    dispatch(setStories({}));
    setLoad(true);
    loadStories();
  };

  return {stories, load, reload};
};

export default useStoriesLoader;
