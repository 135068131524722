import React from 'react';
import {Image, ScrollView, View, StyleSheet} from 'react-native';
import AppText from '../Basic/AppText';


const Performer = (props) => {
  const {pictureThumb, name, ...other} = props;
  //console.log(other);
  return (
    <View style={styles.performerBox}>
      <Image style={styles.performerImage} source={{uri: pictureThumb}}/>
      <View style={styles.performerNameBox}>
        <AppText style={styles.performerNameText}>
          {name}
        </AppText>
      </View>
    </View>
  );
};

const Performers = ({values}) => {
  return (
    <View style={styles.performersList}>
      <ScrollView style={styles.performersScroll}>
        {values.map(item => <Performer key={item?.id} {...item} />)}
      </ScrollView>
    </View>
  );
};

export default Performers;

const styles = StyleSheet.create({
  performersList: {
  },
  performersScroll: {
    maxHeight: 300,
  },
  performerBox: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  performerImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  performerNameBox: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
  },
  performerNameText: {
    fontWeight: '300',
    color: '#000',
  },
});
