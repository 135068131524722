import React, {useState, useEffect, useRef} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import AppText from '../Basic/AppText';
import {CountdownCircleTimer} from 'react-native-countdown-circle-timer';
import {Icon} from '@rneui/themed';

const types = {
  danger: '#dd7300',
  success: '#00b125',
  info: '#00acdd',
  default: '#ffffff',
};

const Toast = options => {
  const {title, type, message, duration, content, showCountdown, onHide} =
    options;
  const [counter, setCounter] = useState(duration);
  let counterInt = useRef();

  useEffect(() => {
    counterInt.current = setInterval(() => {
      setCounter(v => {
        return Math.max(0, v - 1000);
      });
    }, 1000);
    return () => {
      clearInterval(counterInt.current);
    };
  }, []);

  return (
    <View
      style={[
        styles.container,
        {
          borderLeftColor: types[type] || '#dedede',
          borderRightColor: types[type] || '#dedede',
        },
      ]}>
      {showCountdown !== false ? (
        <View style={styles.countdownContainer}>
          <CountdownCircleTimer
            isPlaying
            duration={duration / 1000}
            colors={'#b5b5b5'}
            size={30}
            strokeWidth={3}
            /*colors={['#6E6E6E', '#898989', '#b4b4b4', '#CDCDCD']}
          colorsTime={[
            duration / 1000 / 4,
            (duration / 1000 / 4) * 2,
            (duration / 1000 / 4) * 3,
            (duration / 1000 / 4) * 4,
          ]}*/
          >
            {({remainingTime}) => (
              <AppText style={styles.countdownNum}>{remainingTime}</AppText>
            )}
          </CountdownCircleTimer>
        </View>
      ) : null}
      <View style={styles.messageContainer}>
        <View style={styles.message}>
          <AppText style={styles.title}>{title}</AppText>
          <AppText style={styles.text}>{message}</AppText>
        </View>
        <View style={styles.content}>{content}</View>
      </View>
      <TouchableOpacity style={styles.exit} onPress={onHide}>
        <Icon name={'close'} size={20} color={'rgba(128, 148, 156, 0.5)'} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  countdownNum: {
    color: '#a6a6a6',
  },
  countdownContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    //alignSelf: 'center',
    flex: 1,
    //minWidth: 50,
  },
  exit: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
    //minWidth: 50,
  },
  content: {
    justifyContent: 'center',
  },
  messageContainer: {
    justifyContent: 'center',
    flex: 8,
  },
  message: {
    flexDirection: 'column',
    alignItems: 'center',
    //maxWidth: 300,
  },
  container: {
    flexWrap: 'wrap',
    alignItems: 'stretch',
    paddingHorizontal: 5,
    paddingVertical: 10,
    marginBottom: 6,
    width: '87%',
    minHeight: 50,
    backgroundColor: '#fff',
    borderRadius: 8,
    borderLeftColor: '#08c036',
    borderLeftWidth: 4,
    borderRightColor: '#08c036',
    borderRightWidth: 4,

    flexDirection: 'row',
    justifyContent: 'space-between',

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.39,
    shadowRadius: 8.3,
    elevation: 13,
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '500',

    alignSelf: 'center',
    //maxWidth: '95%',
  },
  text: {
    marginTop: 10,
    fontSize: 18,
    alignSelf: 'center',
  },
});

export default Toast;
