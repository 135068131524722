import { createSlice } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';

const initialState = {
  chosen: [],
  loaded: {},
  loading: 'init',
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setChosen: (state, {payload}) => {
      state.chosen = payload || [];
    },
    setLoaded: (state, {payload}) => {
      state.loaded = payload || {};
    },
    setState: (state, {payload}) => {
      state.loading = payload;
    },
  },
});

const init = () => (dispatch) => {
  AsyncStorage.getItem('userCategories').then((value)=>{
    try {
      let data = JSON.parse(value);
      data = Array.isArray(data) ? data : [];
      dispatch(categoriesSlice.actions.setChosen(data));
    } catch (e){
      AsyncStorage.setItem('userCategories',JSON.stringify([])).then();
    }
  });
  //return AsyncStorage.setItem('goToLogin', v ? '1' : '0');
};

const selectChosen = (state) => state.categories.chosen;
const selectLoaded = (state) => state.categories.loaded;
const selectState = (state) => state.categories.loading;

export const {setChosen,setLoaded,setState} = categoriesSlice.actions;
export {selectChosen,selectLoaded,selectState,init};

export default categoriesSlice.reducer;
