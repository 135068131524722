import React from 'react';
import {View, TouchableOpacity, StyleSheet, ScrollView, Platform} from 'react-native';
import AppText from '../Basic/AppText';
//import humanize from 'humanize-number';
import {Icon} from '@rneui/themed';
import {BottomSheetScrollView} from '@gorhom/bottom-sheet';


const TariffsView = ({tariffs, onPress, bottomSheet}) => {
  //let totalPrice = tariffs.reduce((a,v)=>a + (v.price || 0) * (v.count || 0),0);
  const ScrollComponent = bottomSheet && Platform.OS !== 'web' ? BottomSheetScrollView : ScrollView;

  return (
    <View style={styles.mainContainer}>
      <ScrollComponent style={styles.scroll}>
        <TouchableOpacity onPress={onPress}>
          <View style={styles.itemsContainer}>
          {tariffs
            .filter(i=>i.count)
            .sort((a,b)=>a.price - b.price)
            .map(item =>
            <View key={item.id} style={styles.tariffContainer}>
              <View style={styles.titleContainer}>
                <AppText style={styles.tariffText}>
                  {item.title}{' '}
                </AppText>
              </View>
              <View style={styles.countContainer}>
                <AppText style={styles.tariffTextX}>
                  {'x'}{item.count}
                </AppText>
              </View>
            </View>
          )}
          </View>
        </TouchableOpacity>
      </ScrollComponent>
      <TouchableOpacity style={styles.options} onPress={onPress}>
        <Icon size={19} type={'fontisto'} name={'more-v-a'} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  itemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  tariffContainer: {
    marginRight: 6,
    marginBottom: 3,
    flexDirection: 'row',
    borderRadius: 50,
    borderColor: '#fff',
    backgroundColor: '#ececec',
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  titleContainer: {},
  countContainer: {
    minWidth: 20,
    //height: 15,
    borderRadius: 50,
    borderColor: 'white',
    borderWidth: 0,
    backgroundColor: '#f5f5f5',
  },
  tariffText: {

    fontSize: 15,
    lineHeight: 18,
    color: '#000000',
    textAlignVertical: 'center',
  },
  tariffTextX: {

    textAlignVertical: 'center',
    fontSize: 11,
    textAlign: 'center',
    //lineHeight: 14,
  },
  totalPriceContainer: {
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
  },
  totalPrice: {

    color: '#dedede',
  },
  mainContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  scroll: {
    maxHeight: 75,
  },
  options: {
    alignSelf: 'center',
    width: 10,
    //marginLeft : 30,
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: 3,
  },
});

export default TariffsView;
