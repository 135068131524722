import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  ActivityIndicator,
  ImageBackground,
  View,
  SectionList, ScrollView, FlatList, VirtualizedList, Dimensions,
} from 'react-native';
import TagNavigatedTile from '../Basic/TagNavigatedTile';
import PageHeader, {useGoBack} from '../Basic/PageHeader';
import BackButton from '../Basic/BackButton';
import AntiMenuFade from '../Basic/AntiMenuFade';
import {sortArrayByKeys, sortObjectByKeys} from '../System/helpers';
import {useToast} from 'react-native-toast-notifications';
import OfferDetailsBottomSheet2 from '../BottomSheets/OfferDetailsBottomSheet2';
import offerAdapter from './OfferAdapter';
import {stringDecoder} from '../Errors/ErrorDecoder';
import {api} from '../System/api';
import TileCard from '../Cards/TileCard';
import {defaultStyles} from '../Defaults/Styles';
import {useNavigation, useRoute} from '@react-navigation/native';
import {useSelector} from 'react-redux';
import * as draftBooking from '../GlobalStore/reducers/draftBookingSlice';
import {useBookingContext} from '../Booking/Context';
import {getMinWidth, useAppDimensions} from '../Defaults/DisplayWidth';
import {CardDescription} from '../Cards/CardElements';
import AppText from '../Basic/AppText';
import Animated, {
  Extrapolation,
  interpolate, useAnimatedReaction,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Skeleton, SkeletonContainer} from 'react-native-skeleton-component';
import moment from 'moment';
import AppButton from '../Basic/NewButton';
import ScaledImage from '../Basic/ScaledImage';

const windowWidth = getMinWidth();

//const pageHeight = Dimensions.get('window').height;

const SelectionCard = ({image, name, description, style, loading, ...other}) =>
{
  const [width, setWidth] = useState(0);
  //console.log(other, image);
  if (loading) {
    return <SkeletonContainer>
      <Animated.View style={[styles.selectionBox, style]}>
        <Skeleton style={styles.selectionImage}/>
      </Animated.View>
      <View style={styles.selectionDescription}>
        <View style={styles.selectionTitle}>
          <Skeleton style={styles.simpleLoadingText}/>
        </View>
      </View>
    </SkeletonContainer>
  }
  return (<Animated.View
    onLayout={(event)=>{
      const {x, y, width, height} = event.nativeEvent.layout;
      setWidth(width);
    }}
    style={[styles.selectionBox, style]}>
    <ScaledImage
      width={width}
      source={{uri:api.baseurl + image}}
      style={styles.selectionImage}
      imageStyle={{borderRadius: 6}}
    />
    <View style={styles.selectionDescription}>
      <AppText style={styles.selectionTitle}>{name}</AppText>
      <CardDescription
        html={description}
        maxHeight={150}
        hide={false}
      />
    </View>
  </Animated.View>);
};

const EmptySelection = () =>
{
  const goBack = useGoBack();
  return <View style={styles.emptySelection}>
    <View style={styles.emptySelectionBox}>
      <AppText style={styles.emptySelectionTitle}>
        {'К сожалению, подборка не найдена'}
      </AppText>
      <AppText style={styles.emptySelectionText}>
        {'Возможно срок действия данной подборки истёк или ссылка на нее некоректна'}
      </AppText>
    </View>
    <AppButton style={styles.emptyButton} onPress={()=>goBack()}>
      {'На главную'}
    </AppButton>
  </View>;
};

const Selection = () =>
{
  const navigation = useNavigation();
  const route = useRoute();
  const {code, offer_id} = route.params;
  let offerDetailsRef = useRef();
  let loadListId = useRef();
  let loadOneId = useRef();
  let insets = useSafeAreaInsets();
  const toast = useToast();
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState('');
  const [titleLoading, setTitleLoading] = useState(true);
  const [offers, setOffers] = useState({});
  const [selection, setSelection] = useState({});
  const [loading, setLoading] = useState('ready');
  const [tileScrollLock, setTileScrollLock] = useState(true);



  const lastDraftBooking = useSelector(draftBooking.selectLast);
  const {openBookingForm} = useBookingContext();

  const getOffer = useCallback((offer) => {
    return new Promise((resolve, reject) => {
      Object.values(offers).forEach((items) => {
        Object.values(items).forEach((_offer) => {
          if (_offer.id === offer.id) {
            return resolve(_offer);
          }
        });
      });
      reject(`cant find offer with id ${offer?.id}`);
    });
  },[offers]);

  const updateOffer = (offer, replace = false) => {
    setOffers( _offers => {
      for (let interest in _offers) {
        let items = _offers[interest];
        for (let key in items){
          let _offer = items[key];
          if (_offer.id === offer.id) {
            //console.log('change offer', offer.id, 'set data to', offer);
            _offers[interest][key] = replace ? offer : {..._offer, ...offer};
          }
        }
      }
      return {..._offers};
    });
  };

  const offerPosId = ({prior, id}) => /*100000 -*/ (prior || 0) + '_' + id;

  const loadOffers = async () => {
    if (loading !== 'ready') {return;}
    setLoading('loading');
    let params = {
      code,
      /*limit: 50,
      page,
      order: {
        'interest.prior': 'desc',
        'interest.id': 'desc',
        'offer.prior': 'desc',
      },*/
    };

    if (loading === 'empty') {return;}

    try {
      let res = await api
        .offers
        .setId(lid=>{loadListId.current = lid;})
        .selections(params);
      let data = res.data.data;
      let _selection = res.data.selection;
      console.log(_selection);
      setSelection(_selection);
      setTitle(_selection.name);
      setTitleLoading(false);
      data.forEach((v)=> {
        v.discount = checkDiscount(_selection);
        return addOffer(v, (offer)=>{
          offer.selection = {
            id: _selection.id,
          };
          return offer;
        });
      });
      if (res.data.length === 0) {
        setLoading('empty');
        return;
      }
      setPage(v => v + 1);
    } catch (e) {
      if (api.isAbort(e)) {
        return;
      }
      //console.log(e);
      /*toast.show(stringDecoder(e), {
        title: 'Невозможно загрузить данные',
        type: 'danger',
      });*/
      setTitleLoading(false);
      setLoading('error');
      return;
    }
    setLoading('ready');
  };

  const checkDiscount = (selection) => {
    if (moment().isBefore(selection.dateFrom) || moment().isAfter(selection.dateTo)) {
      return null;
    }
    return selection.discount;
  }

  const addOffer = (offer, modify = c=>c) => {
    let card = offerAdapter(offer);
    let interest = card.interest.id;
    card = modify(card);
    setOffers(v => {
      v[interest] = v[interest] ? v[interest] : {};
      v[interest][offerPosId(offer)] = card;
      return {...v};
    });
    return card;
  };

  const loadLinkedOffer = async id => {
    let params = {
      limit: 1,
      page: 1,
      order: {
        'interest.prior': 'desc',
        'interest.id': 'desc',
        'offer.prior': 'desc',
      },
      id:[id],
    };
    let res = await api
      .offers
      .setId(lid=>{loadOneId.current = lid;})
      .multipliedByInterests(params);
    //console.log(res);
    res.data = res.data.result;
    if (res.data.length === 0) {
      return;
    }
    let offer;
    if (lastDraftBooking?.offer?.id == offer_id)
    {
      let offerData = res.data[0];
      offerData.discount = checkDiscount(selection);
      offer = addOffer(offerData, card => {
        card.tariffs = lastDraftBooking.offer.tariffs;
        card.date = lastDraftBooking.booking.chosenDates;
        card.time = lastDraftBooking.booking.chosenTime;
        return card;
      });
      openBookingForm({
        offer,
        updateOffer,
        date: lastDraftBooking.booking.chosenDates,
        time: lastDraftBooking.booking.chosenTime,
      });
    } else {
      offer = addOffer(res.data[0]);
      offerDetailsRef.current?.openWithOffer(offer);
    }
  };

  useEffect(()=>{
    loadOffers();
    if (offer_id) {
      loadLinkedOffer(offer_id);
    }
    return ()=>{
      api.pending.abort(loadListId.current);
      //api.pending.abort(loadOneId.current);
    };
  },[]);

  /*useEffect(() => {
    return navigation.addListener('focus', () => {
      __DEV__ && console.log('LOAD DATA ON NAVIGATE');
      //setNavUpdateActivator(v=>(v || 0) + 1);
      !loadPart && loadNextPart();
    });
  }, [navigation]);*/

  const groupOffers = (list) => {
    let grouped = {};
    for (let i in list) {
      let interestGrp = list[i];
      for (let j in interestGrp){
        let item = interestGrp[j];
        let section = Object.keys(item.interest).length > 0
          ? item.interest
          : {id:0, prior: 0, title:'Остальное'};
        //console.log({section});
        let sectionKey = section.id;
        if (!grouped[sectionKey]) {
          grouped[sectionKey] = {
            prior: (section.prior || 0),
            section,
            data: [{list:[]}],
          };
        }
        grouped[sectionKey].data[0].list.push(item);
        grouped[sectionKey].data[0].list =
          sortArrayByKeys(grouped[sectionKey].data[0].list, [{prior: 'desc'}]);
      }
    }
    //return Object.values(grouped);
    return Object.values(sortObjectByKeys(grouped, [{prior: 'desc'}]));
  };

  const handleEndReached = () => {
    //loadOffers();
  };

  const AnimatedVirtualizedView = Animated.createAnimatedComponent(FlatList);


  const handleScroll = useCallback((event) => {
    let scrollSize = event.nativeEvent.contentSize.height;
    let scrollPosition = event.nativeEvent.contentOffset.y;
    console.log({scrollSize, scrollPosition});
    if (scrollPosition >= 500) {
      setTileScrollLock(false);
      console.log('scrollLock 1');
    } else {
      setTileScrollLock(true);
      console.log('scrollLock 0');
    }
  },[]);

  return (
    <SafeAreaView style={styles.container}>
      <PageHeader title={title} titleLoading={titleLoading}/>
      <View style={styles.scrollBox}>
        {loading !== 'error'
        ? <TagNavigatedTile
            nestedScrollEnabled={true}
            listStyle={[{backgroundColor: 'rgb(255,255,255)'}]}
            sections={groupOffers(offers)}
            loading={loading === 'loading'}
            ListHeader={()=><SelectionCard
              {...selection}
              loading={loading === 'loading'}
            />}
            ListFooter={()=><AntiMenuFade height={200}>
              {loading === 'loading' || offers && Object.keys(offers).length === 0 ? <ActivityIndicator size={'large'} /> : null}
            </AntiMenuFade>}
            headerEndReached={handleEndReached}
            //sectionsEndReached={handleEndReached}
            renderTileCard={({item})=><TileCard
              {...item}
              onPress={()=>{
                offerDetailsRef.current?.openWithOffer(item);
              }}
            />}
            cardKeyExtractor={(item)=>{
              return item.id + '_' + item?.interest?.id;
            }}
            ListComponent={SectionList}
            scrollEventThrottle={30}
            //onScroll={scrollHandler}
          />
        : <EmptySelection/>}
      </View>
      <OfferDetailsBottomSheet2
        ref={offerDetailsRef}
        getOffer={getOffer}
        updateOffer={updateOffer}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.webScrollView,
    //...defaultStyles.fullScreenHeight,
    backgroundColor: 'white',
  },
  scrollBox: {
    ...defaultStyles.webScrollView,
  },

  //selection
  selectionImage: {
    width: '100%',
    //height: windowWidth / 1.47,
    minHeight: 100,
    borderRadius: 6,
    backgroundColor: '#dedede',
  },
  selectionDescription: {
    //paddingHorizontal: 10,
    paddingBottom: 12,
    backgroundColor: 'white',
  },
  selectionBox: {
    width: '100%',
    borderRadius: 6,
  },
  selectionTitle: {
    paddingVertical: 16,
    fontSize: 21,
    fontWeight: '500',
  },
  simpleLoadingText: {
    height: 25,
    width: 230,
    borderRadius: 8,
  },

  emptySelection: {
    flex:1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20%',
  },
  emptySelectionBox: {

  },
  emptySelectionTitle: {
    fontSize: 18,
    marginBottom: 12,
  },
  emptySelectionText: {
    fontSize: 14,
  },

  emptyButton: {
    marginTop: 46,
    width: '95%',
  },
});

export default Selection;
