const stringDecoder = (e) => {
  switch (e.toString()) {
    case 'TypeError: Network request failed': {
      return 'Нет подключения к сети';
    }
    default:
      return 'Неизвестная ошибка';
  }
};

export {stringDecoder};
