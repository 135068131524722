import AppText from './AppText';
import {TouchableOpacity, View, StyleSheet} from 'react-native';
import React, {useEffect, useState} from 'react';
import {defaultStyles} from '../Defaults/Styles';
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

const blocksCount = 2;

const TumblerItem = ({item, index, onPress, style}) => {
  let renderItem;

  if (typeof item === 'string') {
    renderItem = <AppText style={styles.tumblerText}>{item}</AppText>;
  } else {
    renderItem = item;
  }

  const handlePress = () => {
    onPress(index);
    item?.onChoice && item?.onChoice();
  };

  return (
    <TouchableOpacity style={[styles.tumblerItem, style]} onPress={handlePress}>
      {renderItem}
    </TouchableOpacity>
  );
};

export const Tumbler = ({onChange, items, style, containerStyle, drawerStyle, itemStyle, value}) => {
  const [mainLayout, setMainLayout] = useState({width: 0, height: 0});
  const [selected, setSelected] = useState(1);

  const leftMargin = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      marginLeft: leftMargin.value * 100 + '%',
    };
  });

  useEffect(()=>{
    setSelected(value || 0);
  },[value]);

  useEffect(()=>{
    selected !== undefined && animateDrawer(selected);
  },[selected]);

  const onEndMoveDrawer = () => {};

  const animateDrawer = ind => {
    let left = ind * (1 / blocksCount);
    leftMargin.value = withTiming(
      left,
      {
        duration: 200,
        easing: Easing.linear,
      },
      finish => {
        if (finish) {
          runOnJS(onEndMoveDrawer)();
        }
      },
    );
  };

  const handleItem = ind => {
    animateDrawer(ind);
    onChange && onChange(ind);
  };

  const handleLayout = ({
    nativeEvent: {
      layout: {width, height, x, y},
    },
  }) => {
    setMainLayout({height, width});
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.tumblerBox, style]} onLayout={handleLayout}>
        <Animated.View
          style={[
            styles.tumblerDrawer,
            {
              height: mainLayout.height,
              width: (100 / items.length) + '%',
            },
            drawerStyle,
            animatedStyle,
          ]}
        />
        {items.map((item, ind) => (
          <TumblerItem
            key={ind}
            item={item}
            index={ind}
            onPress={handleItem}
            style={[itemStyle, {width: (100 / items.length) + '%'}]}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f6f6f6',
    padding: 10,
    borderRadius: 8,
  },
  tumblerBox: {
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  tumblerText:{
    textAlign: 'left',
  },
  tumblerItem: {
    padding: 10,
    width: 100 / blocksCount + '%',
  },
  tumblerDrawer: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    borderColor: '#e8e5e5',
    //borderWidth: 1,
    position: 'absolute',
    //flex: 1,
    height: 20,
    width: 100 / blocksCount + '%',
    ...defaultStyles.cardShadow,
    shadowOpacity: 0.35,
  },
})
