import {
  TouchableOpacity,
  Platform,
  View,
} from 'react-native';
import AppText from '../Basic/AppText';
import {Icon} from '@rneui/themed';
import AppButton from './AppButton';
import React from 'react';

const AuthOverlay = ({
                        onPressClose,
                        onPressAuth,
                      }) => {
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.75)',
        justifyContent: 'center',
      }}
      onPress={() => {
        Platform.OS !== 'web' && onPressClose();
      }}>
      <View
        style={{
          paddingTop: 50,
          //flex: 0.2,
          marginHorizontal: 20,
          backgroundColor: 'white',
          alignItems: 'center',
          borderRadius: 20,
        }}
      >
        <TouchableOpacity
          style={{position: 'absolute', right: 20, top: 20}}
          onPress={onPressClose}>
          <Icon name={'close'} size={20} color={'rgba(128, 148, 156, 0.5)'} />
        </TouchableOpacity>
        <Icon
          size={60}
          name={'ios-enter-outline'}
          type={'ionicon'}
          color={'#656565'}
        />
        <AppText
          style={{
            marginVertical: 20,
            marginHorizontal: 50,

            fontSize: 18,
            fontWeight: '500',
            textAlign: 'center',
          }}>
          {'Чтобы продолжить оформление услуги, необходимо войти или зарегистрироваться'}
        </AppText>
        <AppButton
          rootStyle={{width: '85%', marginVertical: 25}}
          style={{width: '100%'}}
          title={'К  авторизации'}
          onPress={onPressAuth}
        />
      </View>
    </TouchableOpacity>
  );
};

export default AuthOverlay;
