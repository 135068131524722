import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import humanize from 'humanize-number';
import AppText from '../Basic/AppText';

const max_amount = 99;

const Tariff = ({
                  id,
                  title,
                  price,
                  active,
                  max_persons,
                  persons_count_switch,
                  onCounterChange,
                  containerStyle,
                  count,
                  discount,
                }) => {
  const [state, setState] = useState();

  let max = persons_count_switch ? (max_persons || max_amount) : max_amount;

  let showDisc = !!discount;
  let finalPrice = price - price * ((discount || 0) / 100);

  const handleTariffClick = () => {
    let v = state;
    let val = v;
    if (v === undefined) {val = 1;}
    if (v === 0) {val = v + 1;}
    if (v === 1) {val = 0;}
    if (val !== v) {onCounterChange(id, val);}
    setState(val);
  };

  useEffect(()=>{ setState(count || 0); }, []);

  const handleClear = () => {
    setState(0);
  };

  const handleMinus = () => {
    let v = state;
    let val = ((v || 0) !== 0 ? v - 1 : v);
    if (v !== val){ onCounterChange(id, val); }
    setState(val);
  };
  const handlePlus = () => {
    let v = state;
    let val = (v !== max ? (v || 0) + 1 : v);
    if (v !== val){ onCounterChange(id, val); }
    setState(val);
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={handleTariffClick}
      style={[styles.tariffContainer, containerStyle]}
    >
      <View style={styles.tariffPointContainer}>
        <View style={styles.tariffPointRound}>
          <View style={[
            styles.tariffPoint,
            state > 0 ? styles.tariffPointActive : {},
          ]} />
        </View>
      </View>
      <View style={styles.tariffTitle}>
        <AppText style={styles.tariffTitleText}>{title}</AppText>
      </View>
      <View style={styles.tariffCounter}>
        <TouchableOpacity style={styles.counterToolContainer} onPress={handleMinus} onLongPress={handleClear}>
          <Text style={styles.counterTool}>-</Text>
        </TouchableOpacity>
        <AppText style={styles.tariffCount}>
          {state || 0}
        </AppText>
        <TouchableOpacity style={styles.counterToolContainer} onPress={handlePlus}>
          <Text style={styles.counterTool}>+</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.priceContainer}>
        {
          showDisc ?
          <AppText style={styles.priceBeforeDisc}>
            {humanize(parseInt(price), {delimiter: ' '}) + ' ₽'}
          </AppText> : null
        }
        <AppText style={styles.price}>
          {humanize(parseInt(finalPrice), {delimiter: ' '}) + ' ₽'}
        </AppText>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  tariffContainer:{
    flexDirection: 'row',
    //borderColor: 'silver',
    //borderWidth: 1,
    borderRadius: 20,
    marginBottom: 7,
    padding: 10,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    margin: 3,//for shadow
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    justifyContent: 'center',
  },
  tariffPointRound:{
    padding: 3,
    borderColor: '#bbbbbb',
    borderWidth: 1,
    borderRadius: 50,
  },
  tariffPointContainer:{
    flex: 1.8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tariffPoint:{
    width: 10,
    height: 10,
    borderRadius: 15,
    backgroundColor: '#bbbbbb',
  },
  tariffPointActive:{
    backgroundColor: '#23b865',
  },
  tariffTitle:{
    flex: 9.5,
    justifyContent: 'center',
  },
  tariffTitleText:{
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 17,
  },
  tariffCounter:{
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1000,
  },
  counterToolContainer:{
    borderColor: 'grey',
    borderRadius: 50,
    borderWidth: 2,
    width: 22,
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
  },
  counterTool:{
    fontSize: 14,
    //textAlignVertical: 'center',
    textAlign: 'center',
  },
  tariffCount:{
    fontSize: 17,
    width: 25,
    height: 23,
    textAlign:'center',
  },
  priceContainer:{
    flex: 5.5,
    justifyContent: 'center',
  },
  price:{
    textAlign: 'right',
    fontSize: 16,
  },
  priceBeforeDisc: {
    textAlign: 'right',
    fontSize: 11,
    lineHeight: 12,
    marginBottom: -5,
    textDecorationLine: 'line-through',
    color: 'rgba(129,129,129,0.47)',
  },
});

export default Tariff;
