import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useContext,
  useCallback,
  forwardRef,
  memo,
} from 'react';
import {
  ImageBackground,
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  FlatList,
} from 'react-native';
import AppText from '../Basic/AppText';
import {CardDescription, MapPoint} from '../Cards/CardElements';
//import {Icon} from '@rneui/themed';
//import {BottomSheetScrollView} from '@gorhom/bottom-sheet';
import AntiMenuFade from '../Basic/AntiMenuFade';
import {colors, defaultStyles} from '../Defaults/Styles';
import CardVideo from '../Cards/CardVideo';
//import {MapContext} from '../BottomSheets/MapBottomSheet';
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import {getMinWidth} from '../Defaults/DisplayWidth';
import LinearGradient from 'react-native-linear-gradient';
import TariffCounter from '../Tariffs/TariffCounter';
import {textStyle} from '../Basic/NewButton';
//import humanize from 'humanize-number';
//import {humanizePrice} from '../Cards/CardPrice';
import {checkTariffs} from '../Validators/Tariffs';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {BookingContext} from '../Booking/Context';
import offerAdapter from '../Offers/OfferAdapter';
import {api} from '../System/api';
import calendarRestrictions from '../Calendar/calendarRestrictions';
//import {buttonsNames} from '../Offers/defaults';
import {PriceButton} from '../Offers/OfferPrice';
import {metricEvent} from '../Metrics/MetricHelper';
import {Icon} from '@rneui/themed';
import {useDispatch, useSelector} from 'react-redux';
import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import * as _auth from '../GlobalStore/reducers/authSlice';
import settings from '../Defaults/settings';

const windowWidth = getMinWidth();
const ratioHeight = windowWidth * (2.75 / 3.75);
const minHeight = 150;
const defaultInset = Platform.OS === 'web' ? 10 : 0;

const ActivityDetails = (props, ref) => {
  let {
    id,
    time,
    date,
    title,
    description,
    state,
    category,
    categories,
    price,
    type,
    place,
    showTime,
    preview,
    video,
    onPressTag,
    autoplay,
    onPressGeo,
    points,
    offer,
    offerId,
    apiSchedule,
    updateActivity,
    roundTop,
    roundBottom,
    duration,
    //other
    ScrollComponent,
  } = props;

  if (!ScrollComponent){
    ScrollComponent = Animated.FlatList;
  }

  const [validate, setValidate] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  const defaultColors = useSelector((state) => state.style.defaultColors);
  const authState = useSelector(_auth.selectState);
  const dispatch = useDispatch();

  const insets = useSafeAreaInsets();

  const {openBookingForm, closeBookingForm} = useContext(BookingContext);

  const videoRef = useRef();

  const geo = (points || []);

  const scrollY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (e) => {
      if (e.contentSize.height/* > minAnimHeight*/){
        scrollY.value = e.contentOffset.y;
      }
    },
  });
  const headerHeight = useAnimatedStyle(() => ({
    height: interpolate(
      scrollY.value || 0,
      [0, ratioHeight - minHeight],
      [ratioHeight, minHeight],
      Extrapolate.CLAMP,
    ),
  }));

  useEffect(()=>{
    if (offerId && !offer){
      handleActivities();
    }
  },[id]);

  useEffect(()=>{
    metricEvent('activity_details_open');
  },[]);

  const handleActivities = useCallback(() => {
    if (offer) {return;}
    setLoading(true);
    offerId && api.offers.list({
      page: 1,
      limit: 1,
      ids: [offerId],
    }).then(offers => {
      let offer = offers?.data[0];
      if (!offer) {return;}
      updateActivity({id, offer: offerAdapter(offer)});
      setLoading(false);
    }).catch(e => {
      setLoading(false);
    });
  },[id]);

  const updateOffer = useCallback((offerProps) => {
    offer &&
    updateActivity({id, offer: {...offer, ...offerProps}});
  }, [updateActivity, offer]);

  /*useEffect(()=>{
    console.log('PROPS:', props);
  },[]);*/

  useImperativeHandle(ref, () => ({
    playVideo: () => {
      videoRef.current?.play();
    },
  }),[videoRef]);

  const tariffsUsed = offer?.tariffs?.length > 0;

  const handlePressBook = useCallback(() => {
    let valid;
    valid = tariffsUsed ? checkTariffs({tariffs: offer?.tariffs}) : null;
    if (valid) {
      setValidate(valid);
    } else {
      if (authState === 'auth' && !settings.enableTemporaryBooking) {
        let restrictions = calendarRestrictions({schedule: apiSchedule, strict: true}, false);
        //console.log('GENERATED RESTRICTIONS',JSON.stringify(restrictions));
        //console.log({time, restrictions});
        let data = {
          date: [date && date.format('YYYY-MM-DD'), null],
          time: time,
          restrictions,
          /*restrictions:{
            workDates: {
              [date.format('YYYY-MM-DD')]: {
                date: date,
                timeList: [time],
              },
            },
          }*/
        };
        openBookingForm({
          offer,
          ...data,
        });
      } else {
        dispatch(appEvent.newEvent('openAuthReq'));
      }
    }
  }, [tariffsUsed, offer, time, date]);

  return <View style={styles.container}>
    <ScrollComponent
      scrollEventThrottle={30}
      onScroll={scrollHandler}
      style={styles.scrollList}
      keyExtractor={(item, index)=>(index)}
      data={[
        <View style={[styles.section, styles.description, styles.borderBottom]}>
          <View style={styles.titleGroup}>
            <AppText style={styles.title}>
              <AppText style={styles.payment}>
                {price ? <Icon style={{marginRight:5, marginLeft: 2}} color={defaultColors.main} size={20} name={'ruble-sign'} type={'font-awesome-5'}/> : null}
              </AppText>
              {title}
            </AppText>
          </View>
          <AppText style={[styles.title, styles.time]}>
            {time}
          </AppText>
          {duration ?
            <View style={styles.markers}>
              <View style={styles.marker}>
                <AppText style={styles.markerLabel}>{'Продолжительность'}</AppText>
                <AppText style={styles.markerText}>{duration}{' минут'}</AppText>
              </View>
            </View> : null}
          <CardDescription
            style={styles.descriptionText}
            html={description}
            hide={true}
          />
        </View>,
        (offer?.tariffs && offer?.tariffs?.length > 0
          ? <View style={[styles.section, styles.borderBottom, styles.tariffsView]}>
            <AppText style={styles.sectionLabel}>
              {'Тарифы'}
            </AppText>
            <TariffCounter
              tariffs={offer.tariffs}
              onCalcPrice={(total, tariffs) => {
                setTotalPrice(total || 0);
                updateOffer({tariffs, total});
                setValidate(undefined);
              }}/>
          </View>
          : null),
        (geo?.length > 0
        ? <MapPoint
          geo={geo}
          containerStyle={styles.section}
        />
        : null),
        <View style={styles.section}>
          <AppText style={styles.sectionLabel}>
            {'С этим смотрят'}
          </AppText>
          <FlatList
            horizontal={true}
            data={categories}
            renderItem={({item:cat}) => (
              <TouchableOpacity
                style={styles.lookMore}
                onPress={()=>onPressTag({...cat, type: 'category'})}
              >
                <AppText>{cat.title}</AppText>
              </TouchableOpacity>
            )}
          />
        </View>,
        <AntiMenuFade height={ratioHeight + 100}/>,
      ]}
      renderItem={({item})=>item}
    />
    <Animated.View
      style={[headerHeight, {position: 'absolute', top: 0, left: 0, width: '100%'}]}
    >
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={()=>{
          videoRef.current && videoRef.current?.toggleMute();
        }}
        onLongPress={()=>{
          videoRef.current && videoRef.current?.togglePlay();
        }}
        style={{height: '100%', width: '100%'}}
      >
      {video
        ? <CardVideo
            ref={videoRef}
            style={[
              styles.image,
              (roundTop ? {borderTopLeftRadius:16, borderTopRightRadius:16} : {}),
              (roundBottom ? {borderBottomLeftRadius:16, borderBottomRightRadius:16} : {}),
            ]}
            preview={preview}
            source={video}
          >
            <LinearGradient
              style={styles.gradient}
              //colors={['#ffffff00', '#FFFFFFB2', '#ffffff']}
              colors={['#ffffff','#ffffff00']}
              start={{x:0, y:1}}
              end={{x:0, y:0}}
              locations={[0.05, 0.99]}
            />
          </CardVideo>
        : <ImageBackground
            style={[
              styles.image,
              (roundTop ? {borderTopLeftRadius:16, borderTopRightRadius:16} : {}),
              (roundBottom ? {borderBottomLeftRadius:16, borderBottomRightRadius:16} : {}),
            ]}
            imageStyle={[
              styles.imageSetts,
              (roundTop ? {borderTopLeftRadius:16, borderTopRightRadius:16} : {}),
              (roundBottom ? {borderBottomLeftRadius:16, borderBottomRightRadius:16} : {}),
            ]} source={preview}
          >
            <LinearGradient
              style={styles.gradient}
              colors={['#ffffff00', '#FFFFFFB2', '#ffffff']}
              //locations={[0.3, 0.7, 1]}
            />
          </ImageBackground>
      }
      </TouchableOpacity>
    </Animated.View>
    <View style={[styles.bookingBox, {paddingBottom: insets.bottom + defaultInset}]}>
      {validate ? <View style={styles.validateContainer}>
        <AppText style={styles.validateText}>{validate}</AppText>
      </View> : null}
      <View style={styles.continueContainer}>
        {!loading && offer
          ? <PriceButton
              offer={offer}
              onPress={handlePressBook}
            />
          : null
        }
        {loading
          ? <ActivityIndicator size={'large'} />
          : null}
      </View>
    </View>
  </View>;
};

const FowardRefComponent = forwardRef(ActivityDetails, {});

const MemoOfFowardRefComponent = memo(
  FowardRefComponent,
  (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
  },
);

export default MemoOfFowardRefComponent;

const styles = StyleSheet.create({
  scrollList: {
    paddingTop: ratioHeight,
    height: '100%',
    ...defaultStyles.webScrollView,
  },
  section: {
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  sectionLabel: {
    paddingVertical: 16,
    fontSize: 17,
    fontWeight: '500',
    color: '#7c7c7c',
  },
  borderBottom: {
    paddingBottom: 24,
    borderBottomColor: '#eeeeee',
    borderBottomWidth: 1,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    ...defaultStyles.webScrollView,
  },
  categoryText: {
    color: 'rgba(128, 148, 156, 1)',
    fontSize: 15,
  },
  caption:{
    fontSize: 16,
    marginVertical: 5,
    color: '#23b865',
    fontWeight: '400',
  },
  place: {
    flexDirection: 'row',
    marginBottom: 16,
    alignItems: 'center',
  },
  placeIcon: {
    marginRight: 9,
  },
  placeText: {
    fontSize: 18,
    fontWeight: '500',
    marginLeft: 4,
  },
  category: {
    flex: 1,
  },
  image: {
    height: '100%',
    width:'100%',
    resizeMode: 'cover',
    backgroundColor: 'white',
  },
  imageSetts: {
  },
  title: {
    fontSize: 22,
    lineHeight: 23,
    flexShrink: 1,
  },
  titleGroup: {
    flexDirection: 'row',
    width: '100%',
  },
  price: {

  },
  priceText: {
    fontSize: 20,
    lineHeight: 23,
    color: colors.default.main,
  },
  time: {
    paddingRight: 10,
    fontWeight: '500',
    minWidth: 65,
    color: '#b4b4b4',
    marginBottom: 12,
    marginTop: 4,
  },
  description: {
    flex: 4,
    paddingTop: 10,
  },
  descriptionText: {
    fontSize: 15,
    //marginBottom: 16,
  },
  lookMore: {
    paddingVertical: 6,
    paddingHorizontal: 10,
    borderRadius: 15,
    shadowColor: 'rgb(115, 139, 186)',
    shadowRadius: 3,
    shadowOpacity: 0.4,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    backgroundColor: 'white',
    margin: 6,
  },
  tariffsView: {
    marginBottom: 16,
  },
  labelsContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
  },
  gradient: {
    height: 80,
    //marginTop: -79,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },

  //markers
  markers: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: 'rgba(246, 246, 246, 1)',
    marginLeft: -12,
    marginRight: -12,
  },
  marker: {
    flex: 1,
    paddingVertical: 16,
    paddingLeft: 12,
  },
  nextMarker: {
    borderLeftWidth: 1,
    borderColor: 'rgba(246, 246, 246, 1)',
  },
  markerLabel: {
    fontSize: 14,
    color: 'rgba(167, 167, 167, 1)',
    marginBottom: 4,
    fontWeight: '300',
  },
  markerText: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 18,
  },

  //booking button
  bookingBox: {
    paddingVertical: 10,
    paddingBottom: Platform.OS === 'web' ? 20 : 0,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.87)',
  },
  validateContainer: {
    //flex: 1,
    alignItems: 'center',
  },
  validateText: {
    color: '#a26524',

    padding: 3,
    paddingBottom: 6,
  },
  buttonPrice: {
    ...textStyle,
    color: '#fff',
    opacity: 0.5,
  },
  payment: {
    paddingRight: 4,
  },
});
