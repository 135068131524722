import React, {useRef, useState} from 'react';
import {ActivityIndicator, StyleSheet, View} from 'react-native';
import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';
import GrayButton from '../Basic/GrayButton';
import AppText from '../Basic/AppText';
import NewButton from '../Basic/NewButton';
import CodeInput from '../Basic/CodeInput';
import LoadingContainer from '../Basic/LoadingContainer';
import {useDispatch, useSelector} from 'react-redux';
import * as _user from '../GlobalStore/reducers/userProfileSlice';
import {BottomSheetTextInput} from '@gorhom/bottom-sheet';
import {api} from '../System/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as auth from '../GlobalStore/reducers/authSlice';
import {useNavigation} from '@react-navigation/native';
import * as appEvent from '../GlobalStore/reducers/appEventSlice';




const onDeleteText = `
  Вы уверены, что хотите удалить свой аккаунт?
  
  Если продолжить, будут безвозвратно удалены: 
  - Все Ваши контактные данные и имя;
  - История заказов и бронирований;
  - Все накопленные баллы и бонусы;
  - Информация о проживании;
  - Избранное.
`;

const DeleteAccount = () => {
  const deleteAccountRef = useRef();
  const [textLoading, setTextLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);
  const [continueDelete, setContinueDelete] = useState(false);
  const [badCode, setBadCode] = useState(false);
  const [code, setCode] = useState('');
  const [continueLoading, setContinueLoading] = useState('loading');
  const [deleteLoading, setDeleteLoading] = useState('wait');
  const [validation, setValidation] = useState('');
  const [deleteLock, setDeleteLock] = useState();

  const user = useSelector(_user.selectValue);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const handleContinue = () => {
    setContinueDelete(true);
    setRequestLoading(true);
    setContinueLoading('loading');
    api.user
      .deleteRequest({phone: user.phone})
      .then(()=>{
        setContinueLoading('ready');
      })
      .catch(()=>{
        setContinueLoading('fail');
        setValidation('Произошла ошибка при попытке удалить аккаунт, пожалуйста попробуйте повторить позднее')
      });
  };

  const handleDelete = () => {
    if (code.length < 5 || badCode || deleteLoading === 'loading') {
      return;
    }

    const onFail = () => {
      setDeleteLoading('fail');
      setValidation('К сожалению, не удалось удалить аккаунт, попробуйте немного позже');
    };

    const onSuccess = () => {
      dispatch(auth.logout());
      dispatch(appEvent.newEvent('accountIsDeleted'));
      //navigation.reset({index: 0, routes: [{name: 'Auth', params: {deleteAccount:'success'}}]});
    };

    __DEV__ && console.log('delete code: ',code);
    setDeleteLoading('loading');
    api.user.deleteConfirm({phone: user.phone, code})
      .then((res)=>{
        AsyncStorage.getAllKeys()
          .then(keys => AsyncStorage.multiRemove(keys))
          .then(() => {
            onSuccess();
          })
          .catch(()=>{
            onFail();
        });
      })
      .catch((err)=>{
        if (err?.data?.result === 'error' || err.code === 403) {
          setBadCode(true);
        } else {
          onFail();
        }
      });
  };

  return <>
    <GrayButton onPress={()=>deleteAccountRef?.current?.open()}>
      {'Удалить аккаунт'}
    </GrayButton>
    <SimpleBottomSheet ref={deleteAccountRef} lastPoint={'50%'}>
      {!continueDelete
        ?
        <View style={styles.consequencesBox}>
          <AppText  style={styles.consequencesText}>{onDeleteText}</AppText>
          <View style={styles.continueBox}>
            <NewButton
              onPress={handleContinue}
              style={styles.continueButton}
            >
              {'Продолжить'}
            </NewButton>
          </View>
        </View>
        :
        <View style={styles.continueBox}>
          {continueLoading === 'loading' ?
          <View style={styles.continueLoading}>
            <AppText>{'Подождите немного, идёт подготовка к удалению'}</AppText>
            <View style={styles.continueLoader}>
              <ActivityIndicator size={'large'}/>
            </View>
          </View> : null}
          {continueLoading === 'ready' ?
            <>
              <AppText>{'Мы отправили на Ваш номер '}{user.phone}</AppText>
              <AppText>{'смс с кодом подтверждения удаления аккаунта,'}</AppText>
              <AppText>{'пожалуйста, введите его ниже'}</AppText>
              <LoadingContainer loading={deleteLoading === 'loading'}>
                <View style={styles.continueCode}>
                  <CodeInput
                    codeLength={5}
                    value={code}
                    badCode={badCode}
                    InputComponent={BottomSheetTextInput}
                    onChange={v=>{
                      setBadCode(false);
                      setCode(v);
                    }}
                  />
                </View>
                <NewButton
                  style={styles.deleteButton}
                  onPress={handleDelete}
                >
                  {'Подтвердить'}
                </NewButton>
                <View style={styles.validBox}>
                  <AppText style={styles.validText}>
                    {validation}
                  </AppText>
                </View>
              </LoadingContainer>
            </> : null}
          {continueLoading === 'fail' ?
            <>
              <View style={styles.validBox}>
                <AppText style={styles.validText}>
                  {validation}
                </AppText>
              </View>
            </> : null}
        </View>
      }
    </SimpleBottomSheet>
  </>;
};

export default DeleteAccount;

const styles = StyleSheet.create({
  consequencesBox: {
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  consequencesText: {
    fontSize: 16,
  },
  continueBox: {
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  continueButton: {},
  continueCode: {
    paddingVertical: 20,
    width: '70%',
    alignSelf: 'center',
  },
  continueLoading: {},
  continueLoader: {
    paddingTop: 20,
  },

  deleteLoading: {},
  deleteButton: {
    marginTop: 40,
  },

  validBox: {
    padding: 10,
  },
  validText: {
    textAlign: 'center',
    color: '#8f3d00',
    justifyContent: 'flex-end',
    marginBottom: 5,
  },
});
