import {AppRegistry} from 'react-native';
import appParams from '../app.json';
import App from '../App';
import './main.css';

AppRegistry.registerComponent(appParams.name, () => App);

AppRegistry.runApplication(appParams.name, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});
