import React from 'react';
import {Text, StyleSheet} from 'react-native';
import {FontFamily} from '../Defaults/Styles';

const AppText = props => {
  const {children, style} = props;
  return (
    <Text {...props} style={[styles.textDefaults, style]}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  textDefaults: {
    fontFamily: FontFamily,
    fontStyle: 'normal',
  },
});

export default AppText;
