import {isObject} from '../System/helpers';
import AppText from '../Basic/AppText';
import React from 'react';
import {StyleSheet} from 'react-native';

export const AttributesLine = ({list, showEmpty, itemStyle}) => {
  if (!isObject(list)) {return null;}
  return (
    <AppText style={{}}>
      {
        Object
        .values(list)
        .filter(v => v.value || (!v.value && showEmpty))
        .map((v, k) => {
          try {
            console.log('attr', title, value);
            let value = v.values.find(itm => itm.id === v.value);
            let title = '';
            switch (v.type) {
              case 'count':
              case 'switch': {
                title = v.title + ` ( ${value?.value} )`;
              }
                break;
              default :
                title = title = v.title + ` ( ${value?.value} )`;
            }
            return (
              <>
                {k > 0 ? <AppText style={styles.attrDelimiter}>{' • '}</AppText> : null}
                <AppText
                  key={k}
                  style={[styles.attributeItem, itemStyle]}
                >
                  {title}
                </AppText>
              </>
            );
          } catch (e) {
            console.log('attribute display error', e);
          }
        })
    }
    </AppText>
  );
};

const styles = StyleSheet.create({
  attributeItem: {
    color: '#a9a9a9',
  },
  attributes: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  attrDelimiter: {
    color: '#a9a9a9',
  },
});
