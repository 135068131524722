import React, {useContext} from 'react';
import {View, TouchableOpacity, Platform, StyleSheet, Image} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Icon} from '@rneui/themed';
import LinearGradient from 'react-native-linear-gradient';
import {DimensionsContext} from '../Defaults/DisplayWidth';
import AppText from '../Basic/AppText';
//import {useNavigation} from '@react-navigation/native';

const TabBar = ({state, descriptors, navigation}) =>
{
  const {width} = useContext(DimensionsContext);
  const currRoute = state.routes[state.index];
  const currRouteOptions = descriptors[currRoute.key].options;

  if (currRouteOptions.tabBarVisible === false) {
    return null;
  }

  return (
    <SafeAreaView
      style={[
        styles.container,
        Platform.OS === 'web' ? {width} : {},
      ]}>
      <LinearGradient
        style={styles.gradient}
        colors={[
          'rgba(255,255,255,0)',
          'rgba(255,255,255,0.96)',
        ]}
        locations={[0, 0.25]}
      />
      <View
        style={styles.route}>
        {state.routes.map((route, index) => {
          const {options} = descriptors[route.key];
          //options.title
          const {icon, showTitles, title, image} = options;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              // The `merge: true` option makes sure that the params inside the tab screen are preserved
              navigation.navigate({name: route.name /*, merge: true*/});
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };

          return (
            <TouchableOpacity
              key={index}
              accessibilityRole="button"
              accessibilityState={isFocused ? {selected: true} : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={styles.routeButton}
            >
              <View style={styles.routeIcon}>
                {
                  icon
                  ? <Icon
                      size={30}
                      color={isFocused ? '#000' : '#B8B8B8'}
                      {...icon}
                    />
                  : null
                }
                {
                  !icon && image
                  ? <Image
                      source={image}
                      style={[
                        styles.buttonImage,
                        !isFocused ? styles.buttonImageUnfocused : null,
                      ]}
                      resizeMode={'contain'}
                    />
                  : null
                }
              </View>
              {showTitles ? <AppText style={[styles.routeTitle,{color: isFocused ? '#000' : '#B8B8B8'} ]}>{title}</AppText> : null}
            </TouchableOpacity>
          );
        })}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    //backgroundColor: 'rgba(0,0,0,0)',
    alignContent: 'stretch',
    flexDirection: 'column',
    marginTop: -110,
    height: 0,
    //marginBottom: 100,
    ...(Platform.OS === 'web'
      ? {
        position: 'fixed',
        bottom: 0,
        //left: 0,
        //right: 0,
        marginTop: 0,
      }
      : {}),
    //alignSelf: 'flex-end',
  },
  gradient: {
    //height: 70,
    height: 80,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    ...(Platform.OS === 'web'
      ? {
        height: 65,
        //marginBottom: -10,
      }
      : {}),
  },
  route: {
    flexDirection: 'row',
    //backgroundColor: 'green',
    // backgroundColor: '#fff',
    position:'absolute',
    bottom: 20,
    ...(Platform.OS === 'web'
      ? {
        width: '100%',
        bottom: 5,
      }
      : {}),
  },
  routeTitle: {
    fontSize: 10,
    lineHeight: 11,
    color: '#5d5d5d',
    paddingTop: 5,
  },
  routeButton: {
    flex: 1,
    alignItems: 'center',
  },
  routeIcon: {
    width: 30,
    height: 30,
  },
  buttonImage: {
    height: 30,
    width: 30,
    //resizeMode: 'center',
  },
  buttonImageUnfocused: {
    opacity: 0.4,
  },
});

export default TabBar;
