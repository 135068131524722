import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {api} from '../../System/api';
import {arrDiff, arrUnique} from '../../System/helpers';

const initialState = {
  value:[],
  state:'init',
};

export const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    set: (state, {payload})=>{
      //console.log('change bookmarks to ', payload);
      state.value = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(init.pending, (state) => {
      state.state = 'loading';
    });
    builder.addCase(init.fulfilled, (state, {payload}) => {
      //console.log('bookmarks to set ', payload);
      return {
        ...state,
        ...payload,
      };
    });
  },
});

const init = createAsyncThunk(
  'bookmarks/init',
  async () => {
    try {
      __DEV__ && console.log('initial compare bookmarks 2');
      let appBookmarks = await AsyncStorage.getItem('bookmarks');
      appBookmarks = JSON.parse(appBookmarks) || [];

      let page = 1, limit = 50, serverBookmarks = [];
      while (1) {
        let res = await api.bookmarks.list({page, limit});
        let data = res.data.wishList;
        serverBookmarks = serverBookmarks.concat(data.map(i => parseInt(i.id)));
        if (data.length < limit) {
          break;
        } else {
          page++;
        }
      }
      let diffWithApp = arrDiff(appBookmarks, serverBookmarks);
      //console.log({diffWithApp, serverBookmarks, appBookmarks});
      if (diffWithApp.length > 0) {
        __DEV__ && console.log('sync bookmarks with server');
        diffWithApp.forEach(offer => {
          //console.log('set offer ' + offer + ' bookmarked');
          api.bookmarks
            .change({offer_id: offer, bookmark: true})
            .then(() => {
            });
        });
      }
      let bothBookmarks = arrUnique(serverBookmarks.concat(appBookmarks));
      await AsyncStorage.setItem('bookmarks', JSON.stringify(bothBookmarks));
      return {
        value: bothBookmarks,
        state: 'loaded',
      };
    } catch (e) {
      __DEV__ && console.warn('init bookmarks err',e);
    }
  }
);
const get = createAsyncThunk(
  'bookmarks/get',
  async () => {
    let appBookmarks = await  AsyncStorage.getItem('bookmarks');
    appBookmarks = JSON.parse(appBookmarks) || [];
    return appBookmarks.map(v => parseInt(v));
  }
);
const edit = createAsyncThunk(
  'bookmarks/edit',
  async ({id, action}) => {
    let value = await AsyncStorage.getItem('bookmarks');
    let bookmarks = JSON.parse(value) || [];
    switch (action){
      case '-':
      case 'del': bookmarks = bookmarks.filter(v=>v !== id); break;
      default :
      case '+':
      case 'add': {
        if (parseInt(id)){
          bookmarks = bookmarks.concat(id);
        }
      } break;
    }
    await AsyncStorage.setItem('bookmarks', JSON.stringify(bookmarks));
    return bookmarks;
  }
);

const clear = createAsyncThunk(
  'bookmarks/clear',
  async () => {
    __DEV__ && console.log('clear user bookmarks');
    await  AsyncStorage.setItem('bookmarks',JSON.stringify([]));
    return [];
  }
);

export const select = (state) => state.bookmarks;
export {init, get, edit, clear};
export const {set} = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
