import AsyncStorage from '@react-native-async-storage/async-storage';
import {isObject} from '../System/helpers';

export const tempBookingStoreName = 'user_temp_bookings';

export const tempBookingStore = (booking) => {
  return new Promise((resolve, reject) => {
    AsyncStorage
      .getItem(tempBookingStoreName)
      .then((bookings) => {
        bookings = JSON.parse(bookings);
        if (!isObject(bookings)) {
          bookings = {};
        }
        bookings[booking.id] = {
          ...booking,
        };
        AsyncStorage
          .setItem(tempBookingStoreName, JSON.stringify(bookings))
          .then(()=>resolve())
          .catch(e=>reject(e));
      })
      .catch(e=>reject(e));
  });
};

export const tempBookingRemove = (booking) => {
  return new Promise((resolve, reject) => {
    if (!booking.id) {
      return reject('booking.id is required');
    }
    AsyncStorage
      .getItem(tempBookingStoreName)
      .then((bookings) => {
        bookings = JSON.parse(bookings);
        if (!isObject(bookings)) {
          bookings = {};
        }
        delete bookings[booking.id];
        AsyncStorage
          .setItem(tempBookingStoreName, JSON.stringify(bookings))
          .then(()=>resolve())
          .catch(e=>reject(e));
      })
      .catch(e=>reject(e));
  });
};

export const tempBookingsGet = (booking) => {
  return new Promise((resolve, reject) => {
    AsyncStorage
      .getItem(tempBookingStoreName)
      .then((bookings)=>{
        bookings = JSON.parse(bookings);
        if (!isObject(bookings)) {
          bookings = {};
        }
        if (booking && booking.id){
          return resolve(bookings[booking.id] || {});
        } else {
          return resolve(bookings);
        }
      })
      .catch(e=>reject(e));
  });
};
