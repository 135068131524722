import {Linking} from 'react-native';
import {metricEvent} from '../Metrics/MetricHelper';

export const communicationAction = ({
  action,
  data,
  isAuthSkipped,
}) => {
  return new Promise((resolve, reject) => {
    let outletPhone = '+78005507382';
    let whatsapp = '+79885260066';
    let telegram = 'MriyaPark_bot';

    let ym_action = (action, unauth) => {
      metricEvent( action + unauth ? '_unauth' : '');
    };

    switch (action) {
      case 'call_later': {
        ym_action('question_request_call', isAuthSkipped);
        return resolve();
      }
      case 'call': {
        let url = `tel:${outletPhone}`;
        Linking.openURL(url)
          .then(resolve)
          .catch(e => {
            e.url = url;
            return reject(e);
          });
        ym_action('question_request_phone', isAuthSkipped);
        return resolve();
      }
      case 'telegram': {
        let message =
          '?start=' +
          (data?.booking_id
            ? 'booking_' + data?.booking_id
            : 'offer_' + data?.offer_id);
        let url = `https://t.me/${telegram}${message}`;
        Linking.openURL(url)
          .then(resolve)
          .catch(e => {
            e.url = url;
            return reject(e);
          });
        ym_action('question_request_telegram', isAuthSkipped);
        return resolve();
      }
      case 'whatsapp': {
        //console.log('data:', data);
        //console.log('DO whatsapp action');
        let item,
          message,
          dateParams = '';
        if (data.booking_id) {
          item = 'бронирования ' + data.booking_id;
          message = encodeURI(
            `Здравствуйте, я по поводу ${item} (${data.offer.name})`,
          );
        } else {
          item = 'услуги ' + data.offer_id;
          if (data.date) {
            let date = data.date_from.format('DD.MM.YYYY');
            let timeFrom = data.time_from;
            let timeTo = data.time_to;
            dateParams = `${date} c ${timeFrom} до ${timeTo}`;
          }
          message = encodeURI(
            `Здравствуйте, я по поводу ${item} (${data.offer.name}) ${dateParams}`,
          );
        }
        let url = `https://api.whatsapp.com/send?phone=${whatsapp}&text=${message}`;
        Linking.openURL(url)
          .then(resolve)
          .catch(e => {
            e.url = url;
            return reject(e);
          });
        ym_action('question_request_whatsapp', isAuthSkipped);
        return resolve();
      }
    }
  });
};
