import React, {useContext, memo} from 'react';
import {TouchableOpacity, View, StyleSheet, Platform} from 'react-native';
import {BackgroundImage} from '@rneui/base';
import AppText from '../Basic/AppText';
import humanize from 'humanize-number';
import HeartToggle from '../Basic/HeartToggle';
import useBookmarks from '../Bookmarks/useBookmarks';
import Animated, {runOnJS, useAnimatedStyle, useSharedValue, withTiming} from 'react-native-reanimated';
import {getMinWidth} from '../Defaults/DisplayWidth';

import * as bookmarks from '../GlobalStore/reducers/bookmarksSlice';
import {useSelector} from 'react-redux';

let windowWidth = getMinWidth();

const TileCard = (props) => {
  const {
    id,
    title,
    description,
    mainImage,
    mainImageMd,
    mainImageSm,
    price,
    discount,
    prior,
    //events
    onPress,
    isUseSlots,

    //states
    bookmarked,

    //flags
    useBookmarkFadeout,

    //events
    onFadeoutEnd,
  } = props;


  const {defaultColors} = useSelector((state) => state.style);
  const _bookmarks = useSelector(bookmarks.select);

  const labelText = '';
  const {toggleBookmark} = useBookmarks();

  let priceWithDiscount = discount ? price?.price - (price?.price * (discount / 100)) : price?.price;
  const currentPrice = price?.price;

  //fadeout manage
  const opacity = useSharedValue(1);
  const handleFadeoutFinish = () => {
    onFadeoutEnd && onFadeoutEnd();
  };
  const fadeoutCard = () => {
    opacity.value = withTiming(0,{duration: 500}, (finished)=>{finished && runOnJS(handleFadeoutFinish)();});
  };
  const fadeoutStyle = useAnimatedStyle(() => ({
      opacity: opacity.value,
      width: '48.5%',
    })
  );

  return (
    <Animated.View style={[fadeoutStyle, styles.cardBox]}>
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={()=>{onPress && onPress({id});}}
      >
      <BackgroundImage
        source={mainImageSm}
        style={styles.cardImage}
        imageStyle={styles.cardImageWrap}
        resizeMode={'cover'}
      >
        {
          isUseSlots
            ? <View style={styles.label}>
              <AppText style={styles.labelText}>{'Оплата онлайн'}</AppText>
            </View>
            : null
        }
        <HeartToggle
          style={{position:'absolute', top: 0, right: 0}}
          checked={bookmarked || _bookmarks.value && _bookmarks.value.includes(id)}
          onChange={(state)=>{
            toggleBookmark(id, state);
            useBookmarkFadeout && state === false && fadeoutCard();
          }}
        />
      </BackgroundImage>
      <View style={styles.descriptionBox}>
        <View style={styles.priceBox}>
          <AppText style={[styles.cardPrice, {color: defaultColors.main}]}>
            {price
              ? (price?.from ? 'от ' : '') + '₽' + (humanize(parseInt(priceWithDiscount), {delimiter: ' '}))
              : 'Бесплатно'
            }
          </AppText>
          {
            discount && price
              ? <AppText style={[styles.cardPrice, styles.discountPrice]}>
                {(price?.from ? 'от ' : '') + '₽' + (humanize(parseInt(currentPrice), {delimiter: ' '}))}
              </AppText>
              : null
          }
        </View>
        <AppText style={styles.cardTitle}>{title}</AppText>
      </View>
    </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({//tileCard
  cardBox:{
    width: '48.5%',
    //height: 150,
    backgroundColor: 'white',
  },
  priceBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardPrice:{
    marginTop: 4,
    color: '#FDB161',
    fontWeight: '500',
    marginRight: 6,
  },
  discountPrice: {
    textDecorationLine: 'line-through',
    color: 'rgba(96,96,96,0.47)',
    fontSize: 12,
  },
  cardTitle:{
    marginTop: 4,
    fontSize: 14,
    lineHeight: 15,
  },
  cardImage: {
    width: '100%',
    aspectRatio: 17 / 16,
    ...(Platform.OS === 'web'
        ? {minHeight: windowWidth * 0.485 * 0.8}
        : {}
    ), // if not supports aspect-ratio
    borderRadius: 8,
    backgroundColor: '#F6F6F6FF',
  },
  cardImageWrap: {
    borderRadius: 8,
  },
  descriptionBox:{
    paddingLeft: 4,
  },

  //label
  label: {
    padding: 2,
    paddingHorizontal: 6,
    backgroundColor:'#1eaad3',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    borderRadius: 8,
    margin: 6,
  },
  labelText : {
    fontSize: 8.5,
    textTransform: 'uppercase',
    color: '#fff',
  },
});

const MemoTileCard = memo(TileCard,(p,n)=>{
  delete p?.children; delete n?.children;
  return JSON.stringify(p) === JSON.stringify(n);
});

export default MemoTileCard;
