import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View, StyleSheet, SafeAreaView, Platform} from 'react-native';
import {Icon} from '@rneui/base';

const defaultSize = 30;
const defaultMaxRate = 5;

export const RatingStar = ({fill, size, onPress, style}) => {

  size = size || defaultSize;
  fill = fill || 0;
  const calcWidth = (Math.floor(size * fill) || 2) - (fill < 1 ? 2 : 0);
  console.log(calcWidth);

  return (
    <TouchableOpacity
      activeOpacity={onPress ? 0.5 : 1}
      onPress={onPress}
      style={[styles.starContainer, style]}
    >
      <View style={{marginBottom: -size}}>
        <Icon
          size={size}
          color={'#7a7a7a'}
          name={'star'}
          type={'font-awesome'}
        />
      </View>
      <View
        style={{width: size || defaultSize}}
      >
        <Icon
          size={size || defaultSize}
          color={'#DAA520'}
          name={'star'}
          type={'font-awesome'}
          style={[
            {width: calcWidth, marginLeft: fill < 1 ? 1 : 0},
            styles.brightStyle,
          ]}
        />
      </View>
    </TouchableOpacity>
  );
};

export const Rating = ({size, rating, maxRate, isStatic, starStyle}) => {
  const [state, setState] = useState(0);
  size = size || defaultSize;
  maxRate = maxRate || defaultMaxRate;
  const filledStars = Math.ceil(state);
  const lastFill = state % 1 || 1;

  useEffect(()=>{
    setState(rating);
    //console.log({rating, lastFill});
  },[rating]);

  const handlePress = (num) => {
    setState(num);
  };

  return (
      <View style={styles.starBox}>
        {
          Array(maxRate).fill(null).map((v, i) => {
            let fill = 0;
            if ((i + 1) < filledStars) {
              fill = 1;
            } else if ((i + 1) === filledStars){
              fill = lastFill;
            }
            return (
              <RatingStar
                key={i}
                fill={fill}
                size={size}
                onPress={!isStatic ? ()=>handlePress(i + 1) : null}
                style={starStyle}
              />
            );
          })
        }
      </View>
  );
};

export const RatingTest = () => {
  return (
    <SafeAreaView style={styles.ratingBox}>
      <Rating rating={2.5} size={30} maxRate={5} isStatic={true}/>
      <Rating rating={3.36} size={30} maxRate={5} />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  ratingBox: {
    flex: 1,
    backgroundColor: 'white',
  },
  starBox: {
    flexDirection: 'row',
  },

  brightStyle: {
    ...(
      Platform.OS === 'web' ? {
        alignItems: 'flex-start',
        marginLeft: 1,
        overflow: 'hidden',
      } : null
    ),
  },
});
