import React from 'react';
import {SafeAreaView, View, StyleSheet, Platform} from 'react-native';
import WebView from 'react-native-webview';
import bitrixChatHtml from './bitrix_chat.html';

/*const bitrixChatHtml = Platform.OS === 'web' ?
  {html:import('./bitrix_chat.html')} :
  require('./bitrix_chat.html');*/

const BitrixChat = () => {
  return (
    <SafeAreaView style={styles.container}>
      <WebView source={Platform.OS === 'web' ? {html:bitrixChatHtml} : require('./bitrix_chat.html')}/>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default BitrixChat;
