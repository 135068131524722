import React, {useCallback, useEffect, useState} from 'react';
import {View, StyleSheet, ActivityIndicator} from 'react-native';
import AppText from '../Basic/AppText';
import {colors} from '../Defaults/Styles';
import {useOutletLoyaltyPercent} from './OfferLoyalty';
import {calcOfferFinalPrice} from '../Cards/CardPrice';
import humanize from 'humanize-number';
import {Tumbler} from '../Basic/Tumbler';



export const BookingFormTumbler = ({onChange, style, containerStyle, outlet, offer}) =>
{
  const [state, setState] = useState({value: 0});
  const {percent, loading, accommodationValue} = useOutletLoyaltyPercent(outlet);

  let userBonus = accommodationValue?.pointBalance || 0;
  let offerPrice = calcOfferFinalPrice(offer);
  let bonusCanToSpend = Math.min((offerPrice * (percent / 100)),userBonus);

  useEffect(()=>{
    state && onChange && onChange({
      useBonus: state.value === 1,
      locked: loading && state.value === 1,
      bonusCount: bonusCanToSpend,
    });
  }, [state, loading, bonusCanToSpend]);

  const handleChange = useCallback((selected) => {
    setState(v=>({...v, value:selected}));
  },[setState]);

  if (!loading && percent === 0) return (
    <View>
      <AppText style={styles.bonusNotAvailable}>
        {'Недоступно'}
      </AppText>
    </View>
  );

  return <Tumbler
    onChange={handleChange}
    style={style}
    containerStyle={containerStyle}
    value={state?.value || 0}
    items={[
      'Оплатить полностью\nкартой',
      <View>
        <AppText>{'Оплатить ' + (percent ? percent + '%' : '') + ' баллами'}</AppText>
        <View style={styles.bpPointsBox}>
          <AppText style={styles.bpAvailableText}>{'Доступно '}</AppText>
          {
            loading
            ? <ActivityIndicator size={'small'}/>
            : <AppText style={styles.bpPointsText}>
                {humanize(bonusCanToSpend)}{'B'}
            </AppText>
          }
        </View>
      </View>,
    ]}
  />;
};

const styles = StyleSheet.create({
  //bonus payment
  bpPointsBox: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  bpAvailableText: {
    color: '#8f8f8f',
    lineHeight: 24,
  },
  bpPointsText: {
    color: colors.default.bonus,
    fontWeight: '500',
    fontSize: 24,
  },

  bonusNotAvailable: {
    color:'#888888',
  },
});
