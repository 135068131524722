/*
  "offerValidPeriods": [
    {
      "date_from": "2021-11-20T10:00:00+00:00",
      "date_to": "2021-11-20T16:00:00+00:00"
    },
    {
      "date_from": "2021-11-21T09:00:00+00:00",
      "date_to": "2021-11-21T15:00:00+00:00"
    }
  ],
*/

import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Moscow');

const defaults = {
  workDates: Array(30)
    .fill(0)
    .reduce(
      (a, v, i) => ({
        ...a,
        [moment().add(i, 'days').format('YYYY-MM-DD')]: {
          min: i > 0 ? 8 : parseInt(moment().add(1, 'hour').format('H')),
          max: 20,
        },
      }),
      {},
    ),
  minHour: 8,
  maxHour: 20,
};

const emptyDefaults = {
  workDates: {
    /*'1970-01-01': {
      timeList: [],
      max: undefined,
      min: undefined,
    },*/
  },
  minHour: undefined,
  maxHour: undefined,
};

const monthLater = moment().add(1, 'month');
const yearLater = moment().add(1, 'year');
const maxCalendarEnd = yearLater;

const calendarRestrictionsOld = ({validPeriods}) => {
  let workDates = {};
  let notWorkDates = {};

  let now = moment();
  if (parseInt(now.format('H')) >= defaults.maxHour) {
    notWorkDates[now.format('YYYY-MM-DD')] = {
      date: now,
    };
  }

  //console.log('ValidPeriods: ', validPeriods);
  for (let i in validPeriods) {
    let period = validPeriods[i];
    let start = moment(period.date_from);
    let end = moment(period.date_to);
    workDates[start.format('YYYY-MM-DD')] = {
      date: start,
      min: parseInt(start.format('H')),
      max: parseInt(end.format('H')),
    };
  }
  //console.log('Restrictions: ', workDates);

  return {
    workDates,
    notWorkDates,
    ...defaults,
  };
};

const calendarRestrictions = props => {
  let schedule = props?.schedule;
  let strict = props?.strict;

  if (!schedule) {
    return strict ? emptyDefaults : defaults;
  }

  let workDates = {};
  let notWorkDates = {};

  let now = moment();

  let period,
    start,
    end,
    startHour,
    endHour,
    interval,
    allDay,
    dow,
    dowSchedule,
    cursor,
    timeList;
  let allDaySchedule = [{from: '00:00', to: '23:59'}];

  //console.log('SCHEDULE:', JSON.stringify(schedule));
  for (let i in schedule) {
    period = schedule[i];
    interval = parseInt(period.interval) || null;
    allDay = period.allDay;
    //console.log({period, allDay, interval});
    start = moment(period.from).startOf('day');
    end = (
      /\d{4}-\d\d-\d\d/.test(period.to)
        ? moment(period.to)
        : moment(period.from)
    ).endOf('day');
    if (end.isBefore(moment())) {
      continue;
    }
    if (start.isBefore(moment())) {
      start = moment().startOf('day');
    }
    end = moment.min([end, maxCalendarEnd]); // restrict max calendar length
    cursor = start.clone();
    /*console.log({
      start,
      cursor,
      end,
      btwn: cursor.isBetween(start, end, undefined, '[]'),
    });*/
    while (cursor.isBetween(start, end, undefined, '[]')) {
      timeList = [];
      dow = cursor.day();
      //console.log(JSON.stringify({start, end, period, dow}));
      //console.log(dow, Object.keys(period), cursor.format('dddd'), cursor.format('DD.MM.YYYY'));
      dowSchedule =
        period[dow] && period[dow][0] && period[dow][0]?.from
          ? period[dow]
          : period[7 /*default*/];
      dowSchedule =
        !(dowSchedule && dowSchedule.length > 0) && allDay
          ? allDaySchedule
          : dowSchedule;

      if (dowSchedule && dowSchedule.length > 0) {
        //console.log('dowSchedule', JSON.stringify(dowSchedule));
        timeList = generateTimesList(dowSchedule, interval);
        startHour = moment(timeList[0], 'HH:mm');
        endHour = moment(timeList[timeList.length - 1], 'HH:mm');

        if (!endHour.isValid() || startHour.isAfter(endHour)) {
          endHour = startHour.clone();
          //console.log('set synth end', endHour);
        }
      } else {
        //startHour = moment(defaults.minHour, 'HH');
        //endHour = moment(defaults.maxHour, 'HH');
        cursor.add(1, 'day');
        continue;
      }
      //console.log('day', cursor.format('YYYY-MM-DD'),'times', timeList, startHour, 'startHour', endHour, 'endHour');
      workDates[cursor.format('YYYY-MM-DD')] = {
        //date: start,
        timeList,
        min: parseInt(startHour.format('HH')),
        max: parseInt(endHour.format('HH')),
      };
      cursor.add(1, 'day');
    }
  }

  //console.log({workDates});

  let currWorkDate;

  if ((currWorkDate = workDates[now.format('YYYY-MM-DD')])) {
    currWorkDate.min = parseInt(now.format('H'));
    if (parseInt(now.format('H')) >= currWorkDate.max) {
      delete workDates[now.format('YYYY-MM-DD')];
    }
  }

  /*if (strict && Object.keys(workDates).length === 0){
    workDates = {
      ...emptyDefaults.workDates,
    };
  }*/

  /*else {
    if (parseInt(now.format('H')) >= defaults.maxHour) {
      notWorkDates[now.format('YYYY-MM-DD')] = {
        date: now,
      };
    }
  }*/

  //console.log('Restrictions: ', JSON.stringify({workDates, notWorkDates}));

  return {
    workDates,
    notWorkDates,
  };
};

const generateTimesList = (timeSchedule, interval) => {
  let times,
    start,
    end,
    cursor,
    timesList = [];
  for (let i in timeSchedule) {
    times = timeSchedule[i];
    start = moment(times.from, 'HH:mm');
    end = moment(times.to, 'HH:mm');

    if (!start.isValid()) {
      continue;
    }
    if (!end.isValid()) {
      end = start.clone();
    }

    if (start.isAfter(end)) {
      end = start;
    }

    if (start.isSame(end, 'time') || !interval) {
      timesList.push(start.format('HH:mm'));
    } else {
      cursor = start.clone();
      //console.log('start/end/1before2',start.toString(),end.toString(),cursor.isBefore(end));
      while (cursor.isBefore(end)) {
        timesList.push(cursor.format('HH:mm'));
        cursor.add(interval, 'minutes');
      }
      /*if (times.from !== times.to) {
        timesList.push(end.format('HH:mm'));
      }*/
    }
  }
  return timesList;
};

export default calendarRestrictions;
