import React, {useEffect, useState} from 'react';
import {FlatList, View, StyleSheet, SafeAreaView} from 'react-native';
import {api} from '../System/api';
import AppButton from '../Basic/AppButton';
import BasketItem from './BasketItem';
import offerAdapter from '../Offers/OfferAdapter';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import AppText from '../Basic/AppText';
import PageHeader from '../Basic/PageHeader';

let data = {
  'basket': {
    'id': 1,
    'created': '2023-08-08T15:09:44+00:00',
    'basketItems': [
      {
        'id': 3,
        'offer': {
          id: 1520,
        },
        'created': '2023-08-21T12:31:12+00:00',
        'offerItem': {
          'id': 1575,
          'title': '123123123',
          'price': 1,
          'max_persons': 12,
          'persons_count_switch': false,
          'prior': 123123,
        },
        'attributesJson': null,
        'finalPrice': 1,
      },
      {
        'id': 4,
        'created': '2023-08-21T12:31:12+00:00',
        'offerItem': {
          'id': 1576,
          'title': '32234234',
          'price': 234234234,
          'max_persons': null,
          'persons_count_switch': false,
          'prior': null,
        },
        'attributesJson': null,
        'finalPrice': 234234234,
      },
    ],
  },
};

const Basket = () =>
{
  const [items, setItems] = useState({});
  const insets = useSafeAreaInsets();
  const [totalSum, setTotalSum] = useState();

  let handleTestCreate = () => {
    let testData = {
      'offer_id': 1162,
      'date_from': '2021-12-10 12:40',
      'date_to': '2021-12-10 12:40',
      'comment': 'test comment',
      'offer_items': [
        {
          'id': 600,
          'count': 2,
        },{
          'id': 606,
          'count': 3,
        },{
          'id': 630,
          'count': 1,
        },{
          'id': 641,
          'count': 5,
        },{
          'id': 845,
          'count': 1,
        },{
          'id': 848,
          'count': 9,
        },{
          'id': 644,
          'count': 2,
        },
      ],
      /*'offerAttribute': [
        {
          'attribute': 2,
          'value': 2,
        },
      ],*/
    };
    api.basket.setItem(testData).then((res)=>{
      console.log(res);
    });
  };

  const renderBasketItem = ({item, key}) => {
    //console.log({item});
    item.offerItem.count = item.count;
    item.offer.offerItems = [item.offerItem];
    //item.offer.date = item.dateFrom;
    item.offer = offerAdapter(item.offer);
    return <BasketItem {...item}/>;
  };

  useEffect(() => {
    api.basket.get().then(res => {
      let basket = res.data.basket;
      setItems(basket.basketItems);
      console.log(JSON.stringify(basket.basketItems));
    });
    //let basket = data.basket;

  },[]);

  return (
    <View style={[styles.mainContainer, {paddingTop: insets.top}]}>
      <PageHeader title={'Корзина'}/>
      <View style={styles.container}>
        <FlatList
          data={items ? Object.values(items) : []}
          renderItem={renderBasketItem}
        />
      </View>
      <View style={[styles.total, {minHeight: insets.bottom + 100,  paddingBottom: insets.bottom}]}>
        <AppButton
          title={'add test basket item'}
          onPress={handleTestCreate}
        />
        <View>
          <AppText>
            {'Итого к оплате:'}{totalSum}
          </AppText>
        </View>
        <AppButton title={'Подтвердить заказ'} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    paddingHorizontal: 12,
  },
  total: {
    minHeight: 100,
    backgroundColor: 'white',
  },
});

export default Basket;
