import React, {useEffect, useRef, useState} from 'react';
import {View, StyleSheet, ActivityIndicator, TouchableOpacity} from 'react-native';
import AppText from '../Basic/AppText';
import {levelById, levelByTitle, loyaltyLevelsParams} from './LevelsData';
import {Icon} from '@rneui/base';
import {colors} from '../Defaults/Styles';
import {LoyaltyLevel} from './Level';
import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';
import {isObject} from '../System/helpers';

import * as loyaltyLevels from '../GlobalStore/reducers/loyaltyLevelsSlice';
import * as accommodation from '../GlobalStore/reducers/accommodationSlice';
import {useDispatch, useSelector} from 'react-redux';
import useProfileWithAccommodation from '../User/useProfileWithAccommodation';

const LevelPercent = ({level, percent}) => {
  const levelData = loyaltyLevelsParams[level];
  return <View style={styles.lpBox}>
    <LoyaltyLevel size={'middle'} style={styles.lpLevel} level={level}/>
    <View style={styles.lpTitleBox}>
      <AppText style={styles.lpLevelName}>
        {levelData.title}
      </AppText>
      <AppText style={styles.lpLevelText}>
        {'оплата до ' + percent + '% бонусами'}
      </AppText>
    </View>
  </View>;
};

export const outletHasLoyalty = outlet => {
  //console.log(typeof outlet?.loyaltyDiscounts, outlet?.loyaltyDiscounts);
  return (
    outlet?.loyaltyDiscounts
    &&
    isObject(outlet?.loyaltyDiscounts)
    &&
    Object.keys(outlet.loyaltyDiscounts).length
  );
};

const OutletLoyaltyList = ({name, loyaltyDiscounts}) =>
{
  const [loading, setLoading] = useState(true);

  const _loyaltyLevels = useSelector(loyaltyLevels.select);
  const {userProfile, loadingState} = useProfileWithAccommodation();

  useEffect(()=>{
    setLoading(true);
    if (_loyaltyLevels.state === 'loaded' && loadingState === 'loaded' ){
      setLoading(false);
    }
  },[_loyaltyLevels, loadingState]);

  return <View style={styles.ollBox}>
    <View style={styles.ollTitleBox}>
      <AppText style={styles.ollTitle}>
        {'Лояльность локации'}
      </AppText>
      <AppText style={styles.ollOutletTitle}>
        {name}
      </AppText>
    </View>
    {loading
      ? <View style={styles.ollLoading}><ActivityIndicator size={'small'}/></View>
      : <View style={styles.ollListBox}>
        {
          isObject(loyaltyDiscounts) ?
            Object.keys(loyaltyDiscounts)
              .filter(name => {
                let id = levelByTitle(name);
                let level = _loyaltyLevels.value[id + ''];
                return  !level?.isPersonal || (level?.isPersonal && userProfile.loyaltyLevel === id);
              })
              .map(name => {
                let level = levelByTitle(name),
                  percent = loyaltyDiscounts[name];
                return {level, percent};
              })
              .sort((a, b) => a?.level - b?.level)
              .map((item, key) => <LevelPercent key={key} {...item}/>)
            : <ActivityIndicator size={'large'}/>
        }
      </View>
    }
  </View>;
};

export const useOutletLoyaltyPercent = (outlet) => {
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(true);

  const loyaltyLevelsValue = useSelector(loyaltyLevels.selectValue);
  const loyaltyLevelsState = useSelector(loyaltyLevels.selectState);
  const accommodationValue = useSelector(accommodation.selectValue);
  const accommodationState = useSelector(accommodation.selectState);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(accommodation.getValue(true)).then();
  }, [dispatch]);

  useEffect(()=>{
    //console.log({outlet});
    if (
      outlet
      &&
      loyaltyLevelsState === 'loaded'
      &&
      accommodationState === 'loaded'
    ) {
      let userLevel = accommodationValue?.loyaltyLevel;
      let level = levelById(userLevel);
      let outletUserPercent = outlet?.loyaltyDiscounts[level.name];
      setPercent(outletUserPercent || 0);
      setLoading(false);
      //console.log({outlet, loyaltyLevelsState, loading, accommodationState});
    }
  }, [outlet, loyaltyLevelsState, accommodationState]);

  return {
    percent,
    loading,
    accommodationValue,
  };
};

export const OutletLoyalty = ({outlet, style}) =>
{
  const loyaltyListRef = useRef();
  const {percent, loading} = useOutletLoyaltyPercent(outlet);

  let text;
  if (percent > 0) {
    text = 'Можно оплатить до ' + percent + '% бонусами';
  } else {
    text = 'Лояльность';
  }


  return <View style={[styles.olBox, style]}>
    {loading ?
      <ActivityIndicator size={'small'} style={styles.olLoading}/>
    : <TouchableOpacity
        onPress={()=>{loyaltyListRef.current?.open();}}
        style={styles.olStatusBox}
      >
        <View style={styles.olTextBox}>
          <Icon
            name={'brightness-percent'}
            type={'material-community'}
            color={colors.default.main}
          />
          <AppText style={styles.olText}>
            {text}
          </AppText>
        </View>
        <Icon
          type={'simple-line-icon'}
          name={'arrow-right'}
          size={10}
          style={styles.olArrow}
        />
    </TouchableOpacity>}
    <SimpleBottomSheet ref={loyaltyListRef} lastPoint={'60%'}>
      <OutletLoyaltyList {...outlet}/>
    </SimpleBottomSheet>
  </View>;
};

const styles = StyleSheet.create({
  //outlet loyalty (ol)
  olBox:{
    flex: 1,
    alignItems: 'stretch',
  },
  olStatusBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  olTextBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  olArrow: {
    flex:1,
    justifyContent: 'center',
  },
  olText:{
    marginLeft: 5,
    color: '#4d4d4d',
    fontWeight: '500',
  },
  olLoading:{

  },


  //outlet loyalty list [oll]
  ollBox:{
    padding: 10,
    paddingHorizontal: 24,
  },
  ollTitleBox:{
    justifyContent: 'center',
  },
  ollTitle:{
    marginTop: 8,
    fontSize: 16,
    textAlign: 'center',
  },
  ollOutletTitle:{
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 24,
  },
  ollListBox:{

  },
  ollLoading: {
    flex: 1,
    justifyContent: 'center',
  },

  //loyalty percent [lp]
  lpBox:{
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  lpLevel:{

  },
  lpTitleBox:{
    flex: 1,
    marginLeft: 10,
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  lpLevelName:{

  },
  lpLevelText:{
  },
});
