import CustomBackdrop from './CustomBackdrop';
import {View, StyleSheet, Platform, TouchableOpacity} from 'react-native';
import {BottomSheetModal, useBottomSheet, useBottomSheetModal} from '@gorhom/bottom-sheet';
import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  memo,
} from 'react';
//import {CloserArrow} from '../Basic/BasicElements';
import {useNavigation} from '@react-navigation/native';
import {defaultStyles} from '../Defaults/Styles';
import {useSelector} from 'react-redux';
import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import {objectsEqual} from '../System/helpers';
//import {NavigationActions} from 'react-navigation';


const SimpleBottomSheetFwRef = forwardRef((props, ref) => {
  const {
    open,
    children,
    points,
    lastPoint,
    style,
    containerStyle,
    onChange,
    onAnimate,
    index,
    closeOnPanout,
    panDownToClose,
    panningGesture,
    disableClose,
    useBackdrop,
    onClose,
    onOpen,
    detached,
    handleColor,
    footerComponent,
    itemNavParam,
  } = props;

  const bottomSheetRef = useRef();
  const _appEvent = useSelector(appEvent.select);
  const navigation = useNavigation();
  //const route = useRoute();
  const {dismiss} = useBottomSheetModal();
  const snapPoints = useMemo(() => {
    return points || [lastPoint || '70%'];
  }, [points ,lastPoint]);

  const [currIndex, setCurrIndex] = useState(0);
  const [closer, makeClose] = useState();
  const bottomSheets = useBottomSheetModal();

  useEffect(()=>{
    if (_appEvent?.name === 'dismissAllBottomSheets'){
      bottomSheets.dismissAll();
    }
  }, [_appEvent, bottomSheets]);

  useEffect(
    () =>
      navigation.addListener('state', (e) => {
        __DEV__ && console.log('fire event change state NAVIGATION', currIndex);
        if (Platform.OS !== 'web') {
          /** TODO: should handle navstate */
          return;
        }
        let currentState = e?.data?.state;
        let ind = currentState?.index || 0;
        if (currentState) {
          let params;
          if (!itemNavParam) {
            params = {name:'details', value:'1'};
          } else {
            params = itemNavParam;
          }
          let currentParams = currentState.routes[ind].params;
          //console.log(currentParams);
          if (currIndex > -1 && currentParams && !currentParams[params.name]) {
            makeClose(1);
          }
        }
      }),
    [navigation, currIndex, itemNavParam]
  );

  useEffect(()=>{
    if (typeof closer !== 'undefined'){
      dismiss();
      makeClose(undefined);
    }
  },[closer]);

  const handleSheetChanges = useCallback((index) => {
    setCurrIndex(index);
    onChange && onChange(index);
    __DEV__ && console.log('current sheet index is', index);
    let params;
    if (!itemNavParam) {
      params = {name:'details', value:'1'};
    } else {
      params = itemNavParam;
    }

    //let currentNav = navigation.getState();
    //let currentRoute = currentNav.routes[currentNav.index];
    if (Platform.OS === 'web') {
      let url = new URL(window.location);
      if (index === snapPoints.length - 1) {
          let param = url.searchParams.get(params.name);
          if (!param){
            url.searchParams.set(params.name, params.value);
            window.history.pushState({}, '', url);
          }
      }
      if (index === -1) {
        //navigation.setParams({[params.name]: undefined});
        url.searchParams.delete(params.name);
        window.history.pushState({}, '', url);
      }
    } else {
      if (index === snapPoints.length - 1) {
        navigation.setParams({[params.name]:params.value});
      }
      if (index === -1) {
        setTimeout(() => {
          navigation.setParams({[params.name]: undefined});
        }, 100);
      }
    }
  }, [snapPoints, itemNavParam]);

  const handleAnimate = useCallback((fromIndex, toIndex) => {
    /*if (closeOnPanout !== false && (toIndex === 0 || toIndex === -1)) {
      bottomSheetRef.current?.dismiss();
      console.log('DISMISS BY CHANGE, index = ',toIndex);
    }*/
    onAnimate && onAnimate(fromIndex,toIndex);
    if (fromIndex > toIndex){onClose && onClose();}
    if (fromIndex < toIndex){onOpen && onOpen();}
    /*if (Platform.OS === 'web'){
      //WEB BACK-CLOSER SUPPORT
      const url = new URL(window.location);
      if (toIndex === -1) {
        //url.searchParams.delete('details');
        //window.history.replaceState({}, '', url);
        //window.history.back();
      }
      if (toIndex === snapPoints.length - 1) {
        let details = url.searchParams.get('details');
        if (!details){
          url.searchParams.set('details', '1');
          window.history.pushState({}, '', url);
        }
      }
    } else {
      //console.log(route.params);
      //navigation.setParams({});
    }*/

    setCurrIndex(toIndex >= 0 ? toIndex : 1);
  }, [closeOnPanout, onAnimate]);

  useImperativeHandle(ref, () => ({
    ...bottomSheetRef.current,
    open: () => {
      bottomSheetRef.current?.present();
    },
    close: () => {
      bottomSheetRef.current?.dismiss();
    },
    getCurrentIndex: () => currIndex,
  }),[bottomSheetRef.current]);

  /*useEffect(() => {
    if (open) {
      bottomSheetRef.current?.present();
    } else {
      bottomSheetRef.current?.dismiss();
    }
  }, [open]);*/

  useEffect(()=>{
    //setCurrIndex(index);
    setCurrIndex(0);
  },[index]);

  return (
    <BottomSheetModal
      ref={bottomSheetRef}
      index={index || 0}
      style={[styles.sheet, style]}
      containerStyle={[styles.contentContainer, containerStyle]}
      snapPoints={snapPoints}
      backdropComponent={props => useBackdrop !== false ? (
        <CustomBackdrop
          onPress={() => {
            disableClose
              ? ref.current?.snapToIndex(0)
              : ref.current?.dismiss();
          }}
          {...props}
        />
      ) : null}
      backgroundComponent={() => <View style={styles.background} />}
      /*handleComponent={() => (
        <CloserArrow
          type={'down'}
          color={'#626262'}
          onPress={() => {
            disableClose
              ? ref.current?.snapToIndex(0)
              : ref.current?.dismiss();
          }}
        />
      )}*/
      handleComponent={() =>
        <TouchableOpacity
          onPress={() => {
            disableClose
              ? ref.current?.snapToIndex(0)
              : ref.current?.dismiss();
          }}
          style={{alignSelf: 'center'}}
        >
          <View style={{
              width: 50,
              height: 6,
              borderRadius: 3,
              backgroundColor: handleColor || '#f5f5f5',
              marginTop: 9,
              marginBottom: Platform.OS === 'web' ? 9 : 0,
            }}></View>
        </TouchableOpacity>
      }
      handleStyle={{backgroundColor: 'transparent'}}
      handleIndicatorStyle={{backgroundColor: 'black'}}
      onChange={handleSheetChanges}
      enableContentPanningGesture={Platform.OS !== 'web' && panningGesture !== false}
      keyboardBehavior="interactive"
      keyboardBlurBehavior="restore"
      enablePanDownToClose={panDownToClose !== false}
      onAnimate={handleAnimate}
      enableDismissOnClose={true}
      //enableDismissOnClose={disableClose !== true}
      overDragResistanceFactor={1.5}
      detached={detached || Platform.OS === 'web'} //needed for correctly working keyboard indents
      footerComponent={footerComponent}
    >
      <View style={styles.content}>
        {children}
      </View>
    </BottomSheetModal>
  );
});

const SimpleBottomSheetFwRefMemo = memo(
  SimpleBottomSheetFwRef,
(prevProps, nextProps) => {
  let eq = prevProps === nextProps;
  /*let eq = objectsEqual(prevProps,nextProps, [
    'open',
    'points',
    'lastPoint',
    'index',
    'disableClose',
    'itemNavParam',
  ]);*/

  if (!eq) {
    //console.log('redraw card ' + nextProps.title);
  }
  return eq;
},);

export default SimpleBottomSheetFwRefMemo;

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    ...(Platform.OS === 'web' ? {paddingBottom: 0} : {}),
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: 'rgba(255,255,255,0)',
  },
  closeLineContainer: {
    alignSelf: 'center',
  },
  closeLine: {
    width: 40,
    height: 6,
    borderRadius: 3,
    backgroundColor: 'white',
    marginTop: 9,
  },
  content: {
    //height: '100%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    height: Platform.OS === 'web' ? 'inherit' : '100%',
    ...defaultStyles.buttonShadow,
    ...Platform.select({
      android: {
        shadowOffset: {height: -4, width: 0},
        shadowRadius: 6,
        marginTop: 10,
      },
      ios: {
        shadowOffset: {height: -4, width: 0},
        shadowRadius: 6,
        marginTop: 10,
      },
      web: {
        shadowOffset: {height: -7, width: 0},
        shadowRadius: 6,
      },
    }),
    backgroundColor: 'white',
  },

  sheet: {
    ...(Platform.OS === 'web' ?
      {
      maxWidth:500,
      margin: 'auto',
    } : {}),
  },
});
