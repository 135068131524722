export const buttonsNames = {
  buy: 'Купить',
  reserve: 'Забронировать',
  going: 'Я пойду',
  partner: 'Найти напарника',
  command: 'Собрать команду',
  default: 'Забронировать',
  free_reserve: 'Бесплатное бронирование',
  order: 'Заказать',
  request: 'Оставить заявку',
  submit_your_application: 'Оставить заявку',
};
