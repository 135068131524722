import React, {useEffect, useState} from 'react';
import {api} from '../../System/api';
import {ActivityIndicator, View, StyleSheet} from 'react-native';
import WebViewDoc from '../WebViewDoc';
import {useRoute} from '@react-navigation/native';



const HtmlDocument = ({id, useTitle}) => {

  const [doc, setDoc] = useState(null);

  useEffect(()=>{
    loadData();
  }, [id]);

  const loadData = () => {
    api.docs.get({id}).then(res=>{
      console.log(res);
      setDoc(res.data);
    });
  };


  return (!doc
    ? <View style={styles.loader}>
        <ActivityIndicator style={'medium'} />
      </View>
    : <WebViewDoc
        title={useTitle !== false ? doc.title : null}
        doc={doc.html}
      />
  );
};

export const HtmlDocumentScreen = () => {
  const route = useRoute();
  const {id} = route?.params;
  return <HtmlDocument id={id}/>;
};

const styles = StyleSheet.create({
  loader: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default HtmlDocument;
