import {api} from '../System/api';
import outletAdapter from '../Outlet/OutletAdapter';
import attributesAdapter from '../Attributes/AttributesAdapter';
import {isObject} from '../System/helpers';
import settings from '../Defaults/settings';

const defaultImage = require('../../img/mr_view_blur.jpg');

const offerAdapter = ({
  id,
  title,
  preview,
  outlet,
  category,
  interest,
  action,
  description,
  mainImage,
  offerImages,
  video,
  timeRange,
  offerValidPeriods,
  schedule,
  inWishlist,
  price,
  priceFrom,
  searchInterest,
  offerItems,
  bookingOfferItems,
  isUseSlots,
  booking,
  points,
  offerSubTypes,
  offerTypeSubTypes,
  offerType,
  discount,
  duration,
  attributeValues,
  successText,
  timeCloseSale,
  ...other
}) => {
  interest = !Array.isArray(interest) ? [interest] : interest;

  let interestIndex = Math.max(
    (interest).findIndex(v => v?.id + '' === searchInterest + ''),
    0,
  );

  outlet = outlet?.id ? outletAdapter(outlet) : {};
  let attributes = attributeValues ? attributesAdapter(attributeValues) : [];

  let outletSchedule = outlet?.schedule;
  try {
    schedule = (typeof schedule === 'object' ? schedule : JSON.parse(schedule));
  } catch (e) {schedule = {};}
  if (!schedule || Object.keys(schedule) === 0){
    schedule = outletSchedule;
  }

  if (category){
    category = Array.isArray(category) ? category[0] : category;
  } else {
    category = null;
  }

  offerSubTypes = isObject(offerSubTypes) ? Object.values(offerSubTypes) : offerSubTypes;


  let offer = {
    id,
    title,
    timeRange: timeRange || [],
    workTime: other.openHours,
    thumb: preview
      ? {
          uri: api.baseurl + preview,
        }
      : defaultImage,
    ...generateImagesUri(mainImage),
    video: video && /^\/uploads\/offer\/.+?\.\w+?$/.test(video)
        ? ({uri: api.baseurl + video, video: true})
        : null,
    description: description,
    gallery: offerImages ? offerImages.map(v =>
      ({
        uri: api.baseurl + v.path,
      })) : [],
    volume: null,
    interests: interest ? interest.map(v => v?.id) : [],
    interest:
      interest && interest[interestIndex]
        ? {
            id: interest[interestIndex].id,
            title: interest[interestIndex].name,
            prior: interest[interestIndex].prior,
        }
        : {},
    category: category ? {...category, id: category.id, title: category.name} : {},
    outlet: outlet ? {...outlet, id: outlet.id, title: outlet.name} : {},
    tags: [
      ...(interest && interest[interestIndex]
        ? [
            {
              ...interest[interestIndex],
              id: interest[interestIndex].id,
              title: interest[interestIndex].name,
              type: 'interest',
            },
          ]
        : []),
      ...(category
        ? [{id: category.id, title: category.name, type: 'category'}]
        : []),
      ...(outlet ? [{id: outlet.id, title: outlet.name, type: 'outlet'}] : []),
    ],
    data: {
      id,
      ...other,
    },
    //autoplay: false,
    actionButton: action,
    showActionButton: true,
    validPeriods: offerValidPeriods,
    schedule,
    bookmarked: !!inWishlist,
    price:
      price || priceFrom
        ? {from: !!priceFrom, price: priceFrom || price}
        : undefined,
    searchInterest,
    prior: other.prior,
    tariffs: offerItems,
    bookedTariffs: bookingOfferItems,
    isUseSlots,
    booking,
    points,
    subTypes: (offerTypeSubTypes || offerSubTypes || []).map(i=>i.id),
    type: offerType?.id,
    discount,
    duration,
    attributes,
    useCurrentDateTime: id === (api.baseurl.indexOf('test') >= 0 ? settings.clubCarOffer.dev : settings.clubCarOffer.prod),
    successBookingPopupText:
      [settings.clubCarOffer.prod, settings.clubCarOffer.dev]
        .includes(id)
        ? 'Спасибо за заявку\nТранспорт прибудет в течение 15 мин.'
        : successText,
    timeCloseSale: timeCloseSale || 0,
  };
  return offer;
};

const generateImagesUri = (mainImage) => {
  let mainUri = mainImage && /^\/uploads\/offer\/.+?\.\w+?$/.test(mainImage)
    ? api.baseurl + mainImage.trim('/') : '';
  let uri = mainUri.replace(/(.*?)(\.[^.]*?$)/,'$1');
  let extension = mainUri.replace(/(.*?)(\.[^.]*?$)/,'$2');
  let images = {
    mainImage: mainUri ? {uri: mainUri} : defaultImage,
    mainImageMd: mainUri ? {uri: uri + '.med' + extension} : defaultImage,
    mainImageSm: mainUri ? {uri: uri + '.min' + extension} : defaultImage,
  };
  if (!settings?.enableUseCompressedImages){
    images.mainImageMd = images.mainImage;
    images.mainImageSm = images.mainImage;
  }
  return images;
};

export default offerAdapter;
