import React, {useState} from 'react';
import {SafeAreaView, View, StyleSheet, ScrollView, KeyboardAvoidingView, Platform} from 'react-native';
import PageHeader, {useGoBack} from '../Basic/PageHeader';
import AppInput from '../Basic/AppInput';
import LoadingContainer from '../Basic/LoadingContainer';
import NewButton from '../Basic/NewButton';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {defaultStyles} from '../Defaults/Styles';
import {useSelector} from 'react-redux';
import * as _profile from '../GlobalStore/reducers/userProfileSlice';
import {api} from '../System/api';
import {useToast} from 'react-native-toast-notifications';
import AntiMenuFade from '../Basic/AntiMenuFade';

const Support = () => {

  const [text, setText] = useState('');
  const [locked, setLocked] = useState(false);
  const goBack = useGoBack();

  const insets = useSafeAreaInsets();
  const toast = useToast();
  const profile = useSelector(_profile.selectValue);

  const handleSend = () => {
    if (text.length < 5) {return}
    setLocked(true);
    api.feedback.support({
      senderName: profile.name,
      //user: 1,
      text,
      //offer: 1,
    }).then((res)=>{
      toast.show('Ваш запрос успешно отправлен, пожалуйста, ожидайте ответа', {duration: 10000});
      setLocked(false);
      goBack();
    }).catch(e=>{
      toast.show('Не удалось отправить сообщение, произошла ошибка. Попробуйте снова немного позднее', {duration: 10000});
      setLocked(false);
      __DEV__ ?? console.warn(e);
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <PageHeader title={'Обратная связь'}/>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.formBox}
      >
        <View style={styles.messageBlock}>
          <View style={styles.messageInputBox}>
            <AppInput
              label={'Опишите, пожалуйста, Вашу проблему:'}
              defaultValue={text}
              onChange={(txt)=>{
                setText(txt);
              }}
              multiline={true}
              numberOfLines={30}
              style={styles.messageInput}
              returnKeyType={'enter'}
              autoFocus={true}
            />
          </View>
          <View style={[styles.sendButtonBlock, {paddingBottom: insets.bottom}]}>
            <LoadingContainer loading={locked} >
              <NewButton
                style={styles.sendButton}
                children={'Отправить'}
                onPress={() => handleSend()}
              />
            </LoadingContainer>
          </View>
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default Support;


const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex:1,
  },
  formBox: {flex:1},

  //send button
  sendButtonBlock: {
    flex:1,
    paddingTop: 10,
    backgroundColor: 'rgba(255,255,255,0.9)',
    //paddingBottom: 15,

    width: '100%',
    //minHeight: 10,
    flexDirection: 'column',
  },
  sendButton: {
    width: '50%',
  },


  //message
  messageBlock: {
    flex: 1,
    padding: 14,
  },
  messageInputBox: {
    flex: 8,
  },
  messageInput: {
    height: '85%',
    maxHeight: null,
  },
});
