import {CommonActions} from '@react-navigation/native';
import {isObject} from './helpers';

export const insertBeforeLast = (routeName, params) => (state) => {
  let prev_routes = state.routes.slice(0, -1);
  //console.log('CHECK ROUTES: ', prev_routes[prev_routes.length - 1], routeName);
  if (prev_routes[prev_routes.length - 1]?.name === routeName){
    return null;
  }
  const routes = [
    ...prev_routes,
    { name: routeName, params },
    state.routes[state.routes.length - 1],
  ];

  return CommonActions.reset({
    ...state,
    routes,
    index: routes.length - 1,
  });
};

export const push = (routes) => (state) => {
  return CommonActions.reset({
    ...state,
    routes: [
      ...state.routes,
      ...routes,
    ],
    index: state.routes.length + routes.length - 1,
  });
};

export const stateToRouteChain = (state, lastLevelParams = {}) => {
  let routeChain = {};
  let route = state?.routes[state?.index || 0];
  console.log(state, route);
  routeChain = {
    screen: route.name,
    params: route?.params || [],
  };
  if (route?.params?.state) {
    delete routeChain.params.state;
    routeChain.params = {
      ...stateToRouteChain(route.params.state),
    };
  } else {
    //add or replace params of last level
    routeChain.params = {
      ...routeChain.params,
      ...lastLevelParams,
    };
  }
  return routeChain;
};

export const webUrlGetAllParams = (url) => {
  let params = {};
  for (let [key, value] of (new URL(url).searchParams.entries())){
    params[key] = value;
  }
  return params;
};

