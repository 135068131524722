import React, {useContext, useEffect, memo} from 'react';
import {View, StyleSheet, ScrollView, TouchableHighlight} from 'react-native';
import {Icon} from '@rneui/themed';
import AppText from '../Basic/AppText';

import {colors} from '../Defaults/Styles';
import {useSelector} from 'react-redux';

const Activity = memo(({
                    id,
                    time,
                    date,
                    title,
                    description,
                    state,
                    category,
                    categories,
                    price,
                    type,
                    place,
                    showTime,
                    onPress,
                    ...other
                  }) => {

  const defaultColors = useSelector((state) => state.style.defaultColors);

  const color = category?.darkColor || defaultColors.main;
  const catTitle = category?.title || '';

  useEffect(()=>{
    //console.log({other});
  },[]);

  const handlePress = () => {
    onPress && onPress({id,date,time});
  };

  return (
    <TouchableHighlight
      key={'activity_' + id}
      style={styles.container}
      underlayColor={'rgba(222,222,222,0.8)'}
      activeOpacity={0.8}
      onPress={handlePress}
    >
      <View style={styles.box}>
        <View style={styles.timeContainer}>
          {showTime !== false ?
            <>
              <AppText style={[styles.time]}>
                {time}
              </AppText>
              {/*<AppText style={[styles.title, {color}]}>{catTitle}</AppText>*/}
            </>
            : null}
        </View>
        <View style={styles.titleBox}>
          <AppText style={styles.text}>
            <AppText style={styles.payment}>
              {price ? <Icon style={{marginRight:5, marginLeft: 2}} color={defaultColors.main} size={14} name={'ruble-sign'} type={'font-awesome-5'}/> : null}
            </AppText>
            {title}
          </AppText>
          <View style={styles.type}>
            <Icon
              style={styles.typeIcon}
              color={styles.typeText?.color}
              //name={'location-on'}
              type={'material'}
              name={'location-on'}
              //type={'octicon'}
              size={15}
            />
            <AppText style={styles.typeText}>{place}</AppText>
          </View>
          <ActivityCategories categories={categories}/>
        </View>
      </View>
    </TouchableHighlight>
  );
},(p, n)=>{
  return p.id + p.time + p.date === n.id + n.time + n.date;
});

const ActivityCategories = ({categories}) => {
  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      style={styles.categoryList}
    >
      {categories.map(cat => (
          <View
            key={cat?.id}
            style={styles.categoryBox}
          >
            <View style={[styles.categoryColor, {backgroundColor: cat.color || colors.default.main}]}/>
            <AppText style={styles.categoryText}>{cat.title}</AppText>
          </View>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: '#F6F6F6',
  },
  box: {
    //flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    //justifyContent: 'space-evenly',
    padding: 10,
  },
  timeContainer: {
    flex: 0.15,
  },
  time: {
    fontSize: 18,
    fontWeight: '500',
    //lineHeight: 20,
  },
  titleBox: {
    flex: 0.7,
  },
  title:{
    fontSize: 12,
    lineHeight: 13,
  },
  payment: {
    //height: 20,
    //textAlign: 'center',
    //justifyContent: 'center',
    paddingRight: 4,
  },
  text: {
    fontWeight: '300',
    fontSize: 17,
    marginBottom: 4,
  },
  type: {
    flexDirection: 'row',
    //justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 6,
  },
  typeIcon: {
    paddingRight: 2,
  },
  typeText: {
    fontSize: 13,
    flex:1,
    color: '#717171',
  },

  //categories
  categoryList: {
    width: '100%',
  },
  categoryBox: {
    backgroundColor: '#fff',
    padding: 4,
    paddingHorizontal: 10,
    borderRadius: 100,
    borderColor: 'rgba(232, 232, 232, 1)',
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 4,
  },
  categoryColor: {
    borderRadius: 100,
    width: 6,
    height: 6,
    marginRight: 5,
  },
  categoryText: {
    fontSize: 12,
  },
});

export default Activity;
