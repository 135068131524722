import {createDataStoreSlice} from '../../System/DataStoreRedux';
import {userProfileStoreParams} from '../../User/StoreParams';

const userProfileSlice = createDataStoreSlice(
  userProfileStoreParams
);

export const {resetValue, setValue} = userProfileSlice.storeSlice.actions;
export const {getValue, reloadValue, init, select, selectState, selectValue} = userProfileSlice;


export default userProfileSlice.storeSlice.reducer;
