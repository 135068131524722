import React from 'react';
import {ActivityIndicator, StyleSheet} from 'react-native';
import AntiMenuFade from './AntiMenuFade';

const ListFooter = ({loading, size, antiFadeHeight, style}) => (
  <AntiMenuFade height={antiFadeHeight || 200} style={[styles.default, style]}>
    {loading ? (
      <ActivityIndicator size={size || 'large'} />
    ) : null}
  </AntiMenuFade>
);

const styles = StyleSheet.create({
  default: {
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export default ListFooter;
