import {
  Image,
  TextInput,
  TouchableOpacity,
  Platform, View,
} from 'react-native';
import AppText from '../Basic/AppText';
import {Icon} from '@rneui/themed';
import AppButton from './AppButton';
import React from 'react';
import PhoneInput from './PhoneInput';

const PhoneOverlay = ({
  onPressClose,
  onPressSend,
  phoneNumber,
  onChangePhoneNumber,
  name,
  onChangeName,
}) => {
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.75)',
        justifyContent: 'center',
      }}
      onPress={() => {
        Platform.OS !== 'web' && onPressClose();
      }}>
      <View
        style={{
          paddingTop: 50,
          //flex: 0.2,
          marginHorizontal: 20,
          backgroundColor: 'white',
          alignItems: 'center',
          borderRadius: 20,
        }}
      >
        <TouchableOpacity
          style={{position: 'absolute', right: 20, top: 20}}
          onPress={onPressClose}>
          <Icon name={'close'} size={20} color={'rgba(128, 148, 156, 0.5)'} />
        </TouchableOpacity>
        <Image
          style={{width: 40, height: 40}}
          source={require('./../../img/png_icons/CallLaterSilver.png')}
        />
        <AppText
          style={{
            marginVertical: 20,
            marginHorizontal: 50,

            fontSize: 18,
            fontWeight: '500',
            textAlign: 'center',
          }}>
          {'Пожалуйста, введите Ваши \n имя и контактный номер телефона'}
        </AppText>
        <TextInput
          style={{

            fontWeight: 'normal',
            letterSpacing: 4,
            height: 46,
            width: '85%',
            backgroundColor: 'rgba(128, 148, 156, 0.2)',
            paddingHorizontal: 15,
            borderRadius: 10,
            marginBottom: 10,
          }}
          placeholder={'Имя'}
          value={name}
          onChangeText={onChangeName}
        />
        <PhoneInput
          //placeholder={'Телефон'}
          style={{
            fontWeight: 'normal',
            letterSpacing: 4,
            height: 46,
            width: '85%',
            backgroundColor: 'rgba(128, 148, 156, 0.2)',
            paddingHorizontal: 15,
            borderRadius: 10,
          }}
          value={phoneNumber}
          onChange={(v)=>onChangePhoneNumber(v.unmasked)}
        />
        <AppButton
          rootStyle={{width: '85%', marginVertical: 25}}
          style={{width: '100%'}}
          title={'отправить'}
          onPress={onPressSend}
        />
      </View>
    </TouchableOpacity>
  );
};

export default PhoneOverlay;
