import React, {useContext, useEffect, useState} from 'react';
import {
  //ImageBackground,
  SafeAreaView,
  TouchableOpacity,
  View,
  Platform,
  StyleSheet,
} from 'react-native';
import {Icon} from '@rneui/themed';
import HeaderAvatar from '../User/HeaderAvatar';
//import HeaderBell from '../Notifications/HeaderBell';
import {useNavigation} from '@react-navigation/native';
import LoginButton from './LoginButton';
import AppText from '../Basic/AppText';

import * as auth from '../GlobalStore/reducers/authSlice';
import {useSelector} from 'react-redux';
import {Skeleton, SkeletonContainer} from 'react-native-skeleton-component';

//import {getMinWidth} from './BaseDisplayWidth';


const titleSize = ({length, max, min}) =>
  length > 29
    ? min || 11
    : length < 10
    ? max || 18
    : 22.4073 - 0.392683 * length;

const HeaderTitle = ({loading, title, children, color, style}) => {
  if (loading){
    return <SkeletonContainer animation={'wave'}>
      <Skeleton
        style={[styles.titleLoading]}
        //imageStyle={styles.storyImg}
      />
    </SkeletonContainer>;
  }
  if (title) {
    return (
      <View style={styles.title}>
        <AppText
          style={[
            styles.titleText,
            {
              fontSize: titleSize({length: title?.length}),
              color: color || 'black',
            },
            style,
          ]}>
          {title}
        </AppText>
      </View>
    );
  } else {
    return (<View style={styles.title}>{children}</View>);
  }
};

const PageHeader = ({
  title,
  titleLoading,
  titleColor,
  titleStyle,
  useBack,
  useAvatar,
  useRing,
  children,
  useExit,
  useLogout,
  onPressAdjust,
  style,
}) => {
  const goBack = useGoBack();
  const [isGuest, setIsGuest] = useState(false);
  const authState = useSelector(auth.selectState);

  useEffect(() => {
    setIsGuest(authState !== 'auth');
  }, [authState]);

  return (
    <>
      <SafeAreaView style={[
        styles.container,
        style,
      ]}>
      <View style={[styles.headerBox]}>
        <View style={styles.goBack}>
          {!useExit && useBack !== false ? (
            <TouchableOpacity
              onPress={()=>goBack()}>
              <Icon type={'simple-line-icon'} name={'arrow-left'} size={20} />
            </TouchableOpacity>
          ) : null}
        </View>
        <HeaderTitle
          title={title}
          color={titleColor}
          style={titleStyle}
          loading={titleLoading}
        >
          {children}
        </HeaderTitle>
        <View
          style={styles.rightButtons}>
          {onPressAdjust ? <HeaderAdjust onPress={onPressAdjust} /> : null}
          {useLogout ? <HeaderLogout /> : null}
          {/*{useRing ? <HeaderBell /> : null}*/}
          {useAvatar ? isGuest ? <LoginButton /> : <HeaderAvatar /> : null}
          {useExit ? <HeaderExit /> : null}
        </View>
      </View>
    </SafeAreaView>
    </>
  );
};

const HeaderLogout = props => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={{
        height: 40,
        marginRight: 15,
        paddingTop: 3,
        justifyContent: 'center',
      }}
      onPress={() => {
        navigation.push('Logout');
      }}>
      <Icon
        name={'ios-exit-outline'}
        type={'ionicon'}
        size={30}
        color={'rgba(128,148,156,0.9)'}
      />
    </TouchableOpacity>
  );
};

const HeaderExit = props => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.goBack();
      }}
      style={{
        width: 40,
        height: 40,
        borderRadius: 100,
        borderWidth: 1,
        borderColor: 'rgba(128, 148, 156, 1)',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Icon type={'feather'} name={'x'} size={20} />
    </TouchableOpacity>
  );
};

const HeaderAdjust = ({onPress}) => {
  return <TouchableOpacity onPress={onPress} style={styles.adjustBox}>
    <Icon type={'simple-line-icon'} name={'equalizer'} size={20} />
  </TouchableOpacity>;
};

export const useGoBack = () => {
  const navigation = useNavigation();
  const goBack = () => {
    if (navigation.canGoBack()){
      navigation.goBack();
    } else {
      if (Platform.OS === 'web'){
        if (/\/concierge/.test(location.href)){
          history.pushState({},'','/concierge');
        } else {
          history.pushState({},'','/');
        }
        location.reload();
      } else {
        navigation.navigate({name: 'Main'});
      }
    }
  };

  return goBack;
};

const styles = StyleSheet.create({
  goBack: {
    //alignSelf: 'flex-start',
    flex: 3,
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  title: {
    flex: 4,
    minWidth: 180,
    alignSelf: 'center',
  },
  titleText: {
    textAlign: 'center',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  rightButtons: {
    flex: 3,
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  adjustBox: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
  },
  headerBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 48,
    paddingHorizontal: 19,
    borderBottomWidth: 1,
    borderBottomColor: 'rgb(240,240,240)',
  },

  //title
  titleLoading: {
    height: 20,
    width: 100,
    borderRadius: 10,
  },
});

export default PageHeader;
