import React, {useContext, useEffect, memo} from 'react';
import {View, StyleSheet, ImageBackground} from 'react-native';
import {Icon} from '@rneui/themed';
import AppText from '../Basic/AppText';
import {ScrollView, TouchableOpacity} from 'react-native-gesture-handler';

import {colors} from '../Defaults/Styles';
import LinearGradient from 'react-native-linear-gradient';
import {useSelector} from 'react-redux';

const Activity = memo(({
                         id,
                         time,
                         date,
                         title,
                         description,
                         state,
                         category,
                         categories,
                         price,
                         type,
                         place,
                         showTime,
                         onPress,
                         preview,
                         ...other
                       }) => {

  const defaultColors = useSelector((state) => state.style.defaultColors);

  useEffect(()=>{
    //console.log({other});
  },[]);

  const handlePress = () => {
    onPress && onPress({id,date,time});
  };

  return (
    <TouchableOpacity
      key={'activity_' + id}
      style={styles.container}
      activeOpacity={0.8}
      onPress={handlePress}
    >
      <ImageBackground
        style={styles.image}
        imageStyle={styles.imageContent}
        source={preview}
      >
        <LinearGradient
          style={styles.topGradient}
          colors={['rgba(40,40,40,0.85)','rgba(0,0,0,0)']}
        />
        <View style={styles.box}>
          <View style={styles.timeContainer}>
            {showTime !== false ?
              <>
                <AppText style={[styles.time]}>
                  {time}
                </AppText>
                {/*<AppText style={[styles.title, {color}]}>{catTitle}</AppText>*/}
              </>
              : null}
          </View>
          <View style={styles.titleBox}>
            <View>
              <AppText style={styles.text}>
                <AppText style={styles.payment}>
                  {price ? <Icon style={{marginRight:5, marginLeft: 2}} color={defaultColors.main} size={14} name={'ruble-sign'} type={'font-awesome-5'}/> : null}
                </AppText>
                {title}
              </AppText>
              <View style={styles.type}>
              <Icon
                style={styles.typeIcon}
                color={styles.typeText?.color || '#fff'}
                //name={'location-on'}
                type={'material'}
                name={'location-on'}
                //type={'octicon'}
                size={15}
              />
              <AppText style={styles.typeText}>{place}</AppText>
            </View>
            </View>
            <View style={styles.categoriesBox}>
              <ActivityCategories categories={categories}/>
            </View>
          </View>
          <View>
            <Icon name={'star'} color={defaultColors.main} size={17}/>
          </View>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
},(p, n)=>{
  return p.id + p.time + p.date === n.id + n.time + n.date;
});

const ActivityCategories = ({categories}) => {
  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      style={styles.categoryList}
    >
      {categories.map(cat => (
        <View
          key={cat?.id}
          style={styles.categoryBox}
        >
          <View style={[styles.categoryColor, {backgroundColor: cat.color || colors.default.main}]}/>
          <AppText style={styles.categoryText}>{cat.title}</AppText>
        </View>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: '#F6F6F6',
  },
  box: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    //justifyContent: 'space-evenly',
    padding: 10,
  },
  topGradient: {
    height: 100,
    marginBottom: -100,
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
  },
  timeContainer: {
    width:'15%',
  },
  time: {
    fontSize: 18,
    fontWeight: '500',
    //lineHeight: 20,
    color:'#fff',
  },
  titleBox: {
    flex:1,
    height: '100%',
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleTypeBox: {
    flexDirection: 'column',
  },
  title: {
    fontSize: 12,
    lineHeight: 13,
  },
  image: {
    height: 120,
    resizeMode: 'stretch',
    marginVertical: 12,
    marginHorizontal: 10,
  },
  imageContent: {
    borderRadius: 8,
  },
  payment: {
    //height: 20,
    //textAlign: 'center',
    //justifyContent: 'center',
    paddingRight: 4,
  },
  text: {
    fontWeight: '300',
    fontSize: 17,
    marginBottom: 4,
    color: '#ffffff',
  },
  type: {
    flexDirection: 'row',
    //justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 6,
  },
  typeIcon: {
    paddingRight: 2,
  },
  typeText: {
    fontSize: 13,
    flex:1,
    color: '#fff',
  },
  categoriesBox: {
    //flex: 1,
    alignItems: 'flex-end',
  },

  //categories
  categoryList: {
    width: '100%',
    alignSelf: 'flex-end',
  },
  categoryBox: {
    backgroundColor: '#fff',
    padding: 4,
    paddingHorizontal: 10,
    borderRadius: 100,
    borderColor: 'rgba(232, 232, 232, 1)',
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 4,
    maxHeight: 30,
  },
  categoryColor: {
    borderRadius: 100,
    width: 6,
    height: 6,
    marginRight: 5,
  },
  categoryText: {
    fontSize: 12,
  },
});

export default Activity;
