import {Platform, TouchableOpacity, View} from 'react-native';
import {Icon} from '@rneui/themed';
import React from 'react';
import AppText from '../Basic/AppText';

const PromiseOverlay = ({onPressClose, text, children}) => {
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.75)',
        justifyContent: 'center',
      }}
      onPress={() => {
        Platform.OS !== 'web' && onPressClose();
      }}>
      <View
        style={{
          paddingTop: 50,
          //flex: 0.2,
          marginHorizontal: 20,
          backgroundColor: 'white',
          alignItems: 'center',
          borderRadius: 20,
        }}>
        <TouchableOpacity
          style={{position: 'absolute', right: 20, top: 20}}
          onPress={onPressClose}>
          <Icon name={'close'} size={20} color={'rgba(128, 148, 156, 0.5)'} />
        </TouchableOpacity>
        {children || (
          <AppText
            style={{
              marginVertical: 20,
              marginHorizontal: 50,

              fontSize: 18,
              fontWeight: '500',
              textAlign: 'center',
            }}>
            text
          </AppText>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default PromiseOverlay;
