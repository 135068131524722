import React from 'react';
import {View, StyleSheet, ActivityIndicator} from 'react-native';

const LoadingContainer = ({children, loading, style, size, progress}) => {
  return (
    <View style={[styles.container, style]}>
      {children}
      {loading ?
        <View style={styles.overlay}>
          <ActivityIndicator
            style={[
              styles.activityIndicator,
              progress ? {justifyContent: 'flex-end'} : {},
            ]}
            size={size || 'small'}/>
          {progress}
        </View>
        : null}
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.76)',
  },
  container: {
    //alignItems:'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  activityIndicator: {
    flex:1, //height: '100%',
  },
});

export default LoadingContainer;
