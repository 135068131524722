import React, {useContext} from 'react';
import {SafeAreaView} from 'react-native';
import PageHeader from '../Basic/PageHeader';
import {WebView} from 'react-native-webview';
import {api} from '../System/api';
import {
  DimensionsContext,
} from '../Defaults/DisplayWidth';
import {useNavigation} from '@react-navigation/native';

const WebViewMessageTest = () => {
  const navigation = useNavigation();
  const url = (__DEV__ ? 'http://127.0.0.1:9000/' : api.baseurl) + 'payment_status.html';
  console.log('URL TO NAVIGATE', url);
  let {width} = useContext(DimensionsContext);

  const messageHandler = (event) => {
    let message = event.nativeEvent.data;
    try {
      const {type, command} = JSON.parse(message);
      console.log('MESSAGE:', message);
      if (type === 'payment_frame_cmd'){
        switch (command){
          case 'payment_fail': {
            break;
          }
          case 'payment_success': {
            console.log('payment is success');
            break;
          }
          case 'goback' : {
            navigation.goBack();
          }
        }
      }
    } catch (e) {
      __DEV__ && console.info('message format is invalid: ',message);
    }
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <PageHeader title={'Оплата заказа тест сообщения'} />
      <WebView
        style={{flex: 1}}
        cacheEnabled={false}
        originWhitelist={['*']}
        source={{uri: url}}
        javaScriptEnabled={true}
        //injectedJavaScriptBeforeContentLoaded={}
        onMessage={messageHandler}
        scalesPageToFit={true}
      />
    </SafeAreaView>
  );
};

export default WebViewMessageTest;
