import React,{createContext, useContext} from 'react';
//import {useToast} from 'react-native-toast-notifications';
import {api} from './api';

import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import {useDispatch} from 'react-redux';

const ApiContext = createContext();
export const ApiProvider = ({children}) => {
  const dispatch = useDispatch();
  const errorHandler = (e, p) => {
    //__DEV__ && console.log('CONTEXT API ERROR', e, p);
    /*toast.show(<TouchableOpacity onPress={() => {
      console.log(requestCode);
    }
    }><AppText>{'повторить запрос'}</AppText></TouchableOpacity>);
    newEvent('apiError', {error:e, requestId});*/
    if ([401].includes(p.responseCode)) {
      dispatch(appEvent.newEvent('unAuthorized'));
      return;
    }
    if (p.useAuth) {

      //console.log('[E-TYPE]', typeof e, typeof e === 'object' ? e : null);
      let errStr = '';
      if (typeof e === 'object'){
        errStr = e?.message || e?.toString();
      } else {
        errStr = e;
      }

      if (
        /Unable to verify the given JWT through the given configuration/
          .test(errStr)
        ||
        errStr === 'token is not found'
      )
      {
        dispatch(appEvent.newEvent('unAuthorized'));
      }
    }
  };

  api.onError = errorHandler;

  const apiMethods = api;

  return <ApiContext.Provider value={apiMethods}>
    {children}
  </ApiContext.Provider>;
};
export const useApiContext = () => useContext(ApiContext);
