import {
  View,
  Modal,
  StyleSheet,
  //Dimensions,
  //ScrollView,
  Platform,
  ActivityIndicator, ScrollView, TextInput,
  //TextInput,
} from 'react-native';
import {
  DateRangeView,
  //Divider,
  PhoneOverlay,
  AuthOverlay,
  //WhatsAppButton,
  //TelegramButton,
  //CallButton,
  //CallLaterButton,
  //CloserArrow,
} from '../Basic/BasicElements';
import MiniOffer from '../Offers/MiniOffer';
import Calendar from '../Calendar/Calendar';
import {BottomSheetScrollView, BottomSheetTextInput, useBottomSheetModal} from '@gorhom/bottom-sheet';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
  forwardRef,
  memo,
} from 'react';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Moscow');

import {api} from '../System/api';

import {getMinWidth} from '../Defaults/DisplayWidth';
//import Picker from '../Basic/Picker';
//import tariff from '../Tariffs/Tariff';
import TariffsView from '../Tariffs/TariffsView';
import AppText from '../Basic/AppText';
import calendarRestrictions from '../Calendar/calendarRestrictions';
import calendarRestrictionAdapter from '../Calendar/calendarRestrictionAdapter';
//import {stringDecoder} from '../Errors/ErrorDecoder';
import {useToast} from 'react-native-toast-notifications';
import AntiMenuFade from '../Basic/AntiMenuFade';
import {defaultStyles} from '../Defaults/Styles';
import {OfferSubtypes} from '../Defaults/Types';
import SimpleBottomSheet from './SimpleBottomSheet';
import NewButton from '../Basic/NewButton';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import TimePickerNew from '../Calendar/TimePickerNew';
import {buttonsNames} from '../Offers/defaults';
import {BookingFormTumbler} from '../Loyalty/BonusPayment';
import LoadingContainer from '../Basic/LoadingContainer';
import settings from '../Defaults/settings';
import {outletHasLoyalty} from '../Loyalty/OfferLoyalty';
import OfferAttributes from '../Attributes/OfferAttributes';
import {metricEvent} from '../Metrics/MetricHelper';

import {useDispatch, useSelector} from 'react-redux';
import * as auth from '../GlobalStore/reducers/authSlice';
import {useNavigation} from '@react-navigation/native';
import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import * as draftBooking from '../GlobalStore/reducers/draftBookingSlice';
import {stateToRouteChain, webUrlGetAllParams} from '../System/navHelpers';
import AppInput from '../Basic/AppInput';


const windowWidth = getMinWidth();
//const windowHeight = Dimensions.get('window').height;


const defaults = {
  minHour: 0,
  maxHour: 23,
};

const maxDaysRender = 365;

/*const RecallPicker = ({onChange}) => {
  const [items, setItems] = useState([{title:'test', id:1}]);
  const [load, setLoad] = useState(true);

  useEffect(()=>{
    setLoad(true);
    api.offers.recall_list().then(res=>{
      let list = [];
      for (let i in res.data.result)
      {
        let v = res.data.result[i];
        //console.log('ITEM',i,v);
        let start, end;
        if (v.timeRange) {
          start = moment(v.timeRange[0],'HH:mm');
          end = moment(v.timeRange[1],'HH:mm');
          //console.log(start,moment(),end,!start.isAfter(moment()));
          /!*if(moment().isAfter(start) && moment().isAfter(end) ){
            continue;
          }*!/
        }
        v.id = i;
        if (!v.title){
          v.title = `с ${start.format('HH:mm')} до ${end.format('HH:mm')}`;
        }
        list.push(v);
      }
      setItems(list);
      setLoad(false);
    });
  },[]);
  return (
    <View>
      <AppText style={styles.calendarLabel}>Выберите удобное время для звонка</AppText>
      {load ?
        <ActivityIndicator /> :
        <Picker onValueChange={onChange} items={items} {...styles} />
      }
    </View>);
};*/

const ScrollViewComponent = Platform.OS === 'web' ? ScrollView : BottomSheetScrollView;

//const TextInputComponent = ['android', 'ios'].includes(Platform.OS) ? AppInput : BottomSheetTextInput;
const TextInputComponent = BottomSheetTextInput;

const commentTitleByAction = (action) => {
  switch (action)
  {
    case 'buy': return 'Комментарий к покупке';
    case 'command': return 'Комментарий команде';
    case 'order': return 'Комментарий к заказу';
    case 'submit_your_application':
    case 'request': return 'Комментарий к заявке';
    case 'free_reserve':
    case 'reserve':
    case 'default':
    case 'book': return 'Комментарий к бронированию';
    default : return 'Комментарий';
  }
};

const BookingFormBottomSheet = (_props, ref) => {
  const {
    onAction,
    offer,
    date,
    time,
    onPressTariffs,
    restrictions,
    updateOffer,
  } = _props;

  const [props, setProps] = useState({});
  useEffect(()=>{
    props && setProps(props);
  }, [props]);

  const toast = useToast();
  const modalRef = useRef();
  const attrRef = useRef();
  const scrollRef = useRef();
  const insets = useSafeAreaInsets();

  const [chosenDates, setChosenDates] = useState([
    moment().format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]);
  const [chosenTime, setChosenTime] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [clientComment, setClientComment] = useState('');
  const [clientRoom, setClientRoom] = useState();
  const [loyaltyBonus, setLoyaltyBonus] = useState(0);
  const [locked, setLocked] = useState(false);
  const [name, setName] = useState('');

  const [restrict, setRestrict] = useState();

  const [minHourState, setMinHourState] = useState(restrict?.minHour || defaults.minHour);
  const [maxHourState, setMaxHourState] = useState(restrict?.maxHour || defaults.maxHour);
  const [currTime, setCurrTime] = useState();
  const [recall, setRecall] = useState();

  const [loadTimeTable, setLoadTimeTable] = useState('loading');
  //loaded, loading, fail

  const [timeRangeRestrictions, setTimeRangeRestrictions] = useState({});
  const [timeKey, setTimeKey] = useState(0);
  const [phoneOverlayShow, setPhoneOverlayShow] = useState(false);
  const [authOverlayShow, setAuthOverlayShow] = useState(false);
  const [bookingAction, setBookingAction] = useState('');
  const [validator, setValidator] = useState();

  const [modalRefresher, setModalRefresher] = useState(1);

  const [isOpen, setIsOpen] = useState(false);

  const navigation = useNavigation();
  const authState = useSelector(auth.selectState);
  const dispatch = useDispatch();


  const handleCalendarSheetChanges = useCallback((index) => {
    if (index === 0) {
      setIsOpen(true);
      metricEvent('booking_form_open');
    }
    if (index < 0 ){
      setIsOpen(false);
    }
  }, [offer]);

  const handleClose = useCallback(()=>{
    api.pending.abort('timetable/');
  }, []);

  const handleOpen = useCallback(() => {
    if (offer?.isUseSlots === true){
      if (moment(currTime,'HH:mm').isBefore(moment().add(-5, 'minutes'))){
        loadCalendarRestrictions(offer);
      } else if (loadTimeTable === 'fail') {
        loadCalendarRestrictions(offer);
      }
    }
    setLoyaltyBonus(0);
  }, [loadTimeTable, offer]);

  useImperativeHandle(ref,() => (
    {
      ...modalRef.current,
      reloadRestrictions: () => {
      __DEV__ &&   console.log('reload offer restrictions');
        loadCalendarRestrictions();
      },
    }
  ), [offer]);

  useEffect(()=>{
    //console.log({offer, isOpen});
    if (isOpen) {
      loadCalendarRestrictions();
    }
  }, [offer, isOpen]);

  const loadCalendarRestrictions = useCallback(() => {
    let _restrictions;

    if (!isOpen) {return;}

    if (offer?.isUseSlots === true) {
      setLoadTimeTable('loading');
      api.offers.getTimeTable({id:offer.id})
        .then((res)=>{
          if (res.data.length === 0) {
            throw Error('TimetableIsEmpty');
          }
        __DEV__ &&   console.log('TIMETABLE', JSON.stringify(res));
          _restrictions = calendarRestrictions({schedule: res.data, strict: true});
          //console.log({_restrictions});
          setRestrict(_restrictions);
          setLoadTimeTable('loaded');
        }).catch((e)=>{
          setLoadTimeTable('fail');
          if (e.name !== 'AbortError' && e.message !== 'TimetableIsEmpty'){
            __DEV__ && console.warn('load time table is fail', e);
            let errorTxt = 'Не удалось узнать свободное время';
            toast.show(errorTxt, {
              title: 'Невозможно загрузить данные',
              type: 'danger',
            });
          }
          setRestrict({
            workDates: {
              /*[moment().format('YYYY-MM-DD')]: {
                timeList: [],
              },*/
            },
          });
        });
    } else {
      //setLoadTimeTable('loading');
      if (!restrictions) {
        //console.log('check default restrictions');
        setRestrict(calendarRestrictions({...offer, strict:true}));
      }
    }
    //console.log('offer changed', offer?.id);
  }, [offer, isOpen]);

  const refreshTimeChoicer = () => {setTimeKey(v=>v + 1);};

  useEffect(() => {
    let restriction,
      currDate = chosenDates[0];
      //console.log('chosenDates:', chosenDates);
      refreshTimeChoicer();
    if (restrict?.workDates && currDate && (restriction = restrict?.workDates[currDate])) {
      setTimeRangeRestrictions(restriction);
    } else {
      setTimeRangeRestrictions({});
    }
  }, [chosenDates, restrict]);

  useEffect(()=>{
    setMaxHourState(restrict?.maxHour);
    setMinHourState(restrict?.minHour);
    //console.log('SET RESTRICTIONS', JSON.stringify(restrict));
    checkPossibleDate();
  }, [restrict]);

  useEffect(()=>{
    __DEV__ && console.log('SET TIME RESTRICTIONS', JSON.stringify(timeRangeRestrictions));
  }, [timeRangeRestrictions]);

  useEffect(()=>{
    setValidator(null);
  }, [clientRoom, offer]);

  const handleAction = async action => {
    __DEV__ && console.log(
      'current nav params',
      stateToRouteChain(navigation.getState()),
      Platform.OS === 'web' ? webUrlGetAllParams(window.location) : null,
    );
    if (!await validate()) {return;}
    setBookingAction(action);
    dispatch(auth.getState())
    .unwrap()
    .then(state => {
      if (state.state !== 'auth') {
        //setPhoneOverlayShow(true);
        setAuthOverlayShow(true);
      } else {
        onAction({
          action: action,
          chosenDates,
          chosenTime,
          recall,
          clientComment,
          clientRoom,
          loyaltyBonus,
          onSuccess:() => {
            setClientComment(null);
            setClientRoom(null);
          },
        });
      }
    });
  };

  const handleChangeBonus = ({useBonus, locked, bonusCount}) => {
    setLoyaltyBonus(useBonus && bonusCount ? bonusCount : 0);
    setLocked(!!locked);
    __DEV__ && console.log({useBonus, locked, bonusCount});
  };

  const handleChangeAttributes = (attrs) => {
    let _attributes = offer?.attributes || {};
    for (let _id in attrs){
      _attributes[_id] = {
        ...offer?.attributes[_id],
        ...attrs[_id],
      };
    }
    setProps(v => ({...v, offer:{...offer, attributes:_attributes}}));
    updateOffer && updateOffer({id:offer.id, attributes:_attributes});
    setValidator(null);
  };

  useEffect(()=>{
    let timeRefresher = setInterval(()=>{
      setCurrTime(moment().format('HH:mm'));
    });
    //set default time
    if (!time) {
      //setChosenTime(moment().add(1, 'hour').format('HH:00'));
    }
    return () => { clearInterval(timeRefresher); };
  }, []);

  useEffect(() => {
    if (date) {
      setChosenDates(date);
    }
  }, [date]);

  const checkPossibleTime = () => {
    let now = moment();
    let defaultMin = restrict?.minHour || defaults.minHour;
    if (now.format('YYYY-MM-DD') === moment(chosenDates[0]).format('YYYY-MM-DD')) {
      let min = Math.max(defaultMin, parseInt(now.format('H')));
      setMinHourState(min);
    } else {
      setMinHourState(defaultMin);
    }
  };

  useEffect(checkPossibleTime, [chosenDates]);

  useEffect(() => {
    if (isOpen === true) {
      if (time) {
        setChosenTime(time);
      } else {
        setChosenTime(undefined);
      }
    }
  }, [time, isOpen]);

  useEffect(()=>{
    __DEV__ && console.log({chosenTime, chosenDates});
  },[chosenTime, chosenDates]);

  const checkPossibleDate = useCallback(() => {
    if (!restrict) {return;}
    let now = moment();

    /*console.log({
      restrict : JSON.stringify(restrict),
      now,
      chDt : chosenDates[0],
      isBefore : now.isBefore(moment(chosenDates[0]).set('hours', restrict.max || 0)),
      timeMark : moment(chosenDates[0]).set('hours', restrict.max || 0),
    });*/
    if (
      chosenDates[0] &&
      restrict?.workDates &&
      restrict?.workDates[chosenDates[0]]
      //&&
      //now.isBefore(moment(chosenDates[0]).set('hours',restrict.max || 0))
    ) {
      setLoadTimeTable('loaded');
      return;
    } else {
      setLoadTimeTable('loading');
      if (!restrict?.workDates){
        //console.log('set state fail');
        setLoadTimeTable('fail');
        return;
      }
    }

    let firstWorkDate = restrict?.workDates ? Object.keys(restrict.workDates)[0] : null;
    if (restrict?.workDates && firstWorkDate && now.isBefore(firstWorkDate) ) {
      now = moment(firstWorkDate);
      setChosenDates([now.format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]);
    }

    if (
      restrict?.notWorkDates
      &&
      restrict.notWorkDates[now.format('YYYY-MM-DD')]
    ) {
      for (let i = 1; i <= maxDaysRender; i++) {
        let dat = now.clone().add(1, 'day');
        //console.log('check possible date ' + i, dat);
        if (restrict?.notWorkDates && !restrict.notWorkDates[dat.format('YYYY-MM-DD')]) {
          setChosenDates([dat.format('YYYY-MM-DD'), dat.format('YYYY-MM-DD')]);
          break;
        }
      }
    }
    /*else if (firstWorkDate) {
      __DEV__ && console.log('choice dates firstWorkDate');
      setChosenDates([now.format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]);
    }*/
    /*let hasWorkDates = true;
    for (let wDate in restrict?.workDates){
      let wDateParams = restrict?.workDates;
      if (
        restrict?.notWorkDates && !restrict?.notWorkDates[wDate]
        &&
        moment(wDate).set('hours',wDateParams.max || 0).isAfter(now)
      ) {
        hasWorkDates = true;
      }
      if (hasWorkDates){break;}
    }*/
    if (!restrict?.workDates || Object.keys(restrict?.workDates).length === 0){
      setLoadTimeTable('fail');
    } else {
      //console.log({wdts:restrict?.workDates});
      setLoadTimeTable('loaded');
    }
  }, [restrict, chosenDates]);

  useEffect(() => {
    checkPossibleDate();
    //checkPossibleTime();
  }, []);

  useEffect(() => {
    checkPossibleDate();
    checkPossibleTime();
  }, [currTime]);

  useEffect(()=>{
    if (offer && offer?.useCurrentDateTime){
      setChosenTime(currTime);
    }
  }, [currTime, offer]);

  useEffect(()=>{
    restrictions && setRestrict(restrictions);
    //console.log({restrictions});
  }, [restrictions]);

  const validate = useCallback(async () => {
    //console.log('OFFER subtypes',offer?.subTypes, 'ROOM', clientRoom);
    if (!chosenTime && !offer?.useCurrentDateTime) {
      setValidator('Пожалуйста, выберите желаемое время');
      return false;
    }
    if (offer?.subTypes.includes(OfferSubtypes.roomService)) {
      if (!clientRoom || (clientRoom + '').length !== 4){
        setValidator('Пожалуйста, введите корректный номер комнаты');
        return false;
      }
    }
    if (offer.attributes && Object.keys(offer.attributes).length > 0) {
      let _attrValid = await attrRef.current?.validate();
      //console.log({_attrValid});
      if (!_attrValid.valid) {
        setValidator('Пожалуйста, выберите обязательные опции');
        try { scrollRef.current.scrollTo({y:400, animated:true}); } catch (e){
          console.log('scroll to error');
        }
        return false;
      }
    }
    return true;
  },[offer, clientRoom, chosenTime]);

  /*useEffect(()=>{
    __DEV__ && console.log('Offer changed:', offer);
  },[offer]);*/

  return (
    <>
      <SimpleBottomSheet
        ref={modalRef}
        key={modalRefresher}
        onChange={handleCalendarSheetChanges}
        onAnimate={(a,b) => {
          if (a > b){
            handleClose();
          }
          if (a < b) {
            handleOpen();
            setIsOpen(true);
          }
        }}
        lastPoint={'92%'}
      >
        <View style={styles.contentContainer}>
          <View style={styles.section}>
            <MiniOffer
              {...offer}
              showTariffs={false}
              showPrice={true}
              showAttributes={true}
              loyaltyBonus={loyaltyBonus}
              onPressTariffs={onPressTariffs}
              date={chosenDates[0]}
              time={chosenTime}
            />
          </View>
          <ScrollViewComponent
            ref={scrollRef}
          >
            {offer?.tariffs && offer.tariffs.length > 0 ?
              <View key={'tariffs'} style={styles.section}>
                <AppText style={[styles.sectionLabel, {marginTop: 10}]}>
                  Выбранные тарифы
                </AppText>
                <TariffsView
                  tariffs={offer?.tariffs}
                  onPress={onPressTariffs}
                  bottomSheet={true}
                />
              </View>
            : null}
            <View key={'datetime_block'} style={styles.dateTimeBlock}>
            {!offer?.useCurrentDateTime
              ?
                  <View style={styles.section}>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                      <AppText style={styles.sectionLabel}>{'Дата'}</AppText>
                      <DateRangeView
                        value={chosenDates}
                        style={[styles.sectionLabel, {color: 'silver'}]}
                      />
                    </View>
                    <Calendar
                      value={chosenDates}
                      onChange={setChosenDates}
                      initialDate={chosenDates[0]}
                      width={Math.min(360, windowWidth)}
                      {...calendarRestrictionAdapter({...restrict, value: chosenDates})}
                      //height={300}
                    />
                  </View>
                : null
              }
              {!offer?.useCurrentDateTime
                ? <View style={[
                  styles.section,
                  offer?.useCurrentDateTime ? {flexDirection: 'row', justifyContent: 'space-between'} : null
                ]}>
                  <AppText style={styles.sectionLabel}>
                    {'Желаемое время'}
                  </AppText>
                  <TimePickerNew
                    key={timeKey}
                    min={minHourState}
                    max={maxHourState}
                    minTime={
                      chosenDates[0] === moment().format('YYYY-MM-DD')
                      ? (moment().add(offer?.timeCloseSale || 0, 'hours'))
                      : null
                    }
                    value={chosenTime}
                    onChange={range => {
                      setChosenTime(range);
                    }}
                    {...timeRangeRestrictions}
                  />
                </View>
                : null
              }
              {(offer?.subTypes || []).includes(OfferSubtypes.roomService)
                ? <View style={styles.section}>
                    <AppText style={styles.sectionLabel}>
                      Номер комнаты (обязательно)
                    </AppText>
                    <TextInputComponent
                      keyboardType={'numeric'}
                      value={clientRoom}
                      maxLength={4}
                      onChangeText={text=>{
                        setClientRoom(text.replace(/[^0-9]/g, ''));
                      }}
                      style={styles.input}
                    />
                  </View>
                : null}
              {
                settings.enableBonusPayment
                && offer?.disableBonusPayment !== true
                && authState === 'auth'
                && offer?.isUseSlots
                && outletHasLoyalty(offer?.outlet)
                ? <View style={styles.section}>
                    <AppText style={styles.sectionLabel}>
                      {'Оплата бонусами'}
                    </AppText>
                    <BookingFormTumbler
                      outlet={offer?.outlet}
                      offer={offer}
                      containerStyle={styles.bonusTumblerContainer}
                      onChange={handleChangeBonus}
                    />
                  </View>
                : null
              }
              <OfferAttributes
                ref={attrRef}
                offer={offer}
                onChange={handleChangeAttributes}
              />
              <View style={styles.section}>
                <AppText style={styles.sectionLabel}>
                  {commentTitleByAction(offer?.actionButton)}
                </AppText>
                <TextInputComponent
                  numberOfLines={3}
                  multiline={true}
                  keyboardType={'default'}
                  defaultValue={clientComment}
                  onChangeText={text => {
                    setClientComment(text);
                  }}
                  style={[styles.input, styles.commentInput]}
                />
              </View>
            </View>
            <AntiMenuFade key={'bottom-space'} height={200}/>
          </ScrollViewComponent>
        </View>
        {
          validator
          ? <View style={styles.validatorBox}>
              <AppText style={styles.validator}>{validator}</AppText>
            </View>
          : null
        }
        <View style={[styles.bookingButtonBlock, {paddingBottom: insets.bottom}]}>
          <LoadingContainer loading={locked} >
            <NewButton
              style={styles.bookingButton}
              children={(buttonsNames[offer?.actionButton] || buttonsNames.default)}
              onPress={() => handleAction('call_later')}
            />
          </LoadingContainer>
        </View>
        {
          loadTimeTable !== 'loaded'
          ? <View style={styles.dateTimeLockOverlay}>
              <View style={styles.dateTimeLockLoader}>
                {loadTimeTable === 'loading' ? <ActivityIndicator size={'large'}/> : null}
                <AppText style={styles.dateTimeLockText}>
                  {loadTimeTable === 'fail' ? 'Нет свободных дат' : 'Ищем свободные даты'}
                </AppText>
              </View>
            </View>
          : null
        }
      </SimpleBottomSheet>
      <Modal
        animationType="fade"
        transparent={true}
        visible={phoneOverlayShow}
        onRequestClose={() => {
          //setLoadOverlay(false);
        }}>
        <PhoneOverlay
          onPressClose={() => setPhoneOverlayShow(false)}
          onPressSend={() => {
            if (
              !phoneNumber ||
              phoneNumber.length < 10 ||
              name.length < 2
            ) {
              return;
            }
            onAction({
              action: bookingAction,
              chosenDates,
              chosenTime,
              clientPhone: '7' + phoneNumber,
              clientName: name,
              recall,
              clientComment,
              clientRoom,
              loyaltyBonus,
              onSuccess:() => {
                setClientComment(null);
                setClientRoom(null);
              },
            });
            setPhoneOverlayShow(false);
          }}
          phoneNumber={phoneNumber}
          onChangePhoneNumber={setPhoneNumber}
          name={name}
          onChangeName={setName}
        />
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={authOverlayShow}
      >
        <AuthOverlay
          onPressClose={() => setAuthOverlayShow(false)}
          onPressAuth={() => {
            let draftBookingData = {
              bookingAction,
              offer:{
                id: offer.id,
                tariffs: offer.tariffs,
                attributes: offer.attributes,
                mainImage: offer.mainImage,
              },
              booking: {
                chosenDates,
                chosenTime,
                clientComment,
                clientRoom,
                loyaltyBonus,
              },
              navParams: stateToRouteChain(
                navigation.getState(),
                (Platform.OS === 'web' ? webUrlGetAllParams(window.location) : {}),
              ),

            };

            dispatch(appEvent.newEvent('dismissAllBottomSheets'));
            //console.log('add draft booking data', draftBookingData);
            dispatch(draftBooking.add(draftBookingData));
            dispatch(auth.logout());
            setTimeout(()=>{
              setAuthOverlayShow(false);
              //navigation.reset({index: 0, routes: [{name: 'Auth'}]});
            }, 500);
            /*dispatch({
              action: bookingAction,
              chosenDates,
              chosenTime,
              clientComment,
              clientRoom,
              loyaltyBonus,
            });*/
          }}
        />
      </Modal>
    </>
  );
};

const FowardRefComponent = forwardRef(BookingFormBottomSheet, {});

const MemoOfFowardRefComponent = memo(
  FowardRefComponent,
  (prev, next) => {
    return  JSON.stringify(prev) === JSON.stringify(next);
  },
);

const styles = StyleSheet.create({
  contentContainer: {
    paddingTop: 25,
    flex: 8,
    height: Platform.OS === 'web' ? 'inherit' : '100%',
  },
  section: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderBottomColor: '#FAFAFA',
    borderBottomWidth: 8,
  },
  sectionLabel: {
    paddingVertical: 16,
    fontSize: 17,
    fontWeight: '500',
  },
  dateRange: {paddingVertical: 6, fontSize: 27},
  calendarLabel: {
    fontSize: 16,
    marginVertical: 5,
    color: '#23b865',
    fontWeight: '400',
  },
  pickerStyle: {
    height: Platform.OS === 'web' ? 40 : 70,
    //width: Platform.OS === 'web' ? 60 : 90,
    borderColor: '#CFCFCF',
    borderRadius: 11,
    fontSize: 18,
    textAlign: 'center',
  },
  itemStyle: {
    height: Platform.OS === 'web' ? 40 : 70,
    //width: 90,
    margin: 0,
    fontSize: 21,
  },
  optionStyle: {
    fontSize: 21,
  },
  dateTimeBlock: {

  },
  dateTimeLockOverlay: {
    backgroundColor: 'rgba(255,255,255,0.85)',
    position: 'absolute',
    top: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  dateTimeLockLoader: {
    backgroundColor: 'rgba(255,255,255,1)',
    paddingVertical: 15,
    paddingHorizontal: 25,
    borderColor: 'transparent',
    borderRadius: 12,
    borderWidth: 1,
    shadowColor: 'rgb(115, 139, 186)',
    shadowRadius: 4,
    shadowOpacity: 0.45,
    shadowOffset: {
      height: 2,
      width: 0,
    },
  },
  dateTimeLockText: {
    color: '#757575',
    fontSize: 16,
    paddingTop: 5,
  },

  //inputs
  roomBlock: {},
  commentBlock: {},
  input: {
    backgroundColor: '#f6f6f6',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 11,
    maxHeight: 160,
    marginBottom: 12,
  },
  commentInput: {
    paddingTop: 15,
    minHeight: 100,
  },

  //booking button
  bookingButtonBlock: {
    flex:0.9,
    paddingTop: 10,
    backgroundColor: 'rgba(255,255,255,0.9)',
    //paddingBottom: 15,
    ...defaultStyles.buttonShadow,
    width: '100%',
    //minHeight: 10,
    flexDirection: 'column',
  },
  bookingButton: {
    width: '50%',
  },

  //validator
  validatorBox: {
    padding: 3,
    paddingBottom: 5,
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,1)',
    marginTop: -100,
  },
  validator: {
    color: '#a26524',
  },

  //bonus section
  bonusTumblerContainer: {
    marginBottom: 20,
  },
});

export default MemoOfFowardRefComponent;
