import {getAttributeTypes} from './Attribute';

const attributeTypes = getAttributeTypes();

const attributesAdapter = (raw_attributes) => {
  if (!raw_attributes) {return {};}
  let _attributes, attributes = {};
  if (Array.isArray(raw_attributes)){
    _attributes = raw_attributes;
  } else {
    try {
      _attributes = JSON.parse(raw_attributes);
      //__DEV__ && console.log(_attributes);
    } catch (e) {
      __DEV__ && console.warn('incorrect attributes', raw_attributes);
      return {};
    }
  }

  for (let i in _attributes){
    let attr = _attributes[i];
    if (!attributeTypes.includes(attr.type)){
      __DEV__ && console.warn('unknown attribute type', attr);
      continue;
    }
    attributes[attr.id] = attr;
  }

  return attributes;
};

export default attributesAdapter;
