import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  FlatList,
  SafeAreaView,
  View,
  StyleSheet, ActivityIndicator,
} from 'react-native';

//import {upi} from './FileUploader';
import AppText from '../Basic/AppText';
import moment from 'moment';
import {defaultStyles} from '../Defaults/Styles';
import PageHeader, {useGoBack} from '../Basic/PageHeader';
import NewButton from '../Basic/NewButton';
import {useNavigation} from '@react-navigation/native';
import {api} from '../System/api';
import {upi} from './FileUploader';

const RequestList = () => {
  const navigation = useNavigation();
  const goBack = useGoBack();
  const [bookingRequests, setBookingRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tryButton, setTryButton] = useState(null);
  const [error, setError] = useState(null);
  const reqId = useRef();
  const [reloadActivator, setReloadActivator] = useState();
  //const [] = useState();

  useEffect(()=>{
    loadList();
    return () => {
      api.pending.abort(reqId.current);
    };
  },[]);

  const loadList = () => {
    setLoading(true);
    setBookingRequests([]);
    setTryButton(null);

    api
      .selfCheckin
      .setId(i => reqId.current = i)
      .requestList()
      .then(res => {
        res=res.data;
        setLoading(false);
        let data = res || [];
        setBookingRequests(data.sort((a,b)=>parseInt(b.id) - parseInt(a.id)));

        //check last request state
        let lastRequest = data.reduce((a,v) =>
            parseInt(a.id) < parseInt(v.id) ? v : a
          , {id: 0});
        if (lastRequest.id === 0){
          //setTryButton('Перейти к регистрации');
        } else {
          if (lastRequest?.isApproved !== null) {
            if (lastRequest.isApproved === false) {
              setTryButton('Повторить регистрацию');
            } else {
              if (moment(lastRequest.dateEnd).isBefore(moment())){
                setTryButton('Перейти к регистрации');
              }
            }
          }
        }
      })
      .catch(e => {
        if (api.isAbort(e)) {return;}
        setLoading(false);
        setError(e?.errors
          ? e.errors.join('\n')
          : 'Не удалось получить данные \n Попробуйте немного позже'
        );
      });
  };

  const reload = useCallback(()=>{
    if (!loading){
      loadList();
    }
  },[loading]);

  useEffect(()=>{reload();}, [reloadActivator]);

  useEffect(() => {
    return navigation.addListener('focus', () => {
      __DEV__ && console.log('LOAD DATA ON NAVIGATE (request list)');
      setReloadActivator(v=>!v);
    });
  }, [navigation]);

  const handleRegister = () => {
    navigation.navigate('SelfCheckin');
  };

  return (
    <SafeAreaView style={styles.container}>
      <PageHeader title={'История бронирований'}/>
      <View style={styles.headerBox}>
        {!loading && bookingRequests.length > 0
          ? <View style={styles.headerTitle}>
              <AppText style={styles.headerTitleText}>
                {'Запросы на саморегистрацию'}
              </AppText>
            </View>
          : null}
      </View>
      <View style={styles.scrollContainer}>
        <FlatList
          renderItem={({item})=><BookingRequestItem {...item}/>}
          keyExtractor={(item)=>item.id}
          data={bookingRequests}
          ListHeaderComponent={()=>{
            return loading ? (
              <View style={styles.loader}>
                <ActivityIndicator size={'large'}/>
              </View>
            ) : null;
          }}
          ListFooterComponentStyle={{flexGrow: 0.5}}
          ListFooterComponent={()=>{
            if (error){
              return (
                <View style={styles.firstLoadBox}>
                  <AppText style={styles.firstLoadText}>
                    {error}
                  </AppText>
                  <View style={styles.firstLoadButton}>
                    <NewButton onPress={()=>goBack()}>
                      {'Назад'}
                    </NewButton>
                  </View>
                </View>
              );
            }
            return !loading && bookingRequests.length === 0 ? (
              <View style={styles.firstLoadBox}>
                <AppText style={styles.firstLoadText}>
                  {'Дорогой Гость!\nДля вашего удобства рекомендуем вам пройти онлайн-регистрацию перед заселением в отель.\nИ подкрепить необходимые документы.'}
                </AppText>
                <View style={styles.firstLoadButton}>
                  <NewButton onPress={handleRegister}>
                    {'Перейти к регистрации'}
                  </NewButton>
                </View>
              </View>
            ) : null;
          }}
          contentContainerStyle={styles.scrollList}
        />
      </View>
      {tryButton
        ? <View style={styles.buttonArea}>
            <NewButton
              onPress={handleRegister}
              style={styles.button}>{tryButton}</NewButton>
          </View>
        : null}
    </SafeAreaView>
  );
};

let getState = (v) => {
  let o = {};
  if (v === true) {
    o = {title: 'Документы приняты', color: '#e1ffcf'};
  }
  if (v === null) {
    o = {title: 'На проверке', color: '#f1f1f1'};
  }
  if (v === false) {
    o = {title: 'Отклонёно', color: '#ffdec8'};
  }
  return o;
};

const BookingRequestItem = ({id, dateCreate, isApproved, dateBegin, dateEnd}) => {
  /*
    'id': 1,
    'client': '/api/clients/861',
    'dateBegin': '2023-04-03T21:37:45+00:00',
    'dateEnd': '2023-04-08T00:00:00+00:00',
    'isApproved': false,
    'dateCreate': '2023-04-06T10:28:30+00:00',
    'dateUpdate': null,
    */

  dateCreate = moment(dateCreate).format('DD.MM.YYYY · HH:mm');
  dateBegin = moment(dateBegin).format('DD MMM YYYY');
  dateEnd = moment(dateEnd).format('DD MMM YYYY');
  const state = getState(isApproved);

  return (
    <View style={styles.riBox}>
      <View style={styles.riTopBox}>
        <AppText style={styles.riDate}>
          {dateCreate}
        </AppText>
      </View>
      <View style={styles.riMiddleBox}>
        <View>
          <View style={styles.riDatesBox}>
            <AppText style={styles.riMiddleLabel}>{'Заезд'}</AppText>
            <AppText style={styles.riMiddleValue}>{dateBegin}</AppText>
          </View>
          <View style={styles.riDatesBox}>
            <AppText style={styles.riMiddleLabel}>{'Выезд'}</AppText>
            <AppText style={styles.riMiddleValue}>{dateEnd}</AppText>
          </View>
        </View>
        <View style={[styles.riStatus, {backgroundColor: state.color}]}>
          <AppText style={styles.riStatusText}>
            {state.title}
          </AppText>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollContainer: {
    ...defaultStyles.webScrollView,
  },
  headerBox: {
    marginTop: 22,
    paddingHorizontal: 22,
  },
  headerTitle: {

  },
  headerTitleText: {
    fontSize: 18,
    fontWeight: '400',
  },

  //first load
  firstLoadBox: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  firstLoadText: {
    color: '#939393',
    fontSize: 25,
    //lineHeight: 27
    textAlign: 'center',
  },
  firstLoadButton: {
    paddingTop: 100,
  },

  //loader
  loader: {
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  //scroll list
  scrollList: {
    padding: 22,
    paddingTop: 16,
    flex: 1,
  },

  //request item
  riBox: {
    padding: 12,
    ...defaultStyles.cardShadow,
    backgroundColor: '#fff',
    marginBottom: 12,
    borderRadius: 8,
  },
  riTopBox: {
    paddingBottom: 10,
  },
  riMiddleBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  riMiddleLabel: {
    color: '#939393',
    fontWeight: '500',
    marginRight: 10,
  },
  riDate: {
    fontWeight: '500',
    color: '#7a7a7a',
    fontSize: 15,
  },
  riDatesBox: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  riStatus: {
    borderRadius: 50,
    paddingHorizontal: 15,
    paddingVertical: 10,
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
  },
  riStatusText: {
    flexWrap: 'wrap',
  },

  //button
  buttonArea: {
    padding: 15,
  },
  button: {},
});

export default RequestList;
