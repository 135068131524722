import React, {memo, Suspense, useEffect, useRef, useState, lazy} from 'react';
import {
  ActivityIndicator,
  SafeAreaView,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
  ImageBackground,
  FlatList,
  Modal as RnModal,
  Image,
  Linking,
} from 'react-native';

//import {TouchableOpacity as TouchableView} from 'react-native-gesture-handler';

const DataProcessingPolicy = lazy(() =>
  import('./../Docs/DocFiles/PersonalDataProcessingPolicyDoc'),
);

const passport_example_1 = require('./../../img/passport/1-min.png');
const passport_example_2 = require('./../../img/passport/2-min.png');


import {launchImageLibraryAsync, MediaTypeOptions} from 'expo-image-picker';
import {readAsStringAsync} from 'expo-file-system';
import AppText from '../Basic/AppText';
import {Icon} from '@rneui/base';
import PageHeader, {useGoBack} from '../Basic/PageHeader';
import AppButton from '../Basic/NewButton';
import AppInput from '../Basic/AppInput';
import AntiMenuFade from '../Basic/AntiMenuFade';
import {DropDownListButton} from '../Basic/DropdownList';
import LoadingContainer from '../Basic/LoadingContainer';

import {useNavigation} from '@react-navigation/native';
import {api} from '../System/api';
//import api from './mockApi';
import NewButton from '../Basic/NewButton';
import {colors, defaultStyles} from '../Defaults/Styles';
import SwitchInput from '../Basic/SwitchInput';
import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';
import ScaledImage from '../Basic/ScaledImage';
import {useAppDimensions} from '../Defaults/DisplayWidth';
import Progress from '../Loyalty/Progress';
import * as statusBar from '../GlobalStore/reducers/statusBarSlice';
import settings from '../Defaults/settings';

const notFoundError = <>
  {'К сожалению, бронирования не найдены.\n\nОбратитесь в единый контактный центр '}
  <AppText
    style={{
      color: colors.default.mainTrDark,
      textDecorationLine: 'underline',
    }}
    onPress={()=>{Linking?.openURL('tel:' + settings.supportPhone);}}
  >
    {settings.supportPrettyPhone}
  </AppText>
</>;

const unknownError = <>
  {'К сожалению, при отправке запроса произошла неизвестная ошибка.\n\nОбратитесь в единый контактный центр '}
  <AppText
    style={{
      color: colors.default.mainTrDark,
      textDecorationLine: 'underline',
    }}
    onPress={()=>{Linking?.openURL('tel:' + settings.supportPhone);}}
  >
    {settings.supportPrettyPhone}
  </AppText>
</>;

const errors = {
  'count files guest less count by type': 'Загружено недостаточное количество фалов для определённого типа',
  'do not find header': 'Возникли проблемы с авторизацией, пожалуйста, войдите в аккаунт и попробуйте снова',
  'auth is null': 'Возникли проблемы с авторизацией, пожалуйста, войдите в аккаунт и попробуйте снова',
  'Сервис недоступен': 'Сервис недоступен',
  'Клиент не найден во внешнем сервисе': unknownError,
  'Онлайн регистрация будет открыта за 3 дня до вашего заезда' : 'Онлайн регистрация будет открыта за 3 дня до вашего заезда',
};

/** todo: fix WORKAROUND
 * (unnecessary process, bookingAvailable must contain draft data)
 * */
const mergeAvailableAndInfoData = (availableBookings, bookingInfo) => {
  for (let i in availableBookings){
    let booking = availableBookings[i];
    for (let j in bookingInfo) {
      let info = bookingInfo[j];
      if (info.NumberTngId == booking.booking_id) {
        booking.draftData = {
          automobile : {
            id: info.automobile.id,
            governmentNumber: info.automobile.GovernmentNumber,
            color: info.automobile.Color,
            carModel: info.automobile.CarModel,
          },
        };
        if (info.guests){
          for (let k in info.guests){
            let guest = info.guests[k];
            let bookingGuestInd = booking.guests.findIndex(v => v.profileID == guest.tngId);
            if (bookingGuestInd >= 0){
              booking.guests[bookingGuestInd].draftData = {
                files: guest.files,
              };
            }
          }
        }
      }
    }
    availableBookings[i] = booking;
  }
  return availableBookings;
};

const FileUploader = () =>
{
  const navigation = useNavigation();
  const [valid, setValid] = useState(null);
  const [load, setLoad] = useState(true);
  const [sendLoad, setSendLoad] = useState(false);
  const [modal, setModal] = useState({
    show: false,
  });
  const [fileLoading, setFileLoading] = useState({
    loadedCount: 0,
    toLoadCount: 0,
    loadingFor: '',
    show: false,
  });
  const [guests, setGuests] = useState({});
  const [booking, setBooking] = useState();
  const [car, setCar] = useState({
    number:'',
    color:'',
    model:'',
    useCar: false,
  });
  const reqId = useRef();
  const sheetRef = useRef();
  const goBack = useGoBack();

  useEffect(() => {
    return navigation.addListener('focus', () => {
      //console.log('focus checkin file upload');
      //startProcess();
    });
  }, [navigation]);

  useEffect(() => {
    startProcess();
    return () => {
      api.pending.abort(reqId.current);
    };
  }, []);

  const startProcess = () => {
    const checkAvailableBookings = () => {
      api
        .selfCheckin
        .setId(id => reqId.current = id)
        .availableBookings()
        .then(res => {
          res = res.data;
          if (!res?.result && !res.result[0] && !res.result[0].guests){
            //console.log({res});
            return setModal({
              show: true,
              text: 'Нет данных о гостях',
              buttonText: 'Назад',
              buttonAction: () => {
                //navigation.navigate('Profile');
                goBack();
                return false;
              },
            });
          }
          let _bookingId = res.result[0].booking_id;
          setBooking(_bookingId);
          let _guestsArr = res.result[0].guests;
          let _guests =
            _guestsArr
              .reduce((a, v) => ({
                ...a,
                [v.profileID]: {
                  ...v,
                  id: v.profileID,
                  files: [],
                },
              }),{});
          setGuests(_guests);
          //console.log({_guests});
          setLoad(false);
        })
        .catch(e => {
          if (api.isAbort(e)) {return;}
          __DEV__ && console.warn(e);
          setModal({
            show: true,
            text: e?.errors ? e.errors.join('\n') : notFoundError,
            buttonText: 'Назад',
            buttonAction:() => {
              goBack();
            },
          });
        });
    };
    api
      .selfCheckin
      .setId(id => reqId.current = id)
      .draft
      .info()
      .then(res => {
        let draftIndex;
        let info = res.data;
        if (info && ['NEW'].includes(info.status.code)){
          setModal({
            show: true,
            text: 'Ваши документы в данный момент на рассмотрении.\n\n' +
              'Ожидайте уведомление о завершении онлайн регистрации',
            buttonText: 'Хорошо',
            buttonAction:() => {
              goBack();
            },
          });
          return;
        }
        if (info && ['NOT_APPROVED'].includes(info.status.code)){
          setModal({
            show: true,
            text: 'Ваши запрос был отклонен.\n\n' +
              'Возможно есть какие-то ошибки или несоответствия с требованиями к фотографиям документов',
            buttonText: 'Попробовать снова',
            buttonAction:() => {},
          });
        }
        if (info && ['APPROVED'].includes(info.status.code)) {
          setModal({
            show: true,
            text: 'Регистрация успешно завершена!',
            buttonText: 'Супер',
            buttonAction: () => {
              goBack();
            },
          });
          return;
        }
        checkAvailableBookings();
      }).catch( res => {
        checkAvailableBookings();
      });
  };

  const validate = () => {
    if (!booking) {
      setValid('Не выбран ID бронирования');
      return false;
    }
    for (let i in guests){
      if (guests[i].files?.length < 1){
        setValid('Файлы выбраны не для всех гостей');
        return false;
      }
      if (!guests[i].docType){
        setValid('Тип документа выбран не у всех гостей');
        return false;
      }
      if (guests[i].docType === 'Passport' && guests[i].files?.length < 2){
        setValid('Должно быть загружено 2 или больше файлов для типа документа "Паспорт"');
        return false;
      }
    }
    if (car.useCar && (!car.color || !car.model || !car.number)){
      setValid('Необходимо заполнить все поля для\nрегистрации транспортного средства');
      return false;
    }
    return true;
  };

  const sendFiles = () => new Promise(async (resolve, reject) =>
  {
    const toLoad = Object.values(guests).reduce((a,v)=>a + v.files.length, 0);
    let loaded = 0;
    setFileLoading(v=>({
      ...v,
      show: true,
      toLoadCount: toLoad,
      loadedCount: loaded,
      loadingFor: '',
    }));
    for (let i in guests) {
      let guest = guests[i];
      setFileLoading(v=>({...v, loadingFor: guest.firstName}));
      for (let j in guest.files) {
        let _file = guest.files[j];
        let file = {
          guestId: guest.id,
          type: guest.docType,
          file: _file.base64,
        };
        try {
          let res = await api.selfCheckin.draft.addFile(file);
          loaded++;
          setFileLoading(v=>({...v, loadedCount: loaded}));
          if (!res?.data?.success) {
            setFileLoading(v=>({...v, show: false}));
            return reject(res);
          }
        } catch (e) {
          setFileLoading(v=>({...v, show: false}));
          return reject(e);
        }
      }
    }
    resolve(true);
  });

  const sendData = () => {
    if (!validate()) {return;}
    setSendLoad(true);
    /*let data = {
      booking_id: booking,
      automobile: {
        governmentNumber: car?.number,
        color: car?.color,
        carModel: car?.model,
      },
      guests: Object.values(guests).map(guest=>({
        id: guest.id,
        type: guest.docType,
        files: (guest.files || []).map(file => ({
          file: file.base64,
        })),
      })),
    };*/
    let automobile = car.useCar
      ? {
          automobile: {
            governmentNumber: car?.number,
            color: car?.color,
            carModel: car?.model,
          },
      }
      : {};

    const onFail = (e) => {
      let text;
      if (e?.errors) {
        text = e.errors.join('\n');
      }
      else if (e?.message && errors[e.message]) {
        text = errors[e.message];
      } else {
        text = unknownError;
      }
      setModal({
        show: true,
        text,
        buttonText: 'Повторить',
        buttonAction:() => {
          //navigation.goBack();
          setSendLoad(false);
        },
      });
    };

    api
      .selfCheckin
      .draft
      .editData(automobile)
      .then(res => {
        if (res?.data?.success === true) {
          console.log('edit data success:', res);
          sendFiles()
            .then(res => {
              console.log('send files res:', res);
              if (!res){
                onFail(res);
                return;
              }
              api
                .selfCheckin
                .draft
                .complete()
                .then(res=>{
                  console.log('complete called', res);
                  if (res?.data?.success === true) {
                    setSendLoad(false);
                    setModal({
                      show: true,
                      text: 'Ваши документы успешно отправлены на проверку.\n\n' +
                        'Ожидайте уведомление о завершении онлайн регистрации',
                      buttonText: 'Супер',
                      buttonAction: () => {
                        goBack();
                        return false;
                      },
                    });
                  } else {
                    console.log('complete failed', res);
                    onFail(res);
                  }
                }).catch(e => {
                  console.log('complete catch', e);
                  onFail(e);
                });
            })
            .catch(e=>{
              console.log('send files catch', e);
              onFail(e);
            });
        } else {
          console.log('edit data fail:', res);
          onFail(res);
        }
      })
      .catch(e => {
        console.log('edit data catch:', e);
        //console.warn(e);
        onFail(e);
      });
    //console.log(data);
  };

  const handleCarProps = ({number, color, model, useCar}) => {
    setValid(null);
    setCar({
      number, color, model, useCar,
    });
  };

  const handleChange = (guest) => {
    setValid(null);
    setGuests(_guests=>{
      return {
        ..._guests,
        [guest.id]: {
          ..._guests[guest.id], ...guest,
        },
      };
    });
  };

  return (
    <>
      {load
        ? <View style={styles.loader}>
            <ActivityIndicator size={'large'}/>
          </View>
        :
        <SafeAreaView style={styles.box}>
          <PageHeader title={'Регистрация в отеле'}/>
          <LoadingContainer
            loading={sendLoad}
            style={styles.loadingContainer}
            size={'large'}
            progress={
              fileLoading.show
              ? <View style={styles.progressBox}>
                <View style={styles.progressCard}>
                  <View style={styles.progressTitles}>
                    <AppText style={styles.progressTitleBox}>
                      {'Загрузка документов'}
                    </AppText>
                    <AppText style={styles.progressTitleBox}>
                      {fileLoading.loadingFor}
                    </AppText>
                  </View>
                  <Progress percent={fileLoading.loadedCount / fileLoading.toLoadCount * 100}/>
                </View>
              </View>
              : null
            }
          >
            <ScrollView
              style={styles.scroll}
              //contentContainerStyle={{flex: 1}}
            >
              <GuestsList guests={guests} onChange={handleChange}/>
              <Car onChange={handleCarProps}/>
              <AntiMenuFade height={200}/>
            </ScrollView>
            <View style={styles.buttonArea}>
              {valid
                ? <View style={styles.validationBox}>
                    <AppText style={styles.validationText}>{valid}</AppText>
                  </View>
                : null}
              <AppButton style={styles.sendButton} onPress={sendData}>{'Отправить'}</AppButton>
            </View>
          </LoadingContainer>
        </SafeAreaView>
      }
      <Modal {...modal} closeModal={()=>setModal(v=>({...v, show: false}))}/>
    </>
  );
};

const Modal = ({show, text, buttonAction, buttonText, closeModal}) => {
  const [_show, setShow] = useState(false);
  useEffect(()=>{
    setShow(show);
  }, [show]);
  return (
    <RnModal
      visible={_show}
      //presentationStyle={'fullScreen'}
      transparent={true}
      animationType={'fade'}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalBox}>
          <AppText style={styles.modalText}>
            {text}
          </AppText>
          <NewButton onPress={() => {
            if (!buttonAction || buttonAction() !== false){
              setShow(false);
              closeModal && closeModal();
            }
          }}>
            {buttonText}
          </NewButton>
        </View>
      </View>
    </RnModal>
  );
};

const Car = ({onChange}) => {
  const [state, setState] = useState({useCar:false});

  useEffect(()=>{
    state && onChange(state);
  }, [state]);

  const handleChange = (item) => {
    return (value) => {
      setState(v => ({...v, [item]:value}));
    };
  };

  return (
    <View style={styles.carBox}>
      <View style={styles.carTitleBox}>
        <AppText style={styles.titleText}>
          {'Транспортное средство'}
        </AppText>
      </View>
      {state.useCar ?
        <>
          <AppInput label={'Гос. Номер'} onChangeText={handleChange('number')}/>
          <AppInput label={'Цвет'} onChangeText={handleChange('color')}/>
          <AppInput label={'Марка'} onChangeText={handleChange('model')}/>
        </>
        : null
      }
      <SwitchInput
        label={'Понадобится ли Вам парковочное место?'}
        value={state.useCar} onValueChange={(a) => {
        setState(v => ({...v, useCar: a}));
      }}/>
    </View>
  );
};

const GuestsList = ({guests, onChange}) =>
{
  const sheetRef = useRef();
  const policySheet = useRef();
  const {width} = useAppDimensions();

  const handleDocType = (id) => {
    return (type) => {
      let docType = type[0].id;
      onChange({id, docType});
    };
  };

  return (
    <View>
      <View style={styles.titleContainer}>
        <AppText style={styles.titleText}>
          {'Загрузка документов'}
        </AppText>
        <AppText style={styles.describeText}>
          {'Для онлайн-регистрации в отеле прикрепите документы, удостоверяющие личность каждого гостя. Для взрослых гостей необходимы фотографии страниц паспорта, '}
          <AppText
            style={[styles.describeText, styles.inlineButton]}
            onPress={()=>{
              sheetRef.current?.open();
            }}
          >{'как на примере:'}</AppText>
          {
          '\n' +
          '  ·  Главной, с фото, датой и местом выдачи удостоверения.\n' +
          '  ·  С отметкой о действующей регистрации (прописке).\n' +
          '  ·  С отметкой о ранее выданных загранпаспортах.\n' +
          'Для детей, не достигших 14 лет, - свидетельство о рождении.'}
        </AppText>
        <AppText>
          {'Прикрепляя документы, Вы соглашаетесь с политикой компании и даете согласие на обработку '}
          <AppText
            onPress={()=>{
              policySheet.current?.open();
            }}
            style={styles.inlineButton}
          >{'персональных данных'}</AppText>
        </AppText>
      </View>
      {Object.values(guests).map((guest,i) =>
        <View key={guest.id} style={styles.fileBox}>
          <View style={styles.guestTitles}>
            <AppText style={styles.subTitleText}>
              {guest.firstName + ' ' + guest.name}
            </AppText>
          </View>
          <FilesList onChange={(files) => onChange({id: guest.id, files})}/>
          <View style={styles.fileTypeBox}>
            <DropDownListButton
              title={'Выберите тип'}
              onChange={handleDocType(guest.id)}
              buttonStyle={styles.docType}
              list={[
                {id: 'Passport', title: 'Паспорт', selected: guest?.docType === 'Passport'},
                {id: 'BirthdayCertificate', title: 'Св. о рождении', selected: guest?.docType === 'BirthdayCertificate'},
              ]}
            />
          </View>
        </View>
      )}
      <SimpleBottomSheet ref={sheetRef}>
        <ScrollView style={styles.exampleScroll}>
          <View style={styles.exampleTextBox}>
            <AppText style={styles.exampleText}>
              {'1-я страница: загрузить фото как на примере, важно, чтоб вся информация была читабельной и просматриваемой.'}
            </AppText>
          </View>
          <View style={styles.exampleImageBox}>
            <ScaledImage width={width} style={styles.exampleImage} source={passport_example_1}/>
          </View>
          <View style={styles.exampleTextBox}>
            <AppText style={styles.exampleText}>
              {'2-я страница: действующая регистрация (прописка)'}
            </AppText>
          </View>
          <View style={styles.exampleImageBox}>
            <ScaledImage width={width} style={styles.exampleImage} source={passport_example_2}/>
          </View>
        </ScrollView>
      </SimpleBottomSheet>
      <SimpleBottomSheet ref={policySheet} open={false} >
        <View
          style={{flex: 1, alignItems: 'center', backgroundColor: 'white'}}>
          <ScrollView style={{width: '100%'}} contentContainerStyle={{height: '100%', width:'100%'}}>
            <Suspense fallback={<ActivityIndicator />}>
              <DataProcessingPolicy />
            </Suspense>
          </ScrollView>
        </View>
      </SimpleBottomSheet>
    </View>
  );
};

const FilesList = ({onChange}) =>
{
  const [files, setFiles] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(()=>{
    files && onChange(files);
  },[files]);

  const handleDeleteFile = (file) => {
    setFiles(_files => _files
      .filter(v=>file.id !== v.id)
      .map((v,i)=>({...v, id: i}))
    );
  };

  /*const handlePickFile = (file) => {
    setFiles(_files => {
      if (file.button) {
        file.button = false;
        _files = [file].concat(files);
        _files = _files.map((v,i)=>({...v, id: i}));
      } else {
        _files[file.id] = file;
      }
      //file.base64 = 0;

      //console.log({fileb: file.button, file, _files});

      return _files;
    });
  };*/
  const handlePickFiles = (files) => {
    setFiles(_files => {
      for (let f in files){
        let file = files[f];
        if (file.button) {
          file.button = false;
          _files = [file].concat(_files);
          _files = _files.map((v,i)=>({...v, id: i}));
        } else {
          _files[file.id] = file;
        }
      }
      //file.base64 = 0;

      //console.log({fileb: file.button, file, _files});

      return [..._files];
    });
  };

  const handleChange = (file) => {
    setFiles(v => {
      v[file.id] = file;
      return [...v];
    });
  };

  const header = () => <View style={{flexDirection: 'row'}}>
    <File key={'file_btn'} button={true} onPick={handlePickFiles} onLoad={setLoad}/>
    {load
      ? <View style={styles.loaderBox}>
        <ActivityIndicator size={'small'}/>
      </View>
      : null
    }
  </View>;

  return (
    <View style={styles.fileListContainer}>
      <FlatList
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        style={styles.fileList}
        data={Object.values(files)}
        ListHeaderComponent={header}
        ListFooterComponent={()=><View style={{width: 100}}/>}
        renderItem={({item}, index)=><File
          key={index}
          {...item}
          onPick={handlePickFiles}
          onLoad={setLoad}
          onDelete={handleDeleteFile}
          onChange={handleChange}
        />}
      />
    </View>

  );
};

const File = memo(({fileName, id, uri, base64, onPick, onLoad, onDelete, onChange, button, docType, ...other}) =>
{
  const fileProps = {fileName, base64, uri};
  const necessaryProps = {id, button, docType};

  //console.log('redraw', id, uri);

  const pickDocument = async () => {

    onLoad(true);
    let result = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      aspect: [4, 3],
      quality: 0.2,
      allowsMultipleSelection: true,
      selectionLimit: !button ? 1 : 0,
    });

    __DEV__ && console.log('file', {result});

    if (!result?.assets || result?.assets?.length < 1) {
      onLoad(false);
      return;
    }

    let files = result.assets.map(async (v, i) => {
      if (Platform.OS === 'web'){
        //console.log({v});
        v.base64 = v.uri.replace(/(.*?,)(.*?$)/,'$2');
        //throw new Error('cant find handler for WEB');
      } else {
        //console.log({v});
        v.base64 = v.uri
          ? await readAsStringAsync(v.uri, { encoding: 'base64' })
          : null;
      }
      return {...v, button, id: id + i};
    });

    files = await Promise.all(files);

    onPick(files);
    onLoad(false);
  };

  const handleDocType = (type) => {
    docType = type[0].id;
    onChange({...fileProps, ...necessaryProps, docType});
  };

  return (
      <View
        style={styles.fileButton}
      >
        <View
          style={styles.filePicker}
        >
          {uri
            ?
            <TouchableOpacity
              style={styles.bottomTouchable}
              onPress={pickDocument}
              activeOpacity={0.5}
            >
                <ImageBackground
                  imageStyle={styles.fileImage}
                  style={styles.fileImageBox}
                  source={{uri}}
                >
                    <TouchableOpacity
                      style={styles.topTouchable}
                      onPress={()=>onDelete({id})}
                    >
                      <View style={styles.fileRemoveIconBox}>
                        <Icon style={styles.fileRemoveIcon} type={'antdesign'} name={'close'} size={15}/>
                      </View>
                    </TouchableOpacity>
                  </ImageBackground>
              </TouchableOpacity>
            : <TouchableOpacity onPress={pickDocument}>
                <View style={styles.iconBox}>
                  <Icon name={'addfile'} type={'antdesign'} size={29} color={'#9f9f9f'}/>
                </View>
              </TouchableOpacity>
            }
        {/*{!button
          ? <View style={styles.fileTitles}>
              <DropDownListButton
                title={'Выберите тип'}
                onChange={handleDocType}
                buttonStyle={styles.docType}
                list={[
                  {id: 'Passport', title: 'Паспорт', selected: docType === 'Passport'},
                  {id: 'BirthdayCertificate', title: 'Св. о рождении', selected: docType === 'BirthdayCertificate'},
                ]}
              >
                {uri ? 'Заменить файл' : 'Выбрать файл'}
              </DropDownListButton>
            </View>
          : null}*/}
      </View>
    </View>
  );
}, (f,n)=>{
  return f.uri === n.uri;
});

const styles = StyleSheet.create({
  box: {
    flex: 1,
    backgroundColor: '#fff',
    width: '100%',
  },
  loadingContainer: {
    //flex: 1,
    width: '100%', //for web
    ...defaultStyles.webScrollView,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
  scroll: {
    flex: 1,
    width: '100%',//for web
  },

  //file
  fileListContainer: {
    //...defaultStyles.webScrollView,
  },
  fileList: {
    flexDirection: 'row',
    paddingHorizontal: 22,
  },
  fileRemoveIconBox: {
    backgroundColor: 'rgba(255,255,255,0.7)',
    alignSelf: 'flex-end',
    width: 30,
    height: 30,
    borderRadius: 50,
    alignContent: 'center',
    justifyContent: 'center',
    margin: 5,
  },
  fileRemoveIcon: {

  },
  fileBox: {
    width: '100%',
    paddingVertical: 22,
    borderBottomColor: '#f5f5f5',
    borderBottomWidth: 1,
  },
  filePicker: {
  },
  fileImage: {
    width: 160,
    height: 100,
    borderRadius: 8,
    borderColor: '#ececec',
    borderWidth: 0.5,
    flexDirection: 'column',
  },
  fileImageBox: {
    width: 160,
    height: 100,
  },
  fileButton: {
    flexDirection: 'column',
    marginRight: 10,
  },
  fileTitles: {
    marginTop: 4,
  },
  iconBox: {
    width: 100,
    height: 100,
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: '#f6f6f6',
    borderRadius: 8,
  },
  docType: {
    width: 160,
  },
  topTouchable: {
    flex: 0.3,
  },
  bottomTouchable: {
    flex:1,
  },
  fileTypeBox: {
    marginTop: 16,
    marginLeft: 22,
  },

  //guest
  guestTitles:{
    marginTop: 8,
    marginBottom: 20,
    marginLeft: 22,
  },
  titleText:{
    fontSize: 20,
    fontWeight: '500',
    color: '#313131',
    //marginBottom: 12,
  },
  subTitleText:{
    fontSize: 17,
    fontWeight: '500',
    color: '#7a7a7a',
  },
  titleContainer:{
    paddingHorizontal: 22,
    paddingTop: 22,
  },
  describeText: {
    paddingTop: 12,
    paddingBottom: 12,
    flexWrap: 'wrap',
  },
  inlineButton: {
    //backgroundColor: colors.default.itemActive,
    color: colors.default.mainTrDark,
    textDecorationLine: 'underline',
    borderRadius: 5,
  },
  carTitleBox: {
    marginBottom: 20,
  },


  //send button
  buttonArea: {
    backgroundColor: 'white',
    padding: 20,
    position: 'absolute',
    width: '100%',
    alignSelf: 'center',
    bottom: 0,
  },
  sendButton: {
    marginBottom:10,
  },
  validationBox: {
    padding: 5,
    alignItems: 'center',
  },
  validationText: {
    color: '#732c00',
    textAlign: 'center',
  },

  //car
  carBox: {
    padding: 22,
  },


  ///
  loaderBox: {
    justifyContent: 'center',
    alignContent: 'center',
    width: 60,
    marginRight: 10,
    height: 100,
  },

  //modal
  modalContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#8c8c8c',
  },
  modalBox: {
    //height: '30%',
    padding: 20,
    width: '70%',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  modalText: {
    textAlign: 'center',
    padding: 16,
    fontSize: 16,
    marginBottom: 16,
  },

  //examples
  exampleImageBox: {
    //width: '100%',
    padding: 5,
  },
  exampleImage: {
    //flex: 1,
    //width: '100%',
    //height: 500,
    //resizeMode:'contain',
  },
  exampleTextBox: {

  },
  exampleText: {
    paddingHorizontal: 20,
  },
  exampleScroll: {
    marginTop: 10,
    paddingTop: 20,
  },
  phone: {
    color: colors.default.mainTrDark,
    textDecorationLine: 'underline',
  },

  //progress
  progressBox:{
    flex: 1,
    padding: '10%',
  },
  progressCard: {
    backgroundColor: '#fff',
    borderRadius: 10,
    ...defaultStyles.cardShadow,
    padding: 24,
  },
  progressTitleBox: {
    paddingBottom: 6,
  },
  progressTitles:{

  },
});

export default FileUploader;
