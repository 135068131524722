import React, {useEffect, useState} from 'react';

import * as userProfile from '../GlobalStore/reducers/userProfileSlice';
import * as accommodation from '../GlobalStore/reducers/accommodationSlice';
import {useDispatch, useSelector} from 'react-redux';
import {getCommonState} from '../System/DataStoreRedux';

const errorStates = ['error', 'fail'];

const useProfileWithAccommodation = () => {
  const [loadingState, setLoadingState] = useState(true);
  const [data, setData] = useState({});

  const userProfileValue = useSelector(userProfile.selectValue);
  const userProfileState = useSelector(userProfile.selectState);
  const accommodationValue = useSelector(accommodation.selectValue);
  const accommodationState = useSelector(accommodation.selectState);
  const dispatch = useDispatch();

  useEffect(()=>{
    ///userProfileGetValue && userProfileGetValue(true);
    dispatch(accommodation.getValue()).unwrap()
      .then(
        //res => console.log('accommodation', res)
      ).catch(e=>{
        __DEV__ && console.warn(e);
        setLoadingState('error');
      });
  }, []);

  useEffect(()=>{
    __DEV__ && console.log('STATES:',accommodationState,userProfileState);
    if (
      accommodationState === 'loaded'
      &&
      userProfileState === 'loaded'
    ) {
      setData({
        ...userProfileValue,
        ...accommodationValue,
        initials: accommodationValue?.initials || accommodationValue?.initials,
        name: userProfileValue?.name || userProfileValue?.name,
      });
    }
    setLoadingState(getCommonState([accommodationState, userProfileState]));
  }, [accommodationState, userProfileState]);

  return {
    userProfile: data,
    loadingState,
  };
};

export default useProfileWithAccommodation;
