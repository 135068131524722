import React, {useImperativeHandle, useState, useRef, useEffect, forwardRef} from 'react';
import {View, StyleSheet, ActivityIndicator, TouchableOpacity} from 'react-native';
import {Video} from 'expo-av';
import {Icon} from '@rneui/themed';
import AppText from '../Basic/AppText';


const CardVideo = (props, ref) =>
{
  const {preview, source, style, showLoader, play, isMuted, children} = props;
  const [videoMuted, setVideoMuted] = useState(true);
  const [videoLapse, setVideoLapse] = useState('00');
  const [videoState, setVideoState] = useState({});
  const videoRef = useRef();

  const toggleMute = () => {
    videoRef.current.getStatusAsync &&
    videoRef.current.getStatusAsync().then(status=>{
      //console.log({status});
      videoRef.current.setIsMutedAsync(!status.isMuted).then(()=>{
        setVideoMuted(!status.isMuted);
      });
    });
  };

  useImperativeHandle(ref, ()=>(
    {
      play: () => {
        videoRef.current.playAsync().then(()=>{});
      },
      stop: () => {
        videoRef.current.stopAsync().then(()=>{});
      },
      pause: () => {
        videoRef.current.pauseAsync().then(()=>{});
      },
      mute: (state) => {
        videoRef.current.setIsMutedAsync(state).then(()=>{
          setVideoMuted(state);
        });
      },
      togglePlay: () => {
        videoRef.current.getStatusAsync &&
        videoRef.current.getStatusAsync().then(status=>{
          status.isPlaying ?
            videoRef.current.pauseAsync() :
            videoRef.current.playAsync();
        });
      },
      toggleMute,
    }),[videoRef]
  );

  useEffect(()=>{
    setVideoMuted(isMuted);
  },[isMuted]);

  return (
  <>
    <Video
      ref={videoRef}
      style={[styles.image, style]}
      videoStyle={styles.video}
      isLooping={true}
      isMuted={isMuted !== false}
      shouldPlay={play === undefined ? true : play}
      resizeMode={'cover'}
      height={'100%'}
      usePoster={true}
      posterSource={preview}
      posterStyle={[styles.image, style]}
      source={source}
      onPlaybackStatusUpdate={(state) => {
        //console.log('video state:', state);
        setVideoState({
          isLoaded: state.isLoaded,
          isBuffering: state.isBuffering,
          isPlaying: state.isPlaying,
          shouldPlay: state.shouldPlay,
        });
        let sec = !state.isLoaded ? 0 : (state.durationMillis - state.positionMillis) / 1000;
        if (isNaN(sec)) {
          return setVideoLapse('00');
        }
        let h = parseInt(sec / 3600);
        let m = parseInt((sec % 3600) / 60);
        let s = parseInt((sec % 3600 % 60));
        let format =
          (h === 0 ? '' : String(h).padStart(2, '0') + ':') +
          (m === 0 ? '' : String(m).padStart(2, '0') + ':') +
          (s === 0 ? '00' : String(s).padStart(2, '0'));
        setVideoLapse(format);
      }}
    />
    {children}
    {
      (!videoState.isLoaded || (videoState.isBuffering && !videoState.isPlaying)) && videoState.shouldPlay
      ? <View style={styles.loaderBox}>
        <ActivityIndicator size={'large'}/>
      </View>
      : null
    }
    <TouchableOpacity
      onPress={()=>toggleMute()}
      style={styles.volumeLabel}>
      <Icon
        name={videoMuted ? 'volume-strike' : 'volume'}
        type={'foundation'}
        color={'white'}
        size={18}
      />
    </TouchableOpacity>
    <View style={styles.lapseLabel}>
      <AppText style={styles.lapseText}>{videoLapse}</AppText>
    </View>
  </>
  );
};


const styles = StyleSheet.create(
  {
    volumeLabel:{
      backgroundColor: 'rgba(45,45,45,0.81)',
      borderRadius: 50,
      width: 25,
      height: 25,
      position: 'absolute',
      bottom: 6,
      right: 6,
      justifyContent: 'center',
    },
    lapseLabel:{
      backgroundColor: 'rgba(45,45,45,0.81)',
      borderRadius: 50,
      height: 15,
      position: 'absolute',
      bottom: 6,
      left: 6,
      justifyContent: 'center',
    },
    lapseText: {
      color: 'white',
      paddingHorizontal: 5,
      fontSize: 10,
    },
    image: {
      resizeMode: 'cover',
      height: '100%',
      width: '100%',
    },
    video: {
      width: '100%',
    },
    loaderBox: {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
    },
    loader: {

    },
  }
);

const FowardRefComponent = forwardRef(CardVideo, {});

export default FowardRefComponent;
