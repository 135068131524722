import SystemNavigationBar from 'react-native-system-navigation-bar';
import chroma from 'chroma-js';
import {colors} from '../../Defaults/Styles';
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  defaultColors: colors.default,
  theme: 'default',
  bottomBarColor: '#fff',
};

/*useEffect(()=>{
  colorVar().then(value => setDefaultColors(value));
},[isExternal]);
*/

export const stylesSlice = createSlice({
  name: 'style',
  initialState,
  reducers: {
    setPalette: (state, {payload}) => {
      state.defaultColors = colors[payload];
    },
    setTheme: (state, {payload}) => {
      state.theme = payload;
    },
    setBottomBarColor: (state, {payload}) => {
      //console.log('change bottom bar color to ', payload, 'prev', state);
      SystemNavigationBar.setNavigationColor(
        payload, //color
        chroma(payload).get('lab.l') < 70 ? 'light' : 'dark'
      ).then(()=>{});
      state.bottomBarColor = payload;
    },
  },
});

export const { setPalette, setTheme, setBottomBarColor} = stylesSlice.actions;

export default stylesSlice.reducer;
