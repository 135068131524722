import React from 'react';
import {View, SafeAreaView} from 'react-native';
import AppText from '../Basic/AppText';
import {Icon} from '@rneui/themed';
import {api} from '../System/api';
import { useNavigation } from '@react-navigation/native';
import NewButton from '../Basic/NewButton';

import * as auth from '../GlobalStore/reducers/authSlice';
import {useDispatch} from 'react-redux';

const Logout = props => {
  const dispatch = useDispatch()
  const navigation = useNavigation();
  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{flex: 1}} />
      <View style={{flex: 1}}>
        <Icon
          name={'ios-exit-outline'}
          type={'ionicon'}
          size={50}
          color={'#52646B'}
          style={{marginBottom: 20}}
        />
        <AppText
          style={{
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: 17,
            fontWeight: '500',
          }}>
          {'Вы действительно\nхотите выйти из системы?'}
        </AppText>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignSelf: 'center',
          width: 300,
        }}>
        <NewButton
          onPress={() => navigation.goBack()}
          style={{minWidth: 120}}
        >
          {'Остаться'}
        </NewButton>
        <NewButton
          style={{
            borderWidth: 1,
            borderColor: 'black',
            backgroundColor: 'white',
            minWidth: 120,
          }}
          textStyle={{color: 'black'}}
          onPress={() => {
            api.user.logout()
              .then(() => {
                dispatch(auth.logout());
                //navigation.reset({index: 0, routes: [{name: 'Auth'}]});
              })
              .catch(e=>{
                //if (e?.error === "Token is expired"){
                  dispatch(auth.logout());
                  //navigation.reset({index: 0, routes: [{name: 'Auth'}]});
                //}
              });
          }}
        >
          {'Выйти'}
        </NewButton>
      </View>
    </SafeAreaView>
  );
};

export default Logout;
