import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  backgroundColor: '#fff',
  barStyle: 'dark-content',
  hidden: false,
};

export const statusBarSlice = createSlice({
  name: 'statusBar',
  initialState,
  reducers: {
    setStyle: (state, {payload}) => {
      state = {...state, ...payload};
    },
  },
});

export const { setStyle } = statusBarSlice.actions;

export default statusBarSlice.reducer;
