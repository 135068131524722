import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: undefined,
  state: 'init',
  alreadyViewed: {},
};

/*useEffect(()=>{
  colorVar().then(value => setDefaultColors(value));
},[isExternal]);
*/

export const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setStories: (state, {payload}) => {
      state.value = {
        ...(state.value ? state.value : {}),
        ...payload,
      };
    },
    addViewedStories: (state, {payload:{id, slideId}}) => {
      let prev = state.alreadyViewed[id];
      state.alreadyViewed[id] = {
        id,
        slides: [...new Set( //unique arr values
          [
            ...(prev ? prev.slides : []),
            slideId,
          ]
        )],
      };
    },
  },
});

export const selectStories = (state) => state.stories.value;
export const selectViewedStories = (state) => state.stories.alreadyViewed;

export const {addViewedStories, setStories} = storiesSlice.actions;

export default storiesSlice.reducer;
