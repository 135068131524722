import {colors} from '../../Defaults/Styles';
import {createSlice, create, createAsyncThunk} from '@reduxjs/toolkit';

const initialState = {
  currentRoute: {name:'', path:'', key:'', params:{}, ready:false},
  currentState: {},
  action: {},
};

export const getCurrentRoute = createAsyncThunk(
  'navigation/getCurrentRoute',
  async (payload, {getState, fulfillWithValue}) => {
    const state = getState();
    return fulfillWithValue(state?.navigation?.currentRoute);
  },
);

export const navSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setCurrentRoute: (state, {payload}) => {
      state.currentRoute = payload;
    },
    setAction: (state, {payload}) => {
      state.action = {
        type: payload.type, //push, replace, navigate
        route: payload.route,
        routes: payload.routes || null,
        index: payload?.index,
        params: payload?.params || {},
        options: payload?.options || {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentRoute.fulfilled, (state, action) => {

    });
  },
});

export const selectAction = (state) => state.navigation.action;
export const selectCurrentRoute = (state) => state.navigation.currentRoute;

export const {setCurrentRoute, setAction} = navSlice.actions;

export default navSlice.reducer;
