import React, {useContext} from 'react';
import {Switch, View, StyleSheet} from 'react-native';
import AppText from './AppText';
import {useSelector} from 'react-redux';
import {colors} from '../Defaults/Styles';

const SwitchInput = ({value, style, containerStyle, label, ...props}) =>
{
  return <View style={[styles.container, containerStyle]}>
    <View style={styles.labelBox}>
      <AppText style={styles.label}>
        {label}
      </AppText>
    </View>
    <View style={styles.switch}>
      <Switch
        style={style}
        trackColor={{false: '#767577', true: colors.default.mainLight/*'#b1dec1'*/}}
        thumbColor={value ? colors.default.main : '#f4f3f4'}
        ios_backgroundColor="#fff"
        value={value}
        activeThumbColor={colors.default.main}
        activeTrackColor={'#b1dec1'}
        {...props}
      />
    </View>
  </View>;
};

const styles = StyleSheet.create({
  container:{
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 15,
  },
  labelBox: {
    flex: 1,
    justifyContent: 'center',
  },
  label:{
    fontWeight: '400',
    fontSize: 14,
    //color: '#86939e',
  },
  switch: {
    flex: 1,
    flexGrow: 0.2,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
});

export default SwitchInput;
