import {getMinWidth} from '../Defaults/DisplayWidth';

import React, {forwardRef, useImperativeHandle} from 'react';
import {TouchableOpacity, View, ScrollView, StyleSheet} from 'react-native';
import {BackgroundImage} from '@rneui/base';
import AppText from '../Basic/AppText';
import {SkeletonContainer, Skeleton} from 'react-native-skeleton-component';
import {useNavigation} from '@react-navigation/native';
import useStoriesLoader from './useStoriesLoader';


import * as auth from '../GlobalStore/reducers/authSlice';
import {useSelector} from 'react-redux';


/*const exampleStories = [
  {
    id: 1,
    images: [
      require('../../img/demo/Story_cocktail.png'),
      require('../../img/demo/Story_gastroset.png'),
      require('../../img/demo/Story_tea.png'),
    ],
    miniature: require('../../img/demo/Story_gastroset.png'),
    title: 'Гастрофест \nв Мрии',
    description: '',
    item: '',
    itemType: '',
  },
  {
    id: 2,
    images: [
      require('../../img/demo/mandarin.jpeg'),
    ],
    miniature: require('../../img/demo/mandarin.jpeg'),
    title: 'Гастрофест \nв Мрии',
    description: '',
    item: '',
    itemType: '',
  },
  {
    id: 3,
    images: [
      require('../../img/demo/mandarin.jpeg'),
    ],
    miniature: require('../../img/demo/mandarin.jpeg'),
    title: 'Гастрофест \nв Мрии',
    description: '',
    item: '',
    itemType: '',
  },
  {
    id: 4,
    images: [
      require('../../img/demo/mandarin.jpeg'),
    ],
    miniature: require('../../img/demo/mandarin.jpeg'),
    title: 'Гастрофест \nв Мрии',
    description: '',
    item: '',
    itemType: '',
  },
  {
    id: 5,
    images: [
      require('../../img/demo/mandarin.jpeg'),
    ],
    miniature: require('../../img/demo/mandarin.jpeg'),
    title: 'Гастрофест \nв Мрии',
    description: '',
    item: '',
    itemType: '',
  },
];*/
const windowWidth = getMinWidth();

const Story = ({
                 id,
                 images,
                 miniature,
                 miniatureMd,
                 miniatureSm,
                 title,
                 item,
                 itemType,
                 onPress,
               }) => {
  return (
    <TouchableOpacity
      onPress={()=>onPress && onPress(id)}
      style={styles.storyBox}
    >
      <BackgroundImage
        source={miniatureSm}
        style={styles.storyBackgr}
        imageStyle={styles.storyImg}
      />
      <View style={styles.storyTitle}>
        <AppText style={styles.storyTitleText}>{title}</AppText>
      </View>
    </TouchableOpacity>
  );
};

const StoriesListPlaceholder = () => (
  <SkeletonContainer animation={'wave'}>
    <View style={styles.storyBox}>
      <Skeleton
        style={[styles.storyBackgr, styles.storyImg]}
        //imageStyle={styles.storyImg}
      />
      <Skeleton style={[styles.storyTitle, {borderRadius: 6, justifyContent: 'center'}]}>
        <AppText style={[styles.storyTitleText, {minHeight: 12, minWidth: 100}]}/>
      </Skeleton>
    </View>
  </SkeletonContainer>
);

const StoryList = forwardRef((props, ref) =>
{
  const navigation = useNavigation();
  const {stories, load, reload} = useStoriesLoader();

  const authState = useSelector(auth.selectState);

  useImperativeHandle(ref, ()=>({
    reload: () => {
      reload();
    },
  }), [reload]);

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      horizontal={true}
      //style={}
      contentContainerStyle={styles.storyScroll}
    >
      {stories && Object.values(stories)
        .filter(item => item?.entityType !== 'specialoffer' || (item?.entityType === 'specialoffer' && authState === 'auth'))
        .sort((a,b) => ((99999 - (a?.prior || 0) - (99999 - (b?.prior || 0)))))
        .map((item) =>
        <Story
          key={item.id}
          {...item}
          onPress={()=>{
            navigation.navigate('StoryGallery', {id: item.id});
          }}
        />
      )}
      {load !== false || !stories
        ? (Array
          .from(Array(4).keys())
          .map(v => <StoriesListPlaceholder key={v}/>)
        ) : null
      }
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  //story
  storyScroll: {
    paddingLeft: 12,
  },
  storyBox: {
    //flexDirection: 'row',
    width: windowWidth * 0.26,
    marginRight: 12,
  },
  storyImg: {
    borderRadius: 1000,
    borderWidth: 2,
    borderColor: '#ffffff',
    backgroundColor: '#f3f3f3',
  },
  storyBackgr: {
    width: '100%',
    aspectRatio: 1,
    minHeight: windowWidth * 0.26, // if not supports aspect-ratio
  },
  storyTitle: {
    maxWidth: 106,
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  storyTitleText: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 13,
    lineHeight: 15,
    fontWeight: '500',
  },
});

export default StoryList;
