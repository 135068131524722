import React, {useCallback, useRef, useImperativeHandle, forwardRef} from 'react';
import {
  View,
  StyleSheet,
  Platform, FlatList,
} from 'react-native';
import SimpleBottomSheet from './SimpleBottomSheet';
import {colors} from '../Defaults/Styles';
import ActivityDetailsNew from '../Activities/ActivityDetailsNew';
import Animated from 'react-native-reanimated';
import {BottomSheetFlatList} from '@gorhom/bottom-sheet';

const ScrollComponent = Animated.createAnimatedComponent(Platform.OS === 'web' ? FlatList : BottomSheetFlatList);

const ActivityBottomSheet =
  ({
     activity,
     onPressBooking,
     onPressTag,
     onPressGeo,
     updateActivity,
   }, ref) => {

    const activityRef = useRef();
    const sheetRef = useRef();

    useImperativeHandle(ref, ()=>({
      ...sheetRef.current,
    }));

    const handleChanges = useCallback((index) =>
    {
      if (index === 1) {
        activityRef.current?.playVideo();
      }
    }, [activity]);

    return <SimpleBottomSheet
      ref={sheetRef}
      index={0}
      lastPoint={'90%'}
      onChange={handleChanges}
    >
      <View style={styles.container}>
        <View style={styles.mainContainer}>
          <ActivityDetailsNew
            ref={activityRef}
            {...activity}
            onPressTag={onPressTag}
            onPressGeo={onPressGeo}
            updateActivity={updateActivity}
            roundTop={true}
            ScrollComponent={ScrollComponent}
          />
        </View>
        {/*<ActivityOfferPrice
          {...activity}
          loading={activity?.offerId && loading && !activity?.offer}
          onPressBook={handlePressBook}
        />*/}
      </View>
    </SimpleBottomSheet>;
  };

const FowardRefComponent = forwardRef(ActivityBottomSheet, {});

export default FowardRefComponent;

const styles = StyleSheet.create({
  container: {
    //height: '100%',
    height: Platform.OS === 'web' ? 'inherit' : '100%',
  },
  validateContainer: {
    //flex: 1,
    alignItems: 'center',
  },
  validateText: {
    color: '#a26524',
    padding: 3,
    paddingBottom: 6,
  },
  mainContainer:{
    flex: 5,
  },
  priceContainer:{
    backgroundColor: 'white',
    //flex: 0.3,
    minHeight: 10,
    width: '100%',
    position: 'relative',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  priceText:{
    fontSize: 20,
    lineHeight: 22,
    color: colors.default.main,
    textAlign: 'center',
    paddingVertical: 5,
  },
  bookingContainer: {
    minHeight: 110,
  },
  bookingButtonBox: {
    flexDirection: 'column',
    minHeight: 80,
    paddingTop: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.41,
    backgroundColor: 'white',
  },
  caption:{
    fontSize: 16,
    marginVertical: 5,
    color: '#23b865',
    fontWeight: '400',
  },
});
