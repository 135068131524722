import React, {useEffect, useState} from 'react';
import {FlatList, StyleSheet, View} from 'react-native';
import AppText from '../Basic/AppText';
import Tariff from './Tariff';
import humanize from 'humanize-number';
import {BottomSheetFlatList} from '@gorhom/bottom-sheet';

const Total = ({total}) => {
  return <View style={styles.totalContainer}>
    <AppText style={styles.totalText}>
      {'Итого: ' + humanize(total, {delimiter: ' '}) + ' ₽'}
    </AppText>
  </View>;
};

const TariffCounter = ({
                         tariffs,
                         onCalcPrice,
                         showTotal,
                         containerStyle,
                         bottomSheet,
                         discount,
}) => {
  const [state, setState] = useState({});

  const ListComponent = bottomSheet ? BottomSheetFlatList : FlatList;

  useEffect(()=>{
    if (tariffs) {
      let obj = {};
      for (let i in tariffs) {
        let v = tariffs[i];
        obj = {...obj, [v.id]: v};
      }
      setState(obj);
      onCalcPrice &&
      onCalcPrice(
        parseInt(calcPrice(obj)),
        Object.values(obj),
      );
    }
    //console.log('TARIFFS', tariffs);
  }, []);

  const handleCounter = (id, count) => {
    let value;
    if (id) {
      value = {
        ...state,
        [id]: {...state[id], count},
      };
      setState(value);
      onCalcPrice &&
      onCalcPrice(
        parseInt(calcPrice(value)),
        Object.values(value),
      );
    }
  };

  const calcPrice = (values) => Object.values(values).reduce((a, v) => {
    return a + (v.price - v.price * ((discount || 0) / 100)) * (v.count || 0);
  },0);

  const tariffRender = ({item}) =>
    <Tariff
      {...item}
      onCounterChange={handleCounter}
      discount={discount}
    />;

  return (
    <View style={[styles.tariffItemsCounter, containerStyle]}>
      <ListComponent
        data={
          /** sorted by @prior desc and @price asc;*/
          tariffs.sort((a,b)=> (b.prior || 0) - (a.prior || 0) || a.price - b.price)
        }
        renderItem={tariffRender}
        keyExtractor={item => item.id}
      />
      {showTotal ? <Total total={calcPrice(state)}/> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  tariffItemsCounter:{
    //padding: 10,
    flex: 1,
  },
  price:{
    textAlign: 'right',

    fontSize: 16,
  },
  totalContainer:{
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  totalText:{

    fontSize: 18,
    color: '#777676',
  },
});

export default TariffCounter;
