import React, {useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  ImageBackground, Platform,
} from 'react-native';
import AppText from '../Basic/AppText';
import PageHeader from '../Basic/PageHeader';
//import chroma from 'chroma-js';
import AntiMenuFade from '../Basic/AntiMenuFade';
import {defaultStyles} from '../Defaults/Styles';
import useCategoryLoader from './CategoriesLoader';
import {useNavigation} from '@react-navigation/native';
import LinearGradient from 'react-native-linear-gradient';

import * as categoriesStore from '../GlobalStore/reducers/categoriesSlice';
import {useSelector, useDispatch} from 'react-redux';

const defaultColor = '#efffe9';
const cardsOnLine = 3;
const cardsMargin = (cardsOnLine - 1) / cardsOnLine;

const selectedGradient = ['#00000000','rgba(0,0,0,0.55)'];

const demoData = [
  {id:1, color:'#faf5ff', name:'Тематические активности', selected:true},
  {id:2, color:'#ffeffe', name:'Отдых с детьми'},
  {id:3, color:'#edffed', name:'Экскурсии и туры'},
  {id:4, color:'#FFEFEF', name:'Спорт'},
  {id:5, color:'#FFF9F2', name:'Гастрономия'},
  {id:6, color:'#EDFCFC', name:'Красота'},
  {id:7, color:'#FFFEE8', name:'Здоровье'},
  {id:8, color:'#E8EAFC', name:'Афиша'},
];

const hasLongWords = (text, maxLen = 10) => {
  let regexp = new RegExp(`[A-Za-zА-Яа-я]{${maxLen + 1},9999}`,'g');
  return regexp.test(text);
};

const Category = ({id, name, index, count, color, selected, onPress, image}) => {
  let residue = count % cardsOnLine;
  const cardWidth = 100 / cardsOnLine - (cardsOnLine > 1 ? 1 : 0);
  let lastsWidth = residue > 1 ? (100 / residue - cardsMargin) : 100;
  color = color || defaultColor;
  let longWords = hasLongWords(name, 10);

  //console.log('darken:',chroma(color).darken(1).saturate(10).hex());
  const handlePress = () => {
    onPress && onPress({id, index, name});
  };

  return (
    <TouchableOpacity
      activeOpacity={0.85}
      onPress={handlePress}
      style={[
        styles.categoryBox,
        {width: cardWidth + '%'},
        (index >= (count - residue) ? {width: lastsWidth + '%'} : null),
        //{backgroundColor: color, borderColor: color},
        //(selected ? {borderColor: chroma(color).darken(1.5).saturate(5).hex()/*.desaturate()*/} : null),
      ]}
    >
      <ImageBackground
        style={styles.categoryBackgr}
        source={image}
        imageStyle={[styles.categoryImage, { opacity: selected ? 1 : 0.45}]}
      >
        {!selected ?
          <LinearGradient colors={['rgba(255,255,255,0.90)', 'rgba(255,255,255,0)']} style={styles.categoryCardGradTop}/>
          : null}
        <LinearGradient colors={selectedGradient} style={styles.categoryCardGrad}/>
        <AppText
          style={[
            styles.categoryTitle,
            longWords ? styles.categorySmallTitle : {},
            //selected ? {color: '#fff'} : {},
          ]}
        >
          {name}
        </AppText>
      </ImageBackground>
    </TouchableOpacity>
  );
};

const CategoryChoice = () => {
  const navigation = useNavigation();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const {categories, loading} = useCategoryLoader();

  const dispatch = useDispatch();

  const chosenCategories = useSelector(categoriesStore.selectChosen);

  const handleNext = () => {
    dispatch(categoriesStore.setChosen(selectedCategories));
    navigation.navigate({name:'InterestsChoice'});
  };

  const handleDiscard = () => {
    setSelectedCategories([]);
    /*setCategories(v => {
      return [...v.map(i=>{i.selected = false; return i;})];
    });*/
  };

  useEffect(()=>{
    chosenCategories && setSelectedCategories(chosenCategories);
    chosenCategories && console.log({chosenCategories});
  },[chosenCategories]);


  /*useEffect(()=>{
    if (loading !== 'loading' && Array.isArray(categories)){
      let selected = categories.filter(i=>i.selected).map(i=>i.id);
      userCategoriesSetValue(selected);
      console.log('selected items:', selected);
    }
  },[categories, loading]);*/

  const handlePress = ({id}) => {
    /*setCategories(v => {
      let indx = v.findIndex(i => i.id === id);
      v[indx].selected = !v[indx].selected;
      return ([...v]);
    });*/
    setSelectedCategories(v=>{
      if (!v.includes(id)){
        v = v.concat(id);
      } else {
        v = v.filter(i=>i !== id);
      }
      return v;
    });
  };

  return (
    <SafeAreaView style={styles.container} >
      <PageHeader title={'Подбор интересов'}/>
      <View style={styles.mainBox}>
        <View style={styles.mainTitleBox}>
          <AppText style={styles.mainTitle}>
            Выберите интересующие вас категории
          </AppText>
        </View>
        <View style={styles.categoriesScroll}>
          <ScrollView
            //style={{flexGrow:1}}
            contentContainerStyle={styles.categoriesBox}
          >
          {(categories ? Object.values(categories) : [])
            .map((v,i) =>
              <Category
                key={v.id}
                {...v}
                index={i}
                count={Object.values(categories).length}
                onPress={handlePress}
                selected={selectedCategories.includes(v.id)}
              />
            )
          }
          {loading === 'loading'
            ? <View style={styles.loader}>
                <ActivityIndicator style={styles.loader} size={'large'}/>
              </View>
            : null}
          <AntiMenuFade height={160}/>
        </ScrollView>
        </View>
        <View style={styles.buttonBox}>
          <TouchableOpacity style={styles.buttonNext} onPress={handleNext}>
            <AppText style={styles.buttonNextText}>{'Далее'}</AppText>
          </TouchableOpacity>
          <TouchableOpacity style={styles.buttonDiscard} onPress={handleDiscard}>
            <AppText style={styles.buttonDiscardText}>{'Сбросить'}</AppText>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  //choicer
  mainBox: {
    paddingHorizontal: 12,
    flex: 1,
  },
  container: {
    width: '100%',
    flex: 1,
    backgroundColor: 'white',
  },
  mainTitleBox: {
    paddingTop: 20,
    paddingBottom: 16,
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: 24,
    lineHeight: 27,
  },
  categoriesScroll: {
    ...defaultStyles.webScrollView,
    //position: 'relative',
  },
  categoriesBox: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  //category
  categoryBox: {
    width: '32%',
    height: 165,
    backgroundColor: 'rgb(255,255,255)',
    borderColor: 'rgb(250,250,250)',
    borderRadius: 10,
    marginBottom: 25,
    borderWidth: 2,
  },
  categoryCardGradTop: {
    height: 165 * 0.3,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  categoryCardGrad: {
    height: 165 * 0.32,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  categoryTitle: {
    //color: 'rgba(34, 34, 34, 1)',
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: '500',
    fontSize: 17,
    lineHeight: 20,
    padding: 10,
    position: 'absolute',
    bottom: 0,
  },
  categorySmallTitle: {
    fontSize: 14,
  },
  categoryBackgr: {
    width: '100%',
    height: '100%',
    resizeMode: 'stretch',
  },
  categoryImage: {
    borderRadius: 10,
  },

  //new button style
  buttonBox: {
    height: 100,
    backgroundColor: 'white',
    marginBottom: Platform.OS === 'web' ? 20 : 0,
  },
  buttonNext: {
    width: '100%',
    height: 48,
    borderRadius: 6,
    backgroundColor: 'rgba(51, 51, 51, 1)',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 12,
  },
  buttonNextText: {
    fontSize: 16,
    color: 'white',
  },
  buttonDiscard: {
    alignSelf: 'center',
    padding: 12,
  },
  buttonDiscardText: {
    color: 'rgb(103,103,103)',
  },

  //loader
  loader: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
  },
});

export default CategoryChoice;
