import React, {
  useContext, useCallback, forwardRef,
} from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity, SectionList, Platform,
} from 'react-native';

import {BackgroundImage} from '@rneui/base';
import AppText from '../Basic/AppText';
import humanize from 'humanize-number';
import {useSelector} from 'react-redux';
import Animated from 'react-native-reanimated';
import {defaultStyles} from '../Defaults/Styles';

const TileCardDefault = (props) => {
  const {
    id,
    title,
    description,
    mainImage,
    price,
    //events
    onPress,
  } = props;

  const defaultColors = useSelector((state) => state.style.defaultColors);

  //console.log('card props', Object.keys(props));
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={styles.cardBox}
      onPress={()=>{onPress && onPress({id});}}
    >
      <BackgroundImage
        source={mainImage}
        style={styles.cardImage}
        imageStyle={styles.cardImageWrap}
        resizeMode={'cover'}
      />
      <View style={styles.descriptionBox}>
        <AppText style={[styles.cardPrice, {color: defaultColors.main}]}>
          {price
            ? (price?.from ? 'от ' : '') + '₽' + (humanize(parseInt(price?.price), {delimiter: ' '}))
            : 'Бесплатно'
          }
        </AppText>
        <AppText style={styles.cardTitle}>{title}</AppText>
      </View>
    </TouchableOpacity>
  );
};

/**
 * Section data format
 *
 * [
 *   {
 *     "prior": 0,
 *     "section": {
 *       "id": 0,
 *       "title": "Name of section"
 *     },
 *     "data": [
 *       {
 *         "list": [
 *           {...offer},
 *           {...offer},
 *           {...offer}
 *         ]
 *       }
 *     ]
 *   }
 * ]*/

const Tile = forwardRef((props, ref) =>
{
  let {
    sections,
    renderTileCard,
    renderSectionHeader,
    keyExtractor,
    cardKeyExtractor,
    headerEndReached,
    sectionsEndReached,
    loading,
    ListHeader,
    ListFooter,
    onPressCard,
    columns,
    SectionListComponent,
    sectionListStyle,
    onScroll,
    ...other
  } = props;

  if (!SectionListComponent){
    SectionListComponent = Animated.FlatList;
  }

  //SectionListComponent = SectionList;

  const _renderTileCard = ({item}) => <TileCardDefault {...item} onPress={onPressCard}/>;

  const _cardKeyExtractor = ((item)=>{return item.id;});

  const renderSection = useCallback(({item:{list}}) => {
    return <FlatList
      data={list}
      initialNumToRender={1000}
      disableVirtualization={Platform.OS === 'web'}
      renderItem={renderTileCard || _renderTileCard}
      keyExtractor={cardKeyExtractor || _cardKeyExtractor}
      numColumns={columns || 2}
      columnWrapperStyle={styles.cardListItem}
    />;
  },[renderTileCard]);

  const _renderSectionHeader = ({section}) => {
    return (
      <View style={styles.cardListSectionSep}>
        <View style={styles.cardListSection}>
          <AppText style={styles.cardListSectionText}>
            {section.section.title}
          </AppText>
        </View>
      </View>
    );
  };

  return (
    <SectionListComponent
      ref={ref}
      initialNumToRender={1000}
      stickySectionHeadersEnabled={true}
      renderSectionHeader={renderSectionHeader || _renderSectionHeader}
      style={[styles.cardPanel, styles.cardList, sectionListStyle]}
      //data={Object.values(offers)}
      sections={sections}
      keyExtractor={keyExtractor || (i=>i)}
      ListHeaderComponent={ListHeader}
      ListFooterComponent={ListFooter}
      viewabilityConfig={{
        viewAreaCoveragePercentThreshold: 20,
      }}
      disableVirtualization={Platform.OS === 'web'}
      legacyImplementation={Platform.OS === 'web'}
      onEndReached={sectionsEndReached || (()=>{})}
      scrollEventThrottle={32}
      onScroll={onScroll || (()=>{})}
      {...other}
      renderItem={renderSection}
    />
  );
});

const styles = StyleSheet.create({
  cardPanel: {
    flex: 1,
  },
  cardList:{
    paddingHorizontal: 12,
  },
  cardListItem:{
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  cardListSectionSep: {
    backgroundColor: '#F6F6F6FF',
    paddingTop: 8,
    marginLeft: -15,
    marginRight: -15,
  },
  cardListSection: {
    //top: -1,
    padding: 10,
    paddingTop: 18,
    width: '100%',
    backgroundColor: '#fff',
  },
  cardListSectionText: {
    fontSize: 21,
    lineHeight: 22,
    fontWeight: '400',
    textAlign: 'center',
    color: '#5d5d5d',
  },


  //tileCard
  cardBox:{
    width: '48.5%',
    //height: 150,
    backgroundColor: 'white',
  },
  cardPrice:{
    marginTop: 4,
    color: '#FDB161',
    fontWeight: '500',
  },
  cardTitle:{
    marginTop: 4,
    fontSize: 14,
    lineHeight: 15,
  },
  cardImage: {
    width: '100%',
    aspectRatio: 17 / 16,
    borderRadius: 8,
    backgroundColor: '#F6F6F6FF',
  },
  cardImageWrap: {
    borderRadius: 8,
  },
  descriptionBox:{
    paddingLeft: 4,
  },
});

export default Tile;
