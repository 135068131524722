import {api} from '../System/api';
import moment from 'moment/moment';
import {levelByName} from '../Loyalty/LevelsData';

const userDemoData = (f) => ({
  //id : 6,
  //fio : '',
  //lastfirsname : '',
  //email : '',
  //isApproved : true,
  //phone : "77777777777",
  //dob : null,
  //sendEmails : true,
  //sendSms : true,
  //accumulationbalance : 3000000,
  //bonusbalance : 13000,
  depositbalance : 2000,
  mainballance : null,
  checkindate : moment().add(-3, 'd').format('YYYY-MM-DD'),
  checkoutdate : moment().add(4, 'd').format('YYYY-MM-DD'),
  roomnumber : 2525,
  roomtype : 'Villa',
  //----------------- not found
  loyaltyLevel: levelByName('Diamond'),
  //----------------- testing data
  pointAccrued: 3000000,
  pointBalance: 13000,
});


const accommodationStoreParams = {
  name: 'accommodation',
  expires: 10, //10 minutes
  attempts: 3,
  loadOnInit: false,
  load: (state) => {
    //setState('loading');
    return new Promise((resolve, reject) => {
      api.user.accommodation().then((res)=>{
        //setState('loaded');
        __DEV__ && console.log('accommodation data',res.data);
        let data = res.data;
        resolve(
          {
            ...data,
            pointAccrued: data?.accumulationbalance,
            pointBalance: data?.bonusbalance,
            loyaltyLevel: levelByName(data?.cardType || ''),
            depositBalance: data?.depositbalance,
            //initials:
            //...(__DEV__ ? userDemoData(data) : {}),
          });
      }).catch(e => {
        //setState('error');
        //console.warn(e);
        __DEV__ && console.warn('accommodation data is not found');
        reject(e);
      });
    });
  },
  /*onError: (setState, load) => {
    setState && setState('fail');
    return load(setState);
  },
  onExpired: (setState, load, attempt) => {
    setState && setState('loading');
    return load(setState, attempt);
  },*/
};

const userProfileStoreParams = {
  name: 'userProfile',
  expires: 10, //10 minutes
  attempts: 3,
  loadOnInit: false,
  load:(state) => {
    return new Promise((resolve, reject) => {
      api.user.get().then(res => {
        let data = res?.data || {};
        //console.log('user_data:', res);
        let initials = (data?.fio || data?.first_name);
        resolve({
          ...data,
          initials: Array.isArray(initials) ? initials[0] : initials,
          name: (data?.fio || data?.first_name),
          referralToken: data?.refaralToken || data?.referralToken,
        });
      }).catch(reject);
    });
  },
  //onError,
  //onExpired,
};

export {
  accommodationStoreParams,
  userProfileStoreParams,
};
