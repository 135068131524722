import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {Text, StyleSheet} from 'react-native';
import {FontFamily} from '../Defaults/Styles';

const Link = forwardRef(({title, url, style, target}, ref) => {

  const linkRef = useRef();
  useImperativeHandle(ref,()=>({
    selfCkick: () => {
      console.log(linkRef.current);
      linkRef.current.click();
    },
  }));

  return (
    <Text
      accessibilityRole={'link'}
      href={url}
      hrefAttrs={{
        target: target || '_self',
      }}
      style={[styles.textStyle, style]}
    >
      {title}
    </Text>
  );
});

const styles = StyleSheet.create({
  textStyle: {
    FontFamily,
  },
});

export default Link;
