import React, {createContext, useContext, useRef, useState} from 'react';
import SimpleBottomSheet from './SimpleBottomSheet';
import {BottomSheetModalProvider, BottomSheetScrollView} from '@gorhom/bottom-sheet';
import {Platform, ScrollView} from 'react-native';

const DynamicBottomSheetContext = createContext();

const ScrollComponent = Platform.OS === 'web' ? ScrollView : BottomSheetScrollView;

export const DynamicBottomSheetProvider = ({children}) => {

  const [content, setContent] = useState();
  const bottomSheetRef = useRef();

  const openWith = (item) => {
    setContent(item);
    bottomSheetRef.current?.open();
  };

  const close = () => {
    bottomSheetRef.current?.close();
  };

  const functions = {
    openWith,
    close,
  };

  return <DynamicBottomSheetContext.Provider value={functions}>
    {children}
    <BottomSheetModalProvider>
      <SimpleBottomSheet ref={bottomSheetRef}>
        <ScrollComponent>
          {content}
        </ScrollComponent>
      </SimpleBottomSheet>
    </BottomSheetModalProvider>
  </DynamicBottomSheetContext.Provider>;
};

export const useDynamicBottomSheet = () =>
  useContext(DynamicBottomSheetContext);
