import React from 'react';
import {WebView} from 'react-native-webview';
import {Platform, SafeAreaView, View} from 'react-native';
import {getMinWidth} from '../Defaults/DisplayWidth';

const windowWidth = getMinWidth();
import PageHeader from '../Basic/PageHeader';


const WebViewDoc = ({doc, title}) => {

  return (
    <SafeAreaView
      style={{flex: 1, backgroundColor: '#fff'}}
    >
      {title ? <PageHeader title={title}/> : null}
      <View style={{paddingHorizontal: 18, flex: 1}}>
        <WebView
          //key={webKey}
          nestedScrollEnabled={true}
          automaticallyAdjustContentInsets={false}
          scrollEnabled={true}
          source={Platform.select({
            web : {html: doc},
            android: {html: doc},
            ios: {html: doc},
          })}
          //onContentProcessDidTerminate={reload}
          style={{
            //justifyContent: 'center',
            width: '100%',//windowWidth - (18 * 2),
            //marginRight: -windowWidth * (Platform.OS === 'web' ? 0 : 0.18),
          }}
          onShouldStartLoadWithRequest={request =>
          {
            console.log('PREVENT CLICK ',request);
            return request.navigationType !== 'click';
          }
          }
          onNavigationStateChange={request =>
          {
            console.log('PREVENT CLICK ',request);
            return request.navigationType !== 'click';
          }
          }
          javaScriptEnabled={true}
          injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=0.5, maximum-scale=0.5, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
          scalesPageToFit={false}
        />
      </View>
    </SafeAreaView>
  );
};

export default WebViewDoc;
