import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
//import {StyleSheet, View, Text} from 'react-native';
//import {Picker as RNPicker} from '@react-native-picker/picker';
import {FlatList, StyleSheet, TouchableOpacity} from 'react-native';
import AppText from '../Basic/AppText';
import {colors} from '../Defaults/Styles';
import {objectsEqual} from '../System/helpers';

import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Moscow');

const timeIsOver = (time, minTime) => {
  //console.log('timeCompare', moment(time,'HH:mm'), minTime);
  return moment(time,'HH:mm').isBefore(moment(minTime));
};

const TimePicker = memo(({
                      value,
                      timeList,
                      min,
                      max,
                      minTime,
                      hourStep,
                      minuteStep,
                      onChange,
                      pickerStyle,
                      itemStyle,
                      optionStyle,
                    }) => {
  const listRef = useRef();
  const scrollWaitRef = useRef();
  const [selectedValue, setSelectedValue] = useState();
  const [scrollToTrigger, setScrollToTrigger] = useState(1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (timeList && timeList.length > 0){
      //console.log(timeList);
      for (let i in timeList) {
        let h = timeList[i].replace(/^(\d\d):.*?$/,'$1');
        let m = timeList[i].replace(/^.*?:(\d\d)$/,'$1');
        if ((!min || parseInt(h) >= min) && (!max || parseInt(h) <= max)){
          let _time = `${h}:${m}`;
          if (!timeIsOver(_time, minTime)) {
            setItems(v => v.concat(_time));
          }
        }
      }
    } else {
      if (min !== undefined && max !== undefined) {
        for (let i = min; i <= max; i += hourStep || 1) {
          if (minuteStep){
            for (let j = 0; j < 60; j += minuteStep){
              let _time = String(i).padStart(2, '0') + ':' + String(j).padStart(2, '0');
              if (!timeIsOver(_time, minTime)) {
                setItems(v => v.concat(_time));
              }
            }
          } else {
            let _time = String(i).padStart(2, '0') + ':00';
            if (!timeIsOver(_time, minTime)) {
              setItems(v => v.concat(_time));
            }
          }
        }
      } else {
        setItems(timeList || []);
      }
    }

    setSelectedValue(value);
    return () => {
      setItems([]);
    };
  }, [min, max, timeList]);

  const handleChangeValue = (itemValue) => {
    setSelectedValue(itemValue);
    onChange(itemValue);
  };

  useEffect(()=>{
    return () => {
      clearInterval(scrollWaitRef.current);
    };
  },[]);

  useEffect(()=>{scrollToCurrent();}, [scrollToTrigger]);

  const scrollToCurrent = useCallback(()=>{
    //console.log({selectedValue, items});
    let itemIndex = items.findIndex(i=>i === selectedValue);
    try {
      //console.log('scroll to',itemIndex);
      listRef.current?.scrollToIndex({index: itemIndex});} catch (e){console.log('scroll to error',itemIndex);}
  },[items, selectedValue]);

  useEffect(() => {
    let tmr;
    if (value) {
      setSelectedValue(value);
      setTimeout(()=>{setScrollToTrigger(v=>++v);},1000);
    }
    return () => {
      clearInterval(tmr);
    };
  }, [value]);

  useEffect(()=>{
    //on change list check exist, and set first if not
    if (selectedValue && items.length > 0 && !items.includes(selectedValue)){
      //console.log('items:', selectedValue, items);
      onChange(items[0]);
    }
  }, [selectedValue, items]);

  const kickScroller = () => {
    clearTimeout(scrollWaitRef.current);
    scrollWaitRef.current = setTimeout(()=>setScrollToTrigger(v=>++v), 4000);
  };

  const renderItem = (item) => {
    return (<Item
      {...item}
      selected={item.item === selectedValue}
      onPress={handleChangeValue}
    />);
  };

  return (
    <FlatList
      ref={listRef}
      showsHorizontalScrollIndicator={false}
      horizontal={true}
      renderItem={renderItem}
      keyExtractor={(item)=> 'timePicker' + item}
      data={items}
      style={pickerStyle}
      itemStyle={itemStyle}
      onScroll={kickScroller}
      scrollEventThrottle={8}
    />
  );
},(p,n)=>{
  return objectsEqual(p,n,['value', 'min', 'max', 'timeList']);
});

const styles = StyleSheet.create({
  item: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: colors.default.item,
    marginRight: 10,
    marginBottom: 10,
    borderRadius: 100,
  },
  itemSelected: {
    backgroundColor: colors.default.main,
  },
  itemLabel: {
    color: 'rgba(113, 113, 113, 1)',
    fontSize: 13,
  },
  itemLabelSelected: {
    color: '#fff',
  },
});

const Item = memo(({item, selected, onPress}) => {
  return (
    <TouchableOpacity
      onPress={()=>onPress(item)}
      style={[styles.item, selected ? styles.itemSelected : null]}
    >
      <AppText style={[styles.itemLabel, selected ? styles.itemLabelSelected : null]} >
        {item}{/*item.replace(':',' : ')}*/}
      </AppText>
    </TouchableOpacity>
  );
},(p,n)=>objectsEqual(p,n,['item','selected']));

export default TimePicker;
