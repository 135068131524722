import React, {useState, useEffect, createContext, useContext} from 'react';
import {Dimensions} from 'react-native';

const DimensionsContext = createContext();

const maxWidth = 500;
const maxHeight = 9000;
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const DimensionsContextProvider = ({children}) => {
  console.log('rerender dimensions context');
  const isPortraitOrient = () => {
    const dim = Dimensions.get('screen');
    return dim.height >= dim.width;
  };

  const [width, setWidth] = useState(Math.min(windowWidth, maxWidth));
  const [height, setHeight] = useState(Math.min(windowHeight, maxHeight));
  const [isPortrait, setIsPortrait] = useState(isPortraitOrient());

  useEffect(() => {
    let dimensionsSubscription = Dimensions.addEventListener('change', () => {
      setWidth(getMinWidth());
      setHeight(getMinHeight());
      setIsPortrait(isPortraitOrient());
    });

    return () => {
      dimensionsSubscription?.remove();
    };
  }, []);

  const getMinWidth = () => {
    return Math.min(Dimensions.get('window').width, maxWidth);
  };
  const getMinHeight = () => {
    return Math.min(Dimensions.get('window').height, maxHeight);
  };

  return (
    <DimensionsContext.Provider
      value={{
        width,
        height,
        isPortrait,
        getMinWidth,
        getMinHeight,
      }}>
      {children}
    </DimensionsContext.Provider>
  );
};

export const useAppDimensions = () => useContext(DimensionsContext);

const getMinWidth = () => {
  return Math.min(windowWidth, maxWidth);
};

export {
  maxWidth,
  maxHeight,
  getMinWidth,
  DimensionsContext,
  DimensionsContextProvider,
  windowWidth,
  windowHeight,
};
