import React, {useEffect, useState} from 'react';

//import Interests from './old/Interests';
import TabBar from './TabBar';
import Header from './Header';
import RoomService from '../RoomService/TileLazy';
import Activities from './ActivitiesLazy';

import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import MainPage from './Main';
import ByInterests from '../Offers/ByInterestsLazy';
import Profile from '../User/ProfileLazy';
import UpcomingBookings from '../Booking/UpcomingBookings';
import settings from '../Defaults/settings';

const myRestImage = require('../../img/logo/LOGO_50.png');

const Tab = createBottomTabNavigator();


const MainTabs = () => {

  return (
    <Tab.Navigator
      backBehavior={'history'}
      tabBar={props => <TabBar {...props} />}
      screenOptions={{
        showTitles:true,
        gestureEnabled: true,
        gestureDirection: 'horizontal',
        tabBarShowLabel: false,
        header: props => <Header {...props} />,
        animationEnabled: true,
      }}>
      <Tab.Screen
        name={'MainPage'}
        component={MainPage}
        options={{
          title: 'Главное',
          /*tabBarVisible: !firstLogin,*/
          //tabBarVisible: false,
          showHeader: false,
          icon: {
            name: 'home',
            type: 'feather',
          },
        }}
      />
      <Tab.Screen
        name={'Activities'}
        component={Activities}
        options={{
          title: 'Афиша',
          showHeader: false,
          icon: {
            name: 'calendar',
            type: 'feather',
          },
        }}
      />
      {/*<Tab.Screen
        name={'Rest'}
        component={Rest}
        options={{
          title: 'Мой отдых',
          icon: {
            name: 'calendar',
            type: 'feather',
          },
        }}
      />*/}
      {/*
        <Tab.Screen
          name={'Chats'}
          component={MyChats}
          options={{
            title: 'Мои чаты',
            icon: {
              name: 'chatbox-outline',
              type: 'ionicon',
            },
          }}
        />*/}
      {
        settings.bottomMenuCenter === 'UpcomingBookings' ?
          <Tab.Screen
            name={'UpcomingBookings'}
            component={UpcomingBookings}
            options={{
              title: 'Мои заказы',
              showHeader: false,
              /*icon: {
                name: 'calendar-clock',
                type: 'material-community',
              },*/
              image: myRestImage,
            }}
          />
        : null
      }
      {
        settings.bottomMenuCenter === 'ByInterests' ?
          <Tab.Screen
            name={'Interests'}
            component={ByInterests}
            options={{
              title: 'Интересы',
              showHeader: false,
              icon: {
                name: 'star-four-points-outline',
                type: 'material-community',
              },
            }}
          />
        : null
      }
      {/*<Tab.Screen
        name={'List'}
        component={List}
        options={{
          title: 'Моё избранное',
          icon: {
            name: 'heart',
            type: 'feather',
          },
        }}
      />*/
      }
      <Tab.Screen
        name={'RoomService'}
        component={RoomService}
        options={{
          title: 'Мой номер',
          showHeader: false,
          icon: {
            name: 'bed',
            type: 'font-awesome',
            size: 26,
          },
        }}
      />
      <Tab.Screen
        name={'Profile'}
        //component={ActivitiesMap}
        component={Profile}
        options={{
          title: 'Профиль',
          showHeader: false,
          icon: {
            name: 'person-outline',
            type: 'ionicons',
            size: 35,
          },
        }}
      />
    </Tab.Navigator>
  );
};

export default MainTabs;
