import React, {useCallback, useEffect, useState} from 'react';
import {Icon} from '@rneui/base';
import {Platform, StyleSheet, TouchableOpacity} from 'react-native';

const HeartToggle = ({size, style, checked, onChange}) => {
  const [state, setState] = useState();

  useEffect(()=>{
    checked !== undefined && setState(checked);
  },[checked]);

  const handleChange = useCallback(()=>{
    setState(!state);
    onChange(!state);
  },[state]);

  size = size || 20;
  return <TouchableOpacity style={[styles.box, style]} onPress={handleChange}>
    {state
      ? <Icon style={styles.icon} size={size} name={'heart'} type={'entypo'}  color={'#fff'}/>
      : <Icon style={styles.icon} size={size} name={'heart-outlined'} type={'entypo'} color={'#fff'}/>
    }
  </TouchableOpacity>;
};

const styles = StyleSheet.create({
  box: {
    //padding: 10,
  },
  icon: {
    marginTop: 12,
    margin: 12,
    ...(Platform.OS === 'web'
    ? {
        textShadowColor: 'rgb(0,0,0)',
        textShadowOpacity: 0.9,
        textShadowRadius: 2,
        textShadowOffset: {height: 0, width: 0},
      }
    : {
        shadowColor: 'rgba(0,0,0,0.29)',
        shadowOpacity: 1,
        shadowRadius: 2,
        shadowOffset: {height: 0, width: 0},
    }),
  },
});

export default HeartToggle;
