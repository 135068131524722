import {Icon} from '@rneui/themed';
import {TouchableOpacity} from 'react-native';
import React from 'react';

const CloserArrow = ({type, onPress, color}) => {
  return (
    <TouchableOpacity
      onPress={onPress || (() => {})}
      style={{
        paddingBottom: 5,
        transform: [{scaleX: 2}, {scaleY: 0.8}],
        backgroundColor: 'rgba(255,255,255,0)',
      }}>
      <Icon
        color={color || '#80949C'}
        name={'arrow-' + (type === 'up' ? 'up' : 'down')}
        type={'simple-line-icon'}
      />
    </TouchableOpacity>
  );
};

export default CloserArrow;
