import storage from './storeHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {isObject} from './helpers';

const sleep = (s) => (new Promise(resolve => setTimeout(resolve, s * 1000)));
const attempter = async (promise, attempts) => {
  try {
    let result = await promise;
    return result;
  } catch (e) {
    __DEV__ && console.log('ATTEMPT ' + attempts + ' TO LOAD ', e);
    if (attempts === 1) {
      throw new Error(e);
    }
    await sleep(2);
    return attempter(promise, attempts - 1);
  }
};

/*
const initialState = {
  defaultColors: colors.default,
  theme: 'default',
  bottomBarColor: '#fff',
};

export const stylesSlice = createSlice({
  name: 'style',
  initialState,
  reducers: {
    setPalette: (state, {payload}) => {
      state.defaultColors = colors[payload];
    },
    setTheme: (state, {payload}) => {
      state.theme = payload;
    },
    setBottomBarColor: (state, {payload}) => {
      console.log('change bottom bar color to ', payload, 'prev', state);
      SystemNavigationBar.setNavigationColor(
        payload, //color
        chroma(payload).get('lab.l') < 70 ? 'light' : 'dark'
      ).then(()=>{});
      state.bottomBarColor = payload;
    },
  },
});

export const { setPalette, setTheme, setBottomBarColor} = stylesSlice.actions;

export default stylesSlice.reducer;
*/

const defaultValue = (v, d) => {
  return v === undefined || v === null ? d : v;
};

export const createDataStoreSlice = ({
  name,
  attempts,
  expires,
  load,
  onExpired,
  onError,
  loadOnInit,
}) =>
{
  const _storage = storage({name, expires});

  const initialState = {
    value: {},
    state: 'init',
    _attempts: 1,
    valuePending: false,
    repeats: 0,
    err: {},
  };

  /*const [value, _setValue] = useState();
  const [state, _setState] = useState('init');
  const [_attempts, _setAttempts] = useState(1);
  const getValuePending = useRef();
  const repeats = useRef();*/

  attempts = attempts || 1;

  (() => {
    if (!load){
      throw Error('load function is required: ' + name);
    }
    if (load.length < 1){
      //console.warn(load);
      throw Error('load function have 1 arg (setState), ' + name);
    }
  })();

  const resetValue = (state) => {
    state.value = {};
    state.state = 'reset';
    _storage.reset().then(()=>{});
  };

  const setValue = (state, {payload}) => {
    state.value = payload;
    _storage.set(payload).then(()=>{});
    //console.log('update value to ', payload, 'state', state);
  };

  const setState = (state, {payload}) => {
    state.state = payload;
  };

  const setPending = (state, {payload}) => {
    state.valuePending = payload;
  };

  /*return {
    [name + 'Value'] : value,
    [name + 'State'] : state,
    [name + 'SetValue'] : setValue,
    [name + 'GetValue'] : getValue,
    [name + 'ResetValue'] : resetValue,
    [name + 'ReloadValue'] : reloadValue,
  };*/

  const storeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
      setPending,
      setState,
      resetValue,
      setValue,
    },
    extraReducers: (builder) => {
      builder.addCase(getValue.fulfilled, (state, { payload }) => {
        //console.log(name + ']: getValue payload fulfilled', payload);
        //console.log('prev state:', state);
        if (isObject(payload)) {
          for (let i in payload) {
            state[i] = payload[i];
          }
        }
        return state;
      });
        /*.addCase(getValue.pending, (state, { payload }) => {
          state.state = 'load';
          console.log('getValue payload pending', payload);
        })
        .addCase(getValue.rejected, (state, { payload }) => {
          state.state = 'error';
          state.valuePending = false;
          console.log('getValue payload pending', payload);
        });*/
    },
  });

  const getValue = createAsyncThunk(
    name + '/getValue',
    async (reload, { rejectWithValue, getState, dispatch}) => {
      //let updateValue = defaultValue(data?.updateValue, true);
      //console.log('get value attempts:', attempts, load);
      const state = getState();
      //console.log({state});
      if (state[name].valuePending) {
        return state[name];
      }
      dispatch(storeSlice.actions.setPending(true));

      try {
        if (reload === true) {
          await _storage.error({state:'reload', type:'immediately'});
        }

        let v = await _storage.get();
        //dispatch(storeSlice.actions.setState({state:'loaded'}));
        //updateValue && dispatch(storeSlice.actions._setValue(v));

        return {
          value: v,
          state:'loaded',
          valuePending:false,
        };
      } catch (e) {
        let _continue = false;
        __DEV__ && console.log('DS value failure state', name, e);
        dispatch(storeSlice.actions.setState('load'));
        try {
          if (e.type === 'empty' || e.type === 'invalid' || e.type === 'immediately') {
            _continue = true;
          }
          if (e.type === 'expired' && (!onExpired || onExpired(state, e))) {
            _continue = true;
          }
          if (e.type === 'error' && (!onError || onError(state, e))) {
            _continue = true;
          }
          if (_continue){
            let result = await attempter(load(state), attempts);
            //save into async store
            await _storage.set(result);
            return {
              value: result,
              state: 'loaded',
              valuePending: false,
            };
          } else {
            return rejectWithValue({
              value: undefined,
              state: 'error',
              valuePending: false,
              err: {
                message: 'stopped by callback',
              },
            });
          }
        } catch (err) {
          //dispatch(storeSlice.actions.setState('error'));
          __DEV__ && console.log('redux store rejected');
          return rejectWithValue({
            value: undefined,
            state: 'error',
            valuePending: false,
            err: {
              message: err.message,
              name: err.name,
            },
          });
        }
      }
    }
  );
  const select = (state) => state[name];
  const selectState = (state) => state[name].state;
  const selectValue = (state) => state[name].value;
  const reloadValue = () => (dispatch) => {
    dispatch(storeSlice.actions.resetValue());
    dispatch(getValue(true));
  };
  const init = () => (dispatch) => {
    __DEV__ && console.log('<initialize ' + name + ' store>');
    if (loadOnInit) {return;}
    dispatch(getValue(true));
  };

  return {
    storeSlice,
    reloadValue,
    getValue,
    select,
    selectState,
    selectValue,
    init,
  };
};

export const getCommonState = (states, showInit = false) =>
{
  if (showInit) {
    for (let i in states) {
      if (states[i] === 'init') {return 'init';}
    }
  }
  for (let i in states) {
    if (states[i] === 'error') {return 'error';}
  }
  let isLoaded = true;
  for (let i in states) {
    isLoaded &&= (states[i] === 'loaded');
  }
  if (isLoaded) {return 'loaded';}
  return 'loading';
};

/*createSlice.types = {
  name: ,
  attempts,
  expires,
  load,
  onExpired,
  onError,
  loadOnInit,
}*/
