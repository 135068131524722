import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import AppText from './AppText';

const AppButton = ({children, textStyle, style, onPress, light}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.buttonBox,
        light ? styles.buttonBoxLight : {},
        style,
      ]}
    >
      {typeof children === 'string'
        ? <AppText
            style={[
              styles.textDefault,
              light ? styles.buttonTextLight : {},
              textStyle,
            ]}
          >
            {children}
          </AppText>
        : children}
    </TouchableOpacity>
  );
};

const textStyle = {
  fontSize: 17,
  color: '#fff',
  textAlign: 'center',
};

const styles = StyleSheet.create({
  textDefault: textStyle,
  buttonBox: {
    backgroundColor: 'rgba(34, 34, 34, 1)',
    paddingVertical: 14,
    paddingHorizontal: 25,
    minWidth: 220,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  buttonBoxLight: {
    backgroundColor: 'rgba(246, 246, 246, 1)',
  },
  buttonTextLight: {
    color: 'rgba(34, 34, 34, 1)',
  },
});

export {textStyle};

export default AppButton;
