import React, {useEffect, useRef} from 'react';
//import {Platform, View} from 'react-native';
//import AppText from '../Basic/AppText';
import {WebView} from 'react-native-webview';
import {useNavigation} from '@react-navigation/native';

const injectedJavascript = `(function() {
  window.postMessage = function(data) {
    window.ReactNativeWebView.postMessage(data);
  };
})()`;

const html = `
    <!Doctype html>
    <head>
      <title>Payment status</title>  
      <script>
        window.addEventListener('load', function(event){
          console.log('send message');
          //console.log(window?.ReactNativeWebView);
          alert('send message');
          window?.postMessage('{"message":"MESSAGE FROM PAYMENT STATUS PAGE"}', '*');
        });
      </script>
    </head>
    <body>
      <div>
          <h2>Завершение платежа</h2>
      </div>
    </body>  
  `;

const PaymentStatusPage = () => {
  const navigation = useNavigation();

  let webViewRef = useRef();

  useEffect(() => {

      /*console.log('try to send message here');
      if (Platform.OS === 'web') {
        console.log('send message');
        window.postMessage('Post message from web', '*');
      }*/
      //window.postMessage('Post message from web', '*');
  }, [navigation]);

  return (
    <WebView
      ref={webViewRef}
      style={{width: '100%', flex: 1, justifyContent: 'center'}}
      originWhitelist={['*']}
      source={{html}}
      javaScriptEnabled={true}
      injectedJavaScriptBeforeContentLoaded={injectedJavascript}
    />
  );
};

export default PaymentStatusPage;
