import React from 'react';
import {TouchableOpacity} from 'react-native';
import AppText from '../Basic/AppText';

const GrayButton = ({onPress, children, title, style, containerStyle}) => {
  return (
    <TouchableOpacity
      style={[{alignItems: 'center'}, containerStyle]}
      onPress={onPress}>
      <AppText
        style={[
          {
            color: 'rgba(128,148,156,0.95)',
            fontSize: 18,
            lineHeight: 26,
          },
          style,
        ]}>
        {children || title}
      </AppText>
    </TouchableOpacity>
  );
};

export default GrayButton;
