const arrIntersect = (arr1, arr2) => arr1.filter(x => arr2.includes(x));
const arrDiff = (arr1, arr2) => arr1.filter(x => !arr2.includes(x));
const multiGetReducer = arr => arr.reduce((a, v) => ({...a, [v[0]]: v[1]}), {});
const sortObject = obj => {
  let sortable = [];
  for (let id in obj) {
    sortable.push([id, obj[id]]);
  }
  sortable.sort((a, b) => {
    if (a < b) {return -1;}
    if (a > b) {return 1;}
    return 0;
  });
  let objSorted = {};
  sortable.forEach(item=>{objSorted[item[0]] = item[1];});
  return objSorted;
};

const  sortComparer = (keys) => {
  let comparer = (a, b, ind = 0) => {
    let key = isObject(keys[ind]) ? Object.keys(keys[ind])[0] : keys[ind];
    let asc = isObject(keys[ind]) ? Object.values(keys[ind])[0] === 'asc' : true;
    if (a[key] < b[key]) {
      return asc ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return asc ? 1 : -1;
    }
    return Math.max(keys.length - 1, 0) === ind ? 0 : comparer(a, b, ind + 1);
  };
  return comparer;
};

/**
 * sortObjectByKeys(object, [{date:'desc'}])
 * @object sortable object
 * @array array of field objects
 * */
const sortObjectByKeys = (obj, keys) => {
  let sorted = Object.values(obj).sort(sortComparer(keys));
  //console.log('before sort',obj);
  //console.log('after sort',Object.assign({},sorted));
  return Object.assign({},sorted);
};

/**
 * sortArrayByKeys(object, [{date:'desc'}])
 * */
const sortArrayByKeys = (obj, keys) => {
  let sorted = Object.values(obj).sort(sortComparer(keys));
  //console.log('before sort',obj);
  //console.log('after sort',Object.assign({},sorted));
  return sorted;
};

const isObject = obj => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

const arrUnique = (arr) => [...(new Set(arr))];

const objectsEqual = (f,n,props = []) => {
  if (props && props.length > 0) {
    let _f = {}, _n = {};
    for (let item of props) {
      _f[item] = f[item];
      _n[item] = n[item];
    }
    return JSON.stringify(_f) === JSON.stringify(_n);
  } else {
    return JSON.stringify(f) === JSON.stringify(n);
  }
};

export {
  arrIntersect,
  arrDiff,
  arrUnique,
  multiGetReducer,
  sortObject,
  sortObjectByKeys,
  sortArrayByKeys,
  isObject,
  objectsEqual,
};
