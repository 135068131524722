//SplashScreen.preventAutoHideAsync().catch(console.warn);
import React, {useEffect, useState} from 'react';
import {StyleSheet, Dimensions} from 'react-native';
import Navigation from './components/AppNavigation';
//import {BottomSheetModalProvider} from '@gorhom/bottom-sheet';

import {
  DimensionsContextProvider,
  //getMinWidth,
} from './components/Defaults/DisplayWidth';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {ToastProvider} from 'react-native-toast-notifications';
import Toast from './components/Notifications/Toast';

//import {StyleProvider} from './components/Defaults/Styles';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {GuideController} from './components/AppGuide/Gallery';
//import {Provider as AppEventProvider} from './components/System/AppEvent';
import {ApiProvider} from './components/System/ApiContext';
import StatusBarController from './components/System/StatusBar';
import GlobalStoreController from './components/GlobalStore/GlobalStoreController';

/*import * as Font from 'expo-font';
import {baseurl} from './components/api';*/

//import WebPushNotificationService from './components/System/WebPushNotificationService';

const styles = StyleSheet.create({
  main: {
    position: 'absolute',
    //height: '100%',
    width: '100%',
  },
});

//import * as wdyr from './wdyr';

//REDUX
import store from './components/GlobalStore/store';
import {Provider} from 'react-redux';

const App = () => {

  /*const loadAppGlyphs = async () => {
    await Font.loadAsync({
      'AppGlyph': require(baseurl + '/ttf_file/624d6bba73b85_my_rest_glyphs_1.0.ttf'),
    });
  };*/

  const isPortrait = () => {
    const dim = Dimensions.get('screen');
    return dim.height >= dim.width;
  };

  const [portrait, setPortrait] = useState(isPortrait());

  useEffect(() => {
    let dimensionsSubscription = Dimensions.addEventListener('change', () => {
      setPortrait(isPortrait());
    });

    //loadAppGlyphs().then(()=>{});

    return () => {
      dimensionsSubscription?.remove();
    };
  }, []);

  return (
    <Provider store={store}>
      {/*<WebPushNotificationService/>*/}
      <DimensionsContextProvider>
        <SafeAreaProvider
          style={{
            //height: Platform.OS === 'web' ? '100vh' : '100%',
            height: '100%',
            //Platform.OS === 'web' ? (portrait ? '100vh' : '100vw') : '100%',
          }}
        >
          <GestureHandlerRootView style={{height: '100%'}}>
            <ToastProvider
              placement="bottom"
              duration={7000}
              animationType="slide-in"
              animationDuration={250}
              successColor="green"
              dangerColor="red"
              warningColor="orange"
              normalColor="gray"
              //icon={<Icon />}
              //successIcon={<SuccessIcon />}
              //dangerIcon={<DangerIcon />}
              //warningIcon={<WarningIcon />}
              textStyle={{fontSize: 20}}
              //offset={50} // offset for both top and bottom toasts
              //offsetTop={30}
              offsetBottom={80}
              swipeEnabled={true}
              renderToast={props => <Toast {...props} />}
            >
              <ApiProvider>
                <StatusBarController/>
                <GuideController/>
                <GlobalStoreController/>
                <Navigation style={{height: '100%'}}/>
              </ApiProvider>
            </ToastProvider>
          </GestureHandlerRootView>
        </SafeAreaProvider>
      </DimensionsContextProvider>
    </Provider>
  );
};

export default App;

//global.__reanimatedWorkletInit = () => {};
