import {
  Animated,
  //Dimensions,
  ImageBackground,
  Platform,
  TouchableOpacity,
  View,
  StyleSheet,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';

import LinearGradient from 'react-native-linear-gradient';
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  memo,
  forwardRef,
} from 'react';
import Carousel from 'react-native-snap-carousel';
import {Icon} from '@rneui/themed';
import {DimensionsContext} from '../Defaults/DisplayWidth';
//import FastImage from 'react-native-fast-image';
import {Image as FastImage} from 'react-native';
//import {round} from 'react-native-reanimated';
import RenderHtml from 'react-native-render-html';
import AppText from '../Basic/AppText';
import CardVideo from './CardVideo';

import {colors, FontFamily} from '../Defaults/Styles';
import {MapContext} from '../BottomSheets/MapBottomSheet';
import NewButton from '../Basic/NewButton';
//import {number} from 'prop-types';

const CardFade = ({opacity, style, gradientStyle}) => (
  <Animated.View
    style={[
      {
        opacity: opacity || 0.5,
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
      },
      style,
    ]}>
    <LinearGradient
      colors={['rgba(0,0,0,1)', 'rgba(0,0,0,0)']}
      style={[
        {
          height: '100%',
          width: '100%',
          borderRadius: 10,
        },
        gradientStyle,
      ]}
    />
  </Animated.View>
);

const CardFog = ({opacity}) => (
  <Animated.View
    style={{
      ...(isNaN(opacity)
        ? {opacity: opacity}
        : {backgroundColor: `rgba(0,0,0,${opacity || 0.4})`}),
      position: 'absolute',
      flex: 1,
      //top: 0,
      //left: 0,
      height: '100%',
      width: '100%',
      borderRadius: 10,
    }}
  />
);

const StatusLabel = ({text, upper, textColor, color}) => {
  return (
    <View
      style={{
        backgroundColor: color || 'green',
        justifyContent: 'center',
        paddingHorizontal: 6,
        marginRight: 6,
        borderRadius: 20,
      }}>
      <AppText
        style={{
          color: textColor || 'white',
          fontSize: 14,

          textTransform: upper !== false ? 'uppercase' : 'none',
        }}>
        {text || 'Статус'}
      </AppText>
    </View>
  );
};

const Dimmer = ({dimmer, opacity}) => {
  switch (dimmer) {
    case 'fog':
      return <CardFog opacity={opacity} />;
    case 'fade':
      return <CardFade opacity={opacity} />;
    case null:
      return null;
    default:
      return <CardFade opacity={opacity} />;
  }
};

const EmployeeAvatar = ({image}) => {
  return (
    <View
      style={{
        backgroundColor: 'white',
        padding: 4,
        borderRadius: 100,
        position: 'absolute',
        right: 45,
        bottom: -65,
        height: 88,
        width: 88,
      }}>
      <View>
        <ImageBackground
          style={{flex: 1}}
          source={image}
          imageStyle={{width: 80, height: 80, borderRadius: 100}}
          resizeMode="cover"
        />
      </View>
    </View>
  );
};

const ImageCarousel = forwardRef(({
  items,
  preview,
  autoplay,
  autoplayVideo,
  mutedVideo,
  style,
  children,
  cardOpen,
  onChangeIndex,
  isCardCarouselItem,
  startIndex,
  onPress,
}, ref) => {
  const {width} = useContext(DimensionsContext);
  const [gallery, setGallery] = useState({count: 1, index: 1});
  const [firstItem, setFirstItem] = useState(0);
  let videoRef = useRef();
  let carouselRef = useRef();
  let carouselScrollTmr = useRef();
  let divider = Platform.OS !== 'ios' ? 0.905 : 0.915;
  let defCardWidth = cardOpen ? 1 : 0.95;
  let cardSliderWidth = isCardCarouselItem ? divider : defCardWidth;

  useEffect(() => {
    if (!autoplay) {
      carouselRef.current?.stopAutoplay();
    } else {
      carouselRef.current?.startAutoplay();
    }
    return () => {
      carouselRef.current?.stopAutoplay();
    };
  }, [autoplay]);

  useEffect(() => {
    setGallery(v => ({...v, count: items?.length || 0}));
  }, [items]);

  useImperativeHandle(ref, ()=>({
    playMutedVideo: () => {
      videoRef.current?.mute(true);
      videoRef.current?.play();
    },
    playVideo: () => {
      videoRef.current?.play();
      videoRef.current?.mute(false);
    },
    stopVideo: () => {
      videoRef.current?.stop();
      videoRef.current?.mute(true);
    },
    muteVideo: (state) => {
      videoRef.current?.mute(state);
    },
  }), [videoRef]);

  useEffect(()=>{
    if (gallery.index !== 1){
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  },[gallery]);

  const kickScrolling = () => {
    if (carouselScrollTmr.current) {
      clearTimeout(carouselScrollTmr.current);
    }
    carouselScrollTmr.current = setTimeout(() => {
      let index = carouselRef.current.currentIndex + 1;
      if (Platform.OS === 'web') {
        let offset = carouselRef.current._currentContentOffset + 50;
        let positions = carouselRef.current._positions;
        index =
          positions.findIndex(
            v => Math.round(v.start) <= offset && Math.round(v.end) > offset,
          ) + 1;
      }
      setGallery(v => ({...v, index}));
      onChangeIndex && onChangeIndex({
        count: gallery.count,
        index,
      });
    }, 150);
  };

  return (
    <View style={{flex: 1, height: '100%'}}>
      <Carousel
        style={{flex: 1, height: '100%'}}
        ref={c => {
          carouselRef.current = c;
        }}
        useScrollView={true}
        //pagingEnabled={true}
        scrollEnabled={true}
        pagingEnabled={Platform.OS !== 'ios'}
        enableSnap={Platform.OS === 'ios'}
        animationOptions={{useNativeDriver: false}}
        containerCustomStyle={style}
        slideStyle={{flex: 1}}
        keyExtractor={(item, number) => number}
        renderItem={({item}) => {
          return (
            <TouchableWithoutFeedback
              onPress={onPress}
              style={{
                flex: 1,
                height: '100%',
                width: '100%',
              }}>
              {
                item?.video
                ? <CardVideo
                    ref={videoRef}
                    source={item}
                    style={{flex: 1, height: '100%', width:'100%'}}
                    preview={preview}
                    play={autoplayVideo}
                    isMuted={mutedVideo}
                  >
                    {children}
                  </CardVideo>
                : <>
                    <FastImage
                      source={item}
                      style={{flex: 1, height: '100%', width:'100%'}}
                      resizeMode={'cover'}
                      defaultSource={require('./../../img/mr_view_blur.jpg')}
                      fallback={Platform.OS === 'web'}
                    />
                    {children}
                  </>
              }
              {/*<ImageBackground
                style={{flex: 1, height: '100%'}}
                source={item}
                resizeMode={'cover'}
                defaultSource={require('./../../img/mr_view_blur.jpg')}
              />*/}
            </TouchableWithoutFeedback>
          );
        }}
        data={items}
        //OLD //itemWidth={windowWidth}
        //OLD //sliderWidth={windowWidth * (cardOpen ? 1 : cardSliderWidth)}
        itemWidth={width * cardSliderWidth}
        sliderWidth={width * cardSliderWidth}
        inactiveSlideOpacity={1}
        inactiveSlideScale={1}
        /*autoplay={autoplay}
        autoplayInterval={3000}
        autoplayDelay={3000}
        loop={true}*/
        //firstItem={firstItem}
        onScroll={kickScrolling}
        disableVirtualization={Platform.OS === 'web'}
      />
    </View>
  );
});

const CardGalleryIndex = ({count, index, containerStyle, textStyle}) => {
  return (
    <View style={[styles.galleryIndexRoot, containerStyle]}>
      <AppText style={[styles.galleryIndexText, textStyle]}>
        <AppText>{index}</AppText>
        <AppText style={styles.galleryIndexDivider}>{'/'}</AppText>
        <AppText>{count}</AppText>
      </AppText>
      <Icon
        style={styles.galleryIndexIcon}
        name={'camera-outline'}
        type={'ionicon'}
        size={11}
        color={'white'}
      />
    </View>
  );
};

const CardTags = ({style, containerStyle, onPress, items}) => {
  return (
    <View
      style={[
        containerStyle,
        {flex: 1, flexWrap: 'wrap', alignItems: 'stretch', width: '85%'},
      ]}>
      {items.map((item, key) => (
        <TouchableOpacity
          key={key}
          style={{marginBottom: 3}}
          onPress={() => onPress && onPress({...item})}>
          <AppText style={style}>{'#' + item.title}</AppText>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const Like = ({like, colorInverse, onPress}) => {
  const [state, setState] = useState(null);
  let stateScheme = {};

  useEffect(() => {
    setState(like === undefined ? null : like);
  }, [like]);

  const handlePressLike = likePressed => {
    let likeState = state === likePressed ? null : likePressed;
    setState(likeState);
    (onPress || (() => {}))(likeState);
  };

  switch (state) {
    case null:
      stateScheme = {
        like: {name: 'like2', color: colorInverse ? 'black' : 'white'},
        dislike: {name: 'dislike2', color: colorInverse ? 'black' : 'white'},
      };
      break;
    case true:
      stateScheme = {
        like: {name: 'like1', color: colorInverse ? 'black' : 'white'},
        dislike: {name: 'dislike2', color: colorInverse ? 'black' : 'white'},
      };
      break;
    case false:
      stateScheme = {
        like: {name: 'like2', color: colorInverse ? 'black' : 'white'},
        dislike: {name: 'dislike1', color: colorInverse ? 'black' : 'white'},
      };
      break;
  }

  return (
    <View
      style={{
        width: 65,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <TouchableOpacity onPress={() => handlePressLike(true)}>
        <Icon type={'ant-design'} size={28} {...stateScheme.like} />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => handlePressLike(false)}>
        <Icon type={'ant-design'} size={28} {...stateScheme.dislike} />
      </TouchableOpacity>
    </View>
  );
};

const Volume = ({volume, onPress}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={{position: 'absolute', width: 30, bottom: 10, right: 10}}>
        <Icon
          color={'white'}
          name={volume === true ? 'volume-2' : 'volume-x'}
          type={'feather'}
        />
      </View>
    </TouchableOpacity>
  );
};

const cardDescriptionPurify = (text) => {
  return (text || '')
    //.replaceAll('(<br\\s*\\/?> ?)?\\n( ?<br\\s*\\/?>)?', '<br />')
    .replace(/(font-family\s*:\s*.*?)(;|"|'|$)/g, '$2');
};

const CardDescription = memo(
  ({html, textStyle, hide, maxHeight}) => {
    return (
      <CollapsibleView maxHeight={maxHeight || 140} hide={hide}>
        <RenderHtml
          source={{html: cardDescriptionPurify(html)}}
          contentWidth={100}
          tagsStyles={{
            body: {
              fontFamily: FontFamily,
              fontSize: 14,
              ...textStyle,
              //whiteSpace: 'pre-line',
            },
          }}
          systemFonts={[FontFamily]}
        />
      </CollapsibleView>
    );
  },
  (prevProps, nextProps) => {
    let eq = JSON.stringify(prevProps.html) === JSON.stringify(nextProps.html);
    if (!eq) {
      //console.log('redraw card ' + nextProps.title);
    }
    return eq;
  }
);

const CollapsibleView = ({hide, children, maxHeight}) =>
{
  const [hidden, setHidden] = useState(true);
  const [useHider, setUseHider] = useState(true);
  const [ready, setReady] = useState(false);
  const [fixedHeight, setFixedHeight] = useState(null);

  /*useEffect(()=>{
    console.log({viewSize});
    if (viewSize.height > 0){
      setFixedHeight(viewSize.height);
    }
    if (viewSize.height <= maxHeight) {
      setUseHider(false);
    }
  }, []);*/

  const layoutHandler = ({nativeEvent: {layout: {width, height}}}) => {
    //console.log('layout height:',height);
    if (fixedHeight === null) {
      setFixedHeight(height);
      if (height <= maxHeight){
        setUseHider(false);
      }
      setReady(true);
    }
  };

  return (<View
    onLayout={layoutHandler}
    style={{
      height: hide && hidden && useHider && ready ? maxHeight : null,
      overflow: 'hidden',
      paddingBottom: hidden || !useHider ? 0 : 50,
    }}
  >
    {children}
    {hide && useHider ?
      <LinearGradient
        colors={['rgba(255,255,255,0.43)', 'rgb(255,255,255)']}
        style={{height: 50, bottom: 0, width: '100%', position: 'absolute'}}
      >
        <TouchableOpacity
          style={{
            backgroundColor: '#e8e8e8',
            padding: 5,
            paddingHorizontal: 10,
            borderRadius: 50,
            borderColor: '#ffffff',
            borderWidth: 4,
            alignSelf: 'center',
          }}
          light={true}
          onPress={()=>{setHidden(v=>!v)}}
        >
          <AppText style={{color: '#333333'}}>
            {hidden ? 'Подробнее' : 'Скрыть'}
          </AppText>
        </TouchableOpacity>
      </LinearGradient>
      : null}
  </View>);
};

const MapPoint = ({geo, onPressGeo, containerStyle}) => {
  const {openMapWithGeo} = useContext(MapContext);
  return (
    <TouchableOpacity
      style={[styles.place, containerStyle]}
      activeOpacity={0.5}
      onPress={()=>{openMapWithGeo(geo) && onPressGeo && onPressGeo(geo);}}
    >
      <Icon
        style={styles.placeIcon}
        color={colors.default.main}
        name={'crosshairs-gps'}
        type={'material-community'}
        size={22}
      />
      <AppText style={styles.placeText}>
        {geo[0]?.title}
      </AppText>
    </TouchableOpacity>
  );
};

const Marker = ({title, children}) => {
  return (<View />);
};

const FingerTapAnimation = () => {
  const [state, setState] = useState(true);
  const [opacity] = useState(new Animated.Value(1));

  useEffect(() => {
    setState(true);
    setTimeout(() => {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 400,
        useNativeDriver: false,
      }).start(({finished}) => {
        if (finished) {
          setState(false);
        }
      });
    }, 1400);
  }, []);

  return (
    state ? (
      <Animated.View
        style={{
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: state ? '100%' : 0,
          opacity: opacity,
        }}>
          <Image
            style={{width: 80, height: 80}}
            source={require('./../../img/FingerTapGestureMin.gif')}
          />
      </Animated.View>
    ) : (
      <></>
    )
  );
};

const styles = StyleSheet.create({
  galleryIndexRoot: {
    flexDirection: 'row',
    backgroundColor: 'rgba(66,66,66,0.6)',
    borderRadius: 20,
    paddingVertical: 2,
    paddingHorizontal: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  galleryIndexIcon: {
    marginLeft: 3,
  },
  galleryIndexText: {
    color: 'white',

    fontSize: 10,
  },
  galleryIndexDivider: {
    fontSize: 9,
    //alignSelf: 'center',
    //
    marginBottom: 10,
  },


  //mapPlace
  place: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  placeIcon: {
    marginRight: 2,
  },
  placeText: {
    fontSize: 18,
    fontWeight: '400',
    marginLeft: 4,
  },
});

export {
  CardFade,
  CardFog,
  StatusLabel,
  Dimmer,
  EmployeeAvatar,
  ImageCarousel,
  CardGalleryIndex,
  CardDescription,
  CardTags,
  Like,
  Volume,
  FingerTapAnimation,
  MapPoint,
  Marker,
};
