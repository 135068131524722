import React, {useEffect, useImperativeHandle, useState, forwardRef} from 'react';
import {Platform, StyleSheet} from 'react-native';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import AppText from './AppText';

const CodeInput = ({codeLength, onChange, badCode, onFullFill, ...other}, ref) => {
  const styles = StyleSheet.create({
    codeFieldRoot: {
      backgroundColor: '#00000000',
      borderRadius: 6,
    },
    cell: {
      width: 50,
      height: 50,
      lineHeight: 48,
      fontSize: 24,
      borderWidth: 1,
      borderColor: badCode || false ? '#ff0000' : '#00000010',
      borderRadius: 6,
      backgroundColor:
        badCode || false
          ? 'rgba(201,136,127,0.47)'
          : 'rgba(128, 148, 156, 0.2)',
      textAlign: 'center',
    },
    focusCell: {
      borderColor: '#000',
    },
  });

  const [value, setValue] = useState('');
  const inputRef = useBlurOnFulfill({value, cellCount: codeLength});
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    onFullFill && value.length === codeLength && onFullFill(value);
  }, [value, codeLength]);

  useImperativeHandle(ref,()=>({
    blur : () => inputRef.current.blur(),
    focus : () => inputRef.current.focus(),
  }),[]);

  return (
    <CodeField
      ref={inputRef}
      autoComplete={Platform.select({ android: 'sms-otp', default: 'one-time-code' })}
      {...props}
      // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
      value={value}
      onChangeText={setValue}
      cellCount={codeLength}
      rootStyle={styles.codeFieldRoot}
      keyboardType="number-pad"
      textContentType="oneTimeCode"
      renderCell={({index, symbol, isFocused}) => (
        <AppText
          key={index}
          style={[styles.cell, isFocused && styles.focusCell]}
          onLayout={getCellOnLayoutHandler(index)}>
          {symbol || (isFocused ? <Cursor /> : null)}
        </AppText>
      )}
      autoFocus={true}
      {...other}
    />
  );
};

const FowardRefComponent = forwardRef(CodeInput, {});

export default FowardRefComponent;
