import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {View, StyleSheet, TouchableOpacity, ScrollView} from 'react-native';
import AppText from './AppText';
import Modal from 'react-native-modal';
import {Icon} from '@rneui/base';

import {getMinWidth} from '../Defaults/DisplayWidth';
import {colors} from '../Defaults/Styles';
import NewButton from './NewButton';
const windowWidth = getMinWidth();


/**list:[
 {
  id: '',
  title: '',
  subtitle: '',
  data: {},
  icon: {name: '', type: '', size: 0},
  onPress : () => {},
 }
]*/
export const DropdownList = forwardRef(({
    list,
    ItemComponent,
    title,
    activeTitle,
    onChange,
    multiple,
    listContainerStyle,
    //onConfirm,
  }, ref) => {

  const [position, setPosition] = useState();
  const [currentItem, setCurrentItem] = useState([]);

  useEffect(()=>{
    if (list && Array.isArray(list)){
      let selectedItems = list.filter(i=>i.selected);
      if (selectedItems.length > 0) {
        if (multiple !== true) {
          setCurrentItem([selectedItems[0]]);
        } else {
          setCurrentItem(selectedItems);
        }
      }
    }
  }, [list, multiple]);

  const handleChange = (id) => {
    let itemData = list.find(v=>v.id === id);
    let ind;
    if (!multiple) {
      setCurrentItem([itemData]);
      onChange && onChange([itemData]);
    } else {
      setCurrentItem(v => {
        if ((ind = v.findIndex(i=>i?.id === id)) > -1) {
          v = v.filter(i=>i?.id !== id);
        } else {
          v = v.concat(itemData);
        }
        return [...v];
      });
    }
  };

  const handleConfirm = () => {
    onChange && onChange(currentItem);
    //onConfirm && onConfirm(currentItem);
  };

  const handleClear = () => {
    /*setCurrentItem(v => {
      return [...(v.map(i=>({...i,selected:false})))];
    });*/
    setCurrentItem([]);
  };

  return (
     <View style={[styles.listBox, listContainerStyle]}>
          <ScrollView>
            {
              list.map(item => {
                let selected = currentItem.findIndex(v => v?.id && v?.id === item.id) > -1;
                __DEV__ && console.log('attr-item',{item});
                return (
                  ItemComponent
                    ? <ItemComponent
                        key={item?.id}
                        {...item}
                        selected={selected}
                      />
                    : <DefaultItemComponent
                        key={item?.id}
                        onPress={handleChange}
                        {...item}
                        selected={selected}
                      />
                );
              })
            }
          </ScrollView>
          {multiple
            ? <View style={styles.confirmBtnBox}>
                <NewButton
                  light={true}
                  style={styles.confirmBtn}
                  onPress={handleClear}
                >
                  {'Сбросить'}
                </NewButton>
                <NewButton
                  style={styles.confirmBtn}
                  onPress={handleConfirm}>{'Применить'}
                </NewButton>
              </View>
            : null
          }
        </View>
  );
});

export const DropDownListButton = forwardRef((props,ref) =>
{
  const {
    title,
    activeTitle,
    buttonStyle,
    buttonActiveStyle,
    buttonTextStyle,
    buttonActiveTextStyle,
    multiple,
    list,
    onChange,
  } = props;


  const [buttonIsActive, setButtonIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [buttonTitle, setButtonTitle] = useState(title);
  const [afterHideEvents, setAfterHideEvents] = useState([]);

  const setMultipleButtonTitle = useCallback((currentItem) => {
    let selectedCount = currentItem.length;
    if (selectedCount) {
      setButtonTitle(
        (activeTitle || 'Выбрано') + ' (' + selectedCount + ')'
      );
    } else {
      setButtonTitle(title);
    }
  }, [title]);

  const setSimpleButtonTitle = useCallback((currentItem)=>{
    if (currentItem.length > 0){
      setButtonTitle(
        currentItem[0]?.title + (currentItem[0]?.subtitle
          ? (', ' + currentItem[0]?.subtitle)
          : '')
      );
    } else {
      setButtonTitle(title);
    }
  }, [title]);

  useEffect(()=>{
    let _selected = (list || []).filter(i=>i.selected);
    setButtonIsActive(_selected.length > 0);
    if (multiple) {
      setMultipleButtonTitle(_selected);
    } else {
      setSimpleButtonTitle(_selected);
    }
  }, [multiple, list]);

  const handleButtonLayout = (e) => {
    //console.log({LAYOUT:e.nativeEvent});
  };

  const handleChange = (currentItem) => {
    if (multiple !== true) {
      setButtonIsActive(currentItem.length > 0);
      if (currentItem && currentItem.length > 0) {
        //console.log({currentItem, activeTitle});
        setSimpleButtonTitle(currentItem);
      } else {
        setButtonTitle(null);
      }
    } else {
      //console.log(currentItem);
      setButtonIsActive((currentItem || []).length > 0);
      if (currentItem && currentItem.length > 0) {
        setMultipleButtonTitle(currentItem);
      } else {
        setButtonTitle(title);
      }
    }
    setShowModal(false);
    onChange && onChange(currentItem);
  };

  useImperativeHandle(ref,()=>({
    show: (f) => {
      setShowModal(true);
    },
    hide: (f) => {
      setAfterHideEvents(v=>[...v, f]);
      setShowModal(false);
    },
  }), [isHidden]);

  useEffect(()=>{
    if (isHidden === true) {
      for (let i in afterHideEvents) {
        let event = afterHideEvents[i];
        event && event();
        setAfterHideEvents(v => {v[i] = null; return [...v];});
      }
    }
    setAfterHideEvents(v => [...(v.filter(i=>i))]);
  }, [isHidden]);

  useEffect(()=>{title && setButtonTitle(title);}, [title]);

  return (
    <>
      <TouchableOpacity
        onPress={()=>{setShowModal(v => !v);}}
        style={[
          styles.button,
          buttonStyle,
          ...(buttonIsActive ? [styles.buttonActive, buttonActiveStyle] : []),
        ]}
        onLayout={handleButtonLayout}
      >
        <AppText style={[
          styles.buttonText,
          buttonTextStyle,
          ...(buttonIsActive ? [styles.buttonActiveText, buttonActiveTextStyle] : []),
        ]}>
          {buttonTitle || 'Dropdown button'}
        </AppText>
        <Icon
          style={styles.buttonIcon}
          name={'keyboard-arrow-down'}
          type={'material'}
          size={16}
          color={
            (buttonIsActive ? buttonActiveTextStyle?.color : null)
            || buttonTextStyle?.color
            || styles?.buttonText?.color
          }
        />
      </TouchableOpacity>
      <Modal
        style={styles.modal}
        backdropOpacity={0.5}
        isVisible={showModal}
        onBackdropPress={()=>setShowModal(false)}
        animationIn={'slideInDown'}
        animationOut={'fadeOut'}
        onModalHide={()=>setIsHidden(true)}
        onModalShow={()=>setIsHidden(false)}
      >
        <DropdownList {...props} onChange={handleChange}/>
      </Modal>
    </>
  );
});

const styles = StyleSheet.create({
  box: {},
  modal: {
    width: windowWidth,
    alignSelf: 'center',
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: '#F6F6F6',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginLeft: 8,
  },
  buttonText: {
    fontSize: 14,
    color: 'rgba(113, 113, 113, 1)',
  },
  buttonActive: {
    backgroundColor: 'rgba(51, 51, 51, 1)',
  },
  buttonActiveText: {
    color: '#fff',
  },
  listBox: {
    width: '100%',
    backgroundColor: '#fff',
  },
  listItem: {
    padding: 16,
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.07)',
  },
  title:{
    color: '#252525',
    fontSize: 17,
  },
  subtitle:{
    color: 'rgba(167, 167, 167, 1)',
    fontSize: 13,
  },
  confirmBtnBox : {
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  confirmBtn: {
    marginLeft: 6,
    marginRight: 6,
    flex: 1,
    minWidth: 120,
  },
  titleBox: {
    flex: 1,
  },

  iconGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    //flex: 1,
    flexGrow: 0.01,
  },
  icon: {
    marginLeft: 15,
  },
  selectDot: {
    backgroundColor: '#dedede',
    width: 10,
    height: 10,
    borderRadius: 50,
  },
  selected: {
    backgroundColor: colors.default.main,
  },
});

const DefaultItemComponent = ({id, title, subtitle, onPress, icon, selected}) => (
  <TouchableOpacity
    onPress={()=>onPress(id)}
    style={styles.listItem}
  >
    <View style={styles.titleBox}>
      <AppText style={styles.title}>{title}</AppText>
      {subtitle ? <AppText style={styles.subtitle}>{subtitle}</AppText> : null}
    </View>
    <View style={styles.iconGroup}>
      <View style={[styles.selectDot, selected ? styles.selected : {}]} />
      {icon ? <Icon style={styles.icon} {...icon}/> : null}
    </View>
  </TouchableOpacity>
);

export default DropDownListButton;
