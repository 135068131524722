import React, {useEffect, useState, forwardRef, memo} from 'react';
import MaskInput from 'react-native-mask-input';
import { Keyboard } from 'react-native';



const PhoneInput = (props, ref) => {
  const {onChange, style, value} = props;
  const [phone, setPhone] = useState('');

  useEffect(()=>{
    value && setPhone(value);
  },[value]);

  return (
    <MaskInput
      ref={ref}
      keyboardType={'numeric'}
      value={phone}
      onChangeText={(masked, unmasked, obfuscated) => {
        setPhone(masked); // you can use the unmasked value as well
        if (unmasked.length >= 10){
          Keyboard.dismiss();
        }
        onChange({
          unmasked, // 99999999999
          masked, // (99) 99999-9999
          obfuscated, // (99) 99999-9999 (there's no obfuscation on this mask example)
        });
      }}
      style={style}
      mask={[
        '+','7',' ','(',/\d/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,'-',/\d/,/\d/,
      ]}
      //selection={{start: 2, end: 2}}
      //enablesReturnKeyAutomatically={true}
      /*onPressOut={()=>{
        Keyboard.dismiss();
      }}*/
      returnKeyType={'done'}
    />
  );
};

const PhoneInputRef = forwardRef(PhoneInput);

const PhoneInputMemo = memo(PhoneInputRef,(p,n)=>(p?.value === n?.value));

export default PhoneInputMemo;
