import React, {useEffect, useRef, useState} from 'react';
import {ActivityIndicator, Platform, SafeAreaView, StyleSheet, View} from 'react-native';
import {Icon, Input} from '@rneui/themed';
import PageHeader from '../Basic/PageHeader';
import GrayButton from '../Basic/GrayButton';
import {api} from '../System/api';
import AppButton from '../Basic/AppButton';
import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';
import AppText from '../Basic/AppText';
import CodeInput from '../Basic/CodeInput';
import {stringDecoder} from '../Errors/ErrorDecoder';
import {useToast} from 'react-native-toast-notifications';
import SwitchInput from '../Basic/SwitchInput';
import {BottomSheetTextInput} from '@gorhom/bottom-sheet';


import * as userProfile from '../GlobalStore/reducers/userProfileSlice';
import {useDispatch, useSelector} from 'react-redux';
import DeleteAccount from './DeleteAccount';

const emailApproveCodeLength = 6;

const DefaultInput = ({children, ...props}) => {
  return (
    <View style={styles.inputGroup}>
      <View style={styles.inputBox}>
        <Input
          inputContainerStyle={styles.inputContainer}
          labelStyle={styles.inputLabel}
          inputStyle={styles.inputText}
          disabled={true}
          rightIcon={
            <Icon
              name="lock"
              size={20}
              type={'simple-line-icon'}
              color="rgba(128, 148, 156, 0.5)"
            />
          }
          {...props}
        />
      </View>
      {children}
    </View>
  );
};

const UserSettings = () => {
  const [load, setLoad] = useState(true);
  const [emailChanged, setEmailChanged] = useState(false);
  const [state, setState] = useState({
    name: '',
    phone: '+7 (___) ___-__-__',
    email: '',
    isApproved: true,
  });

  const [eaCode, setEaCode] = useState('');
  const [eaBadCode, setEaBadCode] = useState(false);
  const [eaLoad, setEaLoad] = useState(false);

  const emailApprove = useRef();
  const codeInputRef = useRef();
  const toast = useToast();

  const _userProfile = useSelector(userProfile.select);
  const dispatch = useDispatch();

  const saveData = () => {
    setLoad(true);
    api.user.set(state).then(() => {
      setLoad(false);
      dispatch(userProfile.setValue(state));
    });
  };

  useEffect(() => {
    setState({
      ..._userProfile.value,
      phone: _userProfile.value?.phone?.toString().replace(
        /(\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/,
        '+$1 ($2) $3 $4 - $5',
      ),
    });
  }, [_userProfile]);

  useEffect(()=>{
    //console.log({userProfileState});
    setLoad(_userProfile.state !== 'loaded');
  },[_userProfile]);

  useEffect(()=>{
    __DEV__ && console.log('eaCode:',eaCode);
  },[eaCode]);

  return (
      <SafeAreaView style={styles.root}>
        <PageHeader title={'Управление аккаунтом'}/>
        <View style={styles.userData}>
          <DefaultInput
            label={'Имя'}
            disabled={false}
            value={state.name}
            rightIcon={{}}
            onChangeText={val => {
              setState(v => ({...v, name: val}));
            }}
          />
          <DefaultInput
            label={'Телефон'}
            //disabled={false}
            value={state.phone}
            //rightIcon={{}}
          />
          <DefaultInput
            label={'Email'}
            disabled={false}
            value={state.email}
            onChangeText={val => {
              setState(v => ({...v, email: val}));
              setEmailChanged(true);
            }}
            autoComplete={'email'}
            autoCapitalize={'none'}
            rightIcon={!state.isApproved || emailChanged
              ? <AppButton
                style={styles.emailApproveBtn}
                titleStyle={styles.emailApproveTxt}
                title={'Подтвердить'}
                onPress={()=>{
                  setLoad(true);
                  api.user.set({email: state.email}).then(res => {
                    api.user.sendApproveEmail(state.email)
                      .then()
                      .catch((e)=>{
                        __DEV__ && console.warn(e);
                      });
                    emailApprove.current.open();
                    setLoad(false);
                  });
                }}
              />
              : <Icon name="check" type="evilicon" color={'rgba(128, 148, 156, 0.5)'}/>
           }
          />
          <SwitchInput
            label={'sms уведомления'}
            containerStyle={styles.switcher}
            value={state.sendSms}
            onValueChange={(value)=>{
              setState(v=>({...v, sendSms:value}));
            }}
          />
          <SwitchInput
            label={'email уведомления'}
            containerStyle={styles.switcher}
            value={state.sendEmails}
            onValueChange={(value)=>{
              setState(v=>({...v, sendEmails:value}));
            }}
          />
          {load ? <ActivityIndicator /> :
          <GrayButton onPress={saveData}>Сохранить</GrayButton>}
        </View>
        <SimpleBottomSheet
          ref={emailApprove}
          lastPoint={350}
          onChange={()=>{
            codeInputRef.current.focus();
          }}
        >
          <View style={styles.eaContainer}>
            <View style={styles.eaDescrBox}>
              <AppText style={styles.eaText}>
                Вам на почту должно прийти письмо с кодом
              </AppText>
              <AppText style={styles.eaText}>
                Введите код ниже чтобы подтвердить свой Email.
              </AppText>
            </View>
            <View style={styles.eaCodeInputBox}>
              <CodeInput
                ref={codeInputRef}
                codeLength={emailApproveCodeLength}
                badCode={eaBadCode}
                onChange={(v)=>{
                  setEaBadCode(false);
                  setEaCode(v);
                }}
                InputComponent={BottomSheetTextInput}
                autoFocus={Platform.OS !== 'web'}
                keyboardType={'default'}
                autoCapitalize={false}
              />
            </View>
            <View style={styles.eaApproveBtnBox}>
              <AppButton
                style={styles.eaApproveBtn}
                title={'Подтвердить'}
                onPress={()=>{
                  if (eaCode.length === emailApproveCodeLength || eaLoad) {
                    setEaLoad(true);
                    api.user.approveEmail({code: eaCode, email: state.email})
                      .then(res => {
                        setEaLoad(false);
                        if (res.data.result === 'error') {
                          setEaBadCode(true);
                        } else {
                          setEaBadCode(false);
                          setEmailChanged(false);
                          emailApprove.current.close();
                          dispatch(userProfile.getValue(true)).then();
                        }
                      })
                      .catch(e => {
                        toast.show(stringDecoder(e), {
                          title: 'Не удалось отправить код',
                          type: 'danger',
                        });
                      });
                  }
                }}
              />
            </View>
            <View style={styles.eaLoaderBox}>
              {eaLoad ? <ActivityIndicator size={'large'}/> : null}
            </View>
          </View>
        </SimpleBottomSheet>
        <DeleteAccount/>
      </SafeAreaView>
  );
};

export default UserSettings;

const styles = StyleSheet.create({
  root: {
    height: '100%',
    backgroundColor: 'white',
  },
  userData: {
    flex: 2,
    padding: 40,
  },
  inputLabel: {
    fontWeight: '400',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  inputText: {
    fontWeight: '400',
    fontSize: 16,
  },
  inputContainer: {
    height: 35,
    marginBottom: 10,
  },
  inputBox: {
    flex: 4,
  },
  inputGroup: {
    flexDirection: 'row',
  },
  emailApprover: {
    flex: 1.6,
    height: 50,
    marginRight: 8,
    justifyContent: 'flex-end',
  },
  emailApproveBtn:{
    width: 100,
    height: 20,
  },
  emailApproveTxt:{
    fontSize: 9,
    lineHeight: null,
    padding: 4,
  },
  eaContainer:{
    height: Platform.OS === 'web' ? 'inherit' : '100%',
    //flex: 1,
    padding: 20,
    maxWidth: 350,
    alignSelf: 'center',
    width: '100%',
    paddingTop: 40,
  },
  eaDescrBox:{
    alignSelf: 'center',
    //padding: 20,
    flex: 1,
  },
  eaText:{
    color: '#4f4f4f',
  },
  eaCodeInputBox:{
    flex: 1,
    alignSelf: 'center',
    width: '100%',
  },
  eaApproveBtn: {
    width: '80%',
  },
  eaApproveBtnBox: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
  },
  eaLoaderBox: {
    flex: 1,
    alignSelf: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  sectionButtons: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },

  switcher: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
});
