import React, {createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Image, View, StyleSheet} from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import Modal from 'react-native-modal';
import {colors} from '../Defaults/Styles';
import {useAppDimensions} from '../Defaults/DisplayWidth';
import AppText from '../Basic/AppText';
import {useRoute} from '@react-navigation/native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import NewButton from '../Basic/NewButton';
import GrayButton from '../Basic/GrayButton';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {GUIDE_VERSION} from './GuideData';

import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import {useSelector} from 'react-redux';

const Highlight = ({children}) => <AppText style={styles.highlightText}>{children}</AppText>;

const slides = {
  1: {
    image:require('../../img/guide/FirstFramed.png'),
    title:'Знакомство с приложением',
    subtitle:'Выбирайте и бронируйте услуги отеля',
  },
  2: {
    image:require('../../img/guide/SecondFramed.png'),
    title:'Навигация в приложении',
    subtitle:(<>
      <Highlight>{'Главное'}</Highlight>
      {' – все услуги курорта и карта навигации\n'}
      <Highlight>{'Афиша'}</Highlight>
      {' – расписание всех мероприятий \n'}
      <Highlight>{'Интересы'}</Highlight>
      {' – удобный фильтр для подбора услуг\n'}
      <Highlight>{'Сервисы'}</Highlight>
      {' – обслуживание номера\n'}
      <Highlight>{'Профиль'}</Highlight>
      {' – история заказов'}
    </>
    ),
  },
  3: {
    image:require('../../img/guide/ThirdFramed.png'),
    title:'Запись на услуги подтверждается онлайн',
    subtitle:'Онлайн-оплата доступна 30 минут после бронирования. \n' +
      'При отсутствии оплаты бронирование отменяется \n',
  },
  4: {
    image:require('../../img/guide/LastFramed.png'),
    title:'Личный кабинет для управления заказами',
    subtitle:'',
  },
};

const GuideSlide = ({image, title, subtitle}) => {
  return (
    <View style={styles.slideBox}>
      <View style={styles.slideContent}>
        <Image
          source={image}
          style={styles.slideImg}
          resizeMode={'contain'}
        />
        <View style={styles.textBox}>
          <AppText style={styles.titleText}>
            {title}
          </AppText>
          <AppText style={styles.subtitleText}>
            {subtitle}
          </AppText>
        </View>
      </View>
    </View>
  );
};

export const Gallery = forwardRef((props, ref) => {
  const {height, width} = useAppDimensions();
  const insets = useSafeAreaInsets();
  const {maxWidth} = useAppDimensions();
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(false);
  const carouselRef = useRef();

  useImperativeHandle(ref, ()=>({
    show: () => setVisible(true),
    hide: () => setVisible(false),
    skip: () => {handleSkip();},
  }));

  const handleSkip = () => {
    AsyncStorage
      .setItem('app_guide_skip', GUIDE_VERSION)
      .then(() => {});
    setVisible(false);
  };

  const renew = () => {
    currentIndex(0);
    carouselRef.current?.snapToIndex(0);
  };

  useEffect(()=>{
    if (props?.params?.show) {
      renew();
      setVisible(true);
    }
  },[props?.params]);

  const isLastSlide = currentIndex === Object.keys(slides).length - 1;
  //console.log({isLastSlide, currentIndex, lst: Object.keys(slides).length - 1})

  return (
    <Modal
      isVisible={visible}
      style={[
        styles.modal,
        {
          height,
          width: maxWidth,
          marginTop: insets.top,
          marginBottom: insets.bottom + 30,
        }]}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      animationInTiming={400}
      backdropOpacity={0.94}
      backdropColor={'#fff'}
    >
      <View style={{width, flex:12}}>
        <Carousel
          ref={carouselRef}
          loop={false}
          data={Object.values(slides)}
          onScrollEnd={()=>{
            setCurrentIndex(
              carouselRef?.current?.getCurrentIndex()
            );
          }}
          //height={height}
          width={width}
          renderItem={({item}) => <GuideSlide {...item}/>}
        />
      </View>
      <View style={styles.buttons}>
        <NewButton
          style={styles.button}
          onPress={()=>{
            if (isLastSlide){
              handleSkip();
            } else {
              carouselRef?.current?.next();
            }
          }}
        >{
          isLastSlide
          ? 'К отдыху мечты >'
          : 'Далее >'
        }</NewButton>
        <GrayButton
          style={styles.skipButton}
          onPress={()=>{
            handleSkip();
          }}
        >пропустить</GrayButton>
      </View>
    </Modal>
  );
});

export const Page = () => {
  const route = useRoute();
  return <Gallery params={route?.params}/>;
};

const GuideContext = createContext();

export const GuideController = () => {
  const ref = useRef();
  const event = useSelector(appEvent.select);

  useEffect(()=>{
    console.log('event change:', event);
    if (event.name === 'showGuide'){
      show();
    }
  },[event]);

  const show = () => {
    ref.current?.show();
  };

  const settings = {
    show: () => {
      show();
    },
    hide: () => {
      ref.current?.hide();
    },
    skip: () => {

    },
  };

  return (
    <View style={styles.container}>
      <Gallery ref={ref}/>
    </View>
  );
};

export const useGuide = () => useContext(GuideContext);

const styles = StyleSheet.create({
  container: {
    //flex:1,
  },
  slide: {},
  slideBox: {
    //padding: 10,
    width:'100%',
    //height: '100%',
    flex:1,
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  slideContent: {
    width: '100%',
    height: '90%',
    //justifyContent: 'center',
    alignSelf: 'center',
  },
  slideImgBox: {
    alignItems: 'center',
  },
  slideImg: {
    //width: '100%',
    width: '70%',
    //height: '70%',
    marginHorizontal: '15%',
    flex:1,
    flexGrow: 6,
    //maxHeight: '80%',
  },
  textBox: {
    flex:1,
    flexGrow: 2,
    padding: 22,
  },
  titleText: {
    textAlign: 'center',
    fontSize: 21,
    lineHeight: 26,
    fontWeight: '500',
  },
  subtitleText: {
    textAlign: 'center',
    fontSize: 15,
    lineHeight: 24,
    marginTop: 8,
    color: 'rgba(113, 113, 113, 1)',
  },
  highlightText : {
    color: colors.default.mainTrDark,
  },



  //
  modal: {
    margin: 0,
  },
  buttons: {
    flex: 1,
  },
  skipButton: {
    fontSize: 13,
    paddingVertical: 15,
  },
});
