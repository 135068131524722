import React from 'react';
import {View} from 'react-native';
import {Icon} from '@rneui/themed';
import AppText from '../Basic/AppText';

const EmptyPage = props => {
  const {children, icon} = props;
  const defaultIcon = (
    <Icon
      type={'entypo'}
      name={'list'}
      color={'rgba(75,90,97,0.15)'}
      size={110}
    />
  );
  const setIcon = icon ? (
    <Icon
      type={icon.type}
      name={icon.name}
      color={'rgba(75,90,97,0.15)'}
      size={icon.size || 120}
    />
  ) : null;
  const emptyIcon = setIcon || defaultIcon;
  return (
    <View style={{padding: '6%'}}>
      <View
        style={{
          marginBottom: '18%',
          alignItems: 'flex-start',
          //backgroundColor: 'yellow',
        }}>
        {emptyIcon}
      </View>
      <View style={{paddingLeft: '6%'}}>
        <AppText style={{ fontSize: 18}}>{children}</AppText>
      </View>
    </View>
  );
};

export default EmptyPage;
