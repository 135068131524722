import React from 'react';
import {View, TextInput, StyleSheet} from 'react-native';
import AppText from './AppText';


const AppInput = ({label, onChange, style, ...props}) => {
  return (
    <View style={styles.box}>
      <View style={styles.inputLabel}>
        <AppText style={styles.inputLabelText}>{label}</AppText>
      </View>
      <TextInput
        style={[styles.input, style]}
        onChangeText={onChange}
        autoFocus={false}
        returnKeyType={'done'}
        {...props}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  box: {

  },
  input: {
    backgroundColor: '#f6f6f6',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 11,
    maxHeight: 160,
    marginBottom: 12,
  },
  inputLabel: {
    paddingVertical: 5,
  },
  inputLabelText: {
    fontWeight: '500',
    color: '#5b5b5b',
  },
});

export default AppInput;
