import React, {useEffect, useRef, useState, memo} from 'react';
import {
  //ActivityIndicator,
  SafeAreaView,
  SectionList,
  View,
  StyleSheet, TouchableOpacity,
} from 'react-native';
//import {RefreshControl} from 'react-native-web-refresh-control';
import {
  AppButton,
  EmptyPage,
  ListFooter,
} from '../Basic/BasicElements';
import {api} from '../System/api';
import offerAdapter from '../Offers/OfferAdapter';
import {
  PopupMenu,
  //popupMenuItemsGenerator
} from '../PopupMenu/PopupMenu';
import moment from 'moment-timezone';
//import popupMenuAction from '../Offers/popupMenuAction';
//import {communicationAction} from '../Offers/communication';
//import CalendarBottomSheet from '../BottomSheets/CalendarBottomSheetOld';
//import {navigateToBookingChat} from './MyChats/chatCallbacks';
//import ReviewBottomSheet from '../BottomSheets/ReviewBottomSheet';
//import SimpleCard from '../Cards/SimpleCard';
//import AsyncStorage from '@react-native-async-storage/async-storage';
import {stringDecoder} from '../Errors/ErrorDecoder';
import {useToast} from 'react-native-toast-notifications';
//import calendarRestrictions from '../Calendar/calendarRestrictions';
import AppText from '../Basic/AppText';
import {
  isObject,
  //sortObjectByKeys
} from '../System/helpers';
import {useNavigation, useRoute} from '@react-navigation/native';
//import PageHeader from '../Basic/PageHeader';
import {colors, defaultStyles} from '../Defaults/Styles';
import {Icon} from '@rneui/base';
import {calcOfferFinalPrice, humanizePrice} from '../Cards/CardPrice';
import {tempBookingRemove, tempBookingsGet} from '../Booking/tempBookingStorage';

import * as auth from '../GlobalStore/reducers/authSlice';
import * as statusBar from '../GlobalStore/reducers/statusBarSlice';
import * as appEvent from '../GlobalStore/reducers/appEventSlice';
import {useDispatch, useSelector} from 'react-redux';

import {bookingStates, prepareState, prepareStateTng, StatusView} from './BookingStatus';


const BookingCard = memo((booking) => {
  //console.log(JSON.stringify({booking}));
  const {
    id : booking_id,
    date,
    time,
    offer,
    onPress,
    dateFrom,
    fullPrice,
    status,
  } = booking;

  const {
    id,
    title,
    price,
    outlet,
    bookedTariffs,
    isUseSlots,
  } = offer;
  const state = prepareState(status);
  let navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    return navigation.addListener('focus', () => {
      dispatch(statusBar.setStyle({backgroundColor: '#fff'}));
    });
  }, [navigation]);

  /*console.log({id,
    title,
    price,
    outlet,
    bookedTariffs,
    isUseSlots,
  });*/

  const handlePay = () => {
    navigation.navigate({
      name:'Payment',
      params: {
        booking_id,
      },
    });
  };

  const needPayment =
    isUseSlots
    &&
    moment().add(-30,'minutes').isBefore(date)
    &&
    fullPrice && fullPrice > 0
  ;

  let formattedPrice = 'Бесплатно';
  if (fullPrice) {
    formattedPrice = humanizePrice({price:fullPrice},true);
  }

  return <View style={styles.bookingBox}>
    <View style={styles.titleBlock}>
      <AppText style={styles.title}>{title}</AppText>
      <View style={styles.bookingDate}>
        <AppText style={styles.bookingDateText}>
          {moment(date).format('DD.MM.YY HH:mm')}
        </AppText>
      </View>
    </View>
    <View style={styles.price}>
      <AppText style={styles.priceText}>{formattedPrice}</AppText>
    </View>
    <View style={styles.geoBox}>
      <Icon style={styles.geoIcon} size={14} name={'crosshairs-gps'} type={'material-community'}/>
      <AppText style={styles.geoText}>{outlet.title || outlet.name}</AppText>
    </View>
    <View style={styles.bookingDetails}>
      {/*<AppText>{dateFrom}</AppText><AppText style={styles.detailsDot}>{'·'}</AppText>*/}
      <AppText style={styles.bookingDetailsText}>{moment(dateFrom).format('HH:mm')}</AppText>
      <AppText style={styles.detailsDot}>{'·'}</AppText>
      <AppText style={styles.bookingDetailsText}>{moment(dateFrom).format('DD.MM.YYYY')}</AppText>
    </View>
    <StatusView {...state}/>
    <View style={styles.buttonBlock}>
      {/*<TouchableOpacity style={styles.bookingCardBtn}>
        <AppText style={styles.bookingCardBtnText}>{'Звонок'}</AppText>
      </TouchableOpacity>
      <TouchableOpacity style={styles.bookingCardBtn}>
        <AppText style={styles.bookingCardBtnText}>{'Чат'}</AppText>
      </TouchableOpacity>*/}
      {/*<TouchableOpacity style={styles.bookingCardBtn}>
        <AppText style={styles.bookingCardBtnText}>{'Оставить отзыв'}</AppText>
      </TouchableOpacity>*/}
      {needPayment
        ? <TouchableOpacity
          onPress={handlePay}
          style={[styles.bookingCardBtn, {backgroundColor: colors.default.main}]}
        >
          <AppText style={[styles.bookingCardBtnText, {color: '#fff'}]}>{'Оплатить'}</AppText>
        </TouchableOpacity>
        : null}
    </View>
  </View>;
}, (p, n) => {
  return JSON.stringify(p) === JSON.stringify(n);
});

const Bookings = () => {
  const [showLoadEarlier, setShowLoadEarlier] = useState(false);
  const [bookings, setBookings] = useState({});
  const [tempBookings, setTempBookings] = useState({});
  const [bookingDateFrom, setBookingDateFrom] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [actionData, setActionData] = useState({});
  const [loadingState, setLoadingState] = useState('loading');
  const [recallShow, setRecallShow] = useState(false);
  const [bottomMenuContent, setBottomPopupMenuContent] = useState();
  const [lastPage, setLastPage] = useState(0);
  const loadingOverlay = useRef();
  const bottomMenuRef = useRef();
  const bookingRef = useRef();
  const confirmPhoneRef = useRef();
  const listScrollTmr = useRef();
  const toast = useToast();
  const route = useRoute();

  const dispatch = useDispatch();
  const _appEvent = useSelector(appEvent.select);

  /*const {changeBookmarks, getAuthState, appEvent, newAppEvent, bookmarks} =
    useGlobalStore();*/

  const groupBookings = items => {
    let frmt = 'DD.MM.YYYY';
    let grouped = {};
    for (let i in items) {
      let item = items[i];
      let from = moment(item.dateFrom || item.date);
      let fromTime = from.format('HH:mm');
      let to = moment(item.dateFrom || item.date);
      let toTime = to.format('HH:mm');
      let date = from.clone();
      let groupName = date.isBefore(moment()) ? 'Завершенные' : 'Активные';
      if (!grouped[groupName]) {
        grouped[groupName] = {
          date: date,
          title: groupName,
          data: [],
        };
      }
      grouped[groupName].data.push({...item, timeRange: [fromTime, toTime]});
      //console.log('while doing');
    }
    for (let group in grouped){
      grouped[group].data = grouped[group].data.sort(
        (f, s) =>
          (moment(s.dateFrom).valueOf() + moment(s.date).valueOf()) -
          (moment(f.dateFrom).valueOf() + moment(f.date).valueOf())
      );
    }
    return Object.values(grouped).sort((f, s) => s.title > f.title ? -1 : 1);
  };

  const onRefresh = () => {
    setLoadingState('loading');
    //getAuthState().then(state => state === 'auth' && loadBookings());
  };

  const loadNextPart = async () =>
  {
    let limit = 10;
    let request = {page:lastPage + 1, limit, order: 'date_desc'};

    try {
      let res = await api.bookings.list(request);
      if (lastPage === 0 && res.data.length === 0) {
        setLoadingState('empty');
      }
      if (res.data.length > 0){
        let preparedBookings = {};
        for (let i in res.data){
          let booking = res.data[i];
          preparedBookings[booking.id] = {
            ...booking,
            offer: offerAdapter({
              ...booking.offer,
              booking:{
                id: booking.id,
                dateFrom: booking.dateFrom,
                dateTo: booking.dateTo,
                status: booking.status,
              },
            }),
          };
        }
        setBookings(v => ({
          ...v,
          ...preparedBookings,
        }));
        setLastPage(v => v + 1);
        setLoadingState('loaded');
      } else {
        setLoadingState('loaded');
      }
    } catch (e) {
      if (!api.isAbort(e)){
        __DEV__ && console.warn(e);
        toast.show(stringDecoder(e), {
          title: 'Невозможно загрузить данные',
          type: 'danger',
        });
      }
      setLoadingState('loaded');
    }

    //setShowLoadEarlier(true);
  };

  useEffect(() => {
    /*setShowLoadEarlier(false);
    setBookings({});*/
    initTempBookings();
    dispatch(auth.getState()).unwrap().then(
      _auth => {
        if (_auth.state === 'auth'){
          setLoadingState('loading');
          loadNextPart();
        }
      },
    );
  }, []);

  useEffect(() => {
    __DEV__ && console.log('handle app event');
    let {name, params} = _appEvent;
    if (name === 'addBooking'){
      api.bookings
        .list({
          id: params.item.id,
          order: 'desc',
          limit: 1,
        })
        .then(res => {
          let booking = res.data[0];
          __DEV__ && console.log({_appEvent, booking});
          addBooking(booking);
        })
        .catch((e) => {
          __DEV__ && console.warn(e);
        });
    }
  }, [_appEvent]);

  const getBookingData = ({booking_id}) => {
    if (!booking_id || !isObject(bookings) || !bookings[booking_id]) {return {};}
    let booking = bookings[booking_id];
    //console.log('getBooking: ',booking);
    return booking;
  };

  const kickLoading = () => {
    if (loadingState === 'empty') {return;}
    if (listScrollTmr.current) {
      clearTimeout(listScrollTmr.current);
    }
    //setScrolling(true);
    listScrollTmr.current = setTimeout(() => {
      //setScrolling(false);
      setLoadingState('loading');
      __DEV__ && console.log('LOAD NEXT PART FIRE');
      loadNextPart();
    }, 200);
  };

  const handleListEnd = props => {
    /** OLD
     if (props && props.distanceFromEnd === 0) {
      return;
    }*/
    __DEV__ && console.log('LIST END REACHED', props);
    kickLoading();
  };

  const initTempBookings = () => {
    tempBookingsGet().then(tmp_bookings => {
      //console.log({tmp_bookings});
      setBookings(v => {
        for (let i in tmp_bookings) {
          let tmp_booking = tmp_bookings[i];
          v[i] = {
            ...tmp_booking,
            dateFrom: tmp_booking.date_from,
            dateTo: tmp_booking.date_to,
            status: 'tmp',
            fullPrice: calcOfferFinalPrice(tmp_booking.offer),
            offer: {
              ...tmp_booking.offer,
              booking: {
                id: tmp_booking.id,
                dateFrom: tmp_booking.date_from,
                dateTo: tmp_booking.date_to,
                status: 'tmp',
              },
            },
          };
        }
        return {...v};
      });
      for (let i in tmp_bookings){
        api.bookings.list({limit:1, page:1, id:i}).then(res=>{
          if (res.data.length > 0 && res.data[0].id === i) {
            tempBookingRemove({id: i});
          }
        });
      }
    });
  };

  const addBooking = (booking) => {
    if (!booking?.id) {return;}
    setBookings(v => ({
      ...v,
      [booking.id]: {
        ...booking,
        offer: offerAdapter({
          ...booking.offer,
          booking:{
            id: booking.id,
            dateFrom: booking.dateFrom,
            dateTo: booking.dateTo,
            status: booking.status,
          },
        }),
      },
    }));
  };

  const loadBookings = async props => {
    let page = props.page || lastPage + 1;
    //console.log('LOAD BOOKINGS PAGE:', page, props.page);

    let limit = 10;
    let request = {page, limit};
    props.dateFrom && (request.dateFrom = props.dateFrom);
    props.dateTo && (request.dateTo = props.dateTo);
    props.order && (request.order = props.order);

    try {
      let res = await api.bookings.list(request);
      if (res.data.length === 0) {
        return setShowLoadEarlier(false);
      }
      res.data.forEach(booking => {
        addBooking(booking);
      });
      setLastPage(page);
      setLoadingState('loaded');
    } catch (e) {
      toast.show(stringDecoder(e), {
        title: 'Невозможно загрузить данные',
        type: 'danger',
      });
      setLoadingState('loaded');
    }

    setShowLoadEarlier(true);
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        {/*<PageHeader title={'Мои заказы'}/>*/}
        {loadingState === 'empty' ? (
          <EmptyPage icon={{name: 'calendar', type: 'feather'}}>
            <AppText>
              Здесь появятся{' '}
              <AppText style={{fontWeight: '600'}}>забронированные{'\n'}</AppText>
              Вами мероприятия и услуги!
            </AppText>
          </EmptyPage>
        ) : null}
          <SectionList
            stickySectionHeadersEnabled={true}
            ListHeaderComponent={() => (
              <>
                {loadingState !== 'loading' && showLoadEarlier ? (
                  <AppButton
                    titleStyle={{padding: 0, paddingHorizontal: 10}}
                    style={{marginBottom: 10}}
                    title={'Загрузить ранние'}
                    onPress={() => {
                      /*let dateFrom = moment(bookingDateFrom)
                      .add(-5, 'day')
                      .format('YYYY-MM-DD');*/
                      setLoadingState('loading');
                      loadBookings({order: 'date_desc'}).then(() => {
                        setLoadingState('loaded');
                      });
                    }}
                  />
                ) : null}
              </>
            )}
            style={{backgroundColor: 'white'}}
            sections={groupBookings(bookings)}
            keyExtractor={(item, index) => item + index}
            renderItem={({item:booking}) => {
              return <BookingCard {...booking}/>;
            }}
            renderSectionHeader={({section: {title}}) => (
              <View style={styles.headerBox}>
                <AppText style={styles.headerText}>{title}</AppText>
              </View>
            )}
            renderSectionFooter={({section})=><View style={styles.sectionFooter}/>}
            onEndReachedThreshold={32}
            onEndReached={handleListEnd}
            ListFooterComponent={<ListFooter loading={loadingState === 'loading'} size={'large'} />}
          />
          <PopupMenu
            ref={bottomMenuRef}
            popupContent={bottomMenuContent}
            popupOpen={false}
          />
          {/*<ReviewBottomSheet ref={bottomReviewRef} popupOpen={false}>
          {bottomReviewContent}
        </ReviewBottomSheet>*/}
      </SafeAreaView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    ...defaultStyles.webScrollView,
    //...defaultStyles.fullScreenHeight,
    backgroundColor: 'white',
  },

  //booking card
  bookingBox : {
    ...defaultStyles.cardShadow,
    backgroundColor: '#fff',
    margin: 12,
    marginBottom: 22,
    marginTop: 0,
    padding: 12,
    borderRadius: 8,
  },
  titleBlock : {
    flexDirection: 'row',
  },
  title : {
    flex: 0.7,
    alignSelf: 'flex-start',
    fontSize: 16,
    lineHeight: 25,
    fontWeight: '300',
    marginBottom: 4,
  },
  bookingDate : {
    flex: 0.3,
    alignSelf: 'flex-start',
  },
  bookingDateText : {
    textAlign: 'right',
    color: 'rgba(167, 167, 167, 1)',
  },
  price : {
    paddingBottom: 8,
    marginBottom: 8,
    borderBottomColor: 'rgba(247, 247, 247, 1)',
    borderBottomWidth: 1,
  },
  priceText : {
    fontSize: 16,
    fontWeight: '500',
    color: '#FDB161',
  },
  geoBox : {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  geoIcon : {
    marginRight: 8,
  },
  geoText : {
    fontSize: 14,
    fontWeight: '400',
  },
  bookingDetails : {
    flexDirection: 'row',
    marginBottom: 8,
    alignItems: 'center',
  },
  bookingDetailsText : {
    color: 'rgba(167, 167, 167, 1)',
  },
  detailsDot: {
    marginHorizontal: 6,
    fontSize: 18,
    lineHeight: 20,
  },
  buttonBlock : {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  bookingCardBtn : {
    marginRight: 8,
    paddingHorizontal: 14,
    paddingVertical: 9,
    backgroundColor: 'rgba(246, 246, 246, 1)',
    borderRadius: 6,
  },
  bookingCardBtnText: {
    fontSize: 14,
    color: 'rgba(90, 90, 90, 1)',
  },

  //section header
  headerBox: {
    paddingVertical: 16,
    paddingLeft: 12,
    backgroundColor: '#fff',
  },
  headerText: {
    fontSize: 22,
    fontWeight: '500',
  },
  sectionFooter: {
    marginTop: 26,
    backgroundColor: '#ececec',
    height: 8,
    width: '100%',
  },
});

export {bookingStates};

export default Bookings;
