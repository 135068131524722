import {api} from '../System/api';
import defaultImage from '../../img/mr_view_blur.jpg';
import settings from '../Defaults/settings';

export default function storyAdapter({name,thumb,storyFiles, ...props}) {
  return  {
    ...props,
    title: name,
    ...generateImagesUri(thumb, 'miniature'),
    images: storyFiles
      ? storyFiles.map(v=>({uri: api.baseurl + v?.name}))
      : [],
  };
}

const generateImagesUri = (image, name= 'image') => {
  let mainUri = api.baseurl + (image || '');
  let uri = mainUri.replace(/(.*?)(\.[^.]*?$)/,'$1');
  let extension = mainUri.replace(/(.*?)(\.[^.]*?$)/,'$2');
  let images = {
    [name]: mainUri ? {uri: mainUri} : defaultImage,
    [name + 'Md']: mainUri ? {uri: uri + '.med' + extension} : defaultImage,
    [name + 'Sm']: mainUri ? {uri: uri + '.min' + extension} : defaultImage,
  };
  if (!settings?.enableUseCompressedImages){
    images[name + 'Md'] = images[name];
    images[name + 'Sm'] = images[name];
  }
  return images;
};
