import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  SafeAreaView,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import {Icon} from '@rneui/base';
import AppText from '../Basic/AppText';
import {humanizePrice} from '../Cards/CardPrice';
import moment from 'moment/moment';
import {colors, defaultStyles} from '../Defaults/Styles';
import {api} from '../System/api';
import AntiMenuFade from '../Basic/AntiMenuFade';
import PageHeader from '../Basic/PageHeader';
import {sortObjectByKeys} from '../System/helpers';
import SimpleBottomSheet from '../BottomSheets/SimpleBottomSheet';
import {CardDescription} from '../Cards/CardElements';

let startDate = '2023-01-01 12:34:56';

const CardGen = (id) => {
  let paid = Math.random() * 10000;
  return {
    id,
    title: 'Аутлет ' + (id * 1000),
    bonusPaid: Math.random() * 100,
    paid,
    date: moment(startDate).add(id, 'day').format('YYYY-MM-DD HH:mm:ss'),
    bonusReceived: paid / 5,
  };
};

export const dataAdapter = (item) => {
  let bonus = (item?.accounts || [])
    .reduce((a,v)=>({
      bonusPaid: a.bonusPaid + (v?.code === 'BONUS' && v?.amount < 0 ? v?.amount : 0),
      bonusReceived: a.bonusReceived + (v?.code === 'BONUS' && v?.amount > 0 ? v?.amount : 0),
      statisticReceived: a.bonusReceived + (v?.code === 'STAT' && v?.amount > 0 ? v?.amount : 0),
    }),{bonusPaid: 0, bonusReceived: 0, statisticReceived:0});

  return ({
    id: item?.id + (item?.outlet?.name || ''),
    title: item?.outlet?.name || ('Оплата ' + item?.id),
    bonusPaid: bonus.bonusPaid,
    bonusReceived: bonus.bonusReceived,
    statisticReceived: bonus.statisticReceived,
    paid: item?.total || 0,
    discount: item?.discount || 0,
    date: moment(item?.date).format('YYYY-MM-DD HH:mm:ss'),
    check: item?.image || 'чек не найден',
  });
};

const Card = ({id, title, date, bonusPaid, bonusReceived, paid, onPress}) => {
  return (
    <TouchableOpacity
      style={styles.cardBox}
      onPress={()=>onPress(id)}
    >
      <View style={styles.cardImageBox}>
        <Icon
          style={styles.cardImage}
          name={'payment'}
          type={'material-icons'}
          color={colors.default.itemDark}
        />
      </View>
      <View style={styles.cardDetailsBox}>
        <View style={styles.cardTitleBox}>
          <AppText style={styles.cardTitleText}>
            {title}
          </AppText>
        </View>
        <View style={styles.cardPaymentDate}>
          <AppText style={styles.cardPaymentDateText}>
            {moment(date).format('DD.MM.YYYY HH:mm')}
          </AppText>
        </View>
        <View style={styles.cardPaymentSum}>
          <AppText style={styles.cardPaymentSumText}>
            {humanizePrice({price: paid})}
          </AppText>
        </View>
      </View>
      <View style={styles.cardBonusBox}>
        {bonusPaid || bonusReceived ?
          <AppText style={styles.bonusTitle}>
            {'Бонусы'}
          </AppText> : null
        }
        {bonusPaid ?
          <View style={styles.bonusMinusBox}>
            <AppText style={styles.bonusMinusText}>
              {Math.floor(bonusPaid)}{'B'}
            </AppText>
          </View> : null
        }
        {bonusReceived ?
          <View style={styles.bonusAddBox}>
            <AppText style={styles.bonusAddText}>
              {'+'}{Math.floor(bonusReceived)}{'B'}
            </AppText>
          </View> : null
        }
      </View>
    </TouchableOpacity>
  );
};

const pause = (sec) => new Promise(resolve => {
  setTimeout(resolve, sec * 1000);
});

export const BonusTransactionsList = () => {
  const [data, setData] = useState({});
  const [loadingState, setLoadingState] = useState('loading');
  const [checkImage, setCheckImage] = useState('');
  const id = useRef();
  const pageRef = useRef(1);
  const sheetRef = useRef(1);


  useEffect(()=>{
    loadPayments();
    return () => {
      api.pending.abort(id.current);
    };
  }, []);

  /*const loadPaymentsSynth = async () => {
    id.current = id?.current || 1;
    setLoadingState('loading');
    await pause(2);
    let items = Array(15).fill(1).map((v,i) => CardGen(i + id.current));
    id.current = data.count + id.current;
    setData(v=>({...v, ...items}));
    setLoadingState('loaded');
  };*/

  const loadPayments = async () => {
    setLoadingState('loading');
    api
      .bookings
      .setId(ind => id.current = ind)
      .transactions({page: pageRef.current || 1, limit: 15}).then(res=>{
      //__DEV__ && console.log({b:'transactions',res});
      let items = {};
      if (res?.data?.success && res?.data?.success?.length > 0){
        for (let i in res?.data?.success){
          let item = res?.data?.success[i];
          item = dataAdapter(item);
          items[item.id] = item;
        }
        pageRef.current = pageRef.current += 1;
        setData(v=>({...v, ...items}));
        setLoadingState('loaded');
      } else {
        setLoadingState('empty');
      }
    }).catch(er=>{
      __DEV__ && console.warn(er);
      setLoadingState('empty');
    });
  };


  const handlePress = (id) => {
    let item = Object.values(data).find(v=>v.id === id);
    if (!item) {return;}
    setCheckImage(item.check);
    sheetRef.current.open();
  };

  const handleEndReached = () => {
    if (loadingState !== 'loading' ){
      loadPayments();
    }
  };

  return (
    <View style={styles.container}>
      <FlatList
        style={styles.itemsList}
        data={Object.values(sortObjectByKeys(data,[{date:'desc'}]))}
        renderItem={({item})=><Card {...item} onPress={handlePress}/>}
        keyExtractor={(item, index)=>item.id || 0}
        viewabilityConfig={{
          viewAreaCoveragePercentThreshold: 20,
        }}
        disableVirtualization={Platform.OS === 'web'}
        legacyImplementation={Platform.OS === 'web'}
        onEndReached={handleEndReached || (()=>{})}
        scrollEventThrottle={32}
        ListFooterComponent={()=>(
          <AntiMenuFade style={styles.loader} height={250}>
            {loadingState === 'loading' ? <ActivityIndicator size={'large'}/> : null}
            {!Object.keys(data).length && loadingState === 'empty' ?
              <View style={styles.notFoundBox}>
                <AppText style={styles.notFoundText}>
                  {'Операции не найдены'}
                </AppText>
              </View>
              : null
            }
          </AntiMenuFade>
        )
        }
      />
      <SimpleBottomSheet ref={sheetRef}>
        <ScrollView>
          <View style={styles.checkContainer}>
            <CardDescription html={'<pre>' + checkImage + '</pre>'}/>
          </View>
        </ScrollView>
      </SimpleBottomSheet>
    </View>
  );
};

const BonusTransactions = () => {
  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <PageHeader title={'Списания и зачисления бонусов'}/>
      <View style={{flex:1, backgroundColor: '#fff'}}>
        <BonusTransactionsList />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  itemsList : {
    ...defaultStyles.webScrollView,
  },
  loader: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  //card styles
  cardBox: {
    flexDirection: 'row',
    //marginBottom: 10,
    alignItems: 'stretch',
    //alignContent: 'stretch',
    padding: 12,
    ...defaultStyles.borderSeparator,
  },
  cardImageBox: {
    width: 52,
    height: 52,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.default.item,
    //backgroundColor: '#dedede',
  },
  cardImage: {

  },
  cardDetailsBox: {
    marginLeft: 8,
    flexGrow: 1,
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  cardTitleBox: {},
  cardTitleText: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 16,
  },
  cardPaymentDate: {

  },
  cardPaymentDateText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'rgb(51, 51, 51)',
    opacity: 0.5,
  },
  cardPaymentSum: {

  },
  cardPaymentSumText: {
    fontSize: 13,
    lineHeight: 16,
    fontWeight: '500',
    color: colors.default.main,
  },
  cardBonusBox: {
    width: 80,
  },

  //
  bonusMinusBox: {
    borderRadius: 10,
    padding: 2,
  },
  bonusMinusText: {
    color: colors.default.bonus,
    fontWeight: '500',
    lineHeight: 16,
  },
  bonusAddBox: {
    padding: 2,
    borderRadius: 10,
  },
  bonusAddText: {
    color: '#28c00b',
    fontWeight: '500',
    lineHeight: 16,
  },
  bonusTitle: {
    color: '#9b9b9b',
    fontSize: 12,
    lineHeight: 16,
  },

  //check text
  checkContainer:{
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkText:{
    textAlign: 'justify',
  },

  //not found
  notFoundText: {
    fontSize: 16,
  },
});

export default BonusTransactions;
