import PhoneOverlay from './PhoneOverlay';
import {Modal} from 'react-native';
import React from 'react';

const ConfirmPhone = ({
  onConfirm,
  closeOnConfirm,
  onCancel,
  onClose,
  onChangeData,
  show,
  phone,
  name,
}) => {
  return (
    <Modal visible={show} transparent={true} animationType={'slide'}>
      <PhoneOverlay
        onPressClose={() => onClose()}
        onPressSend={() => {
          closeOnConfirm !== false && onClose();
          onConfirm();
        }}
        phoneNumber={phone}
        onChangePhoneNumber={v => onChangeData({phone: '7' + v})}
        name={name}
        onChangeName={v => onChangeData({name: v})}
      />
    </Modal>
  );
};

export default ConfirmPhone;
