import {createDataStoreSlice} from '../../System/DataStoreRedux';
import {accommodationStoreParams} from '../../User/StoreParams';

const accommodationSlice = createDataStoreSlice(
  accommodationStoreParams
);

export const {resetValue, setValue} = accommodationSlice.storeSlice.actions;
export const {getValue, reloadValue, init, select, selectState, selectValue} = accommodationSlice;

export default accommodationSlice.storeSlice.reducer;
