import React, {useState, useEffect, useContext, forwardRef, memo} from 'react';
import CalendarPicker from 'react-native-calendar-picker';
import {Icon} from '@rneui/themed';
import moment from 'moment-timezone';
import {useSelector} from 'react-redux';

const weekdays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const monthChoiser = 'Выберите месяц ';
const yearChoiser = 'Выберите год ';

const CalendarArrow = ({direction}) => {
  return (
    <Icon size={15} type={'simple-line-icon'} name={'arrow-' + direction} />
  );
};


const Calendar = (props, calendarRef) => {
  const {value, onChange, allowRangeSelection, dateHeight} = props;
  //const calendarRef = useRef();
  const [state, setState] = useState({
    selectedStartDate: value[0],
    selectedEndDate: allowRangeSelection ? value[1] : undefined,
  });
  const [keyState, setKeyState] = useState(1);
  const [minDate, setMinDate] = useState(moment().format('YYYY-MM-DD'));
  const [maxDate, setMaxDate] = useState(moment().add(2, 'weeks').format('YYYY-MM-DD'));
  const [disabledDates, setDisabledDates] = useState();

  const {defaultColors} = useSelector((state) => state.style);

  const onDateChange = (date, type) => {
    //console.log('real date', date, type);
    let dt;
    //console.log('ch type:', type, date);
    if (type === 'END_DATE') {
      dt = {
        ...state,
        selectedEndDate: allowRangeSelection ? date : null,
      };
    } else {
      dt = {
        ...state,
        selectedStartDate: date,
      };
    }
    setState(dt);

    onChange([
      dt.selectedStartDate ? moment(dt.selectedStartDate)?.format('YYYY-MM-DD') : null,
      dt.selectedEndDate ? moment(dt.selectedEndDate).format('YYYY-MM-DD') : null,
    ]);
  };

  useEffect(() => {
    setKeyState(v=> v + 1);
  }, [props.maxDate, props.minDate, props.value]);

  useEffect(()=>{
    __DEV__ && console.log('calendar changed curr date:', value);
    setState({
      selectedStartDate: value[0],
      selectedEndDate: allowRangeSelection ? value[1] : null,
    });
  }, [value]);


  return (
    <CalendarPicker
      key={keyState}
      //ref={calendarRef}
      //firstDay={1}
      selectedStartDate={!allowRangeSelection ? state.selectedStartDate : undefined}
      minDate={minDate}
      maxDate={maxDate}
      disabledDates={disabledDates}
      startFromMonday={true}
      showDayStragglers={false}
      allowRangeSelection={allowRangeSelection}
      allowBackwardRangeSelect={true}
      previousComponent={<CalendarArrow direction={'left'} />}
      nextComponent={<CalendarArrow direction={'right'} />}
      onDateChange={onDateChange}
      weekdays={weekdays}
      months={months}
      selectMonthTitle={monthChoiser}
      selectYearTitle={yearChoiser}
      headerWrapperStyle={{
        margin: 0,
        padding: 0,
        marginBottom: 0,
        paddingBottom: 0,
      }}
      customDayHeaderStyles={props => {
        return {
          textStyle: {
            color: '#52646BA0',
            fontSize: 18,
            fontWeight: '400',
          },
        };
      }}
      dayLabelsWrapper={{
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(0,0,0,0.1)',
      }}
      customDatesStyles={props => ({
        containerStyle: {height: dateHeight || 30},
        allowDisabled: true,
      })}
      monthTitleStyle={{
        fontSize: 24,
        fontWeight: '500',
      }}
      yearTitleStyle={{
        fontSize: 24,
        fontWeight: '500',
      }}
      selectedRangeStartStyle={{backgroundColor: defaultColors.main}}
      selectedRangeEndStyle={{backgroundColor: defaultColors.main}}
      selectedRangeStartTextStyle={{color: 'white'}}
      selectedRangeEndTextStyle={{color: 'white'}}
      selectedRangeStyle={{
        backgroundColor: /\#/.test(defaultColors.main)
          ? defaultColors.main + '80'
          : defaultColors.main,
      }}
      selectedDayStyle={{backgroundColor: defaultColors.main}}
      selectedDayTextStyle={{color: 'white'}}
      textStyle={{ fontSize: 18}}
      todayBackgroundColor={'#52646BA0'}
      //disabledDatesTextStyle={{color: 'red'}}
      /*customDatesStyles={(obj, obj2) => {
        console.log('DATE:', obj, obj2);
      }}*/
      {...props}
    />
  );
};

const FowardRefComponent = forwardRef(Calendar, {});

const MemoOfFowardRefComponent = memo(
  FowardRefComponent,
  (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
  },
);

export default MemoOfFowardRefComponent;
