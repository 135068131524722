import {api} from '../System/api';

export const loyaltyLevels = {
  name: 'loyaltyLevels',
  expires: 10, //10 MIN
  attempts: 4,
  loadOnInit: false,
  load:(state, attempt)=>{
    return new Promise((resolve, reject) => {
      api.loyalty.levels({page: 1}).then(res => {
        //console.log('loyalty_data:', res);
        //setState('loaded');
        let data = res.data.reduce((a,v) => ({
          ...a, [v.level]:v,
        }),{});
        resolve({
          ...data,
        });
      }).catch(reject);
    });
  },
  //onError,
  //onExpired,
};
