import React, {useCallback, useEffect, useImperativeHandle, useRef, useState, forwardRef} from 'react';
import {
  View,
  StyleSheet, ActivityIndicator, Platform, FlatList,
} from 'react-native';
//import {checkTariffs} from '../Validators/Tariffs';
import SimpleBottomSheet from './SimpleBottomSheet';
//import AppText from '../Basic/AppText';
//import humanize from 'humanize-number';
//import AppButton from '../Basic/AppButton';
import OfferDetails from '../Offers/OfferDetails';
//import {metricEvent} from '../Metrics/MetricHelper';
import Animated from 'react-native-reanimated';
import {BottomSheetFlatList} from '@gorhom/bottom-sheet';
//import cardAdapter from '../Cards/CardAdapter';
import {useNavigation, useRoute} from '@react-navigation/native';

const ScrollComponent = Animated.createAnimatedComponent(Platform.OS === 'web' ? FlatList : BottomSheetFlatList);

const OfferDetailsBottomSheet = forwardRef((props, ref) =>
{
  const {onAction, getOffer, updateOffer} = props;
  const cardRef = useRef();
  const sheetRef = useRef();
  const [offerId, setOfferId] = useState();
  const [dataReady, setDataReady] = useState(false);
  const [data, setData] = useState();

  const navigation = useNavigation();

  useImperativeHandle(ref,
    ()=>({
      ...sheetRef.current,
      openWithOffer: (offer) => {
        sheetRef.current?.open();
        setOfferId(offer.id);
      },
    }),
    [],
  );

  useEffect(() => {
    if (!offerId) {return;}
    setDataReady(false);
    getOffer({id:offerId}).then(res => {
      setData(res);
      setDataReady(true);
    });
  },[offerId]);

  const handleUpdateOffer = (offer) => {
    setData(v=>({...v, ...offer}));
    updateOffer(offer);
  };

  const handleCalendarSheetChanges = (index) => {
    if (index === 0) {
      //metricEvent('offer_details_open');
    }
  };

  return (
    <SimpleBottomSheet
      ref={sheetRef}
      index={0}
      lastPoint={'95%'}
      onAnimate={(a,b)=>{
        if (!dataReady) {return;}
        if (b > a){
          cardRef?.current?.playMutedVideo();
        } else {
          //cardRef?.current?.pauseVideo();
        }
      }}
      onChange={handleCalendarSheetChanges}
      itemNavParam={{name:'offer_id', value: offerId}}
    >
      <View style={styles.cardDetailsList}>
        {dataReady
          ? <OfferDetails
              ref={cardRef}
              {...data}
              updateOffer={handleUpdateOffer}
              onAction={onAction}
              roundTop={true}
              ScrollComponent={ScrollComponent}
          />
          : <View style={styles.detailsLoader}>
            <ActivityIndicator size={'large'}/>
          </View>}
      </View>
    </SimpleBottomSheet>
  );
});

const styles = StyleSheet.create({
  //Card Details
  cardDetailsList: {
    flex: 4,
  },
  detailsLoader: {
    flex:1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  continueContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.41,
    backgroundColor: 'white',
  },
  mainContainer:{
    paddingHorizontal: 15,
    flex: 5,
  },
  totalPriceContainer:{
    flex: 0.4,
    alignSelf: 'flex-end',
    paddingRight: 40,
  },
  totalPriceText:{

    fontSize: 18,
    color: '#777676',
  },
});

export default OfferDetailsBottomSheet;
