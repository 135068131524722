import React from 'react';
import {StatusBar} from 'react-native';
import {useSelector} from 'react-redux';


const StatusBarController = ({children}) => {
  //const [style, setStyle] = useState({});

  /*useEffect(() => {
    console.log({style});
  }, [style]);*/
  const style = useSelector((state) => state.statusBar);

  return <>
    <StatusBar
      //backgroundColor={'#fff'}
      //barStyle={'dark-content'}
      //hidden={false}
      {...style}
      showHideTransition={'fade'}
      animated={true}
    />
    {children}
  </>;
};

export default StatusBarController;
