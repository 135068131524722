import React, {useContext, useRef, useState} from 'react';
import {View, SafeAreaView, TextInput, StyleSheet} from 'react-native';
import PageHeader, {useGoBack} from '../Basic/PageHeader';
import {Icon} from '@rneui/themed';
import AppText from '../Basic/AppText';
import {useSelector} from 'react-redux';
import {defaultStyles} from '../Defaults/Styles';
import AppButton from '../Basic/AppButton';
import {api} from '../System/api';
import {useNavigation} from '@react-navigation/native';
import {encode} from 'js-base64';
import AppInput from '../Basic/AppInput';
import LoadingContainer from '../Basic/LoadingContainer';
import {useToast} from 'react-native-toast-notifications';

const Form = props => {
  const navigation = useNavigation();
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleDeposit = () =>
  {
    let _amount = parseInt(amount,10);
    if (isNaN(_amount) || _amount <= 0) {return;}
    setLoading(true);
    api.deposit.topup(_amount).then(res => {
      setLoading(false);
      navigation.navigate({
        name: 'Payment',
        params: {
          booking_id: res.data.result.id,
          link: encode(res.data.result.link),
        },
      });
    }).catch(e => {
      toast.show('Ошибка при пополнении депозита, пожалуйста, попробуйте немного позднее',{duration: 10000});
      setLoading(false);
    });
  };

  return (
    <SafeAreaView style={{...defaultStyles.fullScreenHeight, backgroundColor: 'white'}}>
      <PageHeader title={'Пополнение депозита'}/>
      <View style={{marginTop: '5%'}}>
        <AppText
          style={{
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 18,
            lineHeight: 26,
          }}>
        </AppText>
        <AppInput
          style={styles.input}
          placeholder="Введите необходимую Вам сумму ₽"
          keyboardType="numeric"
          onChange={val=>setAmount(val.replace(/[^0-9]/g,''))}
          value={amount}
          autoFocus={true}
        />
      </View>
      <LoadingContainer loading={loading} style={styles.loadingContainer}>
        <AppButton  title={'Пополнить'} style={styles.button} onPress={handleDeposit}/>
      </LoadingContainer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  input: {
    margin: 12,
  },
  continueContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.41,
    backgroundColor: 'white',
  },
  button: {
    width: '65%',
    marginVertical: 10,
  },
});

export default Form;
