import React from 'react';
import moment from 'moment';
import AppText from '../Basic/AppText';

const DateRangeView = ({value, style}) => {
  let months = [
    '',
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ];
  let dateStart = moment(value[0], 'YYYY-MM-DD');
  let dateEnd = moment(value[1], 'YYYY-MM-DD');

  let monthStart = months[dateStart.format('M')];
  let dayStart = dateStart.format('D');
  let weekdayStart = dateStart.format('dddd');

  let monthEnd, dayEnd, weekdayEnd;

  if (value.length > 1) {
    monthEnd = months[dateEnd.format('M')];
    dayEnd = dateEnd.format('D');
    weekdayEnd = dateEnd.format('dddd');
  }

  return (
    <AppText style={[{ fontSize: 17}, style]}>
      {value.length > 1 && !dateStart.isSame(dateEnd) && dateEnd.isValid()
        ? `С ${dayStart} ${
            monthStart !== monthEnd ? monthStart : ''
          } по ${dayEnd} ${monthEnd}`
        : dateStart.isValid()
        ? `${dayStart} ${monthStart}, ${weekdayStart}`
        : ''}
    </AppText>
  );
};

export default DateRangeView;
