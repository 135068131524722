import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {getHeaderTitle} from '@react-navigation/elements';
import {SafeAreaView} from 'react-native-safe-area-context';
//import {Avatar, Icon, Badge} from '@rneui/themed';
import HeaderAvatar from '../User/HeaderAvatar';
//import HeaderBell from '../Notifications/HeaderBell';
import {LoginButton} from '../Basic/BasicElements';
import AppText from '../Basic/AppText';
import {useSelector} from 'react-redux';
import * as auth from '../GlobalStore/reducers/authSlice';

const Header = ({navigation, route, options}) => {
  const title = getHeaderTitle(options, route.name);
  const [isGuest, setIsGuest] = useState(false);
  const authState = useSelector(auth.selectState);

  useEffect(() => {
    setIsGuest(authState !== 'auth');
  }, [authState]);

  if(options?.showHeader === false){
    return null;
  }

  return (
    <SafeAreaView
      style={{
        backgroundColor: 'white',
        flexDirection: 'row',
        padding: 6,
        paddingBottom: 10,
        flex: 1,
        alignContent: 'stretch',
      }}>
      <View
        style={{
          width: '60%',
          height: 40,
          paddingLeft: '4%',
          justifyContent: 'center',
        }}>
        <AppText
          style={{

            fontSize: 18,
            fontWeight: '500',
            textTransform: 'uppercase',
          }}>
          {title}
        </AppText>
      </View>
      <View
        style={{
          width: '40%',
          alignItems: 'center',
          flexDirection: 'row',
          height: 40,
          justifyContent: 'flex-end',
          paddingRight: '4%',
        }}>
        {/*<HeaderBell />*/}
        {isGuest ? <LoginButton /> : <HeaderAvatar />}
      </View>
    </SafeAreaView>
  );
};

export default Header;
